const constant = {
  REACT_APP_GOOGLE_CLIENT_ID: '972117868734-ggbg6g46066i6r4mbj3ie7h1vai8forr.apps.googleusercontent.com',
  API_BASE_DEV: 'https://api-dev-dot-manabadi-portal.wl.r.appspot.com/api/v1/',
  API_BASE_QA: 'https://api-qa-dot-manabadi-portal-451822.wl.r.appspot.com/api/v1/',
  API_BASE_STAGE: 'https://api-stage-dot-manabadi-portal.wl.r.appspot.com/api/v1/',
  API_BASE_PROD: 'https://api-prod-dot-manabadi-portal.wl.r.appspot.com/api/v1/',
  API_BASE_PROD_V2: 'https://api-prod-dot-manabadi-portal-451822.wl.r.appspot.com/api/v1/',
  REACT_APP_GOOGLE_REDIRECT_URI: 'https://oauth.pstmn.io/v1/browser-callback',
  REACT_APP_GOOGLE_DRIVE_SCOPE: 'https://www.googleapis.com/auth/drive.file',
  // REACT_APP_GOOGLE_DRIVE_SCOPE: 'https://www.googleapis.com/auth/drive.readonly',
  // REACT_APP_GOOGLE_SPREADSHEET_SCOPE: 'https://www.googleapis.com/auth/spreadsheets',
  REACT_APP_GOOGLE_DRIVE_BASE_URL: '',
};
export default constant;
