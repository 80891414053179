import React, { useEffect, useState } from 'react';
import * as Yup from 'yup';
import { Grid } from '@mui/material';
import FindInPageOutlinedIcon from '@mui/icons-material/FindInPageOutlined';
import { useFormik } from 'formik';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Button, Loader, MultiSelectAutoComplete } from '../../../components/atoms';
import { Buttons, NavigateRoutes } from '../../../constant';
import useStyles from '../../../custom-hooks/useStyles';
import styles from './style';
import commonStyle from '../../../utils/commonClasses';
import { addTeacher, getClassrooms } from '../../../store/actions/getLocationCoordinator';
import TextFieldAtom from '../../../components/atoms/text-field-with-label';

function DialogFooter({
  classes, setDialogOpen, t, onSubmit, formik, viewTeacherLogs, hasLogText,
}) {
  const commonClasses = useStyles(commonStyle)();
  return (
    <Grid container justifyContent="space-between">
      <Grid item xs={12} lg={7}>
        <Grid container spacing={2}>
          <Grid item xs={12} lg={6} className={classes.logText}>
            <TextFieldAtom
              id="logText"
              variant="outlined"
              label={t('CHANGE_LOG')}
              sx={{
                '@media (min-width: 1200px)': {
                  maxWidth: '100%',
                },
              }}
              placeholder="Log Text"
              required
              value={formik.values.logText}
              onChange={(val) => formik.setFieldValue('logText', val.target.value)}
            />
          </Grid>
          <Grid item xs={12} lg={4} className={classes.viewLogs}>
            <Grid container alignItems="center" spacing={1}>
              <Grid item>
                <FindInPageOutlinedIcon />
              </Grid>
              <Grid item onClick={() => viewTeacherLogs()}>
                <div>{t('VIEW_LOGS')}</div>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} lg={5} className={classes.dialogButtons}>
        <Grid container justifyContent="flex-end" spacing={2}>
          <Grid item>
            <Button
              name={t('CANCEL')}
              onClick={() => setDialogOpen(false)}
              btntype={Buttons.SECONDARY}
            />
          </Grid>
          <Grid item>
            <Button
              name={t('ADD_TEACHER')}
              onClick={onSubmit}
              btntype={Buttons.PRIMARY}
              className={commonClasses.activeButton}
              disabled={!hasLogText}
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}
export default function AddTeacher({
  viewLogs, setDialogOpen, addTeacherInfo,
  classroomListPayload,
}) {
  const { t } = useTranslation();
  const reduxStore = useSelector((state) => state);
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [cleanLog, setCleanLog] = useState(false);
  const [hasLogText, setHasLogText] = useState(false);

  const dispatch = useDispatch();
  const courseList = reduxStore?.getStudent?.courses;
  const classes = useStyles(styles)();
  const validationSchema = Yup.object({
  });
  const formik = useFormik({
    initialValues: {
      logText: '',
    },
    validationSchema,
    onSubmit: () => {
      const isPrimaryTeacherSelected = selectedOptions?.filter((op) => op.checked === true);
      if (isPrimaryTeacherSelected?.length) {
        setError(false);
        setLoading(true);
        const loadRefreshData = () => {
          setDialogOpen(false);
          setLoading(false);
        };
        const refreshList = () => {
          dispatch(getClassrooms(classroomListPayload, loadRefreshData));
        };
        const addTeacherData = selectedOptions?.map((i) => ({
          isPrimary: i?.checked,
          email: i?.email,
          userId: i?.key,
          googleClassId: addTeacherInfo?.googleClassId,
          classRoomId: addTeacherInfo?.classRoomId,
        }));
        const payload = addTeacherData.filter((x) => (x.email !== '' || x.userId !== ''));
        if (payload.length > 0) {
          const lastIndex = payload.length - 1;
          payload[lastIndex].logText = formik.values.logText;
        }

        dispatch(addTeacher(payload, refreshList, setCleanLog));
      } else {
        setError(t('TEACHER_REQUIRED'));
      }
    },
  });

  const viewTeacherLogs = () => {
    navigate(NavigateRoutes.STUDENTS_LOGS, {
      state: {
        id: addTeacherInfo?.googleClassId,
        lastRoute: NavigateRoutes.LC_CLASSES,
        logVal: false,
        isAcademicDates: false,
        isRegistrationDates: false,
        isAddTeacher: true,
      },
    });
  };

  useEffect(() => {
    if (cleanLog) {
      formik.setFieldValue('logText', '');
      setCleanLog(false);
    }
  }, [cleanLog]);

  useEffect(() => {
    if (formik.values.logText !== '' && formik.values.logText.length > 2) {
      setHasLogText(true);
    } else {
      setHasLogText(false);
    }
  }, [formik.values.logText]);

  const getErrorText = (key, errorText) => {
    if (formik.touched[key] && formik.errors[key]) {
      return (
        <span data-testid={key} className={classes.errorText}>
          {formik.errors[key]}
        </span>
      );
    } if (errorText) {
      return (
        <span className={classes.errorText}>
          {errorText}
        </span>
      );
    }
    return null;
  };

  useEffect(() => {
  }, [courseList, error]);
  return (
    <Grid container className={classes.gridContainer}>
      <form
        name="course-info"
        noValidate
        autoComplete="off"
        className={classes.form}
      >
        <Grid container spacing={2} className={classes.alignGrid} flexDirection="row">
          <Grid item xs={3} md={1.5} className={classes.label}>
            {t('COURSE')}
          </Grid>
          <Grid item xs={9} md={10.5} flexDirection="row">
            :
            <span className={classes.value}>
              {addTeacherInfo?.course}
              {` (${'Sec'} ${addTeacherInfo?.section})`}
            </span>
          </Grid>
          <Grid container spacing={2} className={classes.alignGrid} flexDirection="row">
            {getErrorText('course')}
          </Grid>
          <Grid container spacing={2} className={classes.alignGrid} flexDirection="row">
            <Grid item xs={3} md={1.5} className={classes.label}>
              {t('TEACHERS')}
            </Grid>
            <Grid item xs={9} md={8} flexDirection="row" className={classes.multiSelect}>
              :
              <MultiSelectAutoComplete
                data={addTeacherInfo}
                setSelectedOptions={setSelectedOptions}
                {...{
                  setSelectedOptions,
                  selectedOptions,
                  setError,
                  t,
                }}
              />
            </Grid>
          </Grid>
          <Grid container spacing={2} className={classes.alignGrid} flexDirection="row">
            {getErrorText({}, error)}
          </Grid>

        </Grid>
      </form>
      <DialogFooter
        classes={classes}
        setDialogOpen={setDialogOpen}
        viewLogs={viewLogs}
        onSubmit={formik.handleSubmit}
        t={t}
        formik={formik}
        viewTeacherLogs={viewTeacherLogs}
        hasLogText={hasLogText}
      />

      {loading && (
        <Grid>
          <Loader message={t('LOADING')} />
        </Grid>
      )}

    </Grid>
  );
}
