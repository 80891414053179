import { Grid } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { interpolateRgb } from 'd3';
import { useSelector } from 'react-redux';
import { colors } from '../../../../../theme';
import Map from './map';
import MapHeader from './mapHeader';

function MainMap({ data }) {
  const COLORS = [colors.torchRed, colors.purpleShade, colors.blackGreen, colors.seaGreen, colors.dullGreen, colors.purple, colors.vibrantGreen, colors.reddishMagenta, colors.borderGrey, colors.pinkShade, colors.skyBlue, colors.persianBlue, colors.darkBrown, colors.rosePink];
  const uniqueCountries = ['All', ...new Set(data?.map((i) => i?.country))];
  const [filterIndex, setFilterIndex] = useState(0);
  const [filterCountry, setFilterCountry] = useState('All');
  const [color, setColor] = useState('#005645');
  const statesUsAndCanada = [
    { name: 'Alabama', identifier: 'us-al' },
    { name: 'Alaska', identifier: 'us-ak' },
    { name: 'Arizona', identifier: 'us-az' },
    { name: 'Arkansas', identifier: 'us-ar' },
    { name: 'California', identifier: 'us-ca' },
    { name: 'Colorado', identifier: 'us-co' },
    { name: 'Connecticut', identifier: 'us-ct' },
    { name: 'Delaware', identifier: 'us-de' },
    { name: 'Florida', identifier: 'us-fl' },
    { name: 'Georgia', identifier: 'us-ga' },
    { name: 'Hawaii', identifier: 'us-hi' },
    { name: 'Idaho', identifier: 'us-id' },
    { name: 'Illinois', identifier: 'us-il' },
    { name: 'Indiana', identifier: 'us-in' },
    { name: 'Iowa', identifier: 'us-ia' },
    { name: 'Kansas', identifier: 'us-ks' },
    { name: 'Kentucky', identifier: 'us-ky' },
    { name: 'Louisiana', identifier: 'us-la' },
    { name: 'Maine', identifier: 'us-me' },
    { name: 'Maryland', identifier: 'us-md' },
    { name: 'Massachusetts', identifier: 'us-ma' },
    { name: 'Michigan', identifier: 'us-mi' },
    { name: 'Minnesota', identifier: 'us-mn' },
    { name: 'Mississippi', identifier: 'us-ms' },
    { name: 'Missouri', identifier: 'us-mo' },
    { name: 'Montana', identifier: 'us-mt' },
    { name: 'Nebraska', identifier: 'us-ne' },
    { name: 'Nevada', identifier: 'us-nv' },
    { name: 'New Hampshire', identifier: 'us-nh' },
    { name: 'New Jersey', identifier: 'us-nj' },
    { name: 'New Mexico', identifier: 'us-nm' },
    { name: 'New York', identifier: 'us-ny' },
    { name: 'North Carolina', identifier: 'us-nc' },
    { name: 'North Dakota', identifier: 'us-nd' },
    { name: 'Ohio', identifier: 'us-oh' },
    { name: 'Oklahoma', identifier: 'us-ok' },
    { name: 'Oregon', identifier: 'us-or' },
    { name: 'Pennsylvania', identifier: 'us-pa' },
    { name: 'Rhode Island', identifier: 'us-ri' },
    { name: 'South Carolina', identifier: 'us-sc' },
    { name: 'South Dakota', identifier: 'us-sd' },
    { name: 'Tennessee', identifier: 'us-tn' },
    { name: 'Texas', identifier: 'us-tx' },
    { name: 'Utah', identifier: 'us-ut' },
    { name: 'Vermont', identifier: 'us-vt' },
    { name: 'Virginia', identifier: 'us-va' },
    { name: 'Washington', identifier: 'us-wa' },
    { name: 'West Virginia', identifier: 'us-wv' },
    { name: 'Wisconsin', identifier: 'us-wi' },
    { name: 'Wyoming', identifier: 'us-wy' },
    { name: 'Alberta', identifier: 'ca-ab' },
    { name: 'British Columbia', identifier: 'ca-bc' },
    { name: 'Manitoba', identifier: 'ca-mb' },
    { name: 'New Brunswick', identifier: 'ca-nb' },
    { name: 'Newfoundland and Labrador', identifier: 'ca-nl' },
    { name: 'Nova Scotia', identifier: 'ca-ns' },
    { name: 'Ontario', identifier: 'ca-on' },
    { name: 'Prince Edward Island', identifier: 'ca-pe' },
    { name: 'Quebec', identifier: 'ca-qc' },
    { name: 'Saskatchewan', identifier: 'ca-sk' },
    { name: 'Northwest Territories', identifier: 'ca-nt' },
    { name: 'Nunavut', identifier: 'ca-nu' },
    { name: 'Yukon', identifier: 'ca-yt' },
  ];

  const selectedFilter = useSelector(
    (reduxState) => reduxState.getStudent.selectedFilter,
  );

  useEffect(() => {
    const countries = selectedFilter.filter((item) => item.filterKey === 'country');
    if (countries[0]?.label === 'USA') {
      setFilterCountry('United States');
      const index = uniqueCountries.findIndex((item) => item === 'USA');
      setFilterIndex(index);
    } else if (countries[0]?.label === 'Canada') {
      setFilterCountry('Canada');
      const index = uniqueCountries.findIndex((item) => item === 'Canada');
      setFilterIndex(index);
    } else {
      setFilterCountry('All');
      setFilterIndex(0);
    }
  }, [selectedFilter]);

  const getColor = (countryValue) => {
    const indexValue = uniqueCountries?.findIndex((country) => country === countryValue);
    const colorss = COLORS[indexValue] || '#005645';
    return colorss;
  };
  let formattedData = data?.reduce((obj, i) => {
    if (i?.country === 'USA') {
      i.country = 'United States';
    }
    const key = `${i.country}_${i.state}`;
    if (obj[key]) {
      obj[key].count += parseInt(i.value, 10);
    } else {
      obj[key] = {
        country: i?.country,
        state: i?.state,
        count: i?.value,
        coordinates: [i?.lat || 0, i?.lng || 0],
        color: getColor(i?.country),
      };
    }
    return obj;
  }, {});
  formattedData = Object.values(formattedData);
  const [coordinatesData, setCoordinatesData] = useState([]);

  useEffect(() => {
    const updatedData = [...formattedData];
    const dataAccToFilter = filterCountry === 'All'
      ? updatedData
      : updatedData?.filter((i) => i?.country === filterCountry);
    let colorss = '#005645';
    if (filterCountry !== 'All' && dataAccToFilter[0]?.color) {
      colorss = dataAccToFilter[0]?.color;
    }
    const maxCount = dataAccToFilter.reduce((max, item) => {
      return item.count > max ? item.count : max;
    }, 0);
    const newData = dataAccToFilter?.map((i) => {
      if (!i?.state) {
        return null;
      }

      const regionKey = statesUsAndCanada.find((state) => state.name === i.state)?.identifier;

      // intensity of color
      const intensity = i.count / maxCount;
      const interpolatedColor = interpolateRgb('#E6E7E8', colorss)(intensity);

      return {
        'hc-key': regionKey,
        value: i?.count,
        color: interpolatedColor,
      };
    }).filter((item) => !!item);
    setCoordinatesData(newData);
    setColor(colorss);
  }, [filterCountry, data]);
  return (
    <Grid container>
      <Grid item xs={12}>
        <Map {...{ coordinatesData, filterCountry, color }} />
      </Grid>
      <Grid item xs={12}>
        <MapHeader {...{
          setFilterIndex, setFilterCountry, uniqueCountries, filterIndex,
        }}
        />
      </Grid>

    </Grid>
  );
}
export default MainMap;
