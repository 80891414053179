/* eslint-disable */
import React, { useEffect, useState } from 'react';
import {
  Grid,
  Typography,
  Box,
  TextField,
} from '@mui/material';
import StarRateIcon from '@mui/icons-material/StarRate';
import DoNotDisturbIcon from '@mui/icons-material/DoNotDisturb';
import FindInPageOutlinedIcon from '@mui/icons-material/FindInPageOutlined';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import { DataGrid } from '@mui/x-data-grid';
import AddIcon from '@mui/icons-material/Add';
import { useSelector, useDispatch } from 'react-redux';
import { useFormik } from 'formik';
import makeStyles from '@mui/styles/makeStyles';
import { useNavigate } from 'react-router-dom';
import {
  setSelectedYear,
  setSelectedLocation,
  createSectionsByLocationYearCourse,
  getAllLocationCourse,
} from '../../../store/actions/getLocationCoordinator';
import MapPin from '../../../assets/images/map-pin.png';
import styles from './style';
import useStyles from '../../../custom-hooks/useStyles';
import Dropdown from '../../../components/atoms/dropdown';
import { DropdownHomeWork, Button } from '../../../components/atoms';
import { getCurrentYear, getFormattedDate } from '../../../utils/methods';
import {
  CustomUnsortedIcon,
  CustomAscendingIcon,
  CustomDescendingIcon,
} from '../../../utils/commonUiComponent';
import getClassroomsData from './data';
import ButtonAtom from '../../../components/atoms/button';
import TextFieldAtom from '../../../components/atoms/text-field-with-label';
import { Buttons, NavigateRoutes } from '../../../constant';
import Constant from '../../../store/constant/index';
import { getLocalStorage } from '../../../utils/localStorageMethod';
import MediaUploader from '../../../utils/uploadSheet';
import DialogAtomSheeet from './dialogSheet';

function CustomToolbar({
  fileHeaders,
  rowData,
  t,
  fileName,
  setLoading,
  setShowTokenAdvice,
  setOpenSheet,
  setOpenAddSection,
}) {
  const classes = useStyles(styles)();
  const saveCSV = () => {
    const payloadCreateSheet = {
      properties: {
        title: fileName,
      },
      sheets: [
        {
          properties: {
            title: `${fileName.split(' - ')[0]} - ${fileName.split(' - ')[1]}`,
          },
        },
      ],
    };

    const payloadPutSheet = [];

    const Cells = {
      updateCells: {
        rows: [],
        start: {
          rowIndex: 0,
          columnIndex: 0,
          sheetId: '',
        },
        fields: 'userEnteredValue',
      },
    };

    const headerValues = fileHeaders.map((header) => ({
      userEnteredValue: {
        stringValue: header.label,
      },
      userEnteredFormat: {
        textFormat: {
          bold: true,
        },
      },
    }));

    Cells.updateCells.rows = [
      {
        values: headerValues,
      },
    ];

    rowData.forEach((row) => {
      const newRowValues = {
        values: [],
      };

      fileHeaders.forEach((header) => {
        const value = row[header.key];

        if (typeof value === 'boolean') {
          newRowValues.values.push({
            userEnteredValue: {
              boolValue: value,
            },
          });
        } else if (typeof value === 'number') {
          newRowValues.values.push({
            userEnteredValue: {
              numberValue: value,
            },
          });
        } else {
          newRowValues.values.push({
            userEnteredValue: {
              stringValue: value || '',
            },
          });
        }
      });

      Cells.updateCells.rows.push(newRowValues);
    });

    payloadPutSheet.push({
      requests: [
        {
          appendDimension: {
            sheetId: '',
            dimension: 'COLUMNS',
            length: headerValues.length || 30,
          },
        },
        Cells,
      ],
    });

    setLoading(true);
    const gettoken = getLocalStorage('accessToken');
    const baseUrl = JSON.parse(gettoken);
    const uploader = new MediaUploader({
      token: baseUrl?.access_token,
      filename: fileName,
      contentType: 'application/vnd.google-apps.spreadsheet',
      metadata: payloadCreateSheet,
      sheetMood: 'New',
      file: payloadPutSheet,
      sheetId: '',
      onComplete: async () => {
        setOpenSheet(true);
      },
      onError: () => {
        setShowTokenAdvice(true);
        setLoading(false);
      },
    });
    uploader.upload();
  };
  return (
    <div>
      {rowData?.length ? (
        <Grid container>
          <Grid item>
            <ButtonAtom
              btntype={Buttons.PRIMARY}
              className={classes.secButton}
              name={t('ADD_SECTION')}
              onClick={() => { setOpenAddSection(true); }}
            />
          </Grid>
          <Grid item>
            <ButtonAtom
              btntype={Buttons.PRIMARY}
              className={classes.secButton}
              name={t('EXPORT_TO_SHEET')}
              onClick={() => saveCSV()}
            />
          </Grid>
        </Grid>
      ) : null}
    </div>
  );
}

function Filters({
  formik,
  classes,
  t,
  fileHeaders,
  rowData,
  years,
  locations,
  fileName,
  setLoading,
  setShowTokenAdvice,
  setOpenSheet,
  setOpenAddSection,
}) {
  const dispatch = useDispatch();
  const handleYearChange = (e) => {
    formik.setFieldValue('year', e.target.value);
    const payload = {
      id: e.target.value,
      name: e.target.value,
    };
    dispatch(setSelectedYear(payload));
  };
  const handleLocationChange = (e) => {
    formik.setFieldValue('location', e.target.value);
    dispatch(setSelectedLocation(e.target));
  };
  return (
    <Grid container className={classes.dropdowns}>
      <Grid item xs={3} lg={1.2} className={classes.year}>
        <Dropdown
          minWidth="100%"
          label={t('YEAR')}
          labelId={t('YEAR')}
          id="year"
          name="year"
          value={formik.values.year}
          handleChange={(e) => handleYearChange(e)}
          options={years}
          customClass={classes.year}
          variant="standard"
        />
      </Grid>
      <Grid item xs={5} lg={6.5} className={classes.locationDropdown}>
        <Dropdown
          minWidth="100%"
          label={t('LOCATION')}
          id="location"
          name="location"
          value={formik.values.location}
          handleChange={(e) => handleLocationChange(e)}
          options={locations}
          customClass={classes.locationDropdown}
          variant="standard"
          icon={<img src={MapPin} alt="" className={classes.mapPinImg} />}
        />
      </Grid>
      <Grid>
        <CustomToolbar
          rowData={rowData}
          fileHeaders={fileHeaders}
          t={t}
          fileName={fileName}
          setLoading={setLoading}
          setShowTokenAdvice={setShowTokenAdvice}
          setOpenSheet={setOpenSheet}
          setOpenAddSection={setOpenAddSection}
        />
      </Grid>
    </Grid>
  );
}

export default function ClassroomList({
  setAddTeacher,
  onAddTeacherClick,
  setLoading,
  loading,
  refreshList,
  setClassroomListPayload,
  setShowTokenAdvice,
  setOpenSheet,
}) {
  const classes = useStyles(styles)();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const displayPrimaryTeacher = (teacher) => (
    <div className={classes.teacherNames}>
      <div className={classes.starIcon}>
        <StarRateIcon />
      </div>
      <div className={classes.primaryTeacher}>
        {`${teacher?.first_name} ${teacher?.last_name}`}
      </div>
      <br />
    </div>
  );
  const displayNonPrimaryTeacher = (teacher) => (
    <span className={classes.nonPrimaryTeacher}>
      {`${teacher?.first_name} ${teacher?.last_name}`}
    </span>
  );
  const validationSchema = Yup.object({});

  const locationCoordinatorData = useSelector(
    (state) => state?.getLocationCoordinator,
  );

  const useStylesSheet = makeStyles(() => ({
    sectionBox: {
      width: '75vw !important',
      justifyContent: 'center !important',
      '&.MuiGrid-root.MuiGrid-item': {
        width: '75vw !important',
        textAlign: 'center',
      },
    },
    successBox: {
      width: '35vw !important',
      justifyContent: 'center !important',
      '&.MuiGrid-root.MuiGrid-item': {
        width: '35vw !important',
        textAlign: 'center',
      },
    },
  }));
  const classrooms = locationCoordinatorData?.classrooms;
  const assignedLocations = locationCoordinatorData?.assignedLocations;
  const assignedYears = locationCoordinatorData?.assignedYears;
  const selectedYearRedux = locationCoordinatorData?.selectedYear;
  const selectedLocationRedux = locationCoordinatorData?.selectedLocations;
  const [courseData, setCourseData] = useState(false);
  const [locations, setLocationData] = useState([]);
  const [years, setYearsData] = useState([]);
  const [fileName, setFileName] = useState(false);
  const [openAddSection, setOpenAddSection] = useState(false);
  const [openAddSectionSuccess, setOpenAddSectionSuccess] = useState(false);
  const [openAddSectionSuccessMsg, setOpenAddSectionSuccessMsg] = useState(false);
  const [isLocationActive, setisLocationActive] = useState(true);
  const classesSheet = useStylesSheet();
  const [sectionsError, setSectionsError] = useState(false);
  const [rowData, setRowData] = useState([]);
  const [cleanLog, setCleanLog] = useState(false);
  const [hasLogText, setHasLogText] = useState(false);
  const [fileHeaders] = useState([
    { label: 'Course Name', key: 'courseName' },
    { label: 'Section', key: 'section' },
    { label: 'Teacher name ', key: 'teachersName' },
    { label: 'Students', key: 'numberOfStudents' },
    { label: 'Classroom Link', key: 'classroomLink' },
  ]);

  const formik = useFormik({
    initialValues: {
      year: years ? years[0]?.id : [],
      location: locations ? locations[0]?.id : [],
      section: 1,
      course: '',
      logText: '',
    },
    validationSchema,
    onSubmit: () => {
      setLoading(true);
    },
  });

  const restrictKeys = (ev, allowDecimal = false) => {
    const restrictedSymbols = ['+', '-', 'e', '*'];

    if (!allowDecimal) {
      restrictedSymbols.push('.');
    }

    if (restrictedSymbols.includes(ev.key)) {
      ev.preventDefault();
    }
  };

  const viewLogs = () => {
    navigate(NavigateRoutes.STUDENTS_LOGS, {
      state: {
        id: formik.values.location,
        lastRoute: NavigateRoutes.LC_CLASSES,
        logVal: false,
        isAcademicDates: false,
        isRegistrationDates: false,
        isAddTeacher: false,
        isAddSection: true,
      },
    });
  };

  useEffect(() => {
    if (cleanLog) {
      formik.setFieldValue('logText', '');
      setCleanLog(false);
    }
  }, [cleanLog]);

  useEffect(() => {
    if (formik.values.logText !== '' && formik.values.logText.length > 2) {
      setHasLogText(true);
    } else {
      setHasLogText(false);
    }
  }, [formik.values.logText]);

  useEffect(() => {
    setLocationData(assignedLocations);
    setYearsData(assignedYears);
    if (selectedLocationRedux?.value) {
      formik.setFieldValue('location', selectedLocationRedux?.value);
    } else {
      formik.setFieldValue('location', assignedLocations[0]?.id);
    }
    const currentYear = getCurrentYear()?.id;
    const selectedYear = assignedYears.filter(
      (opt) => opt?.id === currentYear.toString(),
    );
    if (selectedYearRedux?.id) {
      formik.setFieldValue('year', selectedYearRedux?.id);
    } else {
      formik.setFieldValue('year', selectedYear[0]?.id);
    }
  }, [assignedLocations, assignedYears]);

  useEffect(() => {
    setCourseData(classrooms);
  }, [classrooms]);

  useEffect(() => {
    const locData = assignedLocations?.filter(
      (i) => i?.id === formik.values.location,
    );
    const date = getFormattedDate(new Date(), 'MMDDYYYY');

    const file = `Classes - ${locData[0]?.name}-${formik.values.year}-${date}`;
    setFileName(file);
    const payload = {
      locationId: formik?.values?.location,
      year: formik?.values?.year,
    };
    if (payload?.locationId && payload?.year && setClassroomListPayload) {
      refreshList(payload);
      setClassroomListPayload(payload);
    }
    if (formik?.values?.location) {
      dispatch(getAllLocationCourse({
        locationId: formik?.values?.location,
      }));
    }
  }, [formik?.values?.location, formik?.values?.year]);

  useEffect(() => {
    const rows = courseData ? getClassroomsData(courseData) : 0;
    if (rows) {
      const rowsToCSV = rows.map((element) => {
        if (element.class_teachers.includes(undefined)) {
          element.class_teachers = null;
          element.teachersName = '';
        }
        return element;
      });
      setRowData(rowsToCSV);
    }
  }, [courseData]);

  const columns = [
    {
      field: 'courseName',
      headerName: t('COURSE'),
      sortable: false,
      align: 'left',
      class: 'courseNameField',
      headerClassName: 'courseNameHeader',
      cellClassName: 'courseNameCell',
    },
    {
      field: 'section',
      headerName: t('SECTION'),
      sortable: false,
      align: 'left',
      class: 'courseNameField',
      headerClassName: 'centerHeader',
      cellClassName: 'centerCell',
    },
    {
      field: 'teachers',
      headerName: t('TEACHERS'),
      sortable: false,
      align: '',
      headerClassName: 'parentHeader',
      cellClassName: 'parentNameCell',
      renderCell: (rowCell) => (rowCell?.row?.class_teachers?.length > 0
        && rowCell?.row?.class_teachers?.map((teacher) => {
          if (teacher?.user) {
            if (teacher?.is_primary) {
              return displayPrimaryTeacher(teacher?.user);
            }
            if (!teacher?.is_primary) {
              return displayNonPrimaryTeacher(teacher?.user);
            }
          }
          return true;
        })),
    },
    {
      field: 'numberOfStudents',
      headerName: t('STUDENTS'),
      sortable: false,
      align: 'left',
      headerAlign: 'left',
      headerClassName: 'centerHeader',
      cellClassName: 'centerCell',
    },
    {
      field: 'classroomLink',
      headerName: t('CLASSROOM'),
      sortable: false,
      align: 'left',
      headerAlign: 'left',
      headerClassName: 'classHeader',
      cellClassName: 'classroomLink',

      renderCell: (cellValues) => (
        <a
          className={classes.classroomLink}
          target="_blank"
          href={cellValues.row.classroomLink}
          rel="noreferrer"
        >
          {t('GOOGLE_CLASSROOM')}
        </a>
      ),
    },
    {
      field: 'actions',
      headerName: t('ADD_TEACHER'),
      sortable: false,
      headerAlign: 'center',
      align: 'left',
      headerClassName: 'actionHeader',
      cellClassName: 'actionCell',
      renderCell: (rowInfo) => (
        <span
          className={classes.addIcon}
          onClick={() => {
            onAddTeacherClick(rowInfo?.row);
            setAddTeacher();
          }}
        >
          <AddIcon />
        </span>
      ),
    },
  ];

  useEffect(() => {
    if (locationCoordinatorData?.locationCourses) {
      formik.setFieldValue('course', locationCoordinatorData?.locationCourses[0]?.id);
    }
  }, [locationCoordinatorData?.locationCourses]);

  useEffect(() => {
    if (locationCoordinatorData?.classroomSections && locationCoordinatorData?.classroomSections.length > 0) {
      setOpenAddSectionSuccess(false);
      let response = locationCoordinatorData?.classroomSections.length > 0 ? locationCoordinatorData?.classroomSections : getLocalStorage('ADD_CLASSROOMS_SECTIONS');
      response = response.includes('Error') ? response : `${locationCoordinatorData?.classroomSections.length} ${t('NEW_SECTIONS')}`;
      setOpenAddSectionSuccessMsg(response);
      dispatch({
        type: Constant.ADD_CLASSROOMS_SECTIONS,
        payload: [],
      });
      formik.setFieldValue('section', 1);
      setOpenAddSectionSuccess(true);
    }
  }, [locationCoordinatorData?.classroomSections]);

  const handleCreateSections = () => {
    const userId = getLocalStorage('userId');
    const payload = {
      locationId: formik.values.location,
      year: getCurrentYear()?.id,
      courseId: formik.values.course,
      numSections: formik.values.section,
      logText: formik.values.logText,
      userId,
    };
    setLoading(true);
    dispatch(createSectionsByLocationYearCourse(payload, setLoading));
    setOpenAddSectionSuccessMsg(`${t('SECTIONS_CONFIRMATION')}`);
    setOpenAddSectionSuccess(true);
    setOpenAddSection(false);
  };

  useEffect(() => {
    if (formik.values?.location) {
      let isActive = locations.filter((l) => l.id === formik.values?.location)[0]?.isActive;
      isActive = isActive === undefined || isActive === null ? false : isActive;
      setisLocationActive(isActive);
    }
  }, [formik.values?.location]);

  const verifySections = (value) => {
    let newValue = value;
    if (newValue < 1) {
      newValue = 1;
    }
    if (newValue > 10) {
      newValue = 10;
    }
    if (newValue === '' || newValue === '0' || newValue === null) {
      setSectionsError(true);
    } else {
      setSectionsError(false);
    }

    return newValue;
  };

  const renderAddSection = () => (
    <Grid className={classesSheet.sectionBox}>
      <Box
        component="form"
        sx={{
          '& .MuiTextField-root': { m: 1, width: '25ch' },
        }}
        noValidate
        autoComplete="off"
        className={classes.dialogContent}
      >
        <Grid container spacing={2}>
          <Grid item xs={10}>
            <DropdownHomeWork
              label={`${t('LOCATION')}`}
              required
              id="location"
              name="location"
              labelId="location"
              disabled={false}
              size="small"
              value={formik.values.location}
              options={locations}
              handleChange={(e) => {
                formik.handleChange(e);
                formik.setFieldValue('location', e.target.value);
                setCleanLog(true);
              }}
              onBlur={formik.handleBlur}
              customStyles={{
                padding: '0px',
                marginBottom: '1vw',
                '@media (min-width: 1200px)': {
                  fontSize: '0.9vw',
                },
              }}
              shrink
            />
          </Grid>
          <Grid item xs={2} className={classes.formControlGrid}>
            <TextField
              label={t('ACADEMICYEAR')}
              id="academicYear"
              labelId="academicYear"
              name="academicYear"
              required
              type="text"
              disabled
              value={getCurrentYear()?.id}
              size="small"
              inputProps={{
                style: {
                  '@media (min-width: 1200px)': {
                    fontSize: '0.9vw',
                  },
                },
              }}
              InputLabelProps={{
                style: {
                  '@media (min-width: 1200px)': {
                    fontSize: '0.9vw',
                  },
                }}
              }
            />
          </Grid>
        </Grid>
        {isLocationActive ? (
          <Grid container spacing={2}>
            <Grid item xs={10}>
              <DropdownHomeWork
                label={`${t('COURSE')}`}
                required
                id="course"
                name="course"
                labelId="course"
                disabled={false}
                size="small"
                value={formik.values.course}
                handleChange={(e) => {
                  formik.handleChange(e);
                  formik.setFieldValue('course', e.target.value);
                }}
                onBlur={formik.handleBlur}
                options={locationCoordinatorData?.locationCourses}
                customStyles={{
                  padding: '0px',
                  marginBottom: '1vw',
                  '@media (min-width: 1200px)': {
                    fontSize: '0.9vw',
                  },
                }}
                shrink
              />
            </Grid>
            <Grid item xs={2} className={classes.formControlGrid}>
              <TextField
                label={t('SECTIONS')}
                required
                id="sections"
                name="sections"
                labelId="sections"
                type="number"
                value={formik.values.section > 10 ? 10 : formik.values.section}
                disabled={false}
                onKeyPress={restrictKeys}
                onBlur={formik.handleBlur}
                size="small"
                onChange={(e) => {
                  const newValue = verifySections(e.target.value);
                  formik.handleChange({
                    target: {
                      name: 'section',
                      value: newValue,
                    },
                  });
                }}
                inputProps={{
                  min: '1',
                  max: '10',
                  style: {
                    '@media (min-width: 1200px)': {
                      fontSize: '0.9vw',
                    },
                  },
                }}
                InputLabelProps={{
                  style: {
                    '@media (min-width: 1200px)': {
                      fontSize: '0.9vw',
                    },
                  }}
                }
              />
              {sectionsError === true ? (
                <span style={{ color: 'red', fontSize: '0.9vw' }}>N° of sections can´t be empty</span>
              ) : null}
            </Grid>
          </Grid>
        ) : (
          <Typography
            variant="h6"
            style={{
              color: 'red',
              marginBottom: '1vw',
              marginLeft: '25vw',
            }}
          >
            {t('LOCATION_NONACTIVE')}
          </Typography>
        )}
      </Box>
      <Grid container alignItems="center" spacing={3} style={{ width: '100%' }}>
        <Grid item xs={9} lg={4} className={classes.logText}>
          <TextFieldAtom
            id="logText"
            variant="outlined"
            label={t('CHANGE_LOG')}
            sx={{
              '@media (min-width: 1200px)': {
                maxWidth: '100%',
              },
            }}
            placeholder="Log Text"
            required
            value={formik.values.logText}
            onChange={(val) => formik.setFieldValue('logText', val.target.value)}
          />
        </Grid>
        <Grid item className={classes.viewLogs}>
          <Grid container alignItems="center" spacing={1}>
            <Grid item>
              <FindInPageOutlinedIcon />
            </Grid>
            <Grid item onClick={() => viewLogs()}>
              <div>{t('VIEW_LOGS')}</div>
            </Grid>
          </Grid>
        </Grid>
        <Grid item className={classes.grow} style={{ flexGrow: 1 }} />
        <Grid item className={classes.dialogButtons}>
          <Grid container spacing={2} justifyContent="flex-end">
            <Grid item>
              <Button
                name={t('CANCEL')}
                onClick={() => {
                  formik.setFieldValue('section', 1);
                  setOpenAddSection(false);
                }}
                btntype={Buttons.SECONDARY}
              />
            </Grid>
            <Grid item>
              <Button
                name={t('SAVE')}
                disabled={!isLocationActive || !hasLogText}
                onClick={() => { handleCreateSections(); }}
                btntype={Buttons.PRIMARY}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );

  const renderAddSectionSuccess = () => (
    <Grid className={classesSheet.successBox}>
      <Box
        component="form"
        sx={{
          '& .MuiTextField-root': { m: 1, width: '15ch' },
        }}
        noValidate
        autoComplete="off"
        className={classes.dialogContent}
      >
        <Typography
          variant="h6"
          style={{
            color: String(openAddSectionSuccessMsg).includes('Error') ? 'red' : String(openAddSectionSuccessMsg).includes('Succesfully') ? 'green' : '#104F96',
            marginBottom: '15px',
            textAlign: 'center',
            fontSize: '16px',
            // marginLeft: String(openAddSectionSuccessMsg).includes('Error') ? '11.5vw' : String(openAddSectionSuccessMsg).includes('Succesfully') ? '10px' : '3vw',
          }}
        >
          {openAddSectionSuccessMsg}
        </Typography>
      </Box>
      <Grid item xs={12} style={{ marginLeft: '10vw' }}>
        <ButtonAtom
          className={classes.secButton}
          name={t('OK')}
          onClick={() => { setOpenAddSectionSuccess(false); }}
          btntype={Buttons.SECONDARY}
        />
      </Grid>
    </Grid>
  );

  return (
    <Grid container className={classes.studentDetails}>
      <Grid item xs={12}>
        <Filters
          {...{
            formik,
            classes,
            locations,
            years,
            t,
            rowData,
            fileHeaders,
            fileName,
            setShowTokenAdvice,
            setOpenSheet,
            setLoading,
            setOpenAddSection,
          }}
        />
      </Grid>
      {courseData.length > 0 && !loading ? (
        <Grid item xs={11.8} sm={12} className={classes.courseGrid}>
          <DataGrid
            rows={rowData}
            columns={columns}
            autoHeight
            hideFooter
            disableColumnFilter
            disableColumnSelector
            checkboxSelection={false}
            disableColumnMenu
            disableSelectionOnClick
            className={classes.dataGrid}
            components={{
              ColumnUnsortedIcon: CustomUnsortedIcon,
              ColumnSortedAscendingIcon: CustomAscendingIcon,
              ColumnSortedDescendingIcon: CustomDescendingIcon,
            }}
          />
        </Grid>
      ) : (courseData.length === 0) && !loading ? (
        <Grid container className={classes.noData}>
          <Grid item xs={12}>
            <DoNotDisturbIcon />
          </Grid>
          <Grid item xs={12}>
            <Typography variant="subtitle2" color="text.secondary">
              {t('NO_DATA')}
            </Typography>
          </Grid>
        </Grid>
      ) : null}
      <DialogAtomSheeet
        isOpen={openAddSection}
        dialogHeading={t('ADD_SECTION')}
        content={renderAddSection()}
        secHandle={() => {
          formik.setFieldValue('section', 1);
          setOpenAddSection(false);
        }}
      />
      <DialogAtomSheeet
        isOpen={openAddSectionSuccess}
        dialogHeading={t('ADD_SECTION')}
        content={renderAddSectionSuccess()}
        secHandle={() => { setOpenAddSectionSuccess(false); }}
      />
    </Grid>
  );
}
