import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import {
  Grid,
  Stack, Typography, Menu, MenuItem,
} from '@mui/material';
import {
  useNavigate,
} from 'react-router-dom';
import CircularProgress from '@mui/material/CircularProgress';
import HomeIcon from '@mui/icons-material/HomeOutlined';
import GridViewIcon from '@mui/icons-material/GridView';
import ToggleButton from '@mui/material/ToggleButton';
import AddIcon from '@mui/icons-material/Add';
import FolderIcon from '@mui/icons-material/FolderOutlined';
import FileIcon from '@mui/icons-material/AttachFileOutlined';
import VideoIcon from '@mui/icons-material/OndemandVideoOutlined';
import AddToDeiveIcon from '@mui/icons-material/AddToDriveOutlined';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import DoNotDisturbIcon from '@mui/icons-material/DoNotDisturb';
import ListViewIcon from '../../../../assets/svg/listViewIcon';
import {
  uploadFile,
  uploadVideo,
  createStudentResource,
  getTeacherResource,
  getStudentResourceCourse,
  getFolderDetails,
} from '../../../../store/actions/getResources';
import useStyles from '../../../../custom-hooks/useStyles';
import Dropdown from '../../../../components/atoms/dropdown';
import { DialogAtom, Loader } from '../../../../components/atoms';
import { setLocalStorage, getLocalStorage } from '../../../../utils/localStorageMethod';
import styles from '../style';
import ListView from '../../../resources/list-view';
import GridView from '../../../resources/grid-view';
import ButtonAtom from '../../../../components/atoms/button';
import { Buttons, NavigateRoutes } from '../../../../constant';
import CheckboxAtom from '../../../../components/atoms/checkbox-resources';
import CheckboxAtomDialog from '../../../../components/atoms/checkbox-dialog-resources';
import userRoles from '../../../../constant/userRoles';
import { getAllCourses } from '../../../../store/actions/getStudent';
import { getAssignedUserRoles } from '../../../../store/actions/getUser';
import { GetWithDeactivatedFiles, GetWithoutDeactivatedFiles } from '../helper';
import decryptedUserRoles from '../../../../constant/decryptedUserRoles';
import useDataTableParams from '../../../../custom-hooks/useDataTableParams';

export default function ResourcesData() {
  const {
    nextPage,
  } = useDataTableParams();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const classes = useStyles(styles)();
  const dispatch = useDispatch();
  const reduxStore = useSelector((state) => state?.getAdmin);
  const uploadFileResponse = reduxStore?.uploadFile;
  const uploadVideoResponse = reduxStore?.uploadVideo;
  const getCreateStudentResourceError = reduxStore?.createStudentResourceError;
  const createStudentResourceSuccess = reduxStore?.createStudentResource;
  const [view, setView] = useState('gridView');
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const getFolderDetailsResponse = reduxStore?.folderDetails;
  const [issetAddFileOpenDialog, setAddFileOpenDialog] = useState(false);
  const [issetAddFolderOpenDialog, setAddFolderOpenDialog] = useState(false);
  const [issetSelectVideoFromDriveOpenDialog, setSelectVideoFromDriveOpenDialog] = useState(false);
  const [issetUploadVOpenDialog, setUploadVideoOpenDialog] = useState(false);
  const [issetUploadVideo, setUploadVideo] = useState({});
  const [issetUploadVideoName, setUploadVideoName] = useState({});
  const [issetUploadVideoExt, setUploadVideoExt] = useState({});
  const [issetSelectVideoFromDrive, setSelectVideoFromDrive] = useState([]);
  const [issetUploadFile, setUploadFile] = useState({});
  const [issetUploadFileName, setUploadFileName] = useState({});
  const [issetUploadFileExt, setUploadFileExt] = useState({});
  const [issetUploadFileSize, setUploadFileSize] = useState();
  const [issetUploadVideoSize, setUploadVideoSize] = useState();
  const [issetUploadFileSizeMessage, setUploadFileSizeMessage] = useState('');
  const [uploadFileSizeSuccess, setUploadFileSizeSuccess] = useState('');
  const [uploadVideoSizeSuccess, setUploadVideoSizeSuccess] = useState('');
  const [issetUploadVideoSizeMessage, setUploadVideoSizeMessage] = useState('');
  const [issetSelectVideoFromDriveMessage, setSelectVideoFromDriveMessage] = useState('');

  const [isSelectedvalue, setSelectedValue] = useState();
  const [issetSelectVideoFromDriveFile, setSelectVideoFromDriveFile] = useState();
  const [ischecked, setIsChecked] = useState(false);
  const [isVideochecked, setIsVideoChecked] = useState(false);
  const reduxStudentStore = useSelector((state) => state?.getStudent);
  const assignedCourses = reduxStudentStore?.courses;
  const reduxteacherStore = useSelector((state) => state?.getLocationCoordinator);
  const [teacherResource, setTeacherResource] = useState([]);
  const [resources, setResources] = useState([]);
  const encryptedUserRole = getLocalStorage('userRole');
  const userRole = decryptedUserRoles[encryptedUserRole];
  const tempUserRoleLocalStorage = getLocalStorage('tempUserRole');
  const tempUserRole = decryptedUserRoles[tempUserRoleLocalStorage];
  const isAdmin = (userRole === userRoles.SYSTEM_ADMIN) || (tempUserRole === userRoles.SYSTEM_ADMIN);
  const isCurriculum = (userRole === userRoles.CURRICULUM);
  const isSuperUser = (userRole === userRoles.SUPER_USER) || (tempUserRole === userRoles.SUPER_USER);
  const hasCurriculumRole = JSON.parse(getLocalStorage('hasCurriculumRole'));
  const [addVideoError, setAddVideoError] = useState('');
  const [disabledCheck, setDisabledCheck] = useState(false);
  const [selectedResource, setSelectedResource] = useState([]);
  const [isAddFolderName, setAddFolderName] = useState('');
  const [loadingUploadFile, setLoadingUploadFile] = useState(false);
  const [errorLargeVideo, setErrorLargeVideo] = useState(false);
  const [loading, setLoading] = useState(false);
  const [pagenum, setPagenum] = useState(1);
  const [isFetching, setIsFetching] = useState(false);
  const [isActivateChecked, setActivateChecked] = useState(false);
  const getTeacherResourceInFolderResponse = reduxteacherStore?.teacherResourceInFolder;
  const [assignedUserRoles, setAssignedUserRoles] = useState([]);
  const [isSysAdminOrSuperUser, setIsSysAdminOrSuperUser] = useState(false);
  const [sortBoth, setSortBoth] = useState(true);
  const [openDialogErrorIncorrectFile, setOpenDialogErrorIncorrectFile] = useState(false);
  const [sortOrder, setSortOrder] = useState({
    resourceN: 'both',
    createdBy: 'both',
    createdOn: 'both',
    updatedBy: 'both',
    updatedOn: 'both',
    sizeN: 'both',
  });
  const [folderName, setFolderName] = useState([]);

  useEffect(() => {
    dispatch(getAssignedUserRoles())
      .then((data) => {
        setAssignedUserRoles(data?.data);
      })
      .catch((err) => {
        // eslint-disable-next-line no-console
        console.log(err);
      });
  }, []);

  useEffect(() => {
    let isAdminOrSuperUser = false;
    assignedUserRoles.map((ur) => {
      if (ur?.name === userRoles.SYSTEM_ADMIN || ur?.name === userRoles.SUPER_USER || ur?.name === userRoles.CURRICULUM) {
        isAdminOrSuperUser = true;
      }
      return isAdminOrSuperUser;
    });
    setIsSysAdminOrSuperUser(isAdminOrSuperUser);
  }, [assignedUserRoles]);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleView = (event, myResourceView) => {
    if (myResourceView !== null) {
      setView(myResourceView);
    }
  };
  const redirectToHomeScreen = () => {
    navigate(NavigateRoutes.TEACHERS_RESOURCES);
    setLoading(true);
    setIsFetching(true);
    setSelectedResource([]);
    setFolderName([]);
    if (isSelectedvalue !== '' && isSelectedvalue !== undefined) {
      const payload = {
        courseId: isSelectedvalue,
        userRole,
      };
      dispatch(getTeacherResource(1, 100, nextPage, payload, setIsFetching, setLoading));
    }
  };
  useEffect(() => {
    setTeacherResource(reduxteacherStore?.teacherResources);
  }, [reduxteacherStore?.teacherResources]);

  useEffect(() => {
    setTeacherResource(getTeacherResourceInFolderResponse);
  }, [getTeacherResourceInFolderResponse]);

  const refreshList = () => {
    setTimeout(() => {
      if (isSelectedvalue !== '' && isSelectedvalue !== undefined) {
        const payload = {
          courseId: isSelectedvalue,
          parentKey: selectedResource.selfKey,
          userRole,
        };
        if (!selectedResource.selfKey) {
          setLoading(true);
          dispatch(getTeacherResource(1, 100, nextPage, payload, setIsFetching, setLoading));
        } else {
          setLoading(true);
          dispatch(getTeacherResource(1, 100, nextPage, payload, setIsFetching, setLoading));
        }
      }
    }, 2000);
  };

  useEffect(() => {
    if (assignedCourses[0]?.id) {
      setSelectedValue(assignedCourses[0]?.id);
    }
  }, [assignedCourses]);

  useEffect(() => {
    setLoading(true);
    setIsFetching(true);
    if (isSelectedvalue !== '' && isSelectedvalue !== undefined) {
      const payload = {
        courseId: isSelectedvalue,
        parentKey: selectedResource.selfKey,
        userRole,
      };
      dispatch(getTeacherResource(1, 100, nextPage, payload, setIsFetching, setLoading));
      setSortOrder({
        resourceN: 'both',
        createdBy: 'both',
        createdOn: 'both',
        updatedBy: 'both',
        updatedOn: 'both',
        sizeN: 'both',
      });
    }
  }, [isSelectedvalue]);

  useEffect(() => {
    setResources(new GetWithoutDeactivatedFiles(teacherResource));
  }, [teacherResource]);

  useEffect(() => {
    setUploadFileSizeMessage(getCreateStudentResourceError?.msg);
    setUploadVideoSizeMessage(getCreateStudentResourceError?.msg);
    setSelectVideoFromDriveMessage(getCreateStudentResourceError?.msg);
  }, [getCreateStudentResourceError]);

  const [isDeactivateChecked, setDeactivateChecked] = useState(false);
  const fetchDeactivatedFiles = () => {
    setDeactivateChecked(!isDeactivateChecked);
    if (isDeactivateChecked) {
      setResources(new GetWithoutDeactivatedFiles(teacherResource));
    } else {
      setResources(new GetWithDeactivatedFiles(teacherResource));
    }
  };

  useEffect(() => {
    if (isActivateChecked) {
      if (isDeactivateChecked) {
        setResources(new GetWithDeactivatedFiles(teacherResource));
      } else {
        setResources(new GetWithoutDeactivatedFiles(teacherResource));
      }
    }
  }, [isActivateChecked, teacherResource]);

  useEffect(() => {
    dispatch(getAllCourses());
  }, []);

  useEffect(() => {
    if (uploadFileResponse?.message) {
      const payload = {
        canDownload: ischecked,
        courseId: isSelectedvalue,
        fileSize: uploadFileResponse?.fileSize,
        fileType: uploadFileResponse?.fileType,
        fileUrl: uploadFileResponse?.url ? uploadFileResponse?.url.split(' ').join('') : '',
        isCoverPage: true,
        isPublished: true,
        resourceKind: 'teacher',
        resourceName: uploadFileResponse?.resourceName,
      };
      if (getFolderDetailsResponse) {
        payload.parentKey = getFolderDetailsResponse.selfKey;
      }
      dispatch(createStudentResource(payload));
    }
  }, [uploadFileResponse?.message]);

  useEffect(() => {
    setUploadFileSizeSuccess(uploadFileResponse?.message);
    setTimeout(() => {
      setAddFileOpenDialog(false);
      refreshList();
      setUploadFileSizeMessage('');
      setUploadFileSizeSuccess('');
      uploadFileResponse.message = '';
    }, 1000);
  }, [createStudentResourceSuccess]);

  useEffect(() => {
    if (uploadVideoResponse?.message) {
      const payload = {
        canDownload: isVideochecked,
        courseId: isSelectedvalue,
        fileSize: uploadVideoResponse?.fileSize,
        fileType: uploadVideoResponse?.fileType,
        fileUrl: uploadVideoResponse?.url ? uploadVideoResponse?.url.split(' ').join('') : '',
        isCoverPage: true,
        isPublished: true,
        resourceKind: 'teacher',
        resourceName: uploadVideoResponse?.resourceName,
      };
      if (getFolderDetailsResponse) {
        payload.parentKey = getFolderDetailsResponse.selfKey;
      }
      dispatch(createStudentResource(payload));
    }
  }, [uploadVideoResponse?.message]);

  useEffect(() => {
    setUploadVideoSizeSuccess(uploadVideoResponse?.message);
    setTimeout(() => {
      setUploadVideoOpenDialog(false);
      setSelectVideoFromDriveOpenDialog(false);
      refreshList();
      setUploadVideoSizeMessage('');
      setSelectVideoFromDriveMessage('');
      setUploadVideoSizeSuccess('');
      uploadVideoResponse.message = '';
    }, 1500);
  }, [createStudentResourceSuccess]);

  const handleAddfile = () => {
    if (issetUploadFileExt !== 'crdownload') {
      if (issetUploadFileName !== '') {
        const filenames = `${issetUploadFileName}.${issetUploadFileExt}`;
        const filenamesScript = filenames.replace(/ /g, '_');
        const formData = new FormData();
        formData.append('file', issetUploadFile, filenamesScript);
        dispatch(uploadFile(formData));
        refreshList();
      }
    } else {
      setOpenDialogErrorIncorrectFile(true);
      setIsChecked(false);
    }
  };

  const handleAddvideo = () => {
    setLoadingUploadFile(true);
    if (issetUploadVideoName !== '') {
      const videonames = `${issetUploadVideoName}.${issetUploadVideoExt}`;
      const filenamesScript = videonames.replace(/ /g, '_');
      const formData = new FormData();
      formData.append('video', issetUploadVideo, filenamesScript);
      dispatch(uploadVideo(formData, setLoadingUploadFile, setErrorLargeVideo, setIsVideoChecked));
    }
  };

  const handleSelectVideoFromDrive = () => {
    setLoadingUploadFile(true);
    const formData = new FormData();
    formData.append('video', issetSelectVideoFromDriveFile);
    dispatch(uploadVideo(formData, setSelectVideoFromDriveOpenDialog, setLoadingUploadFile, setIsVideoChecked, setErrorLargeVideo));
    setIsVideoChecked(false);
  };

  const handleCheck = (e) => {
    setIsChecked(e.target.checked);
  };

  const handleVideoCheck = (e) => {
    setIsVideoChecked(e.target.checked);
  };

  const onChangeValue = (e, obj, callback) => {
    setDisabledCheck(true);
    const fileId = e.target.value;
    const gettoken = getLocalStorage('accessToken');
    const baseUrl = JSON.parse(gettoken);
    const xhr = new XMLHttpRequest();
    xhr.open('GET', `https://www.googleapis.com/drive/v3/files/${fileId}?alt=media`, true);
    xhr.setRequestHeader('Authorization', `Bearer ${baseUrl?.access_token}`);
    xhr.responseType = 'blob';
    xhr.onload = () => {
      try {
        const filedata = new Blob([xhr.response], { type: obj?.mimeType });
        const file = new File([filedata], obj?.name.replace(/ /g, '_'));
        callback(file);
      } catch (err) {
        callback(null);
      }
    };
    xhr.onerror = () => {
      callback(null);
    };
    xhr.send();
  };

  const renderAddFileContent = () => (
    <Grid className={classes.addDialog}>
      {uploadFileSizeSuccess && (
        <Grid className={classes.alertMsg}>
          <div className={classes.alertprimary} role="alert">
            {uploadFileSizeSuccess}
          </div>
        </Grid>
      )}
      {issetUploadFileSizeMessage && (
        <Grid>
          <div className={classes.errorPrimary} role="alert">
            {issetUploadFileSizeMessage}
          </div>
        </Grid>
      )}
      <Box
        component="form"
        sx={{
          '& .MuiTextField-root': { m: 1, width: '25ch' },
        }}
        noValidate
        autoComplete="off"
      >
        <div>
          <TextField
            className={classes.popupTextField}
            required
            id="outlined-required"
            label="File Name"
            value={issetUploadFileName}
            onChange={(e) => {
              setUploadFileSizeMessage('');
              if (e.target.value === '') {
                setUploadFileSizeMessage(t('FILE_NAME_CANNOT_BE_EMPTY'));
              }
              setUploadFileName(e.target.value);
            }}
            defaultValue="BalaBadi-I"
          />
        </div>
        <div className={classes.dialogheckbox}>
          <CheckboxAtomDialog
            label={t('USER_DOWNLOAD')}
            id="acceptCheckbox"
            checked={ischecked}
            handleChange={(e) => handleCheck(e)}
          />
          <p className={classes.dialogfooter}>
            {t('FILE_SIZE')}
            :
            &nbsp;
            {issetUploadFileSize}
            MB
          </p>
        </div>
        <Grid item xs={12} className={`${classes.dialogButtons} ${classes.popupButton}`}>
          <ButtonAtom
            className={classes.popupBtn}
            name={t('CANCEL')}
            onClick={() => {
              setAddFileOpenDialog(false);
              setUploadFileSizeMessage('');
              setUploadVideoSizeMessage('');
              setSelectVideoFromDriveMessage('');
              setIsChecked(false);
              dispatch({
                type: 'UPLOAD_FILE',
                payload: [],
              });
            }}
            btntype={Buttons.SECONDARY}
          />
          <div className={issetUploadFileSize > 30 ? classes.popupBtnDisable : classes.popupBtn}>
            <ButtonAtom
              name={t('ADD')}
              onClick={handleAddfile}
              btntype={Buttons.PRIMARY}
              disabled={issetUploadFileSize > 30}
            />
          </div>
        </Grid>
      </Box>
    </Grid>
  );

  const handleAddFolder = () => {
    let payload = {
      canDownload: false,
      courseId: isSelectedvalue,
      fileIcon: '',
      fileSize: '0',
      fileType: 'Folder',
      fileUrl: '',
      isCoverPage: true,
      isPublished: true,
      resourceKind: 'Teacher',
      resourceName: isAddFolderName,
    };
    if (selectedResource.length === 0) {
      dispatch(createStudentResource(payload));
    } else {
      payload = {
        canDownload: false,
        courseId: isSelectedvalue,
        fileIcon: '',
        fileSize: '0',
        fileType: 'Folder',
        fileUrl: '',
        isCoverPage: true,
        isPublished: true,
        resourceKind: 'Teacher',
        resourceName: isAddFolderName,
        parentKey: selectedResource.selfKey,
      };
      dispatch(createStudentResource(payload));
    }
    setAddFolderOpenDialog(false);
    setAddFolderName('');
    refreshList();
  };

  const renderAddFolderContent = () => (
    <Grid>
      <Box
        component="form"
        sx={{
          '& .MuiTextField-root': { m: 1, width: '25ch' },
        }}
        noValidate
        autoComplete="off"
      >
        <div>
          <TextField
            className={classes.popupTextField}
            required
            id="outlined-required"
            label="Folder Name"
            value={isAddFolderName}
            onChange={(e) => setAddFolderName(e.target.value)}
            defaultValue="Lessons"

          />
        </div>
      </Box>
      <Grid item xs={12} className={`${classes.dialogButtons} ${classes.popupButton}`}>
        <ButtonAtom
          className={classes.popupBtn}
          name={t('CANCEL')}
          onClick={() => setAddFolderOpenDialog(false)}
          btntype={Buttons.SECONDARY}
        />
        <ButtonAtom
          className={classes.popupBtn}
          name={t('ADD')}
          btntype={Buttons.PRIMARY}
          onClick={handleAddFolder}
        />
      </Grid>
    </Grid>
  );

  const renderUploadVideoContent = () => (
    <Grid className={classes.addDialog}>
      {uploadVideoSizeSuccess && (
        <Grid>
          <div className={classes.alertprimary} role="alert">
            {uploadVideoSizeSuccess}
          </div>
        </Grid>
      )}
      {issetUploadVideoSizeMessage && (
        <Grid>
          <div className={classes.errorPrimary} role="alert">
            {issetUploadVideoSizeMessage}
          </div>
        </Grid>
      )}
      <Box
        component="form"
        sx={{
          '& .MuiTextField-root': { m: 1, width: '25ch' },
        }}
        noValidate
        autoComplete="off"
      >
        <div>
          <TextField
            className={classes.popupTextField}
            required
            id="outlined-required"
            label="File Name"
            value={issetUploadVideoName}
            onChange={(e) => {
              setAddVideoError('');
              if (e.target.value === '') {
                setAddVideoError(t('FILE_NAME_CANNOT_BE_EMPTY'));
              }
              setUploadVideoName(e.target.value);
            }}
            defaultValue="BalaBadi-I"
          />
        </div>
        <span className={classes.errorText}>{addVideoError}</span>
        <div className={classes.dialogCheckbox}>
          <CheckboxAtomDialog
            label={t('USER_DOWNLOAD')}
            id="acceptCheckbox"
            checked={isVideochecked}
            handleChange={(e) => handleVideoCheck(e)}
          />
          <p className={classes.dialogfooter}>
            {issetUploadVideoSize}
            &nbsp;
            MB
          </p>
        </div>
        <Grid item xs={12} className={`${classes.dialogButtons} ${classes.popupButton}`}>
          <ButtonAtom
            className={classes.popupBtn}
            name={t('CANCEL')}
            onClick={() => {
              setIsVideoChecked(false);
              setUploadVideoOpenDialog(false);
              setUploadVideoSizeMessage('');
              setUploadFileSizeMessage('');
              setSelectVideoFromDriveMessage('');
              dispatch({
                type: 'UPLOAD_VIDEO',
                payload: [],
              });
            }}
            btntype={Buttons.SECONDARY}
          />
          <div className={issetUploadVideoSize > 50 ? classes.popupBtnDisable : classes.popupBtn}>
            <ButtonAtom
              name={t('UPDATE')}
              onClick={handleAddvideo}
              btntype={Buttons.PRIMARY}
              disabled={issetUploadFileSize > 50}
            />
          </div>
        </Grid>
      </Box>
    </Grid>
  );

  const renderSelectVideoFromDriveContent = () => (
    <Grid className={classes.addDialog}>
      {errorLargeVideo && (
        <Grid>
          <div className={classes.errorPrimary} role="alert">
            {t('SIZE_VIDEO_DRIVE')}
          </div>
        </Grid>
      )}
      {disabledCheck && (
        <Grid>
          <div className={classes.alertFetching} role="alert">
            {t('FETCHING_RESOURCES')}
          </div>
        </Grid>
      )}
      {uploadVideoSizeSuccess && (
        <Grid>
          <div className={classes.alertprimary} role="alert">
            {uploadVideoSizeSuccess}
          </div>
        </Grid>
      )}
      {issetSelectVideoFromDriveMessage && (
        <Grid>
          <div className={classes.errorPrimary} role="alert">
            {issetSelectVideoFromDriveMessage}
          </div>
        </Grid>
      )}
      {loadingUploadFile && (
        <Box sx={{
          display: 'flex', position: 'absolute', paddingLeft: '40%', paddingTop: '20%', paddingBottom: '20%',
        }}
        >
          <CircularProgress />
        </Box>
      )}
      {!loadingUploadFile && (
        <>
          <Box
            component="form"
            sx={{
              '& .MuiTextField-root': { m: 1, width: '25ch' },
            }}
            noValidate
            autoComplete="off"
          >
            <div className={classes.resourcesAddListBox}>
              {issetSelectVideoFromDrive?.map((obj) => (
                <div className={classes.popupTextField}>
                  <input
                    disabled={disabledCheck}
                    type="radio"
                    value={obj?.id}
                    style={{ verticalAlign: 'bottom' }}
                    name="name"
                    onChange={(e) => onChangeValue(e, obj, (file) => {
                      if (file) {
                        setSelectVideoFromDriveFile(file);
                        setSelectVideoFromDriveMessage('');
                        setDisabledCheck(false);
                      }
                    })}
                  />
                  <span style={{ marginLeft: '0.5vw' }}>
                    {obj?.name.replace(/ /g, '_')}
                  </span>
                </div>
              ))}
            </div>
            <div className={classes.dialogCheckbox}>
              <CheckboxAtomDialog
                label={t('USER_DOWNLOAD')}
                id="acceptCheckbox"
                checked={isVideochecked}
                handleChange={(e) => handleVideoCheck(e)}
              />
            </div>
          </Box>
          <Grid item xs={12} className={`${classes.dialogButtons} ${classes.popupSelectDriveButton}`}>
            <div>
              <ButtonAtom
                className={classes.popupBtn}
                name={t('CANCEL')}
                onClick={() => {
                  setIsVideoChecked(false);
                  setSelectVideoFromDriveOpenDialog(false);
                  setSelectVideoFromDriveMessage('');
                  setUploadVideoSizeMessage('');
                  setUploadFileSizeMessage('');
                  setIsVideoChecked(false);
                  dispatch({
                    type: 'UPLOAD_VIDEO',
                    payload: [],
                  });
                }}
                btntype={Buttons.SECONDARY}
              />
            </div>
            <div className={issetSelectVideoFromDriveFile ? classes.popupBtn : classes.popupBtnDisable}>
              <ButtonAtom
                name={t('ADD')}
                onClick={handleSelectVideoFromDrive}
                btntype={Buttons.PRIMARY}
                disabled={!issetSelectVideoFromDriveFile}
              />
            </div>
          </Grid>
        </>
      )}
    </Grid>
  );

  const handleAddFolderDialog = () => {
    setAddFolderOpenDialog(true);
  };

  const getData = (resource) => {
    return new Promise((resolve, reject) => {
      const request = new XMLHttpRequest();
      const gettoken = getLocalStorage('accessToken');
      const baseUrl = JSON.parse(gettoken);
      request.addEventListener('readystatechange', () => {
        if (request.readyState === 4 && request.status === 200) {
          const data = JSON.parse(request.responseText);
          resolve(data);
        } else if (request.readyState === 4) {
          reject(new Error('error getting resources'));
        }
      });
      request.open('GET', resource);
      request.setRequestHeader('Authorization', `Bearer ${baseUrl?.access_token}`);
      request.send();
    });
  };

  const handleSelectVideoFromDriveDialog = () => {
    setSelectVideoFromDriveOpenDialog(true);
    getData('https://www.googleapis.com/drive/v3/files?pageSize=1000&supportAllDrives=false&includeItemsFromAllDrives=false&q="root" in parents')
      .then((data) => {
        const fileData = data.files;
        const mineType = fileData.filter(
          (item) => item.mimeType.split('/')[0] === 'video',
        );
        setSelectVideoFromDrive(mineType);
      })
      // eslint-disable-next-line no-unused-vars
      .catch((err) => {
        setSelectVideoFromDriveMessage(t('TOKEN_EXPIRED'));
      });
  };
  const renderErrorFileExt = () => (
    <Grid className={classes.studentBox}>
      <Box
        component="form"
        sx={{
          '& .MuiTextField-root': { m: 1, width: '25ch' },
        }}
        noValidate
        autoComplete="off"
        className={classes.dialogContent}
      >
        <Grid>
          <div className={classes.alertprimary} role="alert">
            {t('ERROR_FILE_EXT')}
          </div>
        </Grid>
      </Box>
      <Grid item xs={12} className={`${classes.dialogButtons} ${classes.popupButton}`}>
        <ButtonAtom
          className={classes.popupBtn}
          name={t('OK')}
          onClick={() => {
            setOpenDialogErrorIncorrectFile(false);
            setAddFileOpenDialog(false);
            setUploadFileSizeMessage('');
            setUploadVideoSizeMessage('');
            setSelectVideoFromDriveMessage('');
            reduxStore.createStudentResourceError = '';
            dispatch({
              type: 'UPLOAD_FILE',
              payload: [],
            });
          }}
          btntype={Buttons.PRIMARY}
        />
      </Grid>
    </Grid>
  );

  const openFolder = (obj) => {
    setLoading(true);
    setIsFetching(true);
    const payload = {
      courseId: obj.courseId,
      parentKey: obj.selfKey,
      userRole,
    };
    if (obj.resourceKind === 'Student' || obj.resourceKind === 'student') {
      dispatch(getStudentResourceCourse(1, 100, nextPage, payload, setIsFetching, setLoading));
    } else {
      dispatch(getTeacherResource(1, 100, nextPage, payload, setIsFetching, setLoading));
    }
    dispatch(getFolderDetails(obj));
  };

  const returnPreviousFolder = (index) => {
    const newArray = folderName.slice(0, index + 1);
    setFolderName(newArray);
    openFolder(folderName[index]);
    setSelectedResource(folderName[index]);
  };

  return (
    <div className={classes.gridPadding}>
      <div className={classes.divstule}>
        <Stack sx={{ width: '100%' }} spacing={4}>
          <Grid container flexDirection="row" display="flex" className={`${classes.divWrapper}`}>
            <Grid container>
              <Grid container item className={classes.titleRow}>
                <Grid item>
                  <Typography className={classes.headerTitle}>
                    {t('TEACHER_RESOURCES')}
                  </Typography>
                </Grid>
                {isAdmin || isCurriculum || isSuperUser || hasCurriculumRole
                  ? (
                    <Grid>
                      <ButtonAtom
                        className={classes.addbutton}
                        name={t('ADD')}
                        btntype={Buttons.PRIMARY}
                        type={Buttons.PRIMARY}
                        onClick={handleClick}
                        icon={<AddIcon />}
                      />
                    </Grid>
                  )
                  : null}
                <Menu
                  className={classes.Menubox}
                  anchorEl={anchorEl}
                  id="account-menu"
                  open={open}
                  onClose={handleClose}
                  PaperProps={{
                    elevation: 0,
                    sx: {
                      overflow: 'visible',
                      filter: 'drop-shadow(0px 2px 1px rgba(0,0,0,0.32))',
                      mt: 1.5,
                      '& .MuiMenuItem-root': {
                        borderBottom: '0.1vw solid #d0d0d0',
                        '@media (min-width: 1200px)': {
                          padding: '1vw',
                          fontSize: '0.9vw',
                        },
                        borderRadius: '0vw',
                        display: 'list-item',
                      },
                    },
                  }}
                  transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                  anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                >
                  <MenuItem
                    className={classes.fileIcon}
                    variant="contained"
                    component="label"
                  >
                    <FileIcon />
                    {t('ADDFILE')}
                    <input
                      type="file"
                      accept=".doc,.docx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document/,image/*,.pdf,.mp3,audio/*"
                      hidden
                      onChange={(e) => {
                        const filesize = (e.target.files[0].size / (1024 * 1024)).toFixed(2);
                        const fileType = e.target.files[0].type;
                        setUploadFileSize(filesize);
                        if ((filesize > 30) && (fileType.includes('audio') || fileType.includes('.mp3'))) {
                          setUploadFileSizeMessage('Maximum 30 MB allowed on audio files.');
                        } else if ((filesize > 30) && !(fileType.includes('audio') || fileType.includes('.mp3'))) {
                          setUploadFileSizeMessage('Maximum 30 MB allowed on common files.');
                        }
                        setUploadFile(e.target.files[0]);
                        const file = (e.target.files[0].name);
                        const filename = file.split('.');
                        setUploadFileExt(filename.pop());
                        setUploadFileName(filename[0]);
                        setAddFileOpenDialog(true);
                        setIsChecked(false);
                        handleClose();
                      }}
                    />

                  </MenuItem>
                  <MenuItem
                    onClick={() => {
                      setUploadFileSizeMessage('');
                      handleAddFolderDialog();
                      handleClose();
                    }}
                    onClose={handleClose}
                  >
                    <FolderIcon />
                    {t('ADDFOLDER')}
                  </MenuItem>
                  <MenuItem
                    variant="contained"
                    component="label"
                  >
                    <VideoIcon />
                    {t('UPLOADVIDEO')}
                    <input
                      type="file"
                      accept=".mp4,.mov"
                      hidden
                      onChange={(e) => {
                        const videosize = (e.target.files[0].size / (1024 * 1024)).toFixed(2);
                        setUploadVideoSize(videosize);
                        if (videosize >= 30) {
                          setUploadVideoSizeMessage('Maximum 30 MB allowed.');
                        }
                        setUploadVideo(e.target.files[0]);
                        const video = (e.target.files[0].name);
                        const videoName = video.split('.');
                        setUploadVideoExt(videoName.pop());
                        setUploadVideoName(videoName[0]);
                        setUploadVideoOpenDialog(true);
                        setAddVideoError('');
                        setIsVideoChecked(false);
                        handleClose();
                      }}
                    />
                  </MenuItem>
                  <MenuItem
                    onClick={() => {
                      handleSelectVideoFromDriveDialog();
                      handleClose();
                    }}
                    onClose={handleClose}
                  >
                    <AddToDeiveIcon />
                    {t('SELECT_VIDEO_FROM_DRIVE')}
                  </MenuItem>
                </Menu>
              </Grid>
            </Grid>
            <DialogAtom
              isOpen={issetAddFileOpenDialog}
              dialogHeading={t('ADDFILE')}
              customClass={classes.DialogAtom}
              content={renderAddFileContent()}
              secHandle={() => {
                setAddFileOpenDialog(false);
                setUploadFileSizeMessage('');
                setUploadVideoSizeMessage('');
                setSelectVideoFromDriveMessage('');
                setIsChecked(false);
                dispatch({
                  type: 'UPLOAD_FILE',
                  payload: [],
                });
              }}
            />
            <DialogAtom
              isOpen={issetAddFolderOpenDialog}
              dialogHeading={t('ADDFOLDER')}
              customClass={classes.DialogAtom}
              content={renderAddFolderContent()}
              secHandle={() => setAddFolderOpenDialog(false)}
            />
            <DialogAtom
              isOpen={issetUploadVOpenDialog}
              dialogHeading={t('UPLOADVIDEO')}
              customClass={classes.DialogAtom}
              content={renderUploadVideoContent()}
              secHandle={() => {
                setUploadVideoOpenDialog(false);
                setUploadVideoSizeMessage('');
                setUploadFileSizeMessage('');
                setSelectVideoFromDriveMessage('');
                dispatch({
                  type: 'UPLOAD_VIDEO',
                  payload: [],
                });
              }}
            />
            <DialogAtom
              isOpen={issetSelectVideoFromDriveOpenDialog}
              dialogHeading={t('SELECT_VIDEO_FROM_DRIVE')}
              customClass={classes.DialogAtomDrive}
              content={renderSelectVideoFromDriveContent()}
              secHandle={() => {
                setSelectVideoFromDriveOpenDialog(false);
                setSelectVideoFromDriveMessage('');
                setUploadFileSizeMessage('');
                setUploadVideoSizeMessage('');
                setIsVideoChecked(false);
                dispatch({
                  type: 'UPLOAD_VIDEO',
                  payload: [],
                });
              }}
            />
            <DialogAtom
              isOpen={openDialogErrorIncorrectFile}
              dialogHeading={t('ADDFILE_ERROR_EXT')}
              content={renderErrorFileExt()}
              secHandle={() => setOpenDialogErrorIncorrectFile(false)}
            />
            <Grid item className={classes.HeaderWrapper}>
              <div className={classes.dropdownWrap}>
                <div className={classes.studentcourse}>
                  <Dropdown
                    minWidth="100%"
                    id="studentCourse"
                    className={classes.helpSite}
                    variant="standard"
                    options={assignedCourses}
                    value={isSelectedvalue}
                    customClass={classes.dropdown}
                    changeCss
                    customFormControlCss={{ width: '100%' }}
                    labelId="studentCourse"
                    handleChange={(e) => {
                      setTeacherResource([]);
                      setLoading(true);
                      setFolderName([]);
                      setSelectedResource([]);
                      setSelectedValue(e.target.value);
                      setPagenum(1);
                    }}
                    icon={(
                      <HomeIcon onClick={() => {
                        redirectToHomeScreen();
                        setPagenum(1);
                      }}
                      />
                    )}
                  />
                </div>
                {selectedResource?.resourceName && (
                  <div className={classes.courseData}>
                    {folderName.map((name, index) => {
                      return (
                        <span style={{ display: 'flex', marginLeft: '10px' }}>
                          <div className={classes.slash}>/</div>
                          <span onClick={() => returnPreviousFolder(index)}>
                            <FolderIcon className={classes.folderIcon} />
                            <span className={classes.folderName}>{name?.resourceName}</span>
                          </span>
                        </span>
                      );
                    })}
                  </div>
                )}
              </div>

              {(isAdmin || isCurriculum || isSuperUser || hasCurriculumRole)
                ? (
                  <div className={`${classes.deactiveCheckbox}`}>
                    <CheckboxAtom
                      label={t('VIEW_INACTIVE')}
                      id="acceptCheckbox"
                      checked={isDeactivateChecked}
                      handleChange={() => fetchDeactivatedFiles()}
                    />
                  </div>
                )

                : null}
              <Stack direction="row" spacing={1} justifyContent="space-between">
                <ToggleButtonGroup
                  value={view}
                  exclusive
                  aria-label="text alignment"
                  onChange={handleView}
                  className={classes.toggleButtonGrp}
                >
                  <ToggleButton
                    value="listView"
                    aria-label="listView"
                    className={classes.toggleButtonList}
                    onClick={() => setView('listView')}
                  >
                    <ListViewIcon />
                  </ToggleButton>
                  <ToggleButton
                    value="gridView"
                    aria-label="gridView"
                    className={classes.toggleButtonGrid}
                    onClick={() => setView('gridView')}
                  >
                    <GridViewIcon />
                  </ToggleButton>
                </ToggleButtonGroup>
              </Stack>
            </Grid>
          </Grid>
          <Grid container className={classes.resourceContainer}>
            {view === 'listView'
              ? (
                <Grid container className={classes.resourcesListView}>
                  {setLocalStorage('myResourceView', 'listView')}
                  <ListView
                    tableData={resources}
                    typeResources="teacher"
                    refreshData={refreshList}
                    selectedResource={setSelectedResource}
                    folderName={folderName}
                    setFolderName={setFolderName}
                    courseSelectedvalue={isSelectedvalue}
                    pageNext={nextPage}
                    pagenum={pagenum}
                    setPagenum={setPagenum}
                    setLoading={setLoading}
                    isFetching={isFetching}
                    setIsFetching={setIsFetching}
                    setActivateChecked={setActivateChecked}
                    allResources={teacherResource}
                    sortOrder={sortOrder}
                    setSortOrder={setSortOrder}
                    setSortBoth={setSortBoth}
                    sortBoth={sortBoth}
                    resourcesData={resources}
                    isSysAdminOrSuperUser={isSysAdminOrSuperUser}
                  />
                </Grid>
              )
              : (
                <Grid container className={classes.resourcesGridView}>
                  {setLocalStorage('myResourceView', 'gridView')}
                  <GridView
                    data={resources}
                    typeResources="teacher"
                    refreshData={refreshList}
                    selectedResource={setSelectedResource}
                    folderName={folderName}
                    setFolderName={setFolderName}
                    courseSelectedvalue={isSelectedvalue}
                    pageNext={nextPage}
                    pagenum={pagenum}
                    setPagenum={setPagenum}
                    setLoading={setLoading}
                    isFetching={isFetching}
                    setIsFetching={setIsFetching}
                    setActivateChecked={setActivateChecked}
                    allResources={teacherResource}
                    sortOrder={sortOrder}
                    setSortOrder={setSortOrder}
                    setSortBoth={setSortBoth}
                    sortBoth={sortBoth}
                    resourcesData={resources}
                    isSysAdminOrSuperUser={isSysAdminOrSuperUser}
                  />
                </Grid>
              )}
          </Grid>
        </Stack>
      </div>
      {(loading && teacherResource?.length === 0) ? (
        <Grid>
          <Loader message={t('LOADING')} />
        </Grid>
      ) : (!loading && teacherResource?.length === 0) && (
        <Grid container className={classes.noData}>
          <Grid item xs={12}>
            <DoNotDisturbIcon />
          </Grid>
          <Grid item xs={12}>
            <Typography variant="subtitle2" color="text.secondary">
              {t('NO_DATA')}
            </Typography>
          </Grid>
        </Grid>
      )}
    </div>
  );
}
