/* eslint-disable no-console */
/* eslint-disable no-unused-expressions */
import Constant from '../constant/index';
import {
  getEmailTemplateService,
  saveEmailTemplateService,
  getAnnouncementListService,
  getAnnouncementByIdService,
  sendAnnouncementService,
  getEmailFilterService,
  getRecipientsByFilterService,
  getSubstitutionTagsService,
  getAnnouncementListServiceByUserID,
  getRecipientsCountService,
} from '../services/auth';

export const getEmailTemplate = () => (dispatch) => {
  getEmailTemplateService()
    .then((response) => {
      dispatch({
        type: Constant.GET_EMAIL_TEMPLATES,
        payload: response.data,
      });
    }).catch((error) => {
      console.log('error :', error);
    });
};

export const saveEmailTemplate = (payload) => () => {
  saveEmailTemplateService(payload)
    .then(() => {
    }).catch((error) => {
      console.log('error :', error);
    });
};

export const getAnnouncementList = (payload, pageNumber, pageLimit, nextPage) => (dispatch) => {
  getAnnouncementListService(payload, pageNumber, pageLimit)
    .then((response) => {
      nextPage
        ? nextPage(
          response,
          pageNumber === 1 ? 100 : response?.count,
          pageNumber,
        )
        : null;
      if (pageNumber === 1) {
        dispatch({
          type: Constant.GET_ANNOUNCEMENTS,
          payload: response?.rows,
        });
        dispatch({
          type: Constant.ANNOUNCEMENTS_COUNT,
          payload: response?.count,
        });
      } else if (pageNumber > 1) {
        dispatch({
          type: Constant.GET_PAGINATED_ANNOUNCEMENTS,
          payload: response?.rows,
        });
        dispatch({
          type: Constant.ANNOUNCEMENTS_COUNT,
          payload: response?.count,
        });
      } else {
        dispatch({
          type: Constant.GET_ANNOUNCEMENTS,
          payload: [],
        });
        dispatch({
          type: Constant.GET_PAGINATED_ANNOUNCEMENTS,
          payload: [],
        });
        dispatch({
          type: Constant.ANNOUNCEMENTS_COUNT,
          payload: 0,
        });
      }
    }).catch((error) => {
      console.log('error :', error);
    });
};

export const getAnnouncementListByUserID = (payload, pageNumber, pageLimit, nextPage) => (dispatch) => {
  getAnnouncementListServiceByUserID(payload, pageNumber, pageLimit)
    .then((response) => {
      nextPage
        ? nextPage(
          response,
          pageNumber === 1 ? 100 : response?.count,
          pageNumber,
        )
        : null;
      if (pageNumber === 1) {
        dispatch({
          type: Constant.GET_ANNOUNCEMENTS,
          payload: response?.rows,
        });
        dispatch({
          type: Constant.ANNOUNCEMENTS_COUNT,
          payload: response?.count,
        });
      } else if (pageNumber > 1) {
        dispatch({
          type: Constant.GET_PAGINATED_ANNOUNCEMENTS,
          payload: response?.rows,
        });
        dispatch({
          type: Constant.ANNOUNCEMENTS_COUNT,
          payload: response?.count,
        });
      } else {
        dispatch({
          type: Constant.GET_ANNOUNCEMENTS,
          payload: [],
        });
        dispatch({
          type: Constant.GET_PAGINATED_ANNOUNCEMENTS,
          payload: [],
        });
        dispatch({
          type: Constant.ANNOUNCEMENTS_COUNT,
          payload: 0,
        });
      }
    }).catch((error) => {
      console.log('error :', error);
    });
};

export const getAnnouncementById = (id) => (dispatch) => {
  getAnnouncementByIdService(id)
    .then((res) => {
      dispatch({
        type: Constant.GET_ANNOUNCEMENT_BY_ID,
        payload: res,
      });
    }).catch((error) => {
      console.log('error :', error);
    });
};

export const sendAnnouncement = (payload, loader, openSuccessDialog, openErrorDialog, setErrorCode) => () => {
  sendAnnouncementService(payload)
    .then(() => {
      loader(false);
      openSuccessDialog(true);
      setErrorCode(0);
    }).catch((error) => {
      loader(false);
      openErrorDialog(true);
      setErrorCode(1);
      console.log('error :', error);
    });
};

export const getRecipientsCount = async (body) => {
  try {
    const res = await getRecipientsCountService(body);
    return res?.data || '0';
  } catch (error) {
    console.error('Error fetching recipients count:', error);
    throw error;
  }
};

export const getEmailFilters = (payload) => (dispatch) => {
  getEmailFilterService(payload)
    .then((res) => {
      dispatch({
        type: Constant.GET_EMAIL_FILTERS,
        payload: res.data,
      });
    }).catch((error) => {
      if (error.message === Constant.NO_PERMISSION) {
        localStorage.setItem('permission', false);
      }

      console.log('error :', error);
    });
};

export const getRecipientsByFilter = (payload, setLoading, openErrorDialog, setErrorCode) => (dispatch) => {
  getRecipientsByFilterService(payload)
    .then((res) => {
      dispatch({
        type: Constant.RECIPIENT_OPTIONS,
        payload: res.data,
      });
      setLoading && setLoading(false);
    }).catch((error) => {
      setLoading && setLoading(false);
      setErrorCode(3);
      openErrorDialog(true);
      console.log('error :', error);
    });
};
export const getSubstitutionTagsList = (openErrorDialog, setErrorCode) => (dispatch) => {
  getSubstitutionTagsService()
    .then((res) => {
      dispatch({
        type: Constant.GET_SUBSTITUTION_TAGS,
        payload: res.data,
      });
    }).catch((error) => {
      openErrorDialog(true);
      setErrorCode(4);
      console.log('error :', error);
    });
};

export default {
  getEmailTemplate,
  saveEmailTemplate,
  getAnnouncementList,
  sendAnnouncement,
  getAnnouncementById,
  getRecipientsByFilter,
  getSubstitutionTagsList,
};
