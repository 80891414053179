import * as React from 'react';
import Card from '@mui/material/Card';
import { useTranslation } from 'react-i18next';
import CardContent from '@mui/material/CardContent';
import { useGoogleLogin } from '@react-oauth/google';
import { Box, Typography, Grid } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import ReactModal from 'react-modal';
import CloseIcon from '@mui/icons-material/Close';
import axios from 'axios';
import constant from '../../constant/config';
import userRoles from '../../constant/userRoles';
import encryptedUserRoles from '../../constant/encryptedUserRoles';
import Button from '../../components/atoms/button';
import Loader from '../../components/atoms/loader';
import { NavigateRoutes } from '../../constant';
import useStyles from '../../custom-hooks/useStyles';
import Divider from '../../components/atoms/divider';
import styles from './style';
import logo from '../../assets/images/logo.png';
import commonStyle from '../../utils/commonClasses';
import DialogAtom from '../../components/atoms/dialog';
import { getLoginTokenService } from '../../store/services/auth';
import emailIcon from '../../assets/images/email-icon.png';
import callIcon from '../../assets/images/call-icon.png';
import videoIcon from '../../assets/images/video-icon.png';
import { setLocalStorage } from '../../utils/localStorageMethod';
import { hasRole } from '../../utils/methods';

export const axiosApiCall = (url, method, body = {}) => axios({
  method,
  url: `${constant.API_BASE_PROD_V2}${url}`,
  data: body,
});
function DialogContent() {
  const { t } = useTranslation('translation');
  return (
    <Grid container justifyContent="center">
      {t('VALID_CREDS')}
    </Grid>
  );
}
function DialogFooter({
  classes, handleDialog, t,
}) {
  const commonclasses1 = useStyles(commonStyle)();
  return (
    <Grid container className={classes.content} justifyContent="center">
      <Button
        name={t('OK')}
        onClick={handleDialog}
        btntype={commonclasses1.PRIMARY}
      />
    </Grid>
  );
}
function HomePage() {
  const { t } = useTranslation();
  const classes = useStyles(styles)();
  const commonclasses = useStyles(commonStyle)();
  const navigate = useNavigate();
  const [isLoginfailed, setFailureDialog] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [message, setMessage] = React.useState('Loading');
  const [open, setOpen] = React.useState(false);
  const handleToggle = (event, reason) => {
    if (reason && reason === 'backdropClick') { return; }
    setOpen(!open);
  };
  const login = useGoogleLogin({
    onSuccess: (response) => {
      const accessToken = response.access_token;
      setLoading(true);
      setTimeout(() => {
        setMessage('Logging you in');
      }, 500);
      if (accessToken) {
        setLocalStorage('accessToken', JSON.stringify(response));
        setFailureDialog(false);
        getLoginTokenService({ accessToken }).then((res) => {
          if (res?.tokenResponse?.status) {
            setLocalStorage('collapseListStudent', false);
            setLocalStorage('academicYear', res?.tokenResponse?.academicYear);
            setLocalStorage('currentAcademicYear', res?.tokenResponse?.academicYear);
            setLocalStorage('token', res.tokenResponse.JWT);
            setLocalStorage('username', `${res?.tokenResponse?.userInfo?.firstName} ${res?.tokenResponse?.userInfo?.lastName}`);
            setLocalStorage('profilePhoto', res?.tokenResponse?.userInfo?.profilePhoto);
            setLocalStorage('userEmailId', res?.tokenResponse?.userInfo?.manabadiEmail);
            setLocalStorage('userId', res?.tokenResponse?.userInfo?.id);
            setLocalStorage('adminBulkEmailUsers', '');
            setLocalStorage('currentDobCutoff', res?.tokenResponse?.dobCutoff);
            const roles = res?.tokenResponse?.userInfo?.roles;
            setLocalStorage('hasCurriculumRole', hasRole(roles, userRoles.CURRICULUM));
            if (roles.find((role) => role.name === userRoles.SYSTEM_ADMIN)) {
              setLocalStorage('userRole', encryptedUserRoles.SYSTEM_ADMIN);
              setLocalStorage('tempUserRole', encryptedUserRoles.SYSTEM_ADMIN);
              navigate(NavigateRoutes.ADMIN_DASHBOARD);
              setLoading(false);
            } else if (roles.find((role) => role.name === userRoles.SUPER_USER)) {
              setLocalStorage('userRole', encryptedUserRoles.SUPER_USER);
              setLocalStorage('tempUserRole', encryptedUserRoles.SUPER_USER);
              navigate(NavigateRoutes.ADMIN_DASHBOARD);
              setLoading(false);
            } else if (roles.find((role) => role.name === userRoles.REGION_COORDINATOR)) {
              setLocalStorage('userRole', encryptedUserRoles.REGION_COORDINATOR);
              setLocalStorage('tempUserRole', encryptedUserRoles.REGION_COORDINATOR);
              navigate(NavigateRoutes.ADMIN_DASHBOARD);
              setLoading(false);
            } else if (roles.find((role) => role.name === userRoles.LOCATION_COORDINATOR)) {
              setLocalStorage('userRole', encryptedUserRoles.LOCATION_COORDINATOR);
              setLocalStorage('tempUserRole', encryptedUserRoles.LOCATION_COORDINATOR);
              navigate(NavigateRoutes.LOCATION_COORDINATOR_STUDENTS_DASHBOARD);
              setLocalStorage('rolePermissions', res?.tokenResponse.userInfo?.roles?.permissions);
              setLoading(false);
            } else if (roles.find((role) => role.name === userRoles.TEACHER)) {
              setLocalStorage('userRole', encryptedUserRoles.TEACHER);
              setLocalStorage('tempUserRole', encryptedUserRoles.TEACHER);
              navigate(NavigateRoutes.TEACHER_DASHBOARD);
              setLocalStorage('rolePermissions', res?.tokenResponse.userInfo?.roles?.permissions);
              setLoading(false);
            } else if (roles.find((role) => role.name === userRoles.CURRICULUM)) {
              setLocalStorage('userRole', encryptedUserRoles.CURRICULUM);
              setLocalStorage('tempUserRole', encryptedUserRoles.CURRICULUM);
              navigate(NavigateRoutes.STUDENTS_RESOURCES);
              setLoading(false);
            } else if (roles.find((role) => role.name === userRoles.STUDENT)) {
              setLocalStorage('userRole', encryptedUserRoles.STUDENT);
              setLocalStorage('tempUserRole', encryptedUserRoles.STUDENT);
              navigate(NavigateRoutes.STUDENT_DASHBOARD);
              setLocalStorage('rolePermissions', res?.tokenResponse.userInfo?.roles?.permissions);
              setLoading(false);
            } else {
              navigate(NavigateRoutes.LOGIN);
              setLoading(false);
            }
          } else {
            setFailureDialog(true);
            setLoading(false);
          }
        }).catch(() => {
          setLoading(false);
        });
      }
    },
    onFailure: () => {
      navigate('/');
      setLoading(false);
    },
    scope: `${constant.REACT_APP_GOOGLE_DRIVE_SCOPE}`,
  });

  const handleDialog = () => {
    setFailureDialog(false);
    navigate('/');
  };
  return (
    <Box className={classes.mainHomeBox}>
      <Box>
        <img
          src={logo}
          alt="Manabadi"
          className={classes.headerLogo}
        />
      </Box>

      <Grid container display="flex" className={classes.wrapper}>
        { }
        <Grid
          item
          xs={12}
          md={7}
          lg={7}
          className={classes.cardLeft}
        />
        <Grid
          item
          xs={12}
          md={5}
          lg={5}
        >
          <Card className={classes.cardRight}>
            <CardContent>
              <Box style={{ marginTop: 5 }}>
                <span className={classes.returnText}>{t('RETURN')}</span>
              </Box>

              <Box
                className={classes.textMargin}
              >
                <Button
                  name={t('SIGN_IN_WITH_MANABADI_ID')}
                  onClick={() => {
                    if (localStorage.length !== 0) {
                      localStorage.clear();
                      sessionStorage.clear();
                    }
                    login();
                  }}
                  className={classes.googleButton}
                />
              </Box>
              <Box
                className={classes.smallTextMargin}
                onClick={() => navigate(NavigateRoutes.FORGOT_PASSWORD)}
              >
                <span className={classes.recoverPassword}>
                  {t('RECOVER_PASS')}
                </span>
              </Box>
              <Box
                className={classes.divider}
              >
                <Divider>or</Divider>
              </Box>
              <Box className={classes.googleButton}>
                <Button
                  name={t('NEW_REGISTER')}
                  onClick={() => navigate(NavigateRoutes.REGISTER)}
                />
              </Box>
              <Box className={classes.smallTextMargin}>
                <Grid container display="flex" className={classes.helpBox}>
                  <Grid
                    item
                    xs={12}
                    md={3}
                    lg={3}
                    className={classes.helpText}
                  >
                    <Typography>
                      <a className={commonclasses.contactUsLinks} href="https://sites.google.com/view/manabadi-helpsite/home" target="_blank" rel="noreferrer">{t('NEED_HELP')}</a>
                    </Typography>
                    <Typography className={classes.helpTextContact}>
                      <a className={commonclasses.contactUsLinks} href="https://manabadi.siliconandhra.org/contact/" target="_blank" rel="noreferrer">Contact Us</a>
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    md={9}
                    lg={9}
                    className={classes.contactUsBox}
                  >
                    <Box display="flex" direction="row" alignItems="center" className={classes.contactUsMargin}>
                      <img alt="" src={videoIcon} className={classes.helpImg} />
                      <Typography className={classes.contactUs}>
                        <a className={commonclasses.contactUsLinks} href="https://sites.google.com/view/manabadi-helpsite/home" target="_blank" rel="noreferrer">{t('REGISTER_VIDEOS')}</a>
                      </Typography>
                    </Box>
                    <ReactModal
                      isOpen={open}
                      onRequestClose={handleToggle}
                      className={classes.reactModal}
                      overlayClassName={classes.reactOverlay}
                      shouldCloseOnOverlayClick={false}
                    >
                      <video
                        className="video-js"
                        src="https://storage.googleapis.com/manabadi-portal-bucket/Others/NewRegistrationHelpVideo.mp4"
                        autoPlay
                        controls
                        style={{
                          height: 'auto', width: '70%',
                        }}
                      >
                        <track src="captions_en.vtt" kind="captions" srcLang="en" label="english_captions" />
                      </video>
                      <div className={classes.fileClose}>
                        <CloseIcon onClick={handleToggle} />
                      </div>
                    </ReactModal>
                    <Box display="flex" direction="row" alignItems="center" className={classes.contactUsMargin}>
                      <img alt="" src={callIcon} className={classes.helpImg} />
                      <Typography className={classes.contactUsDes}>
                        { }
                        <a className={commonclasses.contactUsLinks} href="tel:+18446262334">1 (844) 626-BADI (2234)</a>
                      </Typography>
                    </Box>

                    <Box display="flex" direction="row" alignItems="center" className={classes.contactUsMargin}>
                      <img alt="" src={emailIcon} className={classes.helpImg} />
                      <Typography className={classes.contactUsDes}>
                        <a className={commonclasses.contactUsLinks} href="mailto:support@manabadi.siliconandhra.org">support@manabadi.siliconandhra.org</a>
                      </Typography>
                    </Box>
                  </Grid>
                </Grid>
              </Box>

            </CardContent>
          </Card>
        </Grid>
        <Grid
          item
          lg={1}
        />
      </Grid>
      <DialogAtom
        isOpen={isLoginfailed}
        dialogHeading={t('LOGIN_FAILED')}
        content={(<DialogContent />)}
        footer={(
          <DialogFooter
            classes={classes}
            handleDialog={handleDialog}
            navigate={navigate}
            t={t}
          />
        )}
      />
      {loading && (
        <Grid>
          <Loader message={message} />
        </Grid>
      )}
    </Box>
  );
}

export default HomePage;
