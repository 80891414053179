/* eslint-disable */
import React from 'react';
import { Box, IconButton } from '@mui/material';
import Constant from '../../../store/constant';
import CommonProfileImage from '../../../components/molecules/common/CommonProfileImage';
import {
  getGradeforScore, isNullOrUndefined, renderGradeCell, renderGpaCell, renderAnnualScoreCell,
} from '../../../utils/methods';
import { setLocalStorage } from '../../../utils/localStorageMethod';
import { NavigateRoutes } from '../../../constant';
import { MailIcon, SwapIcon } from '../../../assets/svg';

const getNewReturningHeader = (t) => (
  <div>
    <span style={{ height: '5vh' }}>
      {t('New / ')}
    </span>
    <div>{t('Returning')}</div>
  </div>
);

const getMarksHeader = (t) => (
  <div>
    <span style={{ height: '5vh' }}>
      {t('MARKS')}
    </span>
    <div>{t('Q2')}</div>
  </div>
);

const getHomeworkHeader = (t) => (
  <div>
    <span style={{ height: '5vh', left: 30 }}>
      {t('HOMEWORK')}
    </span>
    <div>{t('Q2')}</div>
  </div>
);

const getSemester3Header = (t) => (
  <div>
    <span style={{ height: '5vh', left: 0 }}>
      {t('PSTU/SUPP')}
    </span>
    <div>
      {' '}
      <span style={{ left: 50, top: 1 }}>{t('MARKS')}</span>
      {' '}
    </div>
  </div>
);

function getAllQuarterFields(t) {
  const allFields = [t('PICTURES'), t('STUDENT_NAME'), t('AGE'), t('PARENT_NAME'),
    t('CONTACT_NO'), t('T-SHIRT-SIZE'), t('COURSE'), t('SECTION'), t('NEW_RETURNING'), t('MARKS'), t('HOMEWORK'),
    t('BONUS_MARKS'), t('GRADE'), t('GPA'), t('ANNUAL_SCORE'), t('ACTIONS'), t('GENDER')
  ];

  return allFields;
}

function getAllSemesterFields2023(t) {
  const allFields = [t('PICTURES'), t('STUDENT_NAME'), t('GENDER'), t('AGE'), t('PARENT_NAME'),
    t('CONTACT_NO'), t('T-SHIRT-SIZE'), t('COURSE'), t('SECTION'), t('NEW_RETURNING'),
    t('S1_EXAM'), t('S1_HW'), t('S1_QUIZ1'), t('S1_QUIZ2'), t('S1_ASSIGNMENT'), t('S1_CO-CURRICULAR'), t('S1_TOTAL'), t('S1_GRADE'),
    t('S2_EXAM'), t('S2_HW'), t('S2_QUIZ3'), t('S2_QUIZ4'), t('S2_ASSIGNMENT'), t('S2_CO-CURRICULAR'), t('S2_TOTAL'), t('S2_GRADE'),
    t('PSTU/Supplementary'), t('GRADE'), t('GPA'), t('ANNUAL_SCORE'), t('ACTIONS')
  ];

  return allFields;
}

function getAllSemesterFields(t) {
  const allFields = [t('PICTURES'), t('STUDENT_NAME'), t('GENDER'), t('AGE'), t('PARENT_NAME'),
    t('CONTACT_NO'), t('T-SHIRT-SIZE'), t('COURSE'), t('SECTION'), t('NEW_RETURNING'),
    t('S1_EXAM'), t('S1_HW'), t('S1_QUIZ1'), t('S1_ASSIGNMENT'), t('S1_CO-CURRICULAR'), t('S1_TOTAL'), t('S1_GRADE'),
    t('S2_EXAM'), t('S2_HW'), t('S2_QUIZ2'), t('S2_ASSIGNMENT'), t('S2_CO-CURRICULAR'), t('S2_TOTAL'), t('S2_GRADE'),
    t('PSTU/Supplementary'), t('GRADE'), t('GPA'), t('ANNUAL_SCORE'), t('ACTIONS')
  ];

  return allFields;
}

function getQuarterCSVHeaders(t) {
  const ALL_FIELDS = getAllQuarterFields(t);
  const CSV_HEADERS = [
    { name: ALL_FIELDS[1], label: ALL_FIELDS[1], key: 'studentName' },
    { name: ALL_FIELDS[2], label: ALL_FIELDS[2], key: 'age' },
    { name: ALL_FIELDS[16], label: ALL_FIELDS[16], key: 'gender' },
    { name: ALL_FIELDS[3], label: ALL_FIELDS[3], key: 'parentName' },
    { name: ALL_FIELDS[4], label: ALL_FIELDS[4], key: 'phoneNumber' },
    { name: ALL_FIELDS[5], label: ALL_FIELDS[5], key: 'tShirtSize' },
    { name: ALL_FIELDS[6], label: ALL_FIELDS[6], key: 'course' },
    { name: ALL_FIELDS[7], label: ALL_FIELDS[7], key: 'section' },
    { name: ALL_FIELDS[8], label: ALL_FIELDS[8], key: 'newReturning' },
    { name: ALL_FIELDS[9], label: 'Marks-Q1', key: 'marksQ1' },
    { name: ALL_FIELDS[9], label: 'Marks-Q2', key: 'marksQ2' },
    { name: ALL_FIELDS[9], label: 'Marks-Q3', key: 'marksQ3' },
    { name: ALL_FIELDS[10], label: 'Homework-Q1', key: 'homeworkQ1' },
    { name: ALL_FIELDS[10], label: 'Homework-Q2', key: 'homeworkQ2' },
    { name: ALL_FIELDS[10], label: 'Homework-Q3', key: 'homeworkQ3' },
    { name: ALL_FIELDS[11], label: ALL_FIELDS[11], key: 'bonus' },
    { name: ALL_FIELDS[12], label: ALL_FIELDS[12], key: 'grade' },
    { name: ALL_FIELDS[13], label: ALL_FIELDS[13], key: 'gpa' },
    { name: ALL_FIELDS[14], label: ALL_FIELDS[14], key: 'annualScore' },
  ];

  return CSV_HEADERS;
}

function getSemesterCSVHeaders(t) {
  const ALL_FIELDS = getAllSemesterFields(t);
  const CSV_HEADERS = [
    { name: ALL_FIELDS[1], label: ALL_FIELDS[1], key: 'studentName' },
    { name: ALL_FIELDS[2], label: ALL_FIELDS[2], key: 'gender' },
    { name: ALL_FIELDS[3], label: ALL_FIELDS[3], key: 'age' },
    { name: ALL_FIELDS[4], label: ALL_FIELDS[4], key: 'parentName' },
    { name: ALL_FIELDS[5], label: ALL_FIELDS[5], key: 'phoneNumber' },
    { name: ALL_FIELDS[6], label: ALL_FIELDS[6], key: 'tShirtSize' },
    { name: ALL_FIELDS[7], label: ALL_FIELDS[7], key: 'course' },
    { name: ALL_FIELDS[8], label: ALL_FIELDS[8], key: 'section' },
    { name: ALL_FIELDS[9], label: ALL_FIELDS[9], key: 'newReturning' },

    { name: ALL_FIELDS[10], label: 'Marks-S1', key: 'marksS1' },
    { name: ALL_FIELDS[11], label: 'Homework-S1', key: 'homeworkS1' },
    { name: ALL_FIELDS[12], label: 'Quiz1-S1', key: 'quizS1Q1' },
    { name: ALL_FIELDS[13], label: 'Assignment-S1', key: 'assignmentS1' },
    { name: ALL_FIELDS[14], label: 'Co-Curricular-S1', key: 'curricularS1' },
    { name: ALL_FIELDS[15], label: 'Total-S1', key: 'S1Total' },
    { name: ALL_FIELDS[16], label: 'Grade-S1', key: 'gradeS1' },

    { name: ALL_FIELDS[17], label: 'Marks-S2', key: 'marksS2' },
    { name: ALL_FIELDS[18], label: 'Homework-S2', key: 'homeworkS2' },
    { name: ALL_FIELDS[19], label: 'Quiz2-S2', key: 'quizS2Q1' },
    { name: ALL_FIELDS[20], label: 'Assignment-S2', key: 'assignmentS2' },
    { name: ALL_FIELDS[21], label: 'Co-Curricular-S2', key: 'curricularS2' },
    { name: ALL_FIELDS[22], label: 'Total-S2', key: 'S2Total' },
    { name: ALL_FIELDS[23], label: 'Grade-S2', key: 'gradeS2' },

    { name: ALL_FIELDS[24], label: 'PSTU/Supplementary', key: 'marksS3' },
    { name: ALL_FIELDS[25], label: ALL_FIELDS[25], key: 'grade' },
    { name: ALL_FIELDS[26], label: ALL_FIELDS[26], key: 'gpa' },
    { name: ALL_FIELDS[27], label: ALL_FIELDS[27], key: 'annualScore' },
  ];

  return CSV_HEADERS;
}

function getSemesterCSVHeaders2023(t) {
  const ALL_FIELDS = getAllSemesterFields2023(t);
  const CSV_HEADERS = [
    { name: ALL_FIELDS[1], label: ALL_FIELDS[1], key: 'studentName' },
    { name: ALL_FIELDS[2], label: ALL_FIELDS[2], key: 'gender' },
    { name: ALL_FIELDS[3], label: ALL_FIELDS[3], key: 'age' },
    { name: ALL_FIELDS[4], label: ALL_FIELDS[4], key: 'parentName' },
    { name: ALL_FIELDS[5], label: ALL_FIELDS[5], key: 'phoneNumber' },
    { name: ALL_FIELDS[6], label: ALL_FIELDS[6], key: 'tShirtSize' },
    { name: ALL_FIELDS[7], label: ALL_FIELDS[7], key: 'course' },
    { name: ALL_FIELDS[8], label: ALL_FIELDS[8], key: 'section' },
    { name: ALL_FIELDS[9], label: ALL_FIELDS[9], key: 'newReturning' },

    { name: ALL_FIELDS[10], label: 'Marks-S1', key: 'marksS1' },
    { name: ALL_FIELDS[11], label: 'Homework-S1', key: 'homeworkS1' },
    { name: ALL_FIELDS[12], label: 'Quiz1-S1', key: 'quizS1Q1' },
    { name: ALL_FIELDS[13], label: 'Quiz2-S1', key: 'quizS1Q2' },
    { name: ALL_FIELDS[14], label: 'Assignment-S1', key: 'assignmentS1' },
    { name: ALL_FIELDS[15], label: 'Co-Curricular-S1', key: 'curricularS1' },
    { name: ALL_FIELDS[16], label: 'Total-S1', key: 'S1Total' },
    { name: ALL_FIELDS[17], label: 'Grade-S1', key: 'gradeS1' },

    { name: ALL_FIELDS[18], label: 'Marks-S2', key: 'marksS2' },
    { name: ALL_FIELDS[19], label: 'Homework-S2', key: 'homeworkS2' },
    { name: ALL_FIELDS[20], label: 'Quiz3-S2', key: 'quizS2Q1' },
    { name: ALL_FIELDS[21], label: 'Quiz4-S2', key: 'quizS2Q2' },
    { name: ALL_FIELDS[22], label: 'Assignment-S2', key: 'assignmentS2' },
    { name: ALL_FIELDS[23], label: 'Co-Curricular-S2', key: 'curricularS2' },
    { name: ALL_FIELDS[24], label: 'Total-S2', key: 'S2Total' },
    { name: ALL_FIELDS[25], label: 'Grade-S2', key: 'gradeS2' },

    { name: ALL_FIELDS[26], label: 'PSTU/Supplementary', key: 'marksS3' },
    { name: ALL_FIELDS[27], label: ALL_FIELDS[27], key: 'grade' },
    { name: ALL_FIELDS[28], label: ALL_FIELDS[28], key: 'gpa' },
    { name: ALL_FIELDS[29], label: ALL_FIELDS[29], key: 'annualScore' },
  ];

  return CSV_HEADERS;
}

function getQuarterColumnsData(
  visibleFields,
  t,
  classes,
  setOpenDialog,
  SwapCourse,
  dispatch,
  navigate,
  setCourseLocationInfo,
  setSwapCourseOrLocation,
) {
  const columnsData = [
    {
      field: 'profilePhoto',
      headerName: t('PICTURES'),
      sortable: false,
      hide: !visibleFields?.includes(t('PICTURES')),
      renderCell: (rowInfo) => (
        <CommonProfileImage
          key={rowInfo?.id}
          src={rowInfo?.row?.studentInfo?.profilePhoto}
        />
      ),
      headerClassName: 'pictureHeader',
      cellClassName: 'pictureCell',
    },
    {
      field: 'studentName',
      headerName: t('STUDENT_NAME'),
      hide: !visibleFields?.includes(t('STUDENT_NAME')),
      align: 'left',
      headerClassName: 'studentNameHeader',
      cellClassName: 'studentNameCell',
    },
    {
      field: 'gender',
      headerName: t('GENDER'),
      hide: !visibleFields?.includes(t('GENDER')),
      align: 'left',
      headerClassName: 'courseHeader',
      cellClassName: 'courseCell',
    },
    {
      field: 'parentName',
      headerName: t('PARENT_NAME'),
      hide: !visibleFields?.includes(t('PARENT_NAME')),
      align: 'left',
      headerClassName: 'commonHeader',
      cellClassName: 'commonCell',
    },
    {
      field: 'phoneNumber',
      hide: !visibleFields?.includes(t('CONTACT_NO')),
      headerName: t('CONTACT_NO'),
      align: 'left',
      headerClassName: 'phoneNumberHeader',
      cellClassName: 'phoneNumberCell',
    },
    {
      field: 'tShirtSize',
      hide: !visibleFields?.includes(t('T-SHIRT-SIZE')),
      headerName: t('T-SHIRT-SIZE'),
      align: 'left',
      headerClassName: 'tShirtSizeHeader',
      cellClassName: 'tShirtSizeCell',
    },
    {
      field: 'course',
      hide: !visibleFields?.includes(t('COURSE')),
      headerName: t('COURSE'),
      align: 'left',
      headerClassName: 'courseHeader',
      cellClassName: 'courseCell',
    },
    {
      field: 'section',
      hide: !visibleFields?.includes(t('SECTION')),
      headerName: t('SECTION'),
      align: 'left',
      headerClassName: 'courseHeader',
      cellClassName: 'courseCell',
    },
    {
      field: 'newReturning',
      hide: !visibleFields?.includes(t('NEW_RETURNING')),
      headerName: getNewReturningHeader(t),
      align: 'left',
      headerClassName: 'newReturningHeader',
      cellClassName: 'newReturningCell',
    },
    {
      field: 'marksQ1',
      disableColumnResize: 'false',
      hide: !visibleFields?.includes(t('MARKS')),
      sortable: false,
      headerName: t('Q1'),
      align: 'center',
      headerClassName: 'marksHeader',
      cellClassName: 'marksCell',
      renderCell: (cellValues) => (
        <span
          className={(isNullOrUndefined(cellValues?.row?.Q1PassingCriteria) && !cellValues?.row?.Q1Attended) ? classes.marksRed
            : cellValues?.row?.marksQ1 >= cellValues?.row?.Q1PassingCriteria ? classes.marksGreen
              : cellValues?.row?.marksQ1 < (parseInt(cellValues?.row?.Q1PassingCriteria, 10) - 10) ? classes.marksRed
                : cellValues?.row?.marksQ1 >= (parseInt(cellValues?.row?.Q1PassingCriteria, 10) - 10) ? classes.marksYellow
                  : classes.marksGrey}
          onClick={(e) => setOpenDialog(e, cellValues, 'marks', 'Q1')}
        >
          {isNullOrUndefined(cellValues?.row?.Q1PassingCriteria) && cellValues?.row?.Q1PassingCriteria >= 0
            ? Number.isInteger(cellValues?.row?.marksQ1) ? cellValues?.row?.marksQ1 : Number(cellValues?.row?.marksQ1).toFixed(2) : '-'}
          {isNullOrUndefined(cellValues?.row?.Q1PassingCriteria) && !cellValues?.row?.Q1Attended ? '(A)' : ''}
        </span>

      ),
    },
    {
      field: 'marksQ2',
      hide: !visibleFields?.includes(t('MARKS')),
      headerName: getMarksHeader(t),
      disableColumnResize: 'false',
      align: 'left',
      headerClassName: 'marksHeaderSpan',
      cellClassName: 'marksQ3Cell',
      sortable: false,
      renderCell: (cellValues) => (
        <span
          className={(isNullOrUndefined(cellValues?.row?.Q2PassingCriteria) && !cellValues?.row?.Q2Attended) ? classes.marksRed
            : cellValues?.row?.marksQ2 >= cellValues?.row?.Q2PassingCriteria ? classes.marksGreen
              : cellValues?.row?.marksQ2 < (parseInt(cellValues?.row?.Q2PassingCriteria, 10) - 10)
                ? classes.marksRed : cellValues?.row?.marksQ2 >= (parseInt(cellValues?.row?.Q2PassingCriteria, 10) - 10) ? classes.marksYellow
                  : classes.marksGrey}
          onClick={(e) => setOpenDialog(e, cellValues, 'marks', 'Q2')}
        >
          {isNullOrUndefined(cellValues?.row?.Q2PassingCriteria) && cellValues?.row?.Q2PassingCriteria >= 0
            ? Number.isInteger(cellValues?.row?.marksQ2) ? cellValues?.row?.marksQ2 : Number(cellValues?.row?.marksQ2).toFixed(2) : '-'}
          {isNullOrUndefined(cellValues?.row?.Q2PassingCriteria) && !cellValues?.row?.Q2Attended ? '(A)' : ''}
        </span>
      ),
    },
    {
      field: 'marksQ3',
      hide: !visibleFields?.includes(t('MARKS')),
      headerName: t('Q3'),
      align: 'center',
      headerClassName: 'marksQ3Header',
      cellClassName: 'marksQ3Cell',
      sortable: false,
      renderCell: (cellValues) => (
        <span
          className={(isNullOrUndefined(cellValues?.row?.Q3PassingCriteria) && !cellValues?.row?.Q3Attended) ? classes.marksRed
            : cellValues?.row?.marksQ3 >= cellValues?.row?.Q3PassingCriteria ? classes.marksGreen
              : cellValues?.row?.marksQ3 < (parseInt(cellValues?.row?.Q3PassingCriteria, 10) - 10)
                ? classes.marksRed : cellValues?.row?.marksQ3 >= (parseInt(cellValues?.row?.Q3PassingCriteria, 10) - 10) ? classes.marksYellow
                  : classes.marksGrey}
          onClick={(e) => setOpenDialog(e, cellValues, 'marks', 'Q3')}
        >
          {isNullOrUndefined(cellValues?.row?.Q3PassingCriteria) && cellValues?.row?.Q3PassingCriteria >= 0
            ? Number.isInteger(cellValues?.row?.marksQ3) ? cellValues?.row?.marksQ3 : Number(cellValues?.row?.marksQ3).toFixed(2) : '-'}
          {isNullOrUndefined(cellValues?.row?.Q3PassingCriteria) && !cellValues?.row?.Q3Attended ? '(A)' : ''}
        </span>
      ),
    },
    {
      field: 'homeworkQ1',
      hide: !visibleFields?.includes(t('HOMEWORK')),
      headerName: t('Q1'),
      align: 'center',
      headerClassName: 'marksHeader',
      cellClassName: 'marksCell',
      sortable: false,
      renderCell: (cellValues) => (
        <span
          className={isNullOrUndefined(cellValues?.row?.Q1HomeWorkWeightage) && (cellValues?.row?.Q1HomeWorkWeightage >= 0)
            ? classes.homeworkColor : classes.marksGrey}
          onClick={(e) => setOpenDialog(e, cellValues, 'homework-marks', 'Q1')}
        >
          {isNullOrUndefined(cellValues?.row?.Q1HomeWorkWeightage) && (cellValues?.row?.Q1HomeWorkWeightage >= 0)
            ? Number.isInteger(cellValues?.row?.homeworkQ1) ? cellValues?.row?.homeworkQ1 : Number(cellValues?.row?.homeworkQ1).toFixed(2) : '-'}
        </span>
      ),
    },
    {
      field: 'homeworkQ2',
      hide: !visibleFields?.includes(t('HOMEWORK')),
      headerName: getHomeworkHeader(t),
      align: 'left',
      headerClassName: 'homeworkHeaderSpan',
      cellClassName: 'marksQ3Cell',
      sortable: false,
      renderCell: (cellValues) => (
        <span
          className={isNullOrUndefined(cellValues?.row?.Q2HomeWorkWeightage) && (cellValues?.row?.Q2HomeWorkWeightage >= 0)
            ? classes.homeworkColor : classes.marksGrey}
          onClick={(e) => setOpenDialog(e, cellValues, 'homework-marks', 'Q2')}
        >
          {isNullOrUndefined(cellValues?.row?.Q2HomeWorkWeightage) && (cellValues?.row?.Q2HomeWorkWeightage >= 0)
            ? Number.isInteger(cellValues?.row?.homeworkQ2) ? cellValues?.row?.homeworkQ2 : Number(cellValues?.row?.homeworkQ2).toFixed(2) : '-'}
        </span>
      ),
    },
    {
      field: 'homeworkQ3',
      hide: !visibleFields?.includes(t('HOMEWORK')),
      sortable: false,
      headerName: t('Q3'),
      align: 'center',
      headerClassName: 'marksQ3Header',
      cellClassName: 'marksQ3Cell',
      renderCell: (cellValues) => (
        <span
          className={isNullOrUndefined(cellValues?.row?.Q3HomeWorkWeightage) && (cellValues?.row?.Q3HomeWorkWeightage >= 0)
            ? classes.homeworkColor : classes.marksGrey}
          onClick={(e) => setOpenDialog(e, cellValues, 'homework-marks', 'Q3')}
        >
          {isNullOrUndefined(cellValues?.row?.Q3HomeWorkWeightage) && (cellValues?.row?.Q3HomeWorkWeightage >= 0)
            ? Number.isInteger(cellValues?.row?.homeworkQ3) ? cellValues?.row?.homeworkQ3 : Number(cellValues?.row?.homeworkQ3).toFixed(2) : '-'}
        </span>
      ),
    },
    {
      field: 'bonus',
      hide: !visibleFields?.includes(t('BONUS_MARKS')),
      sortable: false,
      headerName: t('BONUS_MARKS'),
      headerClassName: 'bonusHeaderSpan',
      cellClassName: 'bonusCell',
      renderCell: (cellValues) => (
        <span
          className={classes.marksGreen}
          onClick={(e) => setOpenDialog(e, cellValues, 'bonus', null)}
        >
          {cellValues?.row?.bonus || 0}
        </span>
      ),
    },
    {
      field: 'grade',
      hide: !visibleFields?.includes(t('GRADE')),
      sortable: false,
      headerName: t('GRADE'),
      headerClassName: 'gradeHeader',
      cellClassName: 'gradeCell',
    },
    {
      field: 'gpa',
      hide: !visibleFields?.includes(t('GPA')),
      sortable: false,
      headerName: t('GPA'),
      headerAlign: 'center',
      align: 'left',
      headerClassName: 'gpaHeader',
      cellClassName: 'gpaCell',
    },
    {
      field: 'annualScore',
      hide: !visibleFields?.includes(t('ANNUAL_SCORE')),
      headerName: t('ANNUAL_SCORE'),
      align: 'left',
      headerClassName: 'commonHeader',
      cellClassName: 'commonCell',
    },
    {
      field: 'actions',
      hide: !visibleFields?.includes(t('ACTIONS')),
      headerName: t('ACTIONS'),
      sortable: false,
      headerAlign: 'center',
      align: 'center',
      headerClassName: 'commonHeader',
      cellClassName: 'actionCells',
      renderCell: (row) => (
        <span>
          <span
            style={{ paddingRight: 25 }}
          >
            <IconButton onClick={() => {
              const data = new SwapCourse(row);
              setCourseLocationInfo(data);
              setSwapCourseOrLocation(true);
            }}
            >
              <SwapIcon />

            </IconButton>
          </span>
          <span>
            <IconButton
              onClick={() => {
                const { manabadiEmail } = row.row.studentInfo;
                const emailParents = [];
                emailParents.push(row?.row?.parent1Info?.personalEmail?.toString());
                emailParents.push(row?.row?.parent2Info?.personalEmail?.toString());
                dispatch({ type: Constant.SET_MAIL_SUBJECT, payload: '' });
                dispatch({ type: Constant.SET_MAIL_BODY, payload: '' });
                dispatch({ type: Constant.RECIPIENTS, payload: [manabadiEmail] });
                dispatch({ type: Constant.MAIL_FILTER, payload: 'Student' });
                dispatch({ type: Constant.MAIL_PARENTS, payload: emailParents });
                setLocalStorage('selectedLocation', row.row.enrolled_courses[0].location.id);
                setLocalStorage('selectedYear', row.row.enrolled_courses[0].academicYear);
                setLocalStorage('showLocationFilterRecipients', false);
                setLocalStorage('showLocationAnnouncementsRecipients', false);
                setLocalStorage('showSelectAllinEmail', false);
                navigate(NavigateRoutes.LOCATION_COORDINATOR_EMAIL);
                setLocalStorage('moduleReturn', 'Students');
              }}
            >
              <MailIcon />
            </IconButton>
          </span>
        </span>
      ),
    },
  ];

  return columnsData;
}

const getTotalQuiz1Marks = (semester, student) => {
  if (semester === 'S1') {
    return ((student?.quizMarks?.['S1Quiz 1'].calculatedWeightage || student?.quizMarks?.['S1Quiz 1'].calculatedWeightage >= 0))
      ? (Number((student?.quizMarks['S1Quiz 1']?.calculatedWeightage)).toFixed(2)) : '-';
  } if (semester === 'S2') {
    return ((student?.quizMarks?.['S2Quiz 1'].calculatedWeightage || student?.quizMarks?.['S2Quiz 1'].calculatedWeightage >= 0))
      ? (Number(student?.quizMarks?.['S2Quiz 1'].calculatedWeightage).toFixed(2)) : '-';
  }
  return null;
};

const getTotalQuiz2Marks = (semester, student) => {
  if (semester === 'S1') {
    return ((student?.quizMarks?.['S1Quiz 2'].calculatedWeightage || student?.quizMarks?.['S1Quiz 2'].calculatedWeightage >= 0))
      ? (Number((student?.quizMarks['S1Quiz 2']?.calculatedWeightage)).toFixed(2)) : '-';
  } if (semester === 'S2') {
    return ((student?.quizMarks?.['S2Quiz 2'].calculatedWeightage || student?.quizMarks?.['S2Quiz 2'].calculatedWeightage >= 0))
      ? (Number(student?.quizMarks?.['S2Quiz 2'].calculatedWeightage).toFixed(2)) : '-';
  }
  return null;
};

const renderS3Cell = (cellValues, setOpenDialog, classes) => {
  return (cellValues?.row?.enrolled_courses?.[0]?.course?.name !== 'BalaBadi - I' && cellValues?.row?.enrolled_courses?.[0]?.course?.name !== 'BalaBadi - II'
    && cellValues?.row?.enrolled_courses?.[0]?.course?.name !== 'Telugu-1' && cellValues?.row?.enrolled_courses?.[0]?.course?.name !== 'Telugu-3')
    ? (cellValues?.row?.enrolled_courses?.[0]?.course?.name === 'Prakasam' || cellValues?.row?.enrolled_courses?.[0]?.course?.name === 'Prabhasam'
    || cellValues?.row?.enrolled_courses?.[0]?.course?.name === 'Telugu-2' || cellValues?.row?.enrolled_courses?.[0]?.course?.name === 'Telugu-4') ? (
      <Box
        component="span"
        onClick={(e) => setOpenDialog(e, cellValues, 'marks', 'S3')}
        className={isNullOrUndefined(cellValues?.row?.marks?.S3?.passingCriteria) && !cellValues?.row?.marks?.S3?.isAttended ? classes.marksRed
          : cellValues?.row?.marks?.S3?.calculatedWeightage >= cellValues?.row?.marks?.S3?.passingCriteria ? classes.marksGreen
            : cellValues?.row?.marks?.S3?.calculatedWeightage < parseInt(cellValues?.row?.marks?.S3?.passingCriteria, 10) - 10 ? classes.marksRed
              : cellValues?.row?.marks?.S3?.calculatedWeightage >= parseInt(cellValues?.row?.marks?.S3?.passingCriteria, 10) - 10 ? classes.marksYellow
                : classes.marksGrey}
      >
        {isNullOrUndefined(cellValues?.row?.marks?.S3?.passingCriteria) && cellValues?.row?.marks?.S3?.passingCriteria >= 0
          ? Number.isInteger(cellValues?.row?.marks?.S3?.calculatedWeightage) ? cellValues?.row?.marks?.S3?.calculatedWeightage : Number(cellValues?.row?.marks?.S3?.calculatedWeightage).toFixed(2) : '-'}
        {isNullOrUndefined(cellValues?.row?.marks?.S3?.passingCriteria) && !cellValues?.row?.marks?.S3?.isAttended ? '(A)' : ''}
      </Box>
      ) : (cellValues?.row?.enrolled_courses?.[0]?.result === 'FAIL' || (cellValues?.row?.marks.S3?.calculatedWeightage > 0 && cellValues?.row?.marks.S3?.isAttended) || (cellValues?.row?.marks.S3?.calculatedWeightage === 0 && cellValues?.row?.marks.S3?.isAttended !== null)) ? (
        <Box
          component="span"
          onClick={(e) => setOpenDialog(e, cellValues, 'marks', 'S3')}
          className={isNullOrUndefined(cellValues?.row?.marks?.S3?.passingCriteria) && !cellValues?.row?.marks?.S3?.isAttended ? classes.marksRed
            : cellValues?.row?.marks?.S3?.calculatedWeightage >= cellValues?.row?.marks?.S3?.passingCriteria ? classes.marksGreen
              : cellValues?.row?.marks?.S3?.calculatedWeightage < parseInt(cellValues?.row?.marks?.S3?.passingCriteria, 10) - 10 ? classes.marksRed
                : cellValues?.row?.marks?.S3?.calculatedWeightage >= parseInt(cellValues?.row?.marks?.S3?.passingCriteria, 10) - 10 ? classes.marksYellow
                  : classes.marksGrey}
        >
          {isNullOrUndefined(cellValues?.row?.marks?.S3?.passingCriteria) && cellValues?.row?.marks?.S3?.passingCriteria >= 0
            ? Number.isInteger(cellValues?.row?.marks?.S3?.calculatedWeightage) ? cellValues?.row?.marks?.S3?.calculatedWeightage : Number(cellValues?.row?.marks?.S3?.calculatedWeightage).toFixed(2) : '-'}
          {isNullOrUndefined(cellValues?.row?.marks?.S3?.passingCriteria) && !cellValues?.row?.marks?.S3?.isAttended ? '(A)' : ''}
        </Box>
      ) : ((cellValues?.row?.marks.S1?.isAttended === false > 0 && cellValues?.row?.marks.S2?.isAttended === false)) ? (
        <Box
          component="span"
          onClick={(e) => setOpenDialog(e, cellValues, 'marks', 'S3')}
          className={isNullOrUndefined(cellValues?.row?.marks?.S3?.passingCriteria) && !cellValues?.row?.marks?.S3?.isAttended ? classes.marksRed
            : cellValues?.row?.marks?.S3?.calculatedWeightage >= cellValues?.row?.marks?.S3?.passingCriteria ? classes.marksGreen
              : cellValues?.row?.marks?.S3?.calculatedWeightage < parseInt(cellValues?.row?.marks?.S3?.passingCriteria, 10) - 10 ? classes.marksRed
                : cellValues?.row?.marks?.S3?.calculatedWeightage >= parseInt(cellValues?.row?.marks?.S3?.passingCriteria, 10) - 10 ? classes.marksYellow
                  : classes.marksGrey}
        >
          {isNullOrUndefined(cellValues?.row?.marks?.S3?.passingCriteria) && cellValues?.row?.marks?.S3?.passingCriteria >= 0
            ? Number.isInteger(cellValues?.row?.marks?.S3?.calculatedWeightage) ? cellValues?.row?.marks?.S3?.calculatedWeightage : Number(cellValues?.row?.marks?.S3?.calculatedWeightage).toFixed(2) : '-'}
          {isNullOrUndefined(cellValues?.row?.marks?.S3?.passingCriteria) && !cellValues?.row?.marks?.S3?.isAttended ? '(A)' : ''}
        </Box>
      ) : (
        'N/A'
      ) : (
      'N/A'
    );
};

function getSemesterColumnsData(
  visibleFields,
  t,
  classes,
  setOpenDialog,
  SwapCourse,
  dispatch,
  navigate,
  setCourseLocationInfo,
  setSwapCourseOrLocation,
  gradesData,
  selectedAcadYear
) {
  const columnsData = [
    {
      field: 'profilePhoto',
      headerName: t('PICTURES'),
      sortable: false,
      hide: !visibleFields?.includes(t('PICTURES')),
      renderCell: (rowInfo) => (
        <CommonProfileImage
          key={rowInfo?.id}
          src={rowInfo?.row?.studentInfo?.profilePhoto}
        />
      ),
      headerClassName: 'pictureHeader',
      cellClassName: 'pictureCell',
    },
    {
      field: 'studentName',
      headerName: t('STUDENT_NAME'),
      hide: !visibleFields?.includes(t('STUDENT_NAME')),
      align: 'left',
      headerClassName: 'studentNameHeader',
      cellClassName: 'studentNameCell',
    },
    {
      field: 'gender',
      headerName: t('GENDER'),
      hide: !visibleFields?.includes(t('GENDER')),
      align: 'left',
      headerClassName: 'courseHeader',
      cellClassName: 'courseCell',
      renderCell: (cellValues) => (
        <span>
          {isNullOrUndefined(cellValues?.row?.gender) && cellValues?.row?.gender !== '' && cellValues?.row?.gender !== 'null'
            ? cellValues?.row?.gender : 'N/A'}
        </span>
      ),
    },
    {
      field: 'age',
      headerName: t('AGE'),
      hide: !visibleFields?.includes(t('AGE')),
      align: 'left',
      headerClassName: 'courseHeader',
      cellClassName: 'courseCell',
      renderCell: (cellValues) => (
        <span>
          {isNullOrUndefined(cellValues?.row?.age) && cellValues?.row?.age !== '' && cellValues?.row?.age !== 'null'
            ? cellValues?.row?.age : 'N/A'}
        </span>
      ),
    },
    {
      field: 'parentName',
      headerName: t('PARENT_NAME'),
      hide: !visibleFields?.includes(t('PARENT_NAME')),
      align: 'left',
      headerClassName: 'commonHeader',
      cellClassName: 'commonCell',
    },
    {
      field: 'phoneNumber',
      hide: !visibleFields?.includes(t('CONTACT_NO')),
      headerName: t('CONTACT_NO'),
      align: 'left',
      headerClassName: 'phoneNumberHeader',
      cellClassName: 'phoneNumberCell',
    },
    {
      field: 'tShirtSize',
      hide: !visibleFields?.includes(t('T-SHIRT-SIZE')),
      headerName: t('T-SHIRT-SIZE'),
      align: 'left',
      headerClassName: 'tShirtSizeHeader',
      cellClassName: 'tShirtSizeCell',
      renderCell: (cellValues) => (
        <span>
          {isNullOrUndefined(cellValues?.row?.tShirtSize) && cellValues?.row?.tShirtSize !== '' && cellValues?.row?.tShirtSize !== 'null'
            ? cellValues?.row?.tShirtSize : 'N/A'}
        </span>
      ),
    },
    {
      field: 'course',
      hide: !visibleFields?.includes(t('COURSE')),
      headerName: t('COURSE'),
      align: 'left',
      headerClassName: 'courseHeader',
      cellClassName: 'courseCell',
    },
    {
      field: 'section',
      hide: !visibleFields?.includes(t('SECTION')),
      headerName: t('SECTION'),
      align: 'left',
      headerClassName: 'courseHeader',
      cellClassName: 'courseCell',
    },
    {
      field: 'newReturning',
      hide: !visibleFields?.includes(t('NEW_RETURNING')),
      headerName: getNewReturningHeader(t),
      align: 'left',
      headerClassName: 'newReturningHeader',
      cellClassName: 'newReturningCell',
    },
    {
      field: 'marksS1',
      disableColumnResize: 'false',
      hide: !visibleFields?.includes(t('S1_EXAM')),
      sortable: false,
      headerName: `S1 ${t('EXAM')}`,
      align: 'center',
      headerClassName: 'marksHeader',
      cellClassName: 'marksCell',
      renderCell: (cellValues) => (
        <span
          className={(isNullOrUndefined(cellValues?.row?.S1PassingCriteria) && !cellValues?.row?.S1Attended) ? classes.marksRed
            : cellValues?.row?.marksS1 >= cellValues?.row?.S1PassingCriteria ? classes.marksGreen
              : cellValues?.row?.marksS1 < (parseInt(cellValues?.row?.S1PassingCriteria, 10) - 10) ? classes.marksRed
                : cellValues?.row?.marksS1 >= (parseInt(cellValues?.row?.S1PassingCriteria, 10) - 10) ? classes.marksYellow
                  : classes.marksGrey}
          onClick={(e) => setOpenDialog(e, cellValues, 'marks', 'S1')}
        >
          {isNullOrUndefined(cellValues?.row?.S1PassingCriteria) && cellValues?.row?.S1PassingCriteria >= 0
            ? Number.isInteger(cellValues?.row?.marksS1) ? cellValues?.row?.marksS1 : Number(cellValues?.row?.marksS1).toFixed(2) : '-'}
          {isNullOrUndefined(cellValues?.row?.S1PassingCriteria) && !cellValues?.row?.S1Attended ? '(A)' : ''}
        </span>

      ),
    },
    {
      field: 'homeworkS1',
      hide: !visibleFields?.includes(t('S1_HW')),
      headerName: `S1 ${t('HW')}`,
      align: 'center',
      headerClassName: 'marksHeader',
      cellClassName: 'marksCell',
      sortable: false,
      renderCell: (cellValues) => (
        <span
          className={isNullOrUndefined(cellValues?.row?.S1HomeWorkWeightage) && (cellValues?.row?.S1HomeWorkWeightage >= 0)
            ? classes.homeworkColor : classes.marksGrey}
          onClick={(e) => setOpenDialog(e, cellValues, 'homework-marks', 'S1')}
        >
          {isNullOrUndefined(cellValues?.row?.S1HomeWorkWeightage) && (cellValues?.row?.S1HomeWorkWeightage >= 0)
            ? Number.isInteger(cellValues?.row?.homeworkS1) ? cellValues?.row?.homeworkS1 : Number(cellValues?.row?.homeworkS1).toFixed(2) : '-'}
        </span>
      ),
    },
    {
      field: 'quiz1S1',
      hide: !visibleFields?.includes(t('S1_QUIZ1')),
      headerName: `S1 ${t('QUIZ1')}`,
      align: 'center',
      headerClassName: 'marksHeader',
      cellClassName: 'marksCell',
      sortable: false,
      renderCell: (cellValues) => (
        <span
          className={((isNullOrUndefined(cellValues?.row?.S1Q1QuizWeightage) && (cellValues?.row?.S1Q1QuizWeightage >= 0)))
            ? classes.quizColor : classes.marksGrey}
          onClick={(e) => setOpenDialog(e, cellValues, 'quiz1-marks', 'S1')}
        >
          {((isNullOrUndefined(cellValues?.row?.S1Q1QuizWeightage) && (cellValues?.row?.S1Q1QuizWeightage >= 0)))
            ? getTotalQuiz1Marks('S1', cellValues?.row) : '-'}
        </span>
      ),
    },
    {
      field: 'quiz2S1',
      hide: (selectedAcadYear !== '2023-2024') || !visibleFields?.includes(t('S1_QUIZ2')),
      headerName: `S1 ${t('QUIZ2')}`,
      align: 'center',
      headerClassName: 'marksHeader',
      cellClassName: 'marksCell',
      sortable: false,
      renderCell: (cellValues) => (
        <span
          className={((isNullOrUndefined(cellValues?.row?.S1Q2QuizWeightage) && (cellValues?.row?.S1Q2QuizWeightage >= 0)))
            ? classes.quizColor : classes.marksGrey}
          onClick={(e) => setOpenDialog(e, cellValues, 'quiz2-marks', 'S1')}
        >
          {((isNullOrUndefined(cellValues?.row?.S1Q2QuizWeightage) && (cellValues?.row?.S1Q2QuizWeightage >= 0)))
            ? getTotalQuiz2Marks('S1', cellValues?.row) : '-'}
        </span>
      ),
    },
    {
      field: 'assignmentS1',
      hide: !visibleFields?.includes(t('S1_ASSIGNMENT')),
      headerName: `S1 ${t('ASS..MENT')}`,
      align: 'center',
      headerClassName: 'marksHeaderSpan',
      cellClassName: 'marksCell',
      sortable: false,
      renderCell: (cellValues) => (
        <span
          className={isNullOrUndefined(cellValues?.row?.S1AssignmentWeightage) && (cellValues?.row?.S1AssignmentWeightage >= 0)
            ? classes.assignmentsColor : classes.marksGrey}
          onClick={(e) => setOpenDialog(e, cellValues, 'assignments', 'S1')}
        >
          {isNullOrUndefined(cellValues?.row?.S1AssignmentWeightage) && (cellValues?.row?.S1AssignmentWeightage >= 0)
            ? Number.isInteger(cellValues?.row?.assignmentS1) ? cellValues?.row?.assignmentS1 : Number(cellValues?.row?.assignmentS1).toFixed(2) : '-'}
        </span>
      ),
    },
    {
      field: 'curricularS1',
      hide: !visibleFields?.includes(t('S1_CO-CURRICULAR')),
      headerName: `S1 ${t('Co-curricular')}`,
      align: 'center',
      headerClassName: 'marksHeader',
      cellClassName: 'curricularCell',
      sortable: false,
      renderCell: (cellValues) => (
        <span
          className={isNullOrUndefined(cellValues?.row?.S1CurricularWeightage) && (cellValues?.row?.S1CurricularWeightage >= 0)
            ? classes.curricularColor : classes.marksGrey}
          onClick={(e) => setOpenDialog(e, cellValues, 'curricular-marks', 'S1')}
        >
          {isNullOrUndefined(cellValues?.row?.S1CurricularWeightage) && (cellValues?.row?.S1CurricularWeightage >= 0)
            ? Number.isInteger(cellValues?.row?.curricularS1) ? cellValues?.row?.curricularS1 : Number(cellValues?.row?.curricularS1).toFixed(2) : '-'}
        </span>
      ),
    },
    {
      field: 'totalS1',
      hide: !visibleFields?.includes(t('S1_TOTAL')),
      headerName: `S1 ${t('Total')}`,
      align: 'center',
      headerClassName: 'marksHeader',
      cellClassName: 'curricularCell',
      sortable: false,
      renderCell: (cellValues) => (
        <span
          className={classes.marksGrey}
        >
          {(cellValues?.row?.S1Total)}
        </span>
      ),
    },
    {
      field: 'gradeS1',
      hide: !visibleFields?.includes(t('S1_GRADE')),
      headerName: `S1 ${t('GRADE')}`,
      align: 'center',
      headerClassName: 'marksHeader',
      cellClassName: 'borderCell',
      sortable: false,
      renderCell: (cellValues) => (
        <span
          className={classes.marksGrey}
        >
          {getGradeforScore(cellValues?.row?.S1Total, gradesData.gradeData)}
        </span>
      ),
    },
    {
      field: 'marksS2',
      disableColumnResize: 'false',
      hide: !visibleFields?.includes(t('S2_EXAM')),
      sortable: false,
      headerName: `S2 ${t('EXAM')}`,
      align: 'center',
      headerClassName: 'marksHeader',
      cellClassName: 'marksCell',
      renderCell: (cellValues) => (
        <span
          className={(isNullOrUndefined(cellValues?.row?.S2PassingCriteria) && !cellValues?.row?.S2Attended) ? classes.marksRed
            : cellValues?.row?.marksS2 >= cellValues?.row?.S2PassingCriteria ? classes.marksGreen
              : cellValues?.row?.marksS2 < (parseInt(cellValues?.row?.S2PassingCriteria, 10) - 10) ? classes.marksRed
                : cellValues?.row?.marksS2 >= (parseInt(cellValues?.row?.S2PassingCriteria, 10) - 10) ? classes.marksYellow
                  : classes.marksGrey}
          onClick={(e) => setOpenDialog(e, cellValues, 'marks', 'S2')}
        >
          {isNullOrUndefined(cellValues?.row?.S2PassingCriteria) && cellValues?.row?.S2PassingCriteria >= 0
            ? Number.isInteger(cellValues?.row?.marksS2) ? cellValues?.row?.marksS2 : Number(cellValues?.row?.marksS2).toFixed(2) : '-'}
          {isNullOrUndefined(cellValues?.row?.S2PassingCriteria) && !cellValues?.row?.S2Attended ? '(A)' : ''}
        </span>

      ),
    },
    {
      field: 'homeworkS2',
      hide: !visibleFields?.includes(t('S2_HW')),
      headerName: `S2 ${t('HW')}`,
      align: 'center',
      headerClassName: 'marksHeader',
      cellClassName: 'marksCell',
      sortable: false,
      renderCell: (cellValues) => (
        <span
          className={isNullOrUndefined(cellValues?.row?.S2HomeWorkWeightage) && (cellValues?.row?.S2HomeWorkWeightage >= 0)
            ? classes.homeworkColor : classes.marksGrey}
          onClick={(e) => setOpenDialog(e, cellValues, 'homework-marks', 'S2')}
        >
          {isNullOrUndefined(cellValues?.row?.S2HomeWorkWeightage) && (cellValues?.row?.S2HomeWorkWeightage >= 0)
            ? Number.isInteger(cellValues?.row?.homeworkS2) ? cellValues?.row?.homeworkS2 : Number(cellValues?.row?.homeworkS2).toFixed(2) : '-'}
        </span>
      ),
    },
    {
      field: 'quiz1S2',
      hide: (!visibleFields?.includes(t('S2_QUIZ3')) && !visibleFields?.includes(t('S2_QUIZ2'))),
      headerName: `S2 ${(selectedAcadYear !== '2023-2024') ? t('QUIZ2') : t('QUIZ3')}`,
      align: 'center',
      headerClassName: 'marksHeaderSpan2',
      cellClassName: 'marksCell',
      sortable: false,
      renderCell: (cellValues) => (
        <span
          className={((isNullOrUndefined(cellValues?.row?.S2Q1QuizWeightage) && (cellValues?.row?.S2Q1QuizWeightage >= 0)))
            ? classes.quizColor : classes.marksGrey}
          onClick={(e) => setOpenDialog(e, cellValues, 'quiz1-marks', 'S2')}
        >
          {((isNullOrUndefined(cellValues?.row?.S2Q1QuizWeightage) && (cellValues?.row?.S2Q1QuizWeightage >= 0)))
            ? getTotalQuiz1Marks('S2', cellValues?.row) : '-'}
        </span>
      ),
    },
    {
      field: 'quiz2S2',
      hide: (selectedAcadYear !== '2023-2024') || !visibleFields?.includes(t('S2_QUIZ4')),
      headerName: `S2 ${t('QUIZ4')}`,
      align: 'center',
      headerClassName: 'marksHeaderSpan2',
      cellClassName: 'marksCell',
      sortable: false,
      renderCell: (cellValues) => (
        <span
          className={((isNullOrUndefined(cellValues?.row?.S2Q2QuizWeightage) && (cellValues?.row?.S2Q2QuizWeightage >= 0)))
            ? classes.quizColor : classes.marksGrey}
          onClick={(e) => setOpenDialog(e, cellValues, 'quiz2-marks', 'S2')}
        >
          {((isNullOrUndefined(cellValues?.row?.S2Q2QuizWeightage) && (cellValues?.row?.S2Q2QuizWeightage >= 0)))
            ? getTotalQuiz2Marks('S2', cellValues?.row) : '-'}
        </span>
      ),
    },
    {
      field: 'assignmentS2',
      hide: !visibleFields?.includes(t('S2_ASSIGNMENT')),
      headerName: `S2 ${t('ASS..MENT')}`,
      align: 'center',
      headerClassName: 'marksHeaderSpan',
      cellClassName: 'marksCell',
      sortable: false,
      renderCell: (cellValues) => (
        <span
          className={isNullOrUndefined(cellValues?.row?.S2AssignmentWeightage) && (cellValues?.row?.S2AssignmentWeightage >= 0)
            ? classes.assignmentsColor : classes.marksGrey}
          onClick={(e) => setOpenDialog(e, cellValues, 'assignments', 'S2')}
        >
          {isNullOrUndefined(cellValues?.row?.S2AssignmentWeightage) && (cellValues?.row?.S2AssignmentWeightage >= 0)
            ? Number.isInteger(cellValues?.row?.assignmentS2) ? cellValues?.row?.assignmentS2 : Number(cellValues?.row?.assignmentS2).toFixed(2) : '-'}
        </span>
      ),
    },
    {
      field: 'curricularS2',
      hide: !visibleFields?.includes(t('S2_CO-CURRICULAR')),
      headerName: `S2 ${t('Co-curricular')}`,
      align: 'center',
      headerClassName: 'marksHeader',
      cellClassName: 'marksCell',
      sortable: false,
      renderCell: (cellValues) => (
        <span
          className={isNullOrUndefined(cellValues?.row?.S2CurricularWeightage) && (cellValues?.row?.S2CurricularWeightage >= 0)
            ? classes.curricularColor : classes.marksGrey}
          onClick={(e) => setOpenDialog(e, cellValues, 'curricular-marks', 'S2')}
        >
          {isNullOrUndefined(cellValues?.row?.S2CurricularWeightage) && (cellValues?.row?.S2CurricularWeightage >= 0)
            ? Number.isInteger(cellValues?.row?.curricularS2) ? cellValues?.row?.curricularS2 : Number(cellValues?.row?.curricularS2).toFixed(2) : '-'}
        </span>
      ),
    },
    {
      field: 'totalS2',
      hide: !visibleFields?.includes(t('S2_TOTAL')),
      headerName: `S2 ${t('Total')}`,
      align: 'center',
      headerClassName: 'marksHeader',
      cellClassName: 'curricularCell',
      sortable: false,
      renderCell: (cellValues) => (
        <span
          className={classes.marksGrey}
        >
          {(cellValues?.row?.S2Total)}
        </span>
      ),
    },
    {
      field: 'gradeS2',
      hide: !visibleFields?.includes(t('S2_GRADE')),
      headerName: `S2 ${t('GRADE')}`,
      align: 'center',
      headerClassName: 'marksHeader',
      cellClassName: 'borderCell',
      sortable: false,
      renderCell: (cellValues) => (
        <span
          className={classes.marksGrey}
        >
          {getGradeforScore(cellValues?.row?.S2Total, gradesData.gradeData)}
        </span>
      ),
    },
    {
      field: 'marksS3',
      disableColumnResize: 'false',
      hide: !visibleFields?.includes(t('PSTU/Supplementary')),
      sortable: false,
      headerName: getSemester3Header(t),
      align: 'center',
      headerClassName: 'marksHeaderSpan',
      cellClassName: 'marksCell',
      renderCell: (cellValues) => renderS3Cell(cellValues, setOpenDialog, classes),
    },
    {
      field: 'grade',
      hide: !visibleFields?.includes(t('GRADE')),
      sortable: false,
      headerName: t('GRADE'),
      headerClassName: 'semGradeHeader',
      cellClassName: 'semGradeCell',
      renderCell: (cellValues) => renderGradeCell(cellValues),
    },
    {
      field: 'gpa',
      hide: !visibleFields?.includes(t('GPA')),
      sortable: false,
      headerName: t('GPA'),
      headerAlign: 'center',
      align: 'left',
      headerClassName: 'semGpaHeader',
      cellClassName: 'semGpaCell',
      renderCell: (cellValues) => renderGpaCell(cellValues),
    },
    {
      field: 'annualScore',
      hide: !visibleFields?.includes(t('ANNUAL_SCORE')),
      headerName: t('ANNUAL_SCORE'),
      align: 'left',
      headerClassName: 'commonHeader',
      cellClassName: 'commonCell',
      renderCell: (cellValues) => renderAnnualScoreCell(cellValues),
    },
    {
      field: 'actions',
      hide: !visibleFields?.includes(t('ACTIONS')),
      headerName: t('ACTIONS'),
      sortable: false,
      headerAlign: 'center',
      align: 'center',
      headerClassName: 'commonHeader',
      cellClassName: 'semesterActionCells',
      renderCell: (row) => (
        <span>
          <span
            style={{ paddingRight: 25 }}
          >
            <IconButton onClick={() => {
              const data = new SwapCourse(row);
              setCourseLocationInfo(data);
              setSwapCourseOrLocation(true);
            }}
            >
              <SwapIcon />

            </IconButton>
          </span>
          <span>
            <IconButton
              onClick={() => {
                const { manabadiEmail } = row.row.studentInfo;
                const emailParents = [];
                emailParents.push(row?.row?.parent1Info?.personalEmail?.toString());
                emailParents.push(row?.row?.parent2Info?.personalEmail?.toString());
                dispatch({ type: Constant.SET_MAIL_SUBJECT, payload: '' });
                dispatch({ type: Constant.SET_MAIL_BODY, payload: '' });
                dispatch({ type: Constant.RECIPIENTS, payload: [manabadiEmail] });
                dispatch({ type: Constant.MAIL_FILTER, payload: 'Student' });
                dispatch({ type: Constant.MAIL_PARENTS, payload: emailParents });
                setLocalStorage('selectedLocation', row.row.enrolled_courses[0].location.id);
                setLocalStorage('selectedYear', row.row.enrolled_courses[0].academicYear);
                setLocalStorage('showLocationFilterRecipients', false);
                setLocalStorage('showLocationAnnouncementsRecipients', false);
                setLocalStorage('showSelectAllinEmail', false);
                navigate(NavigateRoutes.LOCATION_COORDINATOR_EMAIL);
                setLocalStorage('moduleReturn', 'Students');
              }}
            >
              <MailIcon />
            </IconButton>
          </span>
        </span>
      ),
    },
  ];

  return columnsData;
}

export {
  getQuarterColumnsData,
  getSemesterColumnsData,
  getQuarterCSVHeaders,
  getAllQuarterFields,
  getSemesterCSVHeaders,
  getAllSemesterFields,
  getAllSemesterFields2023,
  getSemesterCSVHeaders2023,
};
