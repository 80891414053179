/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable no-unused-vars */
/* eslint-disable no-nested-ternary */
/* eslint-disable no-confusing-arrow */
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';
import { Grid } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import useStyles from '../../../custom-hooks/useStyles';
import styles from './style';
import CheckboxAtom from '../../../components/atoms/checkbox';
import RadioboxAtom from '../../../components/atoms/radiobox';
import ButtonAtom from '../../../components/atoms/button';
import { Buttons } from '../../../constant';
import commonStyle from '../../../utils/commonClasses';
import DialogAtom from '../../../components/atoms/dialog';
import TextFieldAtom from '../../../components/atoms/text-field-with-label';
import constant from '../../../store/constant';
import { getLocalStorage } from '../../../utils/localStorageMethod';

function AddDialogFooter({
  classes, t, secHandle, primaryHandle,
}) {
  const commonClasses = useStyles(commonStyle)();
  return (
    <Grid container className={classes.dialogButtons}>
      <Grid item xs={8} />
      <Grid item xs={12} className={classes.button} justifyContent="flex-end">
        <ButtonAtom
          name={t('CANCEL')}
          onClick={secHandle}
          btntype={Buttons.SECONDARY}
        />
        <ButtonAtom
          name={t('CREATE')}
          onClick={primaryHandle}
          btntype={Buttons.PRIMARY}
          className={commonClasses.activeButton}
        />
      </Grid>
    </Grid>
  );
}

export default function StudentFilters({
  filterOptions, onAddView, setFilterOptions, setFilterView,
  handleGraphOption, setFilterChanged, isUserManagerView,
  setFilterYear,
  setSortBoth, setSelectAllCheck, setSelected, ...props
}) {
  const [allSelection, setAllSelection] = useState(false);
  const [allExpand, setAllExpand] = useState(false);
  const [selectedYearIndex, setSelectedYearIndex] = useState(0);
  const [selectedStatusIndex, setSelectedStatusIndex] = useState(0);
  const dispatch = useDispatch();
  const classes = useStyles(styles)();
  const { t } = useTranslation();
  const [showAddViewDialog, setShowAddViewDialog] = useState(false);
  const moduleName = getLocalStorage('moduleReturn');
  const { hideAddToView } = props;

  const allExpanded = useSelector(
    (reduxState) => moduleName === 'UserManager' ? reduxState.getUser.allExpanded : reduxState.getStudent.allExpanded,
  );

  // eslint-disable-next-line no-unused-vars

  const [title, setTitle] = useState('');
  const [titleError, setTitleError] = useState('');

  const onExpand = (filterValue, filterExpanded) => {
    const updatedFilters = [...filterOptions];
    const selectedFilterIndex = _.findIndex(updatedFilters, {
      value: filterValue,
    });
    updatedFilters[selectedFilterIndex].expanded = !filterExpanded;

    const filtersExpanded = updatedFilters.filter((item) => item.expanded).map((item) => item.label);
    dispatch({
      type: moduleName === 'UserManager' ? constant.SET_USER_EXPANDED_LABELS : constant.SET_EXPANDED_LABELS,
      payload: filtersExpanded,
    });
    if (allExpanded) {
      dispatch({
        type: moduleName === 'UserManager' ? constant.SET_ALL_USER_EXPAND : constant.SET_ALL_EXPAND,
        payload: !allExpanded,
      });
      setAllExpand(!allExpand);
    }

    setFilterOptions(updatedFilters);
  };

  const setSelectedFilter = (filters) => {
    const selectedFil = [];
    filters.forEach((filter) => {
      filter.options.forEach((opt) => {
        if (opt.checked) {
          selectedFil.push({
            label: opt.label?.toString(),
            groupBy: filter.label,
            filterKey: filter.filter,
          });
        }
      });
    });

    if (!isUserManagerView) {
      dispatch({
        type: constant.SET_SELECTED_FILTER,
        payload: selectedFil,
      });
    } else {
      dispatch({
        type: constant.SET_USERS_SELECTED_FILTER,
        payload: selectedFil,
      });
    }
  };

  const onFilterSelection = (filterValue, optValue, optChecked, index) => {
    if (setFilterView) setFilterView('');
    if (handleGraphOption) handleGraphOption('');
    setSelectAllCheck(false);
    if (setSortBoth) {
      setSortBoth(true);
      setSelected([]);
      setTimeout(() => {
        setSortBoth(false);
      }, 1000);
    }
    if (filterValue === 'Status') {
      setSelectedStatusIndex(index);
    } else {
      setSelectedYearIndex(index);
    }
    const selectedFilterIndex = _.findIndex(filterOptions, {
      value: filterValue,
    });
    const selectedOptionIndex = _.findIndex(
      filterOptions[selectedFilterIndex].options,
      (op) => op.value === optValue,
    );
    if (filterValue === 'Academic Year' || filterValue === 'Status') {
      const updatedFilters = [...filterOptions];
      updatedFilters[selectedFilterIndex].options = updatedFilters[selectedFilterIndex].options.map((val) => {
        val.checked = false;
        return val;
      });
      updatedFilters[selectedFilterIndex].options[selectedOptionIndex].checked = true;
      setFilterOptions(updatedFilters);
      setSelectedFilter(updatedFilters);
    } else {
      const updatedFilters = [...filterOptions];
      updatedFilters[selectedFilterIndex].options[selectedOptionIndex].checked = !optChecked;
      setFilterOptions(updatedFilters);
      setSelectedFilter(updatedFilters);
    }
    if (setFilterChanged) setFilterChanged(true);
  };
  const onAllExpand = () => {
    dispatch({
      type: constant.SET_ALL_EXPAND,
      payload: !allExpanded,
    });
    setAllExpand(!allExpand);
    const upFilters = [...filterOptions].map((filter) => ({
      ...filter,
      expanded: !allExpand,
    }));
    setFilterOptions(upFilters);
  };

  const clearTitleAndError = () => {
    setTitleError(false);
    setTitle('');
    setShowAddViewDialog(false);
  };

  const onCreate = () => {
    setTitleError(!title ? t('TITLE_REQUIRED') : '');
    if (!title) return;
    onAddView(title, filterOptions, clearTitleAndError, setTitleError);
  };

  const apiReqLoading = useSelector(
    (reduxState) => reduxState.getStudent.loading,
  );
  return (
    <div className={`${classes.filterContainer} ${apiReqLoading ? classes.disable : ''}`}>
      <div className={classes.dFlex}>
        <span className={classes.allOption}>
          {' '}
          {t('ALL')}
        </span>
        <div className={classes.expandIconAll} onClick={onAllExpand}>
          {allExpand ? '-' : '+'}
        </div>
      </div>

      {filterOptions?.map((filter, i) => (
        <React.Fragment key={i}>
          {filter?.label
            && (
              <div className={classes.line}>
                <div style={{ width: '19vw' }}>
                  {t(filter?.label)}
                </div>
                <div
                  className={classes.expandIcon}
                  onClick={() => onExpand(filter.value, filter?.expanded)}
                >
                  {filter?.expanded ? '-' : '+'}
                </div>
              </div>
            )}
          <div className={classes.filterUsed}>
            {filter?.label === 'Academic Year' && (filter?.expanded || allExpanded) ? (
              <>
                {filter?.options?.map((opt, index) => (
                  <RadioboxAtom
                    key={index}
                    label={`${t(opt.label)} ${opt.count ? `(${opt.count})` : ''}`}
                    checked={opt.checked}
                    handleChange={() => onFilterSelection(
                      filter.value,
                      opt.value,
                      opt.checked,
                      index,
                    )}
                    customClasses={classes.checkBoxAll}
                  />
                ))}
              </>
            ) : filter?.label === 'Status' && (filter?.expanded || allExpanded) ? (
              <>
                {filter?.options?.map((opt, index) => (
                  <RadioboxAtom
                    key={index}
                    label={`${opt.label === true || opt.label === 'Active' ? 'Active' : 'Inactive'}  ${opt.count ? `(${opt.count})` : ''}`}
                    checked={opt.checked}
                    handleChange={() => onFilterSelection(
                      filter.value,
                      opt.value,
                      opt.checked,
                      index,
                    )}
                    customClasses={classes.checkBoxAll}
                  />
                ))}
              </>
            ) : (filter?.expanded || allExpanded) ? (
              <>
                {filter?.options?.map((opt, index) => (
                  <CheckboxAtom
                    key={index}
                    label={`${t(opt.label)} ${opt.count ? `(${opt.count})` : ''}`}
                    checked={opt.checked}
                    disabled={opt.disabled || filter.disabled}
                    handleChange={() => onFilterSelection(filter.value, opt.value, opt.checked, index)}
                    customClasses={classes.checkBoxAll}
                  />
                ))}
              </>
            ) : (
              ''
            )}
          </div>
        </React.Fragment>
      ))}
      {
        !hideAddToView && (
          <>
            <ButtonAtom
              name={t('ADD_TO_MY_VIEW')}
              onClick={() => setShowAddViewDialog(true)}
              btntype={Buttons.PRIMARY}
              className={classes.btnFullView}
            />
            <DialogAtom
              customClass={classes.addmyView}
              secHandle={clearTitleAndError}
              isOpen={showAddViewDialog}
              dialogHeading={t('ADD_TO_MY_VIEW')}
              footer={(
                <AddDialogFooter
                  classes={classes}
                  t={t}
                  primaryHandle={onCreate}
                  secHandle={clearTitleAndError}
                />
              )}
              content={(
                <Grid container spacing={2}>
                  <Grid item xs={11.5} md={8} lg={11.5} className={classes.alignGrid}>
                    <TextFieldAtom
                      label={`${t('PLEASE_ADD_TITLE')}`}
                      id="title"
                      required
                      name="title"
                      type="text"
                      value={title}
                      onBlur={() => {
                        setTitleError(!title ? t('TITLE_REQUIRED') : '');
                      }}
                      error={titleError}
                      onChange={(e) => {
                        setTitle(e.target.value);
                        setTitleError(!e.target.value ? t('TITLE_REQUIRED') : '');
                      }}
                    />
                    <span className={classes.errorText}>
                      {titleError}
                    </span>
                  </Grid>
                </Grid>
              )}
            />
          </>
        )
      }

    </div>
  );
}
