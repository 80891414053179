/* eslint-disable eqeqeq */
/* eslint-disable no-unsafe-optional-chaining */
/* eslint-disable no-nested-ternary */
import React, { useState, useEffect } from 'react';
import * as Yup from 'yup';
// import axios from 'axios';
import {
  Box, Grid, Tooltip, Dialog, DialogTitle, DialogContent, IconButton, Button,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { FormikProvider, useFormik } from 'formik';
import { useTranslation } from 'react-i18next';
import FindInPageOutlinedIcon from '@mui/icons-material/FindInPageOutlined';
import { useDispatch, useSelector } from 'react-redux';
import _ from 'lodash';
import moment from 'moment';
import ButtonAtom from '../../../../components/atoms/button';
import LabeledMultiSelect from '../../../../components/atoms/multi-select-with-label';
import Dropdown from '../../../../components/atoms/dropdown';
import { Buttons } from '../../../../constant';
import useStyles from '../../../../custom-hooks/useStyles';
import style from '../../../register/style';
// import { getLocalStorage } from '../../../../utils/localStorageMethod';
import styles from './style';
// eslint-disable-next-line import/named
import {
  genderOptions,
} from '../../../register/student-info/constants';
import ImageUpload from '../../../../components/atoms/image-upload';
import LabeledAddressAutoComplete from '../../../../components/atoms/labeled-auto-compelete';
import {
  editStudentByAdmin,
  getStudentAccountDetails,
  updateSiblingInfo,
  updateStudentInfo,
  getNextEnrollCourseAdmin,
} from '../../../../store/actions/getStudent';
import Loader from '../../../../components/atoms/loader';
import MapContainer from '../../../../components/atoms/google-map';
import {
  getCurrentAcaYear,
  getDistanceBetweenTwoPoint,
  removeSpecialChar,
} from '../../../../utils/methods';
import { getParentPlaceInformation } from '../../../../utils/mapMethod';
import useStudent from '../../../../custom-hooks/useStudent';
import { Datepicker, PerformantTextField, TextField } from '../../../../components/atoms';
import { getStudentValues, getStudentValuesForEnroll } from './helper';
import { commonFields, reqFieldsForAdmin, reqFieldsForStudent } from './constants';

function EditDialogFooter({
  classes, setDialogOpen, t, viewLogs, onSubmit, setStudentInfo, isFormValid,
}) {
  return (
    <Grid container className={classes.dialogButtons}>
      {viewLogs && (
        <Grid
          item
          xs={5}
          className={classes.viewLogs}
        >
          <FindInPageOutlinedIcon style={{ height: 16 }} />
          <div onClick={() => viewLogs(setDialogOpen)}>{t('VIEW_LOGS')}</div>
        </Grid>
      )}
      <Grid item xs={7} className={classes.gridButtonsFooter}>
        <ButtonAtom
          name={t('CANCEL')}
          onClick={() => {
            if (setStudentInfo) {
              setStudentInfo(0);
            }
            setDialogOpen(false);
          }}
          btntype={Buttons.SECONDARY}
        />
        <br className={classes.brButtons} />
        <ButtonAtom
          name={t('SAVE_STUDENT_INFORMATION')}
          onClick={onSubmit}
          btntype={Buttons.PRIMARY}
          className={classes.activeButton}
          disabled={!isFormValid}
        />
      </Grid>
    </Grid>
  );
}

function EditDialogFooterForStudent({
  classes,
  onCancel,
  t,
  onSubmit,
}) {
  return (
    <Grid container className={classes.saveCancelContainer}>
      <Grid container justifyContent="flex-end">
        <ButtonAtom
          name={t('CANCEL')}
          onClick={onCancel}
          btntype={Buttons.SECONDARY}
        />
        <ButtonAtom
          name={t('SAVE')}
          onClick={onSubmit}
          btntype={Buttons.PRIMARY}
          className={classes.activeButton}
        />
      </Grid>
    </Grid>
  );
}

export default function EditStudent({
  viewLogs,
  dobDisable,
  setDialogOpen,
  isStudent,
  isSibling,
  studentInfo,
  parentInfo,
  isEnrollStudent,
  conscent,
  isPrimary,
  setStudentData,
  setEditSibling,
  setEditStudent,
  siblingId,
  setSiblingData,
  editStudentClasses,
  setStudentState,
  setFormikControl,
  enrollCourseList,
  refreshStudentsData,
  setStudentInfo,
  isAdminUser,
  placeSelected,
  isStudentDashboard = false,
  checkClassLevel = false,
  SiblingsData = [],
}) {
  const { t } = useTranslation();
  const selectedStudentData = useStudent();
  const [loading, setLoading] = useState(false);
  const [imageUpload, setImageUpload] = useState();
  const classes = useStyles(styles)();
  const dispatch = useDispatch();
  const tShirtOptions = selectedStudentData?.tshirts;
  const gradeOptions = selectedStudentData?.academicGrades.map((gopt) => ({
    ...gopt,
    id: gopt.name,
  }));

  const extracurricularOptions = selectedStudentData?.extraCurricularActivites;
  const [mapOptions, setMapOptions] = useState(selectedStudentData?.locations);
  const courseOptions = isEnrollStudent ? enrollCourseList : selectedStudentData?.courses;

  const [classLevelOption, setClassLevelOption] = useState(courseOptions);
  const [selectedYear, setSelectedYear] = useState();
  const academicOptions = getCurrentAcaYear();
  const registerClasses = editStudentClasses || useStyles(style)();
  const [address, setAddress] = useState(null);
  const [addressName, setAddressName] = useState(null);
  const [checkDob, setCheckDob] = useState(null);
  const [isDobFlag, setIsDobFlag] = useState(null);
  const [isSiblingFirstNameMatch, setIsSiblingFirstNameMatch] = useState(false);
  const [isSiblingLastNameMismatch, setIsSiblingLastNameMismatch] = useState(false);
  const commonSchema = Yup.object({
    profileImage: Yup.mixed(t('PROFILEIMAGE_REQUIRED')).required(
      t('PROFILEIMAGE_REQUIRED'),
    ),
    firstName: Yup.string(t('FIRSTNAME_REQUIRED'))
      .matches(/^[aA-zZ\s]+$/, t('NOT_SPECIAL_CHAR'))
      .min(2, t('FIRSTNAME_MIN'))
      .required(t('FIRSTNAME_REQUIRED')),
    lastName: Yup.string(t('LASTNAME_REQUIRED'))
      .matches(/^[aA-zZ\s]+$/, t('NOT_SPECIAL_CHAR'))
      .min(2, t('LASTNAME_MIN'))
      .required(t('LASTNAME_REQUIRED')),

    dateOfBirth: Yup.date()
      .max(new Date(), t('FUTURE_DATE_NOT_ALLOWED'))
      .typeError(t('INVALID_DATE'))
      .required(t('DOB_REQUIRED'))
      .test('isValidDate', t('INVALID_DATE'), (value) => {
        const currentYear = new Date().getFullYear();
        let validYear = (value?.getFullYear() >= 1900 && currentYear >= value?.getFullYear())
          || false;
        validYear = validYear && checkDob ? (checkDob?.split('/')[2] >= 1900 && currentYear >= checkDob?.split('/')[2]) : validYear;
        setIsDobFlag(validYear);
        return validYear;
      }),

    gender: Yup.string(t('GENDER_REQUIRED')).required(t('GENDER_REQUIRED')).nullable(t('GENDER_REQUIRED')),
    tShirt: Yup.string(t('TSHIRT_REQUIRED')).required(t('TSHIRT_REQUIRED')),
    grade: Yup.string(t('GRADE_REQUIRED')).required(t('GRADE_REQUIRED')),
    academicSchool: Yup.string(t('SEARCHSCHOOL_REQUIRED'))
      .required(t('SEARCHSCHOOL_REQUIRED'))
      .typeError(t('SEARCHSCHOOL_REQUIRED')),
    extraCurricularActivities: Yup.array()
      .min(1, t('EXTRACURRICULARACTIVITIES_REQUIRED'))
      .required(t('EXTRACURRICULARACTIVITIES_REQUIRED')),
  });
  const [isShirtSizeDialogOpen, setShirtSizeDialogOpen] = useState(false);
  // const [imageUrl, setImageUrl] = useState(null);
  // const [imageNotFound, setImageNotFound] = useState(false);
  // const [error401, setError401] = useState(false);
  // const [accessToken, setAccessToken] = useState();
  // useEffect(() => {
  //   const rawToken = JSON.parse(getLocalStorage('accessToken'));
  //   setAccessToken(rawToken.access_token);
  // }, []);
  // const fetchImageFromFolder = async (folderId) => {
  //   try {
  //     setLoading(true);
  //     setImageNotFound(false);
  //     setError401(false);

  //     const listFilesUrl = `https://www.googleapis.com/drive/v3/files?q='${folderId}' in parents and trashed = false&supportsAllDrives=true&fields=files(id,name,mimeType)&access_token=${accessToken}`;

  //     // First API call: file list (JSON)
  //     const response = await axios.get(listFilesUrl, {
  //       headers: {
  //         Authorization: `Bearer ${accessToken}`,
  //       },
  //     });

  //     if (response.status === 401) {
  //       setError401(true);
  //       throw new Error('Login again, token expired');
  //     }

  //     // Check if we have any files
  //     const { files = [] } = response.data;
  //     if (files.length === 0) {
  //       setImageNotFound(true);
  //       return;
  //     }

  //     // Pick the first file (or implement additional logic if needed)
  //     const { id: fileId, mimeType } = files[0];

  //     // 2) Fetch the actual file content (binary data) using alt=media
  //     const fileUrl = `https://www.googleapis.com/drive/v3/files/${fileId}?alt=media`;
  //     const fileResponse = await axios.get(fileUrl, {
  //       headers: {
  //         Authorization: `Bearer ${accessToken}`,
  //       },
  //       responseType: 'blob',
  //     });

  //     if (fileResponse.status === 401) {
  //       setError401(true);
  //       throw new Error('Login again, token expired');
  //     }

  //     // Convert the blob to an object URL and update state
  //     const blob = new Blob([fileResponse.data], { type: mimeType });
  //     const url = URL.createObjectURL(blob);
  //     setImageUrl(url);
  //   } catch (error) {
  //     // Handle errors
  //     if (error.response && error.response.status === 401) {
  //       setError401(true);
  //     } else {
  //       setImageNotFound(true);
  //     }
  //   } finally {
  //     setLoading(false);
  //   }
  // };

  // useEffect(() => {
  //   if (isShirtSizeDialogOpen) {
  //     const folderId = '1SNk5fuBfgvIl3A1tvQlwsUMAAE_gWlth';
  //     fetchImageFromFolder(folderId);
  //   } else {
  //     setImageUrl(null);
  //     setLoading(false);
  //     setImageNotFound(false);
  //   }
  // }, [isShirtSizeDialogOpen]);
  const handleShirtSizeDropdownClick = () => {
    setShirtSizeDialogOpen(true);
  };

  const capitalizeFirstLetter = (value) => {
    if (value) {
      return value.charAt(0).toUpperCase() + value.slice(1);
    }
    return value; // Devolver el valor original si es una cadena vacía
  };

  const handleShirtSizeDialogClose = () => {
    setShirtSizeDialogOpen(false);
  };
  const studentSchema = Yup.object({
    academicYear: Yup.string(t('ACADEMIC_YEAR_REQUIRED')).required(
      t('ACADEMIC_YEAR_REQUIRED'),
    ),
    classLevel: Yup.string(t('CLASSLEVEL_REQUIRED')).required(
      t('CLASSLEVEL_REQUIRED'),
    ),
    manabadiLocation: Yup.string(t('SORTEDNEAREST_REQUIRED')).required(
      t('SORTEDNEAREST_REQUIRED'),
    ),
  });

  const adminSchema = Yup.object({
    changeLogs: Yup.string(t('CHANGE_LOG_REQUIRED')).required(
      t('CHANGE_LOG_REQUIRED'),
    ),
  });

  const checkIfManabadiLocationIsActive = (manabadiLocation) => {
    return selectedStudentData?.locations.find((location) => location.id === manabadiLocation
      || location.name === manabadiLocation);
  };

  const isStudentOrSibling = isStudent || isSibling;
  const validationSchema = isStudentOrSibling || (isEnrollStudent && conscent) || (isEnrollStudent && isPrimary)
    ? commonSchema.concat(studentSchema)
    : commonSchema.concat(adminSchema);
  const formikData = isStudentOrSibling || isEnrollStudent ? studentInfo : studentInfo?.s1;
  const manabadiLocationActive = checkIfManabadiLocationIsActive(formikData?.sortedNearest);

  const formik = useFormik({
    initialValues: {
      firstName: formikData?.firstName,
      middleName: formikData?.middleName,
      lastName: formikData?.lastName,
      dateOfBirth: formikData?.dateOfBirth || new Date(),
      gender: '',
      tShirt: '',
      grade: '',
      academicSchool: formikData?.academicSchool,
      extraCurricularActivities: [],
      profileImage: formikData?.profileImage,
      changeLogs: '',
      latitude: '',
      longitude: '',
      academicYear: formikData?.academicYear,
      classLevel: formikData?.selectedClassLevel,
      manabadiLocation: manabadiLocationActive ? formikData?.sortedNearest : '',
    },
    validationSchema,
    onSubmit: (values) => {
      if (isEnrollStudent) {
        return;
      }
      const fAcaYear = localStorage.getItem('academicYear');
      setLoading(true);
      const tShirtSize = tShirtOptions?.find(
        (shirt) => shirt.id === values?.tShirt,
      );
      const grade = gradeOptions?.find((g) => g.name == values?.grade);
      const ac = extracurricularOptions?.filter((op) => values?.extraCurricularActivities?.some(
        (value) => op?.id === value,
      ));
      const selectedAc = ac?.map((c) => c.name);
      const image = imageUpload && imageUpload !== '' ? imageUpload : values?.profileImage;
      const payload = {
        acaYear: values?.academicYear || fAcaYear,
        dateOfBirth: values.dateOfBirth,
        gender: values.gender,
        academicGrade: grade?.name,
        academicSchool: {
          address: addressName,
          aptSuite: '',
          latitude: Number(values?.latitude),
          longitude: Number(values?.longitude),
        },
        extraCurricularActivities: selectedAc,
        tShirtSize: tShirtSize?.name,
        image,
        isStudentDashboard,
      };
      if (!isStudentOrSibling) {
        payload.firstName = values.firstName;
        payload.lastName = values.lastName;
        payload.middleName = values.middleName;
        payload.logMessage = values.changeLogs;
      }
      if (isStudent) {
        setLoading(true);

        const refreshList = () => {
          const loadRefreshData = () => {
            setEditStudent(false);
            setLoading(false);
          };
          dispatch(getStudentAccountDetails(loadRefreshData, setStudentState));
        };
        dispatch(updateStudentInfo(payload, refreshList, setLoading));
      } else if (isSibling) {
        setLoading(true);
        const refreshList = () => {
          const loadRefreshData = () => {
            setEditSibling(false);
            setLoading(false);
          };
          dispatch(
            getStudentAccountDetails(
              loadRefreshData,
              setStudentState,
              setSiblingData,
            ),
          );
        };
        dispatch(
          updateSiblingInfo(payload, siblingId, refreshList, setLoading),
        );
      } else {
        const studentData = isStudentOrSibling || isEnrollStudent ? studentInfo : studentInfo?.s1;
        if (payload.image || imageUpload) {
          payload.profilePhoto = payload.image || imageUpload;
          delete payload.image;
        }
        dispatch(
          editStudentByAdmin(studentData?.studentId, payload, setLoading),
        ).then(() => {
          if (refreshStudentsData) {
            refreshStudentsData();
          }
          if (setStudentInfo) {
            setStudentInfo(0);
          }
          setDialogOpen(false);
        });
      }
    },
  });
  const isFormValid = formik.isValid && formik.dirty;

  const formatDate = (date) => {
    if (!date) {
      return null;
    }
    const userTimezone = moment.tz.guess();
    if (typeof date === 'string') {
      const d = date?.split('T')?.[0];
      const dateConv = `${d}T17:00:00.000Z`;
      return moment(dateConv).tz(userTimezone).format('MM/DD/YYYY');
    }
    return moment(date).tz(userTimezone).format('MM/DD/YYYY');
  };

  useEffect(() => {
    setLoading(studentInfo === 0);
    if (studentInfo === 0) {
      formik.handleChange({
        target: {
          name: 'profileImage',
          value: '',
        },
      });
      setImageUpload('');
    }
  }, [studentInfo]);

  useEffect(() => {
    formik.values.profileImage = imageUpload || formik.values.profileImage;
    const info = getStudentValuesForEnroll(
      formik.values,
      studentInfo,
      isStudentOrSibling,
      extracurricularOptions,
      tShirtOptions,
      classLevelOption,
    );
    formik.setFieldValue('academicYear', info.academicYear);
    if (isEnrollStudent && Object.keys(formik.touched).length > 0) {
      setStudentData(info);
    }
  }, [formik.touched, formik.values.classLevel, formik.values.profileImage, imageUpload]);

  useEffect(() => {
    if (setFormikControl) {
      setFormikControl(formik);
    }
  }, [formik.errors]);
  useEffect(() => {
    formik.validateField('dateOfBirth');
  }, [formik.values.dateOfBirth, isDobFlag]);
  const student = isStudentOrSibling || isEnrollStudent ? studentInfo : studentInfo?.s1;
  useEffect(() => {
    const selectedTshirt = tShirtOptions?.find(
      (shirt) => shirt.name === student?.tShirt,
    );
    const selectedGrade = gradeOptions?.find((g) => g.name == student?.grade);
    const results = extracurricularOptions?.filter((op) => student?.extraCurricularActivities?.some(
      (value) => op?.name === value,
    ));
    const selectedEC = results?.map((c) => c.id);
    const values = getStudentValues(student, isStudentOrSibling, isEnrollStudent, selectedTshirt, selectedGrade, selectedEC);
    const isLocationActive = checkIfManabadiLocationIsActive(formikData?.sortedNearest);
    if (!isLocationActive) {
      values.manabadiLocation = '';
    }
    formik.setValues(values);
    formik.setFieldValue(
      'extraCurricularActivities',
      selectedEC,
    );
  }, [studentInfo]);

  function displayValue(key, value) {
    return (
      <Grid container item xs={12} spacing={0} className={classes.dataPadding}>
        <Grid item xs={4} className={classes.label}>
          {key}
        </Grid>
        <Grid item xs={8} className={classes.value}>
          <span> : </span>
          <span className={classes.previewValue}>{`${value}`}</span>
        </Grid>
      </Grid>
    );
  }

  const getErrorText = (key, errorText) => (formik.touched[key] && formik.errors[key] ? (
    <span data-testid={key} className={classes.errorText}>
      {formik.errors[key]}
    </span>
  ) : errorText ? (
    <span className={classes.errorText}>{errorText}</span>
  ) : null);

  const getDestinationLatLng = (nearestValue) => {
    if (!nearestValue) {
      return { lat: '', lng: '' };
    }
    const selectedAdd = _.find(mapOptions, (opt) => opt.id === nearestValue);
    return selectedAdd;
  };

  const getCoordinatorDetail = (locObj) => {
    const locCoor = locObj?.location_coordinators || [];
    return locCoor.find((o) => o?.isPrimary);
  };
  const getFormatTime = (time) => moment(time, 'hh:mm a').format('hh:mm a');

  const setPreviousFieldTouch = (key) => {
    const allFields = isStudentOrSibling
      ? commonFields.concat(reqFieldsForStudent)
      : commonFields.concat(reqFieldsForAdmin);

    const index = allFields.indexOf(key);
    if (index > -1) {
      const obj = {};
      // eslint-disable-next-line no-plusplus
      for (let i = 0; i <= index; i++) {
        const element = allFields[i];
        obj[element] = true;
      }
      formik.setTouched({ ...formik.touched, ...obj }, true);
    }
  };

  useEffect(() => {
    if (checkClassLevel && formik?.values && formik?.values?.firstName?.toLowerCase() === checkClassLevel) {
      formik.validateField('classLevel');
      setPreviousFieldTouch('classLevel');
    }
  }, [checkClassLevel]);

  useEffect(() => {
    if (address) {
      const obj = {
        target: {
          name: 'academicSchool',
          value: addressName,
        },
      };
      const latObj = {
        target: {
          name: 'latitude',
          value: address?.geometry?.location?.lat(),
        },
      };
      const lonObj = {
        target: {
          name: 'longitude',
          value: address?.geometry?.location?.lng(),
        },
      };
      formik.handleChange(obj);
      formik.handleChange(latObj);
      formik.handleChange(lonObj);
      if (isEnrollStudent) {
        formik.setFieldValue('latitude', address?.geometry?.location?.lat());
        formik.setFieldValue('longitude', address?.geometry?.location?.lng());
      }

      setPreviousFieldTouch('academicSchool');
    }
  }, [address]);
  useEffect(() => { }, [studentInfo]);
  useEffect(async () => {
    if (parentInfo?.homeAddressInfo.add) {
      let distanceMapOptions = await mapOptions?.map((opt) => {
        const res = getDistanceBetweenTwoPoint(parentInfo?.homeAddressInfo, {
          lat: opt?.locationAddress?.latitude,
          lng: opt?.locationAddress?.longitude,
        });
        const miles = res ? res / 1609.34 : 0;
        return { ...opt, distance: miles ? miles.toFixed(2) : 0 };
      });
      const res = await getParentPlaceInformation(parentInfo?.homeAddressInfo.add);
      distanceMapOptions = distanceMapOptions.filter((item) => item.locationAddress.latitude !== null && item.locationAddress.longitude);
      distanceMapOptions = distanceMapOptions.filter((item) => item.locationAddress.country === res.country);
      distanceMapOptions = distanceMapOptions?.sort((a, b) => a.distance - b.distance);
      const distanceMapOptionsExists = !!(distanceMapOptions && distanceMapOptions?.length);
      const isMore20Miles = !(distanceMapOptionsExists && distanceMapOptions[0]?.distance <= 20);
      distanceMapOptions = distanceMapOptionsExists ? distanceMapOptions : selectedStudentData?.locations;
      const reorderedOptions = [...distanceMapOptions];
      const locationId = res?.country === 'United States' ? '906e5655-10ee-4948-a35c-28f99d152e71' : '846413a4-a9de-4454-92e5-035f9d1a2884';
      const index = reorderedOptions.findIndex((option) => option.id === locationId);
      if (index !== -1 && isMore20Miles) {
        const [item] = reorderedOptions.splice(index, 1);
        reorderedOptions.unshift(item);
      }
      setMapOptions(reorderedOptions);
    }
  }, [selectedStudentData?.locations, parentInfo, placeSelected]);

  const clear = () => {
    setImageUpload('');
    formik.handleChange({
      target: {
        name: 'profileImage',
        value: '',
      },
    });
  };

  useEffect(() => {
    setLoading(studentInfo === 0);
    return () => clear();
  }, []);

  useEffect(() => {
    const AddressName = typeof formik.values.academicSchool === 'string' ? formik.values.academicSchool : (formik.values.academicSchool?.name || formik.values.academicSchool?.id);
    setAddressName(AddressName);
  }, [formik?.values]);

  const locationStore = useSelector((state) => state?.getLocationCoordinator);

  const checkEligibilityForFLC = (options) => {
    const selectedCourses = [];
    if (options.length <= 2) {
      _.forEach(options, (opt) => {
        selectedCourses.push(opt);
      });
    } else {
      _.forEach(options, (opt) => {
        _.forEach(courseOptions, (co) => {
          if (co.id === opt.id) {
            selectedCourses.push(co);
          }
        });
      });
    }
    const selectedGrade = _.find(gradeOptions, (go) => go.id === formik?.values?.grade);
    let classOptions = selectedCourses;
    if (parseInt(selectedGrade?.name, 10) < 7 || selectedGrade?.name === 'K' || selectedGrade?.name === 'Pre-K') {
      classOptions = selectedCourses?.filter((o) => o.name !== 'Telugu-1' && o.name !== 'Telugu-2' && o.name !== 'Telugu-3' && o.name !== 'Telugu-4');
    }

    classOptions.sort((a, b) => {
      const yearA = parseInt(a?.academic_infos?.academicYear.split('-')[0], 10) || parseInt(a?.academicYear.split('-')[0], 10);
      const yearB = parseInt(b?.academic_infos?.academicYear.split('-')[0], 10) || parseInt(b?.academicYear.split('-')[0], 10);
      if (yearA > yearB) {
        return -1;
      }
      if (yearA < yearB) {
        return 1;
      }

      return 0;
    });
    const uniqueClassOptions = Array.from(new Map(classOptions.map((item) => [item.id, item])).values());
    setClassLevelOption(uniqueClassOptions);
  };

  useEffect(() => {
    if (formik.values.classLevel && formik.values.classLevel !== '') {
      const selectedClassLevel = _.find(classLevelOption, (co) => co.id === formik.values.classLevel);
      setSelectedYear(selectedClassLevel?.academicYear);
    }
  }, [formik.values.classLevel]);

  const getClassLevelOptions = (dob, location) => {
    const studentData = isStudentOrSibling || isEnrollStudent ? studentInfo : studentInfo?.s1;
    const academicGrade = formik.values.grade || '';
    let dobToSend = null;
    if (dob !== studentData?.dateOfBirth) {
      let formattedDob;
      if (moment(dob, moment.ISO_8601, true).isValid()) {
        formattedDob = moment(dob).format('YYYY-MM-DD');
      } else if (moment(dob, 'MM/DD/YYYY', true).isValid()) {
        formattedDob = moment(dob, 'MM/DD/YYYY').format('YYYY-MM-DD');
      }
      dobToSend = (formattedDob !== studentData?.dateOfBirth.split('T')[0]) ? `${formattedDob}T17:00:00.000Z` : null;
    }

    if (isEnrollStudent && academicGrade !== '') {
      const payload = {
        id: studentData?.studentId,
        isAdminUser,
        locationId: location?.id || location,
        isFromEditPage: true,
        academicGrade,
        ...(dobToSend && { dateOfBirth: dobToSend }),
      };
      dispatch(getNextEnrollCourseAdmin(payload));
    }
  };

  const getEnrollCourseList = (stuId) => {
    if (!stuId) return [];
    const data = selectedStudentData?.nextEnrollCourseList?.find((stu) => stu.studentId === stuId);
    if (!data || !data?.course) return [];
    const courses = data?.course.map((i) => ({
      id: i?.toCourse?.id,
      name: i?.toCourse?.name,
      academicYear: i?.toCourse?.academicYear,
    }));
    return courses;
  };
  useEffect(async () => {
    if (isEnrollStudent) {
      const coursesList = await getEnrollCourseList(studentInfo?.studentId);
      checkEligibilityForFLC(coursesList);
      const idEncontrado = coursesList.some((objeto) => objeto.id === formik?.values?.classLevel);
      if (idEncontrado) {
        formik.setFieldValue('classLevel', formik?.values?.classLevel);
      }
    } else {
      checkEligibilityForFLC(locationStore?.locationCourses);
      if (locationStore?.locationCourses?.length === 0) {
        formik.setFieldValue('classLevel', formik?.values?.classLevel);
      }
    }
  }, [locationStore?.locationCourses, selectedStudentData.nextEnrollCourseList]);

  useEffect(() => {
    if (formik?.values?.dateOfBirth !== '' && formik?.values?.sortedNearest !== '') {
      getClassLevelOptions(formik?.values?.dateOfBirth, formik?.values?.manabadiLocation);
    } else if (formik?.values?.dateOfBirth !== '') {
      getClassLevelOptions(formik?.values?.dateOfBirth, '');
    }
  }, [formik?.values?.dateOfBirth, formik?.values?.manabadiLocation, formik?.values?.grade]);

  useEffect(() => {
    const capitalizedValue = capitalizeFirstLetter(formik?.values?.firstName);
    const cleanedValue = removeSpecialChar(capitalizedValue);

    const isMatch = SiblingsData.some(
      (sibling) => sibling?.studentInfo?.firstName && sibling.studentInfo.firstName.toLowerCase().trim() === cleanedValue.toLowerCase().trim(),
    );

    setIsSiblingFirstNameMatch(isMatch);
  }, [formik?.values?.firstName]);

  useEffect(() => {
    const capitalizedValue = capitalizeFirstLetter(formik?.values?.lastName);
    const cleanedValue = removeSpecialChar(capitalizedValue);

    const isMatch = SiblingsData.some(
      (sibling) => sibling?.studentInfo?.lastName && sibling.studentInfo.lastName.toLowerCase().trim() !== cleanedValue.toLowerCase().trim(),
    );

    setIsSiblingLastNameMismatch(isMatch);
  }, [formik?.values?.lastName]);

  return (
    <Box className={registerClasses.gridContainer}>
      <Grid container className={classes.mainContainer}>
        <FormikProvider value={formik}>
          <form
            name="student-info"
            noValidate
            autoComplete="off"
          >
            <Grid container spacing={2} flexDirection="row">
              <Grid className={classes.imageAlign}>
                <Grid item xs={12} sm={2} md={2} lg={2}>
                  <ImageUpload
                    id="profileImage"
                    name="profileImage"
                    isEditForm
                    imageUploaded={(loading && !formik.values.profileImage) ? ''
                      : (imageUpload || (formik.values.profileImage && formik.values.profileImage.startsWith('data:image/') ? formik.values.profileImage : formik.values.profileImage && !formik.values.profileImage.startsWith('data:image/') ? `${formik.values.profileImage}?${performance.now()}` : ''))}
                    setImageUpload={(e) => setImageUpload(e?.target?.value)}
                  />
                </Grid>
                <div>
                  {getErrorText('profileImage')}
                </div>
              </Grid>

              <Grid item xs={10} sm={10} md={10} lg={10}>
                <Grid container spacing={2}>
                  <Tooltip
                    title={t('HOVER_STU_FIRSTNAME')}
                    placement="bottom-end"
                    arrow
                  >
                    <Grid item xs={12} md={6} lg={6} className={classes.alignGrid}>
                      <PerformantTextField
                        label={`${t('FIRST_NAME')}`}
                        id="firstName"
                        required
                        name="firstName"
                        type="text"
                        disabled={isStudentOrSibling}
                        value={formik.values.firstName}
                        onBlur={() => setPreviousFieldTouch('firstName')}
                        error={getErrorText('firstName', isSiblingFirstNameMatch ? `${t('SIBLING_FIRSTNAME_ERROR4')}` : null)}
                        onChange={formik.handleChange}
                      />
                    </Grid>
                  </Tooltip>
                  <Tooltip title={t('HOVER_STU_MIDDLENAME')} placement="bottom" arrow>
                    <Grid item xs={12} md={6} lg={6} className={classes.alignGrid}>
                      <PerformantTextField
                        disabled={isStudentOrSibling}
                        label={`${t('MIDDLE_NAME')}`}
                        id="middleName"
                        required={false}
                        name="middleName"
                        type="text"
                        value={formik.values.middleName}
                        onBlur={() => setPreviousFieldTouch('middleName')}
                        onChange={formik.handleChange}
                      />
                    </Grid>
                  </Tooltip>
                  <Tooltip title={t('HOVER_STU_LASTNAME')} placement="bottom-end" arrow>
                    <Grid item xs={12} md={6} lg={6} className={classes.alignGrid}>
                      <PerformantTextField
                        label={`${t('LAST_NAME')} `}
                        disabled={isStudentOrSibling}
                        id="lastName"
                        required
                        name="lastName"
                        type="text"
                        value={formik.values.lastName}
                        onBlur={() => setPreviousFieldTouch('lastName')}
                        error={getErrorText('lastName', isSiblingLastNameMismatch ? `${t('SIBLING_LASTNAME_ERROR')}` : null)}
                        onChange={formik.handleChange}
                      />
                    </Grid>
                  </Tooltip>
                  <Grid item xs={12} md={6} lg={6} className={classes.alignGrid}>
                    <Datepicker
                      wrapperClassName={classes.datePicker}
                      label={`${t('DOB')} *`}
                      minWidth="100%"
                      id="dateOfBirth"
                      name="dateOfBirth"
                      type="date"
                      disabled={dobDisable}
                      value={formik.values.dateOfBirth}
                      onBlur={() => setPreviousFieldTouch('dateOfBirth')}
                      onChange={(newDate) => {
                        window.newDate = formatDate(newDate);
                        const obj = {
                          target: {
                            name: 'dateOfBirth',
                            value: moment(newDate).format('MM/DD/YYYY'),
                          },
                        };
                        setCheckDob(obj.target?.value);
                        formik.handleChange(obj);
                      }}
                      error={getErrorText('dateOfBirth')}
                      maxDate={new Date()}
                    />
                    {getErrorText('dateOfBirth')}
                  </Grid>
                  <Grid item xs={12} md={6} lg={6} className={`${classes.alignGrid} ${classes.editLabel}`}>
                    <Dropdown
                      shrink
                      minWidth="100%"
                      label={`${t('GENDER')}`}
                      id="gender"
                      name="gender"
                      value={formik.values.gender}
                      onBlur={() => setPreviousFieldTouch('gender')}
                      error={getErrorText('gender')}
                      handleChange={formik.handleChange}
                      options={genderOptions}
                      required
                      labelId="gender"
                    />
                    {getErrorText('gender')}
                  </Grid>
                  {isStudentOrSibling ? (
                    <>
                      <Grid item xs={8} md={10} lg={2} sm={10} className={`${classes.alignGrid} ${classes.editLabel}`}>
                        <Dropdown
                          shrink
                          minWidth="100%"
                          label={`${t('TSHIRT')}`}
                          id="tShirt"
                          name="tShirt"
                          value={formik.values.tShirt}
                          onBlur={() => setPreviousFieldTouch('tShirt')}
                          error={getErrorText('tShirt')}
                          handleChange={formik.handleChange}
                          options={tShirtOptions}
                          required
                          labelId="tShirt"
                        />
                        {getErrorText('tShirt')}
                      </Grid>
                      <Grid
                        item
                        xs={2}
                        style={{
                          marginTop: '4px',
                        }}
                      >
                        <Button
                          fullWidth
                          variant="contained"
                          onClick={handleShirtSizeDropdownClick}
                          sx={{
                            fontSize: '12px !important',
                            padding: '9px !important',
                            color: 'white !important',
                            fontWeight: '600 !important',
                            textTransform: 'capitalize',
                            fontFamily: 'inherit !important',
                            background: '#3971b5 !important',
                            borderRadius: '6px !important',
                          }}
                        >
                          Size Chart
                        </Button>
                      </Grid>
                    </>
                  ) : isEnrollStudent
                    ? (
                      <>
                        <Grid item xs={9} md={4} lg={2} className={`${classes.alignGrid} ${classes.editLabel}`}>
                          <Dropdown
                            shrink
                            minWidth="100%"
                            label={`${t('TSHIRT')}`}
                            id="tShirt"
                            name="tShirt"
                            value={formik.values.tShirt}
                            onBlur={() => setPreviousFieldTouch('tShirt')}
                            error={getErrorText('tShirt')}
                            handleChange={formik.handleChange}
                            options={tShirtOptions}
                            required
                            labelId="tShirt"
                          />
                          {getErrorText('tShirt')}
                        </Grid>
                        <Grid item xs={2}>
                          <Button
                            fullWidth
                            variant="contained"
                            onClick={handleShirtSizeDropdownClick}
                            sx={{
                              fontSize: '12px !important',
                              padding: '10px !important',
                              color: 'white !important',
                              fontWeight: '600 !important',
                              textTransform: 'capitalize',
                              fontFamily: 'inherit !important',
                              background: '#3971b5 !important',
                              borderRadius: '6px !important',
                              marginTop: '6px !important',
                            }}
                          >
                            Size Chart
                          </Button>
                        </Grid>
                      </>
                    ) : (
                      <>
                        <Grid item xs={10} md={4} lg={4} className={`${classes.alignGrid} ${classes.editLabel}`}>
                          <Dropdown
                            shrink
                            minWidth="100%"
                            label={`${t('TSHIRT')}`}
                            id="tShirt"
                            name="tShirt"
                            value={formik.values.tShirt}
                            onBlur={() => setPreviousFieldTouch('tShirt')}
                            error={getErrorText('tShirt')}
                            handleChange={formik.handleChange}
                            options={tShirtOptions}
                            required
                            labelId="tShirt"
                          />
                          {getErrorText('tShirt')}
                        </Grid>
                        <Grid item xs={2}>
                          <Button
                            fullWidth
                            variant="contained"
                            onClick={handleShirtSizeDropdownClick}
                            sx={{
                              fontSize: '12px !important',
                              padding: '8px !important',
                              textTransform: 'capitalize',
                              fontFamily: 'inherit !important',
                              background: '#3971b5 !important',
                            }}
                          >
                            Size Chart
                          </Button>
                        </Grid>
                      </>
                    )}
                  {isStudentOrSibling ? (
                    <Grid item xs={12} md={6} lg={4} className={classes.alignGrid}>
                      <PerformantTextField
                        minWidth="100%"
                        label={`${t('ACADEMICYEAR')}`}
                        id="academicYear"
                        name="academicYear"
                        disabled={isStudentOrSibling}
                        value={selectedYear || academicOptions?.id}
                        error={getErrorText('academicYear')}
                        required
                        labelId="academicYear"
                      />
                    </Grid>
                  ) : isEnrollStudent ? (
                    <Grid item xs={12} md={6} lg={4} className={classes.alignGrid}>
                      <TextField
                        minWidth="100%"
                        label={`${t('ACADEMICYEAR')}`}
                        id="academicYear"
                        name="academicYear"
                        disable={isEnrollStudent}
                        value={selectedYear || academicOptions?.id}
                        error={getErrorText('academicYear')}
                        required
                        labelId="academicYear"
                      />
                      {getErrorText('academicYear')}
                    </Grid>
                  ) : null}
                  <Grid item xs={12} md={6} lg={6} className={`${classes.alignGrid} ${classes.editLabel}`}>
                    <Dropdown
                      shrink
                      minWidth="100%"
                      label={`${t('ACA_GRADE')}`}
                      id="grade"
                      name="grade"
                      value={formik.values.grade}
                      onBlur={() => setPreviousFieldTouch('grade')}
                      error={getErrorText('grade')}
                      handleChange={formik.handleChange}
                      options={gradeOptions}
                      required
                      labelId="grade"
                    />
                    {getErrorText('grade')}
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    md={isStudentOrSibling ? 6 : 6}
                    lg={isStudentOrSibling ? 6 : 6}
                    className={`${classes.alignGrid} ${classes.academicSchool}`}
                  >
                    <LabeledAddressAutoComplete
                      label={`${t('ACADEMIC_SCHOOL')} *`}
                      id="academicSchool"
                      required
                      name="academicSchool"
                      value={(typeof formik.values.academicSchool == 'string') ? formik.values.academicSchool
                        : (formik.values.academicSchool?.name || formik.values.academicSchool?.id)}
                      error={getErrorText('academicSchool')}
                      onPlaceSelected={(e) => {
                        setAddress(e);
                      }}
                      onChange={(e) => {
                        e.target.name = 'academicSchool';
                        formik.handleChange(e);
                      }}
                      onBlur={(e) => {
                        const onlyName = e.target.value.split(',')[0].trim();
                        setAddressName(onlyName);
                      }}
                      isStudentOrSibling={isStudentOrSibling}
                    />
                    {
                      getErrorText('academicSchool')
                    }
                  </Grid>

                  {isStudentOrSibling ? (
                    <Grid item xs={12} md={6} lg={4} className={classes.alignGrid}>
                      <PerformantTextField
                        minWidth="100%"
                        label={`${t('MANABADI_LOCATION')}`}
                        id="manabadiLocation"
                        name="manabadiLocation"
                        disabled={isStudentOrSibling}
                        value={formik.values.manabadiLocation}
                        error={getErrorText('manabadiLocation')}
                        required
                        labelId="manabadiLocation"
                      />
                    </Grid>
                  ) : isEnrollStudent ? (
                    <Grid item xs={12} md={6} lg={4} className={classes.alignGrid}>
                      <Dropdown
                        shrink
                        minWidth="100%"
                        label={`${t('MANABADI_LOCATION')}`}
                        id="manabadiLocation"
                        name="manabadiLocation"
                        disable={isStudentOrSibling}
                        value={formik.values.manabadiLocation}
                        onBlur={() => setPreviousFieldTouch('manabadiLocation')}
                        error={getErrorText('manabadiLocation')}
                        handleChange={(e) => {
                          formik.handleChange(e);
                        }}
                        options={mapOptions}
                        required
                        labelId="manabadiLocation"
                      />
                      {getErrorText('manabadiLocation')}
                    </Grid>
                  ) : null}

                  {isStudentOrSibling ? (
                    <Grid item xs={12} md={6} lg={4} className={classes.alignGrid}>
                      <PerformantTextField
                        minWidth="100%"
                        label={`${t('CLASSLEVEL')}`}
                        id="classLevel"
                        name="classLevel"
                        disabled={isStudentOrSibling}
                        value={formik.values.classLevel}
                        error={getErrorText('classLevel')}
                        required
                        labelId="classLevel"
                      />
                    </Grid>
                  ) : isEnrollStudent ? (
                    <Grid item xs={12} md={6} lg={4} className={classes.alignGrid}>
                      <Dropdown
                        shrink
                        minWidth="100%"
                        label={`${t('CLASSLEVEL')}`}
                        id="classLevel"
                        name="classLevel"
                        value={formik.values.classLevel}
                        onBlur={() => setPreviousFieldTouch('classLevel')}
                        error={getErrorText('classLevel')}
                        handleChange={formik.handleChange}
                        options={classLevelOption}
                        required
                        labelId="classLevel"
                      />
                      {getErrorText('classLevel')}
                    </Grid>
                  )
                    : (
                      <Grid
                        item
                        xs={12}
                        md={isStudentOrSibling ? 6 : 12}
                        lg={isStudentOrSibling ? 6 : 12}
                        className={`${classes.alignGrid} ${classes.extraCurricular} ${classes.editLabel}`}
                      >
                        <LabeledMultiSelect
                          minWidth="100%"
                          tooltip
                          labelId="grade"
                          label={`${t('EXTRA_ACTIVITIES')}*`}
                          options={extracurricularOptions}
                          id="extraCurricularActivities"
                          name="extraCurricularActivities"
                          onBlur={() => setPreviousFieldTouch('extraCurricularActivities')}
                          error={getErrorText('extraCurricularActivities')}
                          handleChange={formik.handleChange}
                          value={formik.values.extraCurricularActivities}
                          required
                        />
                        {getErrorText('extraCurricularActivities')}
                      </Grid>
                    )}
                  {isStudentOrSibling || isEnrollStudent ? (
                    <Grid
                      item
                      xs={12}
                      md={6}
                      lg={6}
                      className={classes.alignGrid}
                    >
                      <LabeledMultiSelect
                        minWidth="100%"
                        labelId="grade"
                        label={`${t('EXTRA_ACTIVITIES')}*`}
                        options={extracurricularOptions}
                        id="extraCurricularActivities"
                        name="extraCurricularActivities"
                        onBlur={() => setPreviousFieldTouch('extraCurricularActivities')}
                        error={getErrorText('extraCurricularActivities')}
                        handleChange={formik.handleChange}
                        value={formik.values.extraCurricularActivities}
                        required
                      />
                      {getErrorText('extraCurricularActivities')}
                    </Grid>
                  ) : (
                    <Grid
                      item
                      xs={12}
                      md={12}
                      lg={12}
                      className={classes.alignGrid}
                    >
                      <PerformantTextField
                        label={`${t('CHANGE_LOG')}`}
                        id="changeLogs"
                        name="changeLogs"
                        type="text"
                        value={formik.values.changeLogs}
                        onBlur={() => setPreviousFieldTouch('changeLogs')}
                        error={getErrorText('changeLogs')}
                        onChange={formik.handleChange}
                        required
                        allowSpecialCharacter
                      />
                    </Grid>
                  )}
                </Grid>
              </Grid>
            </Grid>
          </form>
        </FormikProvider>
        <Dialog
          open={isShirtSizeDialogOpen}
          onClose={handleShirtSizeDialogClose}
          PaperProps={{
            style: {
              borderRadius: '20px',
            },
          }}
        >
          <DialogTitle
            disableTypography
            style={{
              backgroundColor: '#e4f5fd',
              color: '#104F96',
              padding: '.75vw 1vw .75vw 2vw',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              fontSize: '1vw',
              fontWeight: '600',
            }}
          >
            T-Shirt Sizes
            <IconButton
              aria-label="close"
              onClick={handleShirtSizeDialogClose}
              style={{ position: 'absolute', right: 8, top: 8 }}
            >
              <CloseIcon />
            </IconButton>
          </DialogTitle>
          <DialogContent>
            <div>
              {loading && <p>Loading image...</p>}
              {/* {!loading && !error401 && imageUrl && (
              )} */}
              <img src="https://storage.googleapis.com/manabadi-portal-bucket/Others/T-ShirtSizeChart.png" alt="tshirt size" style={{ maxWidth: '100%', height: 'auto', borderRadius: '8px' }} />
              {/* {!loading && error401 && <p>Session expired. Please log in again.</p>}
              {!loading && !error401 && !imageUrl && !imageNotFound && <p>No image available.</p>}
              {!loading && !error401 && imageNotFound && <p>Image has been deleted or is no longer available.</p>} */}
            </div>
          </DialogContent>
        </Dialog>

        {isEnrollStudent
          && (
            <Grid
              container
              item
              spacing={4}
              flexDirection="row"
              mt={1}
            >
              <Grid item xs={12} md={12} lg={6} className={classes.mapContainerGrid}>
                <MapContainer
                  originLatLng={parentInfo?.homeAddressInfo || { lat: 0, lng: 0 }}
                  destinationLatLng={{
                    lat: formik?.values?.manabadiLocation !== '906e5655-10ee-4948-a35c-28f99d152e71' && formik?.values?.manabadiLocation !== '846413a4-a9de-4454-92e5-035f9d1a2884' ? getDestinationLatLng(
                      formik?.values?.manabadiLocation,
                    )?.locationAddress?.latitude || 0 : parentInfo?.homeAddressInfo?.lat,
                    lng: formik?.values?.manabadiLocation !== '906e5655-10ee-4948-a35c-28f99d152e71' && formik?.values?.manabadiLocation !== '846413a4-a9de-4454-92e5-035f9d1a2884' ? getDestinationLatLng(
                      formik?.values?.manabadiLocation,
                    )?.locationAddress?.longitude || 0 : parentInfo?.homeAddressInfo?.lng,
                  }}
                />
              </Grid>
              <Grid item xs={12} md={12} lg={6} className={classes.mapContainerDescGrid}>
                <div className={classes.locationHeader}>
                  {getDestinationLatLng(formik?.values?.manabadiLocation)?.name || ''}
                </div>

                <div className={classes.borderBottom} />
                {formik?.values?.manabadiLocation ? (
                  <>
                    {displayValue(t('LOCATION_ADDRESS'), (formik?.values?.manabadiLocation !== '906e5655-10ee-4948-a35c-28f99d152e71' && formik?.values?.manabadiLocation !== '846413a4-a9de-4454-92e5-035f9d1a2884' ? `${getDestinationLatLng(formik?.values?.manabadiLocation)?.locationAddress?.address}` : ''), classes)}
                    {displayValue(t('COORDINATOR'), `${getCoordinatorDetail(getDestinationLatLng(formik?.values?.manabadiLocation))?.user?.first_name || ''} ${getCoordinatorDetail(getDestinationLatLng(formik?.values?.manabadiLocation))?.user?.middle_name || ''} ${getCoordinatorDetail(getDestinationLatLng(formik?.values?.manabadiLocation))?.user?.last_name || ''}`, classes)}
                    {displayValue(t('COORDINATOREMAIL'), `${getCoordinatorDetail(getDestinationLatLng(formik?.values?.manabadiLocation))?.user?.manabadi_email || ''}`, classes)}
                    {displayValue(t('CONTACT_NO'), `${getCoordinatorDetail(getDestinationLatLng(formik?.values?.manabadiLocation))?.user?.contact_number || ''}`, classes)}
                    {displayValue(t('CLASS_TIMINGS'), `${getDestinationLatLng(formik?.values?.manabadiLocation)?.classTiming || ''} -
                    ${getFormatTime(getDestinationLatLng(formik?.values?.manabadiLocation)?.startTime) || ''} to
                    ${getFormatTime(getDestinationLatLng(formik?.values?.manabadiLocation)?.endTime) || ''}`, classes)}
                    {displayValue(t('DISTANCEFROMHOME'), (formik?.values?.manabadiLocation !== '906e5655-10ee-4948-a35c-28f99d152e71' && formik?.values?.manabadiLocation !== '846413a4-a9de-4454-92e5-035f9d1a2884' ? `${(getDestinationLatLng(formik?.values?.manabadiLocation)?.distance) !== null && (getDestinationLatLng(formik?.values?.manabadiLocation)?.distance) !== undefined ? getDestinationLatLng(formik?.values?.manabadiLocation)?.distance : ''} miles` : '0 miles'), classes)}
                  </>
                ) : ''}
              </Grid>
            </Grid>
          )}
      </Grid>
      {
        !isEnrollStudent ? (
          <div>
            <br />
            {isStudentOrSibling ? (
              <EditDialogFooterForStudent
                registerClasses={registerClasses}
                classes={classes}
                setDialogOpen={setDialogOpen}
                onSubmit={formik.handleSubmit}
                isStudent={isStudent}
                isSibling={isSibling}
                onCancel={() => (isStudent ? setEditStudent(false) : setEditSibling(false))}
                t={t}
              />
            ) : (
              <EditDialogFooter
                registerClasses={registerClasses}
                classes={classes}
                setStudentInfo={setStudentInfo}
                setDialogOpen={setDialogOpen}
                viewLogs={viewLogs}
                onSubmit={formik.handleSubmit}
                isFormValid={isFormValid}
                t={t}
              />
            )}
          </div>
        ) : null
      }
      {
        loading && (
          <Grid>
            <Loader message={t('LOADING')} />
          </Grid>
        )
      }
    </Box>
  );
}
