/* eslint-disable no-restricted-syntax */
import { colors } from '../../../../theme';

const announceDialog = () => ({
  announcementDialogAtom: {
    '@media (min-width: 1200px)': {
      fontSize: '0.9vw',
    },
    '& .MuiDialogContent-root': {
      '@media (min-width: 1200px)': {
        minWidth: '50vw',
        maxWidth: '50vw',
      },
    },
  },
  mailID: {
    minHeight: '15vw !important',
    maxHeight: '15vw !important',
    border: `1px solid ${colors.black}`,
    marginTop: '1vw',
    marginBottom: '2vw',
    padding: '1vw',
    overflow: 'scroll',
    backgroundColor: colors.bgGrey,
  },
  scrollableContent: {
    height: 'auto !important',
    '@media (min-width: 1810px)': {
      maxHeight: '78px',
    },
    '@media (max-width: 1809px)': {
      maxHeight: '75px',
    },
    overflowY: 'auto',
    paddingRight: '10px',
  },
  announcementDetails: {
    '@media (min-width: 1200px)': {
      fontSize: '0.9vw',
    },
    '& .MuiTypography-root': {
      '@media (min-width: 1200px)': {
        fontSize: '0.9vw',
        height: '1.5vw',
        '& svg': {
          width: '1.3vw',
          height: '1.3vw',
        },
      },
    },
  },
  menssageHeader: {
    color: colors.primary,
    '@media (min-width: 1200px)': {
      width: '8vw',
    },
    float: 'left',
    margin: 0,
  },
});

export default announceDialog;
