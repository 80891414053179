/* eslint-disable */
import React, {
  useEffect, useState, memo, useRef,
} from 'react';
import {
  Grid, Card,
  Typography,
  Collapse,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  IconButton,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import _ from 'lodash';
import { FormikProvider } from 'formik';
import ButtonAtom from '../../../components/atoms/button';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { isValidPhoneNumber } from 'react-phone-number-input';
import { Paper } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import Tooltip from '@mui/material/Tooltip';
import { getCourseByAge } from '../../../store/services/getCourseByAge';

import {
  Datepicker,
  AddressAutoComplete,
  PhoneInputRegistration,
  TextField,
} from '../../../components/atoms';
import TextFieldAtom from '../../../components/atoms/text-field-with-label-spot-registration';
import Dropdown from '../../../components/atoms/dropdown-with-labelRegistration';
import { Buttons } from '../../../constant';
import useStyles from '../../../custom-hooks/useStyles';
// eslint-disable-next-line import/no-named-as-default
import styles from './style';
import style from '../style';
import commonStyle from '../../../utils/commonClasses';
import { titleOptions, genderOptions } from './constant';

import { getParentPlaceInformation } from '../../../utils/mapMethod';
import { getEmailAvailabilityService } from '../../../store/services/auth';
import { removeSpecialChar, getCurrentYear, hasNumericOrSpecialChar, checkDateValid, getDistanceBetweenTwoPoint } from '../../../utils/methods';
import Loader from '../../../components/atoms/loader';
import PerformantTextField from '../../../components/atoms/PerformantTextFieldRegistration';
import useParent from '../../../custom-hooks/useParent';
import PerfromantDropdown from '../../../components/atoms/PerfromantDropDownRegistration';
import getLocationByCourse from '../../../store/services/getLocationByCourse';

const UserForm = ({
  handleStepper,
  steps,
  activeStep,
  formik,
  setPreviousFieldTouch,
  parentTwoInfo,
  setParentTwoInfo,
  studentTwoInfo,
  setStudentTwoInfo,
  isStudentTwoFilled,
  isParentTwoFilled,
  homeAddressInfo,
  setHomeAddressInfo,
  loading,
  setLoading,
  studentInfoFromStore,
  gradeOptions,
  mapOptions,
  setMapOptions,
  classLevelOptionSTwo,
  setClassLevelOptionSTwo,
  classLevelOptionSOne,
  setClassLevelOptionSOne,
  selectedYearStudentOne,
  setSelectedYearStudentOne,
  selectedYearStudentTwo,
  setSelectedYearStudentTwo,
  missingHomeAddress,
  setMissingHomeAddress,
  getGeneralErrorText,
  registrationTempInfo,
  parentOneEmailAlreadyExist,
  setParentOneEmailAlreadyExist,
  parentTwoEmailAlreadyExist,
  setParentTwoEmailAlreadyExist,
  isSiblingLastNameMismatch,
  setIsSiblingLastNameMismatch,
  isSiblingFirstNameMatch,
  setIsSiblingFirstNameMatch,
  checkEmail,
  setCheckEmail
}) => {
  const { t } = useTranslation();

  const [isExpandedParentTwo, setIsExpandedParentTwo] = useState(false);
  const [isExpandedStudentTwo, setIsExpandedStudentTwo] = useState(false);
  const [selectedOption, setSelectedOption] = useState('');
  const [parentOneCellphone, setParentOneCellphone] = useState('');
  const [parentTwoCellphone, setParentTwoCellphone] = useState('');
  const [parentOneDefaultCountry, setParentOneDefaultCountry] = useState('US');
  const [parentTwoDefaultCountry, setParentTwoDefaultCountry] = useState('US');
  const addressRef = useRef(null);
  const tShirtOptions = studentInfoFromStore?.tshirts;
  const [isShirtSizeDialogOpen, setShirtSizeDialogOpen] = useState(false);

  const handleShirtSizeDropdownClick = () => {
    setShirtSizeDialogOpen(true);
  };

  const handleShirtSizeDialogClose = () => {
    setShirtSizeDialogOpen(false);
  };
  const handleExpandParentTwoClick = () => {
    setIsExpandedParentTwo(!isExpandedParentTwo);
  };
  const handleExpandStudentTwoClick = () => {
    setIsExpandedStudentTwo(!isExpandedStudentTwo);
  };
  const handleClearStudentTwoClick = () => {
    setSelectedYearStudentTwo(getCurrentYear()?.id);
    setStudentTwoInfo({
      studentTwoFirstName: '',
      studentTwoLastName: '',
      studentTwoDOB: '',
      studentTwoAcaGrade: '',
      studentTwoCourse: '',
      studentTwoGender: '',
      studentTwoShirt: '',
    });
    setIsSiblingLastNameMismatch(false);
    setIsSiblingFirstNameMatch(false);
    const auxStudentOneDOB = formik.values.studentOneDOB;
    const auxStudentTwoDOB = new Date();
    formik.resetForm({
      values:
      {
        parentOneTitle: formik.values.parentOneTitle,
        parentOneFirstName: formik.values.parentOneFirstName,
        parentOneLastName: formik.values.parentOneLastName,
        parentOneEmail: formik.values.parentOneEmail,
        homeAddress: formik.values.homeAddress,
        aptSuite: formik.values.aptSuite,
        parentOneContact: formik.values.parentOneContact,
        parentTwoTitle: formik.values.parentTwoTitle,
        parentTwoFirstName: formik.values.parentTwoFirstName,
        parentTwoLastName: formik.values.parentTwoLastName,
        parentTwoEmail: formik.values.parentTwoEmail,
        parentTwoContact: formik.values.parentTwoContact,
        studentOneFirstName: formik.values.studentOneFirstName,
        studentOneLastName: formik.values.studentOneLastName,
        studentOneDOB: formik.values.studentOneDOB,
        studentOneAcaGrade: formik.values.studentOneAcaGrade,
        studentOneLocation: formik.values.studentOneLocation,
        studentOneClassTimings: formik.values.studentOneClassTimings,
        studentOneCourse: formik.values.studentOneCourse,
        studentOneAcaYear: formik.values.studentOneAcaYear,
        studentOneGender: formik.values.studentOneGender,
        studentOneShirt: formik.values.studentOneShirt,
        studentTwoFirstName: '',
        studentTwoLastName: '',
        studentTwoDOB: new Date(),
        studentTwoAcaGrade: '',
        studentTwoLocation: formik.values.studentOneLocation,
        studentTwoClassTimings: formik.values.studentOneClassTimings,
        studentTwoCourse: '',
        studentTwoAcaYear: '',
        studentTwoGender: '',
        studentTwoShirt: '',
      }
    });
    formik.setFieldValue('studentOneDOB', auxStudentOneDOB);
    formik.setFieldValue('studentTwoDOB', auxStudentTwoDOB);
    setIsSiblingLastNameMismatch(false);
    setIsSiblingFirstNameMatch(false);
  };
  const registerClasses = useStyles(style)();

  const textField = (
    label,
    id,
    type,
    handleChange,
    onBlur,
    value,
    error,
    setValue = () => { },
    required = true,
    allowSpecialCharacter = true,
    customClass = '',
    resetValue = false
  ) => {
    const inputRef = useRef();

    useEffect(() => {
      if (!value && inputRef?.current?.value) {
        setValue?.(id, inputRef?.current?.value);
      }
    }, [inputRef?.current?.value]);

    return (
      <PerformantTextField
        label={`${label} ${required ? '' : ''}`}
        id={id}
        required={required}
        name={id}
        type={type}
        inputRef={inputRef}
        value={value}
        onBlur={onBlur}
        error={error}
        inputStyle={{
          WebkitBoxShadow: '0 0 0 1000px white inset',
        }}
        onChange={(e) => {
          handleChange(e);
          if (id === 'studentOneFirstName') {
            const primaryStudentFirstName = e.target.value;
            const siblingFirstName = formik.values?.studentTwoFirstName;
            if (siblingFirstName !== '' && siblingFirstName.toLowerCase()?.trim() === primaryStudentFirstName.toLowerCase()?.trim()) {
              setIsSiblingFirstNameMatch(true);
            } else {
              setIsSiblingFirstNameMatch(false);
            }
          }
          if (id === 'studentOneLastName') {
            const primaryStudentLastName = e.target.value;
            const siblingLastName = formik.values?.studentTwoLastName;
            if (siblingLastName !== '' && siblingLastName.toLowerCase()?.trim() !== primaryStudentLastName.toLowerCase()?.trim()) {
              setIsSiblingLastNameMismatch(true);
            } else {
              setIsSiblingLastNameMismatch(false);
            }
          }
          if (Object.keys(parentTwoInfo).includes(id)) {
            setParentTwoInfo({ ...parentTwoInfo, [id]: e.target.value });
          }
          if (Object.keys(studentTwoInfo).includes(id)) {
            setStudentTwoInfo({ ...studentTwoInfo, [id]: e.target.value });
          }
        }}
        allowSpecialCharacter={allowSpecialCharacter}
        customClass={customClass}
      />
    );
  };
  const phoneInput = (
    label,
    id,
    handleChange,
    onBlur,
    onCountryChange,
    defaultCountry,
    value,
    error,
    setValue = () => { },
    required = true,
  ) => {
    const inputRef = useRef();

    useEffect(() => {
      if (!value && inputRef?.current?.value) {
        setValue?.(id, inputRef?.current?.value);
      }
    }, [inputRef?.current?.value]);

    return (
      <PhoneInputRegistration
        customClass="customClass1"
        label={label}
        inputRef={inputRef}
        inputProps={{
          style: {
            WebkitBoxShadow: '0 0 0 1000px white inset',
          },
        }}
        id={id}
        required={required}
        name={id}
        value={value}
        onBlur={onBlur}
        shrink
        showLabel
        onCountryChange={onCountryChange}
        defaultCountry={defaultCountry}
        error={error && Object.keys(error).length}
        onChange={(e) => {
          handleChange(e);
        }}
      />
    );
  };

  const classes = useStyles(styles)();
  const setTitle = (title) => (
    <Grid container className={classes.heading}>
      <Grid item xs={12}>
        {title}
      </Grid>
    </Grid>
  );

  const getErrorText = (key, errorText, specificID) => {
    let errorClass = classes.errorText;

    switch (key) {
      case 'homeAddress':
        errorClass = key === 'homeAddress' ? classes.errorTextGrid1 : classes.errorText;
        break;
      case 'parentOneContact':
        errorClass = key === 'parentOneContact' ? classes.errorTextGrid1 : classes.errorText;
        break;
      case 'parentTwoContact':
        errorClass = key === 'parentTwoContact' ? classes.errorTextGrid1 : classes.errorText;
        break;
      case 'parentOneTitle':
        errorClass = key === 'parentOneTitle' ? classes.errorTextGrid2 : classes.errorText;
        break;
      case 'parentTwoTitle':
        errorClass = key === 'parentTwoTitle' ? classes.errorTextGrid2 : classes.errorText;
        break;
      default:
        errorClass = classes.errorText;
    }

    if (formik.touched[key] && formik.errors[key]) {
      return (
        <span data-testid={key} className={errorClass}>
          {formik.errors[key]}
        </span>
      );
    }
    if (errorText) {
      return (
        <span className={errorClass}>
          {errorText}
        </span>
      );
    }
    return null;
  };

  function showPhoneError(touched, values, flag) {
    if (touched && !values) {
      return getErrorText(flag);
    } if (values && isValidPhoneNumber(values)) {
      return true;
    } if (touched) {
      return getErrorText(null, t('INVALID_PHONE'));
    }
    return true;
  }

  const checkEligibilityForFLC = (options, student) => {
    let selectedGrade
    if (student === 1) {
      selectedGrade = _.find(gradeOptions, (go) => go.id === formik.values.studentOneAcaGrade);
    }
    if (student === 2) {
      selectedGrade = _.find(gradeOptions, (go) => go.id === formik.values.studentTwoAcaGrade);
    }

    let classOptions = options;
    if (parseInt(selectedGrade?.name, 10) < 7 || selectedGrade?.name === 'K' || selectedGrade?.name === 'Pre-K' || selectedGrade?.name === undefined) {
      classOptions = options.filter((o) => o.name !== 'Telugu-1' && o.name !== 'Telugu-2' && o.name !== 'Telugu-3' && o.name !== 'Telugu-4');
    }
    classOptions.sort((a, b) => {
      const yearA = parseInt(a.academic_infos.academicYear.split('-')[0], 10);
      const yearB = parseInt(b.academic_infos.academicYear.split('-')[0], 10);
      if (yearA > yearB) {
        return -1;
      }
      if (yearA < yearB) {
        return 1;
      }

      return 0;
    });
    const uniqueClassOptions = Array.from(new Map(classOptions.map((item) => [item.id, item])).values());

    return uniqueClassOptions;
  };

  const getClassLevelOptionsSOne = (dob, location) => {
    getCourseByAge(dob, location, false).then((options) => {
      const courseOptions = checkEligibilityForFLC(options, 1);
      setClassLevelOptionSOne(courseOptions);
    }).catch(() => { });
  };

  const getClassLevelOptionsSTwo = (dob, location) => {
    getCourseByAge(dob, location, false).then((options) => {
      const courseOptions = checkEligibilityForFLC(options, 2);
      setClassLevelOptionSTwo(courseOptions)
    }).catch(() => { });
  };

  useEffect(() => {
    if (formik.values.studentOneDOB !== '' && formik.values.studentOneLocation !== '') {
      getClassLevelOptionsSOne(formik.values.studentOneDOB, formik.values.studentOneLocation);
    }
  }, [formik.values.studentOneDOB, formik.values.studentOneLocation, formik.values.studentOneAcaGrade]);

  useEffect(() => {
    if (formik.values.studentTwoDOB !== '' && formik.values.studentTwoLocation !== '') {
      getClassLevelOptionsSTwo(formik.values.studentTwoDOB, formik.values.studentTwoLocation);
    }
  }, [formik.values.studentTwoDOB, formik.values.studentTwoLocation, formik.values.studentTwoAcaGrade]);

  const getMapOptions = async (options) => {
    if (fetch) {
      if (formik.values.homeAddress && formik.values.homeAddress !== '') {
        const resCountry = await getParentPlaceInformation(formik.values.homeAddress);
        let distanceMapOptions = options?.map((opt) => {
          const res = getDistanceBetweenTwoPoint(resCountry, {
            lat: opt?.locationAddress?.latitude,
            lng: opt?.locationAddress?.longitude,
          });
          const miles = res ? res / 1609.34 : 0;
          return { ...opt, distance: miles ? miles.toFixed(2) : 0 };
        });
        distanceMapOptions = distanceMapOptions.filter((item) => item.locationAddress.latitude !== null && item.locationAddress.longitude);
        distanceMapOptions = distanceMapOptions.filter((item) => item.locationAddress.country === resCountry.country);
        distanceMapOptions = distanceMapOptions?.sort((a, b) => a.distance - b.distance);
        setMissingHomeAddress('');
        distanceMapOptions = distanceMapOptions.map((item) => {
          if (item.id !== '906e5655-10ee-4948-a35c-28f99d152e71' && item.id !== '846413a4-a9de-4454-92e5-035f9d1a2884') {
            item.name = `${item.name} - ${item.locationAddress.address} ~ ${item.distance} mi`;
          }
          return item;
        });
        const distanceMapOptionsExists = !!(distanceMapOptions && distanceMapOptions?.length);
        const isMore20Miles = !(distanceMapOptionsExists && distanceMapOptions[0]?.distance <= 20);
        distanceMapOptions = distanceMapOptionsExists ? distanceMapOptions : options;
        const reorderedOptions = [...distanceMapOptions];
        const locationId = resCountry?.country === 'United States' ? '906e5655-10ee-4948-a35c-28f99d152e71' : '846413a4-a9de-4454-92e5-035f9d1a2884';
        const index = reorderedOptions.findIndex((option) => option.id === locationId);
        if (index !== -1 && isMore20Miles) {
          const [item] = reorderedOptions.splice(index, 1);
          reorderedOptions.unshift(item);
        }
        setMapOptions(reorderedOptions);
      } else {
        setMissingHomeAddress(`${t('MISSING_HOME_ADDRESS')}`);
      }
    }
  };

  const getLocationsByCourse = (courseId) => {
    getLocationByCourse(courseId)
      .then((options) => {
        getMapOptions(options);
      })
      .catch(() => { });
  };
  useEffect(() => {
    formik.validateField('homeAddress');
  }, [homeAddressInfo]);
  useEffect(() => {
    if (formik.values.studentOneCourse !== '') {
      getLocationsByCourse(formik.values.studentOneCourse);
      const selectedClassLevel = _.find(classLevelOptionSOne, (co) => co.id === formik.values.studentOneCourse);
      setSelectedYearStudentOne(selectedClassLevel?.academic_infos?.academicYear);
    }
  }, [formik.values.studentOneCourse]);

  useEffect(() => {
    if (formik.values.studentTwoCourse !== '') {
      const selectedClassLevel = _.find(classLevelOptionSTwo, (co) => co.id === formik.values.studentTwoCourse);
      setSelectedYearStudentTwo(selectedClassLevel?.academic_infos?.academicYear);
    }
  }, [formik.values.studentTwoCourse]);

  function convertTo12HourFormat(time24) {
    if (time24) {
      const [hours, minutes, seconds] = time24?.split(':').map(Number);
      const period = hours < 12 ? 'AM' : 'PM';
      const hours12 = (hours % 12) || 12;
      return `${hours12}:${minutes < 10 ? '0' : ''}${minutes} ${period}`;
    }
  }

  useEffect(() => {
    if (mapOptions?.length > 0) {
      const location = mapOptions.filter((l) => l.id === formik.values.studentOneLocation)[0];
      if (location) {
        const startTime = convertTo12HourFormat(location?.startTime);
        const endTime = convertTo12HourFormat(location?.endTime);
        const classTiming = `${location?.classTiming} - ${startTime} to ${endTime}`;
        formik.setFieldValue('studentOneClassTimings', classTiming || '');
        formik.setFieldValue('studentTwoClassTimings', classTiming || '');
      }
    }
  }, [formik.values.studentOneLocation]);

  useEffect(() => {
    if (mapOptions?.length > 0) {
      const location = mapOptions.filter((l) => l.id === formik.values.studentTwoLocation)[0];
      if (location) {
        const startTime = convertTo12HourFormat(location?.startTime);
        const endTime = convertTo12HourFormat(location?.endTime);
        const classTiming = `${location?.classTiming} - ${startTime} to ${endTime}`;
        formik.setFieldValue('studentTwoClassTimings', classTiming || '');
      }
    }
  }, [formik.values.studentTwoLocation]);

  const handleParentOneCellphone = (phone) => {
    setParentOneCellphone(phone);
    formik.setFieldValue('parentOneContact', phone || '');
  };
  const handleParentTwoCellphone = (phone) => {
    setParentTwoInfo({ ...parentTwoInfo, parentTwoContact: phone });
    setParentTwoCellphone(phone);
    formik.setFieldValue('parentTwoContact', phone || '');
  };
  const handleParentOneDefaultCountry = (value) => {
    setParentOneDefaultCountry(value);
  };
  const handleParentTwoDefaultCountry = (value) => {
    setParentTwoDefaultCountry(value);
  };

  useEffect(() => {
    getMapOptions(studentInfoFromStore?.locations);
  }, [studentInfoFromStore?.locations, formik.values.homeAddress]);

  const setHeaderTitle = (title) => (
    <Grid container spacing={0} justifyContent="space-between">
      <Grid item xs={11}>
        <div className={registerClasses.headerTitle}>
          {title === 'parent' ? t('PARENT_INFORMATION') : t('STUDENT_INFORMATION')}
        </div>
      </Grid>
    </Grid>
  );

  // Function to show alert when user tries to leave the page
  useEffect(() => {
    const handleBeforeUnload = (event) => {
      event.preventDefault();
      // Cancel the event
      event.returnValue = '';
      // Chrome requires returnValue to be set
      return '';
    };
    localStorage.removeItem('paymentSuccess');
    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, []);
  return (
    <>
      <Card sx={{ paddingBottom: 2, width: '96% !important', }} className={classes.card}>
        <FormikProvider value={formik}>
          <form
            name="tenantUserForm"
            noValidate
            autoComplete="off"
            className={`${registerClasses.form} ${classes.form}`}
          >
            {/* Header Parent */}
            <Grid
              container
              className={registerClasses.headerContainer}
              xs={12}
              justifyContent="space-between"
            >
              {setHeaderTitle('parent')}
            </Grid>
            {/* Parent 1 */}
            <Grid container className={registerClasses.mainContainer}>
              <Paper elevation={1} style={{ background: "#e4f5fd", padding: '5px 5px 5px 13px', margin: '10px auto 5px auto', height: '40px', width: '97%', boxShadow: 'none' }}>
                <Grid container spacing={2} className={classes.innerContainer} alignItems="center">
                  <Grid item className={classes.innerContainerTitle}>
                    {setTitle(t('PARENT_ONE_DETAILS'))}
                  </Grid>
                </Grid>
              </Paper>
              <Grid container spacing={2} className={classes.innerContainer}>
                <Grid item className={classes.alignGridTitle} style={{ paddingRight: '0px' }}>
                  <PerfromantDropdown
                    minWidth="100%"
                    label={t('TITLE')}
                    labelId={t('TITLE')}
                    id="parentOneTitle"
                    name="parentOneTitle"
                    value={formik.values.parentOneTitle}
                    onBlur={() => setPreviousFieldTouch('parentOneTitle', 'parentOne')}
                    error={getErrorText('parentOneTitle')}
                    required
                    handleChange={formik.handleChange}
                    options={titleOptions}
                    className={classes.dropdownText}
                  />
                  {getErrorText('parentOneTitle')}
                </Grid>
                <Tooltip
                  title={t('HOVER_FIRSTNAME')}
                  placement="bottom-end"
                  arrow
                >
                  <Grid
                    item
                    className={classes.alignGridFirstName}

                  >
                    {textField(
                      t('FIRST_NAME'),
                      'parentOneFirstName',
                      'text',
                      formik.handleChange,
                      () => setPreviousFieldTouch('parentOneFirstName', 'parentOne'),
                      removeSpecialChar(formik.values.parentOneFirstName),
                      getErrorText('parentOneFirstName'),
                      formik.setFieldValue,
                      true,
                      false,
                    )}
                  </Grid>
                </Tooltip>
                <Tooltip title={t('HOVER_LASTNAME')} placement="bottom-end" arrow>
                  <Grid
                    item
                    className={classes.alignGridLastName}

                  >
                    {textField(
                      t('LAST_NAME'),
                      'parentOneLastName',
                      'text',
                      formik.handleChange,
                      () => setPreviousFieldTouch('parentOneLastName', 'parentOne'),
                      removeSpecialChar(formik.values.parentOneLastName),
                      getErrorText('parentOneLastName'),
                      formik.setFieldValue,
                      true,
                      false,
                    )}
                  </Grid>
                </Tooltip>
                <Grid
                  item
                  className={classes.alignGridEmail}

                >
                  <TextFieldAtom
                    label={t('EMAIL')}
                    id='parentOneEmail'
                    required
                    type='email'
                    value={formik.values.parentOneEmail}
                    onBlur={(e) => {
                      setCheckEmail(true);
                      getEmailAvailabilityService(e.target.value)
                        .then((parentOneResponse) => {
                          if (parentOneResponse?.email_found) {
                            setParentOneEmailAlreadyExist(
                              <span style={{
                                color: '#ff0000', fontSize: '10px',
                                '@media (max-width: 768px)': {
                                  fontSize: '12px'
                                }
                              }}>
                                {t('EMAIL_ALREADY_EXIST')}
                              </span>);
                          } else {
                            setParentOneEmailAlreadyExist(false);
                          }
                          setCheckEmail(false);
                        });
                      setPreviousFieldTouch('parentOneEmail', 'parentOne');
                    }}
                    error={parentOneEmailAlreadyExist || getErrorText('parentOneEmail')}
                    onChange={(e) => {
                      formik.setFieldValue('parentOneEmail', e.target.value)
                    }}
                  />
                  {parentOneEmailAlreadyExist || getErrorText('parentOneEmail')}
                </Grid>
              </Grid>
              <Grid container spacing={2} className={classes.innerContainerWithoutTitle}>
                <Grid
                  item
                  className={classes.alignGridAdress}
                >
                  <AddressAutoComplete
                    ref={addressRef}
                    shrink
                    showLabel
                    className={classes.textArea}
                    label={`${t('HOMEADDRESS')} `}
                    id="homeAddress"
                    required
                    name="homeAddress"
                    value={formik.values.homeAddress}
                    error={getErrorText('homeAddress')}
                    onPlaceSelected={async (e) => {
                      const res = await getParentPlaceInformation(e.formatted_address);
                      formik.setFieldValue(
                        'homeAddress',
                        e.formatted_address,
                        true,
                      );
                      setHomeAddressInfo({ ...res, add: e.formatted_address });
                      addressRef.current = { value: e.formatted_address };
                    }}
                    onChange={(e) => {
                      formik.setFieldValue('homeAddress', e.target.value);
                    }}
                    OnInput={(e) => {
                      // Actualizar el estado interno del formulario aquí
                      formik.setFieldValue('homeAddress', e.target.value);
                    }}
                    onBlur={() => setPreviousFieldTouch('homeAddress', 'parentOne')}
                  />
                  {getErrorText('homeAddress')}
                </Grid>
                <Grid
                  item
                  className={classes.alignGridAppSuite}
                >
                  {textField(
                    t('APISUITE'),
                    'aptSuite',
                    'text',
                    formik.handleChange,
                    () => setPreviousFieldTouch('aptSuite', 'parentOne'),
                    formik.values.aptSuite,
                    false,
                    formik.setFieldValue,
                    '',
                  )}
                </Grid>
                <Grid
                  item
                  className={classes.alignTextGridPhone}
                >
                  {phoneInput(
                    `${t('CONTACT_NO')} *`,
                    'parentOneContact',
                    handleParentOneCellphone,
                    () => setPreviousFieldTouch('parentOneContact'),
                    handleParentOneDefaultCountry,
                    parentOneDefaultCountry,
                    formik?.values?.parentOneContact,
                    getErrorText('parentOneContact')
                    || (formik?.touched?.parentOneContact
                      && formik?.values?.parentOneContact
                      && !isValidPhoneNumber(formik?.values?.parentOneContact)
                      && getErrorText(null, t('INVALID_PHONE'))),
                  )}
                  {showPhoneError(formik?.touched?.parentOneContact, formik?.values.parentOneContact, 'parentOneContact')}
                </Grid>
              </Grid>
            </Grid>
            {/* Parent 2 */}
            <Grid container className={registerClasses.mainContainer2}>
              <Paper elevation={1} style={{ background: "#e4f5fd", padding: '5px 5px 5px 13px', margin: '10px auto 5px auto', height: '40px', width: '98.5%', boxShadow: 'none' }}>
                <Grid container spacing={2} className={classes.innerContainer} alignItems="center">
                  <Grid item xs={10} className={classes.innerContainerTitle}>
                    {setTitle(t('PARENT_TWO_DETAILS'))}
                  </Grid>
                  <Grid item xs={2} style={{ textAlign: 'right', padding: '2px', margin: '-5px 0px 0px -20px' }}>
                    <ButtonAtom
                      btntype={Buttons.SECONDARY}
                      onClick={handleExpandParentTwoClick}
                      style={{ padding: '0px', minWidth: 'auto' }}
                      className={classes.expandIcon}
                      icon={isExpandedParentTwo ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                    />
                  </Grid>
                </Grid>
              </Paper>
              <Collapse in={isExpandedParentTwo} style={{ paddingBottom: '15px' }}>
                <Grid container spacing={2} className={classes.innerContainer2}>
                  <Grid
                    item
                    className={classes.alignGridTitle2}
                    style={{ paddingRight: '0px' }}
                  >
                    <PerfromantDropdown
                      minWidth="100%"
                      label={t('TITLE')}
                      labelId={t('TITLE')}
                      id="parentTwoTitle"
                      name="parentTwoTitle"
                      value={formik.values.parentTwoTitle}
                      onBlur={() => setPreviousFieldTouch('parentTwoTitle', 'parentTwo')}
                      error={getErrorText('parentTwoTitle')}
                      required={isParentTwoFilled()}
                      handleChange={(e) => {
                        formik.handleChange(e);
                        formik.setFieldValue('parentTwoTitle', e.target.value);
                        setParentTwoInfo({
                          ...parentTwoInfo,
                          parentTwoTitle: e.target.value,
                        });
                      }}
                      options={titleOptions}
                      className={classes.dropdownText}
                    />
                    {getErrorText('parentTwoTitle')}
                  </Grid>
                  <Tooltip
                    title={t('HOVER_FIRSTNAME')}
                    placement="bottom-end"
                    arrow
                  >
                    <Grid
                      item
                      className={classes.alignGridFirstName2}
                    >
                      {textField(
                        t('FIRST_NAME'),
                        'parentTwoFirstName',
                        'text',
                        formik.handleChange,
                        () => setPreviousFieldTouch('parentTwoFirstName', 'parentTwo'),
                        removeSpecialChar(formik.values.parentTwoFirstName),
                        getErrorText('parentTwoFirstName'),
                        formik.setFieldValue,
                        isParentTwoFilled(),
                        false,
                      )}
                    </Grid>
                  </Tooltip>
                  <Tooltip title={t('HOVER_LASTNAME')} placement="bottom-end" arrow>
                    <Grid
                      item
                      className={classes.alignGridLastName2}
                    >
                      {textField(
                        t('LAST_NAME'),
                        'parentTwoLastName',
                        'text',
                        formik.handleChange,
                        () => setPreviousFieldTouch('parentTwoLastName', 'parentTwo'),
                        removeSpecialChar(formik.values.parentTwoLastName),
                        getErrorText('parentTwoLastName'),
                        formik.setFieldValue,
                        isParentTwoFilled(),
                        false,
                      )}
                    </Grid>
                  </Tooltip>
                  <Grid
                    item
                    className={classes.alignTextGrid2}
                    style={{ paddingBottom: '7px' }}
                  >
                    <TextFieldAtom
                      label={t('EMAIL')}
                      id='parentTwoEmail'
                      required={isParentTwoFilled()}
                      type='email'
                      value={formik.values.parentTwoEmail}
                      onBlur={(e) => {
                        if (e.target.value !== '') {
                          setCheckEmail(true);
                          getEmailAvailabilityService(e.target.value)
                            .then((parentOneResponse) => {
                              if (parentOneResponse?.email_found) {
                                setParentTwoEmailAlreadyExist(
                                  <span style={{ color: 'red', fontSize: '0.7vw', marginTop: '-10px' }}>
                                    {t('EMAIL_ALREADY_EXIST')}
                                  </span>);
                              } else {
                                setParentTwoEmailAlreadyExist(false);
                              }
                              setCheckEmail(false);
                            });
                          setPreviousFieldTouch('parentTwoEmail', 'parentTwo');
                        } else {
                          setParentTwoEmailAlreadyExist(false);
                          setCheckEmail(false);
                        }
                      }}
                      error={parentTwoEmailAlreadyExist || getErrorText('parentTwoEmail')}
                      onChange={(e) => {
                        formik.setFieldValue('parentTwoEmail', e.target.value)
                        if (Object.keys(parentTwoInfo).includes('parentTwoEmail')) {
                          setParentTwoInfo({ ...parentTwoInfo, ['parentTwoEmail']: e.target.value });
                        }
                      }}
                    />
                    {parentTwoEmailAlreadyExist || getErrorText('parentTwoEmail')}
                  </Grid>
                </Grid>
                <Grid container spacing={2} className={classes.innerContainerWithoutTitle}>
                  <Grid
                    item
                    className={classes.alignTextGridPhone2}
                  >
                    {phoneInput(
                      `${t('CONTACT_NO')} ${isParentTwoFilled() ? '*' : ''}`,
                      'parentTwoContact',
                      handleParentTwoCellphone,
                      () => {
                        formik.setTouched(
                          { ...formik.touched, parentTwoContact: true },
                          true,
                        );
                        setPreviousFieldTouch('parentTwoContact', 'parentTwo');
                      },
                      handleParentTwoDefaultCountry,
                      parentTwoDefaultCountry,
                      formik?.values?.parentTwoContact,
                      getErrorText('parentTwoContact')
                      || (formik?.touched?.parentTwoContact
                        && formik?.values?.parentTwoContact
                        && !isValidPhoneNumber(formik?.values?.parentTwoContact)
                        && getErrorText(null, t('INVALID_PHONE'))),
                    )}
                    {showPhoneError(formik?.touched?.parentTwoContact, formik?.values.parentTwoContact, 'parentTwoContact')}
                  </Grid>
                </Grid>
              </Collapse>
            </Grid>
            {/* Header Student */}
            <Grid
              container
              className={registerClasses.headerContainer}
              xs={12}
              justifyContent="space-between"
            >
              {setHeaderTitle('Student')}
            </Grid>
            {/* Student 1 */}
            <Grid container className={registerClasses.mainContainer}>
              <Paper elevation={1} style={{ background: "#e4f5fd", padding: '5px 5px 5px 13px', margin: '10px auto 5px auto', height: '40px', width: '97%', boxShadow: 'none' }}>
                <Grid container spacing={2} className={classes.innerContainer} alignItems="center">
                  <Grid item xs={10} className={classes.innerContainerTitle}>
                    {setTitle(t('STUDENT_ONE_DETAILS'))}
                  </Grid>
                </Grid>
              </Paper>
              <Grid container spacing={2} className={classes.innerContainer}>
                <Grid container spacing={2} className={classes.innerContainer}>
                  <Tooltip title={t('HOVER_STU_FIRSTNAME')} placement="bottom-end" arrow>
                    <Grid item className={classes.alignGridFirstNamesTUDENT}>
                      {textField(
                        t('FIRST_NAME'),
                        'studentOneFirstName',
                        'text',
                        formik.handleChange,
                        () => setPreviousFieldTouch('studentOneFirstName', 'studentOne'),
                        removeSpecialChar(formik.values.studentOneFirstName),
                        getErrorText('studentOneFirstName', isSiblingFirstNameMatch ? `${t('SIBLING_FIRSTNAME_ERROR4')}` : null),
                        formik.setFieldValue,
                        true,
                        false,
                      )}
                    </Grid>
                  </Tooltip>
                  <Tooltip title={t('HOVER_STU_LASTNAME')} placement="bottom-end" arrow>
                    <Grid item className={classes.alignGridFirstNamesTUDENT}>
                      {textField(
                        t('LAST_NAME'),
                        'studentOneLastName',
                        'text',
                        formik.handleChange,
                        () => setPreviousFieldTouch('studentOneLastName', 'studentOne'),
                        removeSpecialChar(formik.values.studentOneLastName),
                        getErrorText('studentOneLastName', isSiblingLastNameMismatch ? `${t('SIBLING_LASTNAME_ERROR2')}` : null),
                        formik.setFieldValue,
                        true,
                        false,
                      )}
                    </Grid>
                  </Tooltip>
                  <Grid item className={classes.alignGridFirstNamesTUDENT} style={{ paddingTop: '16px' }}>
                    <Datepicker
                      wrapperClassName={classes.datePicker}
                      customClass={classes.datePickerCustom}
                      label={`${t('DOB')} *`}
                      minWidth="100%"
                      id="studentOneDOB"
                      name="studentOneDOB"
                      type="date"
                      value={formik.values.studentOneDOB}
                      onBlur={() => setPreviousFieldTouch('studentOneDOB', 'studentTwo')}
                      onChange={(newDate) => {
                        window.newDate = newDate;
                        formik.setFieldValue('studentOneDOB', newDate);
                      }}
                      error={!checkDateValid(formik.values.studentOneDOB)}
                      maxDate={new Date()}
                      inputProps={{
                        style: {
                          WebkitBoxShadow: '0 0 0 1000px white inset',
                        },
                      }}
                    />
                    {!checkDateValid(formik.values.studentOneDOB) ? getErrorText(null, t('INVALID_DATE')) : true}
                  </Grid>
                  <Grid item lg={3} className={classes.alignGridAcaGradeStudent}>
                    <Dropdown
                      minWidth="100%"
                      label={`${t('ACA_GRADE')} *`}
                      id="studentOneAcaGrade"
                      name="studentOneAcaGrade"
                      value={formik.values.studentOneAcaGrade}
                      onBlur={() => setPreviousFieldTouch('studentOneAcaGrade', 'studentOne')}
                      error={getErrorText('studentOneAcaGrade')}
                      handleChange={(e) => {
                        formik.setFieldValue('studentOneAcaGrade', e.target.value);
                      }}
                      options={gradeOptions}
                      required
                      labelId="studentOneAcaGrade"
                    />
                    {getErrorText('studentOneAcaGrade')}
                  </Grid>
                </Grid>
              </Grid>
              <Grid container spacing={2} className={classes.innerContainer1}>
                <Grid item className={`${classes.alignGridStudentLocation} ${formik.values.studentOneLocation ? classes.alignGridStudentData : ''}`}>
                  <Dropdown
                    minWidth="100%"
                    label={`${t('MANABADI_LOCATION')} *`}
                    id="studentOneLocation"
                    name="studentOneLocation"
                    value={formik.values.studentOneLocation}
                    onBlur={() => setPreviousFieldTouch('studentOneLocation', 'studentOne')}
                    error={getErrorText('studentOneLocation')}
                    handleChange={(value) => {
                      formik.setFieldValue('studentOneLocation', value.target.value)
                      formik.setFieldValue('studentTwoLocation', value.target.value)
                    }}
                    options={formik.values.homeAddress !== '' ? mapOptions : []}
                    required
                    labelId="studentOneLocation"
                  />
                  {getErrorText('studentOneLocation')}
                </Grid>
                <Tooltip title={t('CLASS_TIMINGS_LOCATION')} placement="bottom-end" arrow>
                  <Grid item className={`${classes.alignGridFirstNamesTUDENT} ${formik.values.studentOneLocation ? classes.alignGridStudentData : ''}`}>
                    <TextField
                      minWidth="100%"
                      label={`${t('CLASS_TIMINGS')}`}
                      id="studentOneClassTimings"
                      name="studentOneClassTimings"
                      disable
                      value={formik.values?.studentOneClassTimings || ''}
                      labelId="studentOneClassTimings"
                      customFormControlCss={{ margin: '0', }}
                    />
                  </Grid>
                </Tooltip>
                <Grid item className={`${classes.alignGridFirstNamesTUDENT} ${formik.values.studentOneLocation ? classes.alignGridStudentData : ''}`}>
                  <Dropdown
                    minWidth="100%"
                    label={`${t('CLASSLEVEL')} *`}
                    id="studentOneCourse"
                    name="studentOneCourse"
                    value={formik.values.studentOneCourse}
                    onBlur={() => setPreviousFieldTouch('studentOneCourse', 'studentOne')}
                    error={getErrorText('studentOneCourse')}
                    handleChange={formik.handleChange}
                    options={classLevelOptionSOne}
                    required
                    labelId="studentOneCourse"
                  />
                  {getErrorText('studentOneCourse')}

                </Grid>
                <Grid item className={`${classes.alignGridAcaYearStudent} ${formik.values.studentOneLocation ? classes.alignGridStudentData : ''}`}>
                  <TextField
                    minWidth="100%"
                    label={`${t('ACADEMICYEAR')}`}
                    id="academicYear"
                    name="academicYear"
                    disable
                    value={selectedYearStudentOne ? selectedYearStudentOne : getCurrentYear()?.id}
                    required
                    labelId="academicYear"
                    customFormControlCss={{ margin: '0' }}
                    InputLabelProps={{ shrink: true }}
                  />
                </Grid>
              </Grid>
              <Grid container spacing={2} className={classes.innerContainer1}>
                <Grid item className={classes.alignGridFirstNamesTUDENT} style={{ paddingTop: '20px' }}>
                  <Dropdown
                    minWidth="100%"
                    label={`${t('GENDER')} *`}
                    id="studentOneGender"
                    name="studentOneGender"
                    value={formik.values.studentOneGender}
                    onBlur={() => setPreviousFieldTouch('studentOneGender', 'studentOne')}
                    error={getErrorText('studentOneGender')}
                    handleChange={(e) => formik.setFieldValue('studentOneGender', e.target.value)}
                    options={genderOptions}
                    required
                    labelId="studentOneGender"
                  />
                  {getErrorText('studentOneGender')}
                </Grid>
                <Grid item xs={9} md={9} lg={9} className={classes.alignGridFirstNamesTUDENT} style={{ paddingTop: '20px' }}>
                  <Dropdown
                    minWidth="100%"
                    label={`${t('TSHIRT')} *`}
                    id="studentOneShirt"
                    name="studentOneShirt"
                    value={formik.values.studentOneShirt}
                    onBlur={() => setPreviousFieldTouch('studentOneShirt', 'studentOne')}
                    error={getErrorText('studentOneShirt')}
                    handleChange={(e) => formik.setFieldValue('studentOneShirt', e.target.value)}
                    options={tShirtOptions}
                    required
                    labelId="studentOneShirt"
                  />
                  {getErrorText('studentOneShirt')}
                </Grid>
                <Grid
                  item
                  xs={2}
                  style={{
                    marginTop: '4px',
                  }}
                >
                  <Button
                    fullWidth
                    variant="contained"
                    onClick={handleShirtSizeDropdownClick}
                    sx={{
                      fontSize: '12px !important',
                      padding: '9px !important',
                      color: 'white !important',
                      fontWeight: '600 !important',
                      textTransform: 'capitalize',
                      fontFamily: 'inherit !important',
                      background: '#3971b5 !important',
                      borderRadius: '6px !important',
                    }}
                  >
                    {t('SIZE_CHART')}
                  </Button>
                </Grid>

              </Grid>
            </Grid>
            {/* Student 2 */}
            <Grid container className={registerClasses.mainContainer}>
              <Paper elevation={1} style={{ background: "#e4f5fd", padding: '5px 5px 5px 13px', margin: '20px auto 5px auto', height: '40px', width: '97%', boxShadow: 'none' }}>
                <Grid container spacing={2} className={classes.innerContainer} alignItems="center">
                  <Grid item xs={10} className={classes.innerContainerTitle}>
                    {setTitle(t('STUDENT_TWO_DETAILS'))}
                  </Grid>
                  <Grid
                    item
                    xs={2}
                    className={`${isStudentTwoFilled() ? classes.resetBtn : classes.noResetBtn}`}
                  >  {isStudentTwoFilled() ?
                    <Typography variant="body1" className={classes.reset} onClick={handleClearStudentTwoClick}>
                      {t('RESET')}
                    </Typography>
                    : null
                    }
                    <ButtonAtom
                      btntype={Buttons.SECONDARY}
                      onClick={handleExpandStudentTwoClick}
                      style={{ padding: '0px', minWidth: 'auto' }}
                      className={classes.expandIcon}
                      icon={isExpandedStudentTwo ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                    />
                  </Grid>
                </Grid>
              </Paper>
              <Collapse in={isExpandedStudentTwo} style={{ width: '98.8%', paddingBottom: '15px' }}>
                <Grid container spacing={2} className={classes.innerContainer}>
                  <Grid container spacing={2} className={classes.innerContainer}>
                    <Tooltip title={t('HOVER_STU_FIRSTNAME')} placement="bottom-end" arrow>
                      <Grid item className={classes.alignGridFirstNamesTUDENT2}>
                        <TextFieldAtom
                          label={`${t('FIRST_NAME')} ${isStudentTwoFilled() ? '' : ''}`}
                          id='studentTwoFirstName'
                          required={isStudentTwoFilled()}
                          type='text'
                          value={formik.values.studentTwoFirstName}
                          onBlur={() => setPreviousFieldTouch('studentTwoFirstName', 'studentTwo')}
                          error={getErrorText('studentTwoFirstName')}
                          onChange={(e) => {
                            const cleanedValue = removeSpecialChar(e.target.value); // Apply removeSpecialChar to the input value
                            formik.setFieldValue('studentTwoFirstName', cleanedValue); // Update formik with the cleaned value
                            const primaryStudentFirstName = formik.values?.studentOneFirstName;
                            const siblingFirstName = cleanedValue; // Use the cleaned value for comparison
                            if (siblingFirstName !== '' && siblingFirstName.toLowerCase()?.trim() === primaryStudentFirstName.toLowerCase()?.trim()) {
                              setIsSiblingFirstNameMatch(true);
                            } else {
                              setIsSiblingFirstNameMatch(false);
                            }
                            if (Object.keys(parentTwoInfo).includes('studentTwoFirstName')) {
                              setParentTwoInfo({ ...parentTwoInfo, ['studentTwoFirstName']: cleanedValue });
                            }
                            if (Object.keys(studentTwoInfo).includes('studentTwoFirstName')) {
                              setStudentTwoInfo({ ...studentTwoInfo, ['studentTwoFirstName']: cleanedValue });
                            }
                          }}
                        />
                        {getErrorText('studentTwoFirstName', isSiblingFirstNameMatch ? `${t('SIBLING_FIRSTNAME_ERROR4')}` : null)}
                      </Grid>
                    </Tooltip>
                    <Tooltip title={t('HOVER_STU_LASTNAME')} placement="bottom-end" arrow>
                      <Grid item className={classes.alignGridFirstNamesTUDENT2}>
                        <TextFieldAtom
                          label={`${t('LAST_NAME')} ${isStudentTwoFilled() ? '' : ''}`}
                          id='studentTwoLastName'
                          required={isStudentTwoFilled()}
                          type='text'
                          value={formik.values.studentTwoLastName}
                          onBlur={() => setPreviousFieldTouch('studentTwoLastName', 'studentTwo')}
                          error={getErrorText('studentTwoLastName')}
                          onChange={(e) => {
                            const cleanedValue = removeSpecialChar(e.target.value); // Apply removeSpecialChar to the input value
                            formik.setFieldValue('studentTwoLastName', cleanedValue); // Update formik with the cleaned value 
                            const primaryStudentLastName = formik.values?.studentOneLastName;
                            const siblingLastName = cleanedValue; // Use the cleaned value for comparison
                            if (siblingLastName !== '' && siblingLastName.toLowerCase()?.trim() !== primaryStudentLastName.toLowerCase()?.trim()) {
                              setIsSiblingLastNameMismatch(true);
                            } else {
                              setIsSiblingLastNameMismatch(false);
                            }
                            if (Object.keys(parentTwoInfo).includes('studentTwoLastName')) {
                              setParentTwoInfo({ ...parentTwoInfo, ['studentTwoLastName']: cleanedValue });
                            }
                            if (Object.keys(studentTwoInfo).includes('studentTwoLastName')) {
                              setStudentTwoInfo({ ...studentTwoInfo, ['studentTwoLastName']: cleanedValue });
                            }
                          }}
                        />
                        {getErrorText('studentTwoLastName', isSiblingLastNameMismatch ? `${t('SIBLING_LASTNAME_ERROR2')}` : null)}
                      </Grid>
                    </Tooltip>
                    <Grid item className={classes.alignGridFirstNamesTUDENTDate2} style={{ paddingTop: '24px' }}>
                      <Datepicker
                        wrapperClassName={classes.datePicker}
                        customClass={classes.datePickerCustom}
                        label={`${t('DOB')} ${isStudentTwoFilled() ? '*' : ''}`}
                        minWidth="100%"
                        id="studentTwoDOB"
                        name="studentTwoDOB"
                        type="date"
                        value={formik.values.studentTwoDOB}
                        onBlur={() => setPreviousFieldTouch('studentTwoDOB', 'studentTwo')}
                        onChange={(newDate) => {
                          window.newDate = newDate;
                          formik.setFieldValue('studentTwoDOB', newDate);
                          if (Object.keys(studentTwoInfo).includes('studentTwoDOB')) {
                            setStudentTwoInfo({ ...studentTwoInfo, ['studentTwoDOB']: newDate });
                          }
                        }}
                        error={!checkDateValid(formik.values.studentTwoDOB)}
                        maxDate={new Date()}
                        inputProps={{
                          style: {
                            WebkitBoxShadow: '0 0 0 1000px white inset',
                          },
                        }}
                      />
                      {!checkDateValid(formik.values.studentTwoDOB) ? getErrorText(null, t('INVALID_DATE')) : true}
                    </Grid>
                    <Grid item lg={3} className={classes.alignGridAcaGradeStudent2} style={{ paddingTop: '24px' }}>
                      <Dropdown
                        minWidth="100%"
                        label={`${t('ACA_GRADE')} ${isStudentTwoFilled() ? '*' : ''}`}
                        id="studentTwoAcaGrade"
                        name="studentTwoAcaGrade"
                        value={formik.values.studentTwoAcaGrade}
                        onBlur={() => setPreviousFieldTouch('studentTwoAcaGrade', 'studentTwo')}
                        error={getErrorText('studentTwoAcaGrade')}
                        handleChange={(value) => {
                          formik.setFieldValue('studentTwoAcaGrade', value.target.value)
                          if (Object.keys(studentTwoInfo).includes('studentTwoAcaGrade')) {
                            setStudentTwoInfo({ ...studentTwoInfo, ['studentTwoAcaGrade']: value.target.value });
                          }
                        }}
                        options={gradeOptions}
                        required={isStudentTwoFilled()}
                        labelId="studentTwoAcaGrade"
                      />
                      {getErrorText('studentTwoAcaGrade')}
                    </Grid>
                  </Grid>
                </Grid>
                <Grid container spacing={2} className={classes.innerContainer}>
                  <Grid item className={classes.alignGridStudentLocation2} style={{ paddingTop: '20px' }}>
                    <Dropdown
                      minWidth="100%"
                      label={`${t('MANABADI_LOCATION')} ${isStudentTwoFilled() ? '*' : ''}`}
                      id="studentTwoLocation"
                      name="studentTwoLocation"
                      value={formik.values.studentTwoLocation}
                      onBlur={() => setPreviousFieldTouch('studentTwoLocation', 'studentTwo')}
                      error={getErrorText('studentTwoLocation')}
                      handleChange={(value) => {
                        formik.setFieldValue('studentTwoLocation', value.target.value)
                      }}
                      options={formik.values.homeAddress !== '' ? mapOptions : []}
                      required={isStudentTwoFilled()}
                      labelId="studentTwoLocation"
                    />
                    {getErrorText('studentTwoLocation')}
                  </Grid>
                  <Tooltip title={t('CLASS_TIMINGS_LOCATION')} placement="bottom-end" arrow>
                    <Grid item className={classes.alignGridFirstNamesTUDENTCL2} style={{ paddingTop: '20px' }}>
                      <TextField
                        minWidth="100%"
                        label={`${t('CLASS_TIMINGS')}`}
                        id="studentTwoClassTimings"
                        name="studentTwoClassTimings"
                        disable
                        value={formik.values?.studentTwoClassTimings || ''}
                        labelId="studentTwoClassTimings"
                        customFormControlCss={{ margin: '0' }}
                        required={isStudentTwoFilled()}
                      />
                    </Grid>
                  </Tooltip>
                  <Grid item className={classes.alignGridFirstNamesTUDENTCL2} style={{ paddingTop: '20px' }}>
                    <Dropdown
                      minWidth="100%"
                      label={`${t('CLASSLEVEL')} ${isStudentTwoFilled() ? '*' : ''}`}
                      id="studentTwoCourse"
                      name="studentTwoCourse"
                      value={formik.values.studentTwoCourse}
                      onBlur={() => setPreviousFieldTouch('studentTwoCourse', 'studentTwo')}
                      error={getErrorText('studentTwoCourse')}
                      handleChange={(value) => {
                        formik.setFieldValue('studentTwoCourse', value.target.value)
                        if (Object.keys(studentTwoInfo).includes('studentTwoCourse')) {
                          setStudentTwoInfo({ ...studentTwoInfo, ['studentTwoCourse']: value.target.value });
                        }
                      }}
                      options={classLevelOptionSTwo}
                      required={isStudentTwoFilled()}
                      labelId="studentTwoCourse"
                    />
                    {getErrorText('studentTwoCourse')}

                  </Grid>
                  <Grid item className={classes.alignGridAcaYearStudent2} style={{ paddingTop: '20px' }}>
                    <TextField
                      minWidth="100%"
                      label={`${t('ACADEMICYEAR')}`}
                      id="studentTwoAcaYear"
                      name="studentTwoAcaYear"
                      disable
                      value={selectedYearStudentTwo ? selectedYearStudentTwo : getCurrentYear()?.id}
                      required={isStudentTwoFilled()}
                      labelId="studentTwoAcaYear"
                      customFormControlCss={{ margin: '0' }}
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={2} className={classes.innerContainer1}>
                  <Grid item xs={9} md={9} lg={9} className={classes.alignGridFirstNamesTUDENT} style={{ paddingTop: '20px' }}>
                    <Dropdown
                      minWidth="100%"
                      label={`${t('GENDER')} ${isStudentTwoFilled() ? '*' : ''}`}
                      id="studentTwoGender"
                      name="studentTwoGender"
                      value={formik.values.studentTwoGender}
                      onBlur={() => setPreviousFieldTouch('studentTwoGender', 'studentTwo')}
                      error={getErrorText('studentTwoGender')}
                      handleChange={(e) => {
                        formik.setFieldValue('studentTwoGender', e.target.value)
                        if (Object.keys(studentTwoInfo).includes('studentTwoGender')) {
                          setStudentTwoInfo({ ...studentTwoInfo, ['studentTwoGender']: e.target.value });
                        }
                      }}
                      options={genderOptions}
                      required={isStudentTwoFilled()}
                      labelId="studentTwoGender"
                    />
                    {getErrorText('studentTwoGender')}
                  </Grid>
                  <Grid item xs={3} md={3} lg={3} className={classes.alignGridFirstNamesTUDENT} style={{ paddingTop: '20px' }}>
                    <Dropdown
                      minWidth="100%"
                      label={`${t('TSHIRT')} ${isStudentTwoFilled() ? '*' : ''}`}
                      id="studentTwoShirt"
                      name="studentTwoShirt"
                      value={formik.values.studentTwoShirt}
                      onBlur={() => setPreviousFieldTouch('studentTwoShirt', 'studentTwo')}
                      error={getErrorText('studentTwoShirt')}
                      handleChange={(e) => {
                        formik.setFieldValue('studentTwoShirt', e.target.value)
                        if (Object.keys(studentTwoInfo).includes('studentTwoShirt')) {
                          setStudentTwoInfo({ ...studentTwoInfo, ['studentTwoShirt']: e.target.value });
                        }
                      }}
                      options={tShirtOptions}
                      required={isStudentTwoFilled()}
                      labelId="studentTwoShirt"
                    />
                    {getErrorText('studentTwoShirt')}
                  </Grid>
                  <Grid
                    item
                    xs={2}
                    style={{
                      marginTop: '4px',
                    }}
                  >
                    <Button
                      fullWidth
                      variant="contained"
                      onClick={handleShirtSizeDropdownClick}
                      sx={{
                        fontSize: '12px !important',
                        padding: '9px !important',
                        color: 'white !important',
                        fontWeight: '600 !important',
                        textTransform: 'capitalize',
                        fontFamily: 'inherit !important',
                        background: '#3971b5 !important',
                        borderRadius: '6px !important',
                      }}
                    >
                      {t('SIZE_CHART')}
                    </Button>
                  </Grid>

                </Grid>
              </Collapse>
            </Grid>
            <Grid item xs={12} className={classes.outerContainer}>
              <div className={classes.footerBottom} />
            </Grid>

            <Grid container className={classes.button}>

              <Grid container xs={6} md={9} lg={9} className={classes.button}>
                <Grid>
                  <Button
                    id="submit"
                    className={
                      classes.activeButton
                    }
                    disabled={checkEmail}
                    onClick={getGeneralErrorText}
                  >
                    {t('SAVE_CONTINUE')}
                  </Button>
                </Grid>
              </Grid>
            </Grid>

            {loading && (
              <Grid>
                <Loader message={t('LOADING')} />
              </Grid>
            )}
          </form>
        </FormikProvider>
      </Card>
      <Dialog
        open={isShirtSizeDialogOpen}
        onClose={handleShirtSizeDialogClose}
        maxWidth="md"
        fullWidth
        PaperProps={{
          style: {
            borderRadius: '20px',
            margin: '16px',
            width: '90%',
            maxWidth: '600px',
          },
        }}
      >
        <DialogTitle
          disableTypography
          style={{
            backgroundColor: '#e4f5fd',
            color: '#104F96',
            padding: '12px 16px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            fontSize: '18px',
            fontWeight: '600',
            position: 'relative',
          }}
        >
          T-Shirt Sizes
          <IconButton
            aria-label="close"
            onClick={handleShirtSizeDialogClose}
            style={{
              position: 'absolute',
              right: 8,
              top: '50%',
              transform: 'translateY(-50%)',
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <div style={{ 
            width: '100%',
            position: 'relative',
            paddingTop: '16px'
          }}>
            <img 
              src="https://storage.googleapis.com/manabadi-portal-bucket/Others/T-ShirtSizeChart.png" 
              alt="T-Shirt Size Chart"
              style={{ 
                width: '100%',
                height: 'auto',
                borderRadius: '8px',
                display: 'block',
                maxHeight: 'calc(90vh - 120px)',
                objectFit: 'contain'
              }} 
            />
          </div>
        </DialogContent>
      </Dialog>
    </>
  );
}

export default UserForm;
