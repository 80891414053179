import React, {
  useState, useEffect, memo, useRef,
} from 'react';
import {
  Grid, Tooltip, Dialog, DialogTitle, DialogContent, IconButton, Button,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import _ from 'lodash';
import moment from 'moment';
// import { getLocalStorage } from '../../../utils/localStorageMethod';
import { getCourseByAge } from '../../../store/services/getCourseByAge';
import userRoles from '../../../constant/userRoles';
import decryptedUserRoles from '../../../constant/decryptedUserRoles';

import {
  containsSpecialChars,
  checkDateValid,
  hasNumericOrSpecialChar,
  getDistanceBetweenTwoPoint,
  getCurrentAcaYear,
  removeSpecialChar,
} from '../../../utils/methods';
import {
  Datepicker, ImageUpload, MapContainer, MultiSelectDropdownRegistration, AddressAutoComplete, TextField,
} from '../../../components/atoms';
import Dropdown from '../../../components/atoms/dropdown-with-labelRegistration';
import useStyles from '../../../custom-hooks/useStyles';
import styles from './style';
import {
  genderOptions, studentInitialValues, studentInitialErrors,
} from './constants';
import { getPlaceInformation, getParentPlaceInformation } from '../../../utils/mapMethod';
import CommonInput from './common-input';
import getLocationByCourse from '../../../store/services/getLocationByCourse';

function AddStudent({
  stuInfo, mainFormError, parentInfo, newParentAddressEnroll, setMainForm, setMainFormError, dobDisable, isAdminUser = false, isStudentDashboard = false,
  SiblingsData = [],
}) {
  const { t } = useTranslation();
  const firstNameRef = useRef();
  const lastNameRef = useRef();
  const midNameRef = useRef();
  const studentInfoFromStore = useSelector((state) => state?.getStudent);
  const tShirtOptions = studentInfoFromStore?.tshirts;
  const gradeOptions = studentInfoFromStore?.academicGrades;
  const extracurricularOptions = studentInfoFromStore?.extraCurricularActivites;
  const classes = useStyles(styles)();
  // states
  const [classLevelOption, setClassLevelOption] = useState([]);
  const [selectedYear, setSelectedYear] = useState();
  const [address, setAddress] = useState(null);
  const [mapOptions, setMapOptions] = useState(studentInfoFromStore?.locations);
  const initialValues = { ...studentInitialValues };
  const initialErrors = { ...studentInitialErrors };
  const [studentError, setStudentError] = useState(mainFormError || initialErrors);
  const [studentForm, setStudentForm] = useState(stuInfo || initialValues);
  const encryptedUserRole = localStorage.getItem('userRole');
  const [isShirtSizeDialogOpen, setShirtSizeDialogOpen] = useState(false);
  const [isSiblingFirstNameMatch, setIsSiblingFirstNameMatch] = useState(false);
  const [isSiblingLastNameMismatch, setIsSiblingLastNameMismatch] = useState(false);
  // const [imageUrl, setImageUrl] = useState(null);
  // const [loading, setLoading] = useState(false);
  // const [imageNotFound, setImageNotFound] = useState(false);

  // const fetchImageFromFolder = (folderId) => {
  //   setLoading(true);
  //   setImageNotFound(false);
  //   const gettoken = getLocalStorage('accessToken');
  //   const baseUrl = JSON.parse(gettoken);
  //   const listFilesUrl = `https://www.googleapis.com/drive/v3/files?q='${folderId}' in parents and trashed = false&fields=files(id,name,mimeType)&access_token=${baseUrl?.access_token}`;
  //   // console.log(listFilesUrl);
  //   fetch(listFilesUrl)
  //     .then((response) => {
  //       if (!response.ok) {
  //         throw new Error('Failed to fetch files from the folder');
  //       }
  //       return response.json();
  //     })
  //     .then((data) => {
  //       if (data.files && data.files.length > 0) {
  //         const { id: fileId, mimeType, name: fileName } = data.files[0];
  //         // console.log(data.files);
  //         const xhr = new XMLHttpRequest();
  //         xhr.open('GET', `https://www.googleapis.com/drive/v3/files/${fileId}?alt=media`, true);
  //         xhr.setRequestHeader('Authorization', `Bearer ${baseUrl?.access_token}`);
  //         xhr.responseType = 'blob';

  //         xhr.onload = () => {
  //           try {
  //             const filedata = new Blob([xhr.response], { type: mimeType });
  //             const file = new File([filedata], fileName.replace(/ /g, '_'));
  //             const url = URL.createObjectURL(file);
  //             setImageUrl(url);
  //           } catch {
  //             // console.error('Error fetching the file:', err);
  //           } finally {
  //             setLoading(false);
  //           }
  //         };

  //         xhr.onerror = () => {
  //           // console.error('Error during the request');
  //           setLoading(false);
  //         };

  //         xhr.send();
  //       } else {
  //         // console.error('No files found in the folder');
  //         setImageNotFound(true);
  //         setLoading(false);
  //       }
  //     })
  //     .catch(() => {
  //       // console.error('Error fetching folder files:', err);
  //       setLoading(false);
  //       setImageNotFound(true);
  //     });
  // };

  // useEffect(() => {
  //   if (isShirtSizeDialogOpen) {
  //     const folderId = '1BouOsRjjW0ViVfGqOeHzwZk9WoTXiskT';
  //     fetchImageFromFolder(folderId);
  //   } else {
  //     setImageUrl(null);
  //     setLoading(false);
  //     setImageNotFound(false);
  //   }
  // }, [isShirtSizeDialogOpen]);
  const handleShirtSizeDropdownClick = () => {
    setShirtSizeDialogOpen(true);
  };

  const handleShirtSizeDialogClose = () => {
    setShirtSizeDialogOpen(false);
  };
  const userRole = decryptedUserRoles[encryptedUserRole];
  useEffect(() => {
    setStudentError(mainFormError);
  }, [mainFormError]);

  const getMapOptions = async (options) => {
    if (newParentAddressEnroll?.add !== undefined) {
      let distanceMapOptions = options?.map((opt) => {
        const res = getDistanceBetweenTwoPoint(newParentAddressEnroll, {
          lat: opt?.locationAddress?.latitude,
          lng: opt?.locationAddress?.longitude,
        });
        const miles = res ? res / 1609.34 : 0;
        return { ...opt, distance: miles ? miles.toFixed(2) : 0 };
      });
      let resCountry;
      if (newParentAddressEnroll.country) {
        resCountry = newParentAddressEnroll.country;
      } else {
        const placeInfo = await getParentPlaceInformation(newParentAddressEnroll?.add);
        resCountry = placeInfo?.country;
      }
      distanceMapOptions = distanceMapOptions.filter((item) => item.locationAddress.latitude !== null && item.locationAddress.longitude);
      distanceMapOptions = distanceMapOptions.filter((item) => item.locationAddress.country === resCountry);
      distanceMapOptions = distanceMapOptions?.sort((a, b) => a.distance - b.distance);
      const distanceMapOptionsExists = !!(distanceMapOptions && distanceMapOptions?.length);
      const isMore20Miles = !(distanceMapOptionsExists && distanceMapOptions[0]?.distance <= 20);
      distanceMapOptions = distanceMapOptionsExists ? distanceMapOptions : options;
      const reorderedOptions = [...distanceMapOptions];
      const locationId = resCountry === 'United States' ? '906e5655-10ee-4948-a35c-28f99d152e71' : '846413a4-a9de-4454-92e5-035f9d1a2884';
      const index = reorderedOptions.findIndex((option) => option.id === locationId);
      if (index !== -1 && isMore20Miles) {
        const [item] = reorderedOptions.splice(index, 1);
        reorderedOptions.unshift(item);
      }
      setMapOptions(reorderedOptions);
    } else {
      let distanceMapOptions = options?.map((opt) => {
        const res = getDistanceBetweenTwoPoint(parentInfo?.homeAddressInfo, {
          lat: opt?.locationAddress?.latitude,
          lng: opt?.locationAddress?.longitude,
        });
        const miles = res ? res / 1609.34 : 0;
        return { ...opt, distance: miles ? miles.toFixed(2) : 0 };
      });
      let resCountry;
      if (parentInfo?.homeAddressInfo.country) {
        resCountry = parentInfo?.homeAddressInfo.country;
      } else if (newParentAddressEnroll?.add) {
        const placeInfo = await getParentPlaceInformation(newParentAddressEnroll?.add);
        resCountry = placeInfo?.country;
      } else if (parentInfo?.homeAddressInfo?.add) {
        const placeInfo = await getParentPlaceInformation(parentInfo?.homeAddressInfo?.add);
        resCountry = placeInfo?.country;
      } else {
        resCountry = 'United States';
      }
      distanceMapOptions = distanceMapOptions.filter((item) => item.locationAddress.latitude !== null && item.locationAddress.longitude);
      distanceMapOptions = distanceMapOptions.filter((item) => item.locationAddress.country === resCountry);
      distanceMapOptions = distanceMapOptions?.sort((a, b) => a.distance - b.distance);
      const distanceMapOptionsExists = !!(distanceMapOptions && distanceMapOptions?.length);
      const isMore20Miles = !(distanceMapOptionsExists && distanceMapOptions[0]?.distance <= 20);
      distanceMapOptions = distanceMapOptionsExists ? distanceMapOptions : options;
      const reorderedOptions = [...distanceMapOptions];
      const locationId = resCountry === 'United States' ? '906e5655-10ee-4948-a35c-28f99d152e71' : '846413a4-a9de-4454-92e5-035f9d1a2884';
      const index = reorderedOptions.findIndex((option) => option.id === locationId);
      if (index !== -1 && isMore20Miles) {
        const [item] = reorderedOptions.splice(index, 1);
        reorderedOptions.unshift(item);
      }
      setMapOptions(reorderedOptions);
    }
  };

  useEffect(() => {
    if (newParentAddressEnroll?.add !== undefined || parentInfo?.homeAddressInfo?.add !== undefined) {
      getMapOptions(studentInfoFromStore?.locations);
    }
  }, [newParentAddressEnroll, parentInfo?.homeAddressInfo]);

  function formatDateToPST(date) {
    if (date) {
      // Format to UTC 1700 hours so once converted to IST, EST, CST still in same day
      const day = moment(date).format('YYYY-MM-DD');
      const dateUtcConv = `${day}T17:00:00.000`;
      return dateUtcConv;
    }
    return null;
  }

  const getLocationsByCourse = (courseId) => {
    getLocationByCourse(courseId)
      .then((options) => {
        getMapOptions(options);
      })
      .catch(() => { });
  };

  useEffect(() => {
    if (studentForm?.classLevel !== '') {
      getLocationsByCourse(studentForm?.classLevel);
      const selectedClassLevel = _.find(classLevelOption, (co) => co.id === studentForm.classLevel);
      setSelectedYear(selectedClassLevel?.academic_infos?.academicYear);
    }
  }, [studentForm.classLevel]);

  const checkEligibilityForFLC = (options) => {
    const selectedGrade = _.find(gradeOptions, (go) => go.id === studentForm.grade);
    let classOptions = options;
    if (parseInt(selectedGrade?.name, 10) < 7 || selectedGrade?.name === 'K' || selectedGrade?.name === 'Pre-K' || studentForm?.grade === '') {
      classOptions = options.filter((o) => o.name !== 'Telugu-1' && o.name !== 'Telugu-2' && o.name !== 'Telugu-3' && o.name !== 'Telugu-4');
    }
    classOptions.sort((a, b) => {
      const yearA = parseInt(a.academic_infos.academicYear.split('-')[0], 10);
      const yearB = parseInt(b.academic_infos.academicYear.split('-')[0], 10);
      if (yearA > yearB) {
        return -1;
      }
      if (yearA < yearB) {
        return 1;
      }

      return 0;
    });
    const uniqueClassOptions = Array.from(new Map(classOptions.map((item) => [item.id, item])).values());

    setClassLevelOption(uniqueClassOptions);
  };

  const getClassLevelOptions = (dob, location) => {
    if (userRoles.SYSTEM_ADMIN === userRole && !localStorage.getItem('impersonateToken')) {
      getCourseByAge(dob, location, true).then((options) => {
        checkEligibilityForFLC(options);
        if (options.length === 0) {
          setStudentForm({
            ...studentForm,
            classLevel: '',
          });
        }
      }).catch(() => { });
    } else if (localStorage.getItem('impersonateToken')) {
      getCourseByAge(dob, location, false).then((options) => {
        checkEligibilityForFLC(options);
        if (options.length === 0) {
          setStudentForm({
            ...studentForm,
            classLevel: '',
          });
        }
      }).catch(() => { });
    } else {
      getCourseByAge(dob, location, isAdminUser).then((options) => {
        checkEligibilityForFLC(options);
        if (options.length === 0) {
          setStudentForm({
            ...studentForm,
            classLevel: '',
          });
        }
      }).catch(() => { });
    }
  };

  useEffect(() => {
    if (studentForm?.dateOfBirth !== '' && studentForm?.sortedNearest !== '') {
      let dob;
      if (typeof studentForm?.dateOfBirth === 'string') {
        dob = studentForm?.dateOfBirth?.split('T')[0] || studentForm?.dateOfBirth;
      } else {
        dob = moment(studentForm?.dateOfBirth).format('YYYY-MM-DD');
      }
      getClassLevelOptions(dob, studentForm?.sortedNearest);
    }
  }, [studentForm?.dateOfBirth, studentForm?.sortedNearest, studentForm?.grade]);

  useEffect(() => {
    const allFields = ['profileImage', 'firstName', 'middleName', 'lastName', 'dateOfBirth', 'gender',
      'tShirt', 'academicYear', 'grade', 'searchSchool', 'sortedNearest',
      'classLevel', 'extraCurricularActivities',
    ];

    const lcStudentError = { ...studentError };
    // eslint-disable-next-line no-plusplus
    for (let i = 0; i <= allFields.length; i++) {
      const element = allFields[i];
      if (studentForm[element]?.length === 0) {
        if (isStudentDashboard && (element === 'academicYear' || element === 'middleName' || element === 'gender' || element === 'tShirt' || element === 'searchSchool' || element === 'extraCurricularActivities' || element === 'profileImage')) {
          lcStudentError[element] = '';
        }
      }
    }
    setStudentError({ ...lcStudentError });
  }, [studentError?.gender, studentError?.tShirt, studentError?.searchSchool, studentError?.extraCurricularActivities, studentError?.profileImage]);

  const onFieldBlur = async (fieldKey) => {
    const getUpdatedError = (errorObj, fiKey, errorMsg) => ({ ...errorObj, [fiKey]: errorMsg });

    if (fieldKey === 'searchSchool' && address) {
      const res = await getPlaceInformation(address);
      setStudentForm({
        ...studentForm,
        studentSchoolInfo: res,
        searchSchool: address,
      });
    }
    if (fieldKey === 'dateOfBirth') {
      const valid = checkDateValid(studentForm[fieldKey]);
      if (!valid) {
        setStudentError({ ...getUpdatedError(studentError, fieldKey, t('INVALID_DATE')) });
        return;
      }
    }

    const allFields = ['profileImage', 'firstName', 'middleName', 'lastName', 'dateOfBirth', 'gender',
      'tShirt', 'academicYear', 'grade', 'searchSchool', 'sortedNearest',
      'classLevel', 'extraCurricularActivities',
    ];

    const index = allFields.indexOf(fieldKey);
    if (index > -1) {
      const lcStudentError = { ...studentError };
      // eslint-disable-next-line no-plusplus
      for (let i = 0; i <= index; i++) {
        const element = allFields[i];
        if (studentForm[element]?.length === 0) {
          if (isStudentDashboard && (element === 'academicYear' || element === 'middleName' || element === 'gender' || element === 'tShirt' || element === 'searchSchool' || element === 'extraCurricularActivities' || element === 'profileImage')) {
            lcStudentError[element] = '';
          } else {
            lcStudentError[element] = t(`${element.toUpperCase()}_REQUIRED`);
          }
        }
      }
      setStudentError({ ...lcStudentError });
    } else if (fieldKey === 'firstName'
      && (studentForm[fieldKey])?.length <= 1
    ) {
      setStudentError({ ...getUpdatedError(studentError, fieldKey, t('FIRSTNAME_MIN')) });
    } else if (fieldKey === 'lastName'
      && (studentForm[fieldKey])?.length <= 1
    ) {
      setStudentError({ ...getUpdatedError(studentError, fieldKey, t('LASTNAME_MIN')) });
    } else if ((fieldKey === 'firstName' || fieldKey === 'lastName') && containsSpecialChars(studentForm[fieldKey])) {
      setStudentError({ ...getUpdatedError(studentError, fieldKey, t('NOT_SPECIAL_CHAR')) });
    }
  };

  const getErrorText = (fKey, errorText) => {
    if (isStudentDashboard && (fKey === 'academicYear' || fKey === 'middleName' || fKey === 'gender' || fKey === 'tShirt' || fKey === 'searchSchool' || fKey === 'extraCurricularActivities' || fKey === 'profileImage')) {
      return null;
    }
    if (studentError[fKey]) {
      return (
        <span data-testid={`$${fKey}`} className={classes.errorText}>
          {studentError[fKey]}
        </span>
      );
    }
    if (errorText) {
      return (
        <span className={classes.errorText}>
          {errorText}
        </span>
      );
    }
    return null;
  };

  const getDestinationLatLng = (nearestValue) => {
    if (!nearestValue) {
      return { lat: '', lng: '' };
    }
    return _.find(mapOptions, (opt) => opt.id === nearestValue);
  };

  const getMappingOfSelectedExtraCurricularActivities = (selectedActivity) => {
    const selectedExtraCurricularActivities = [];
    selectedActivity?.forEach((selectedVal) => {
      const selectedValueObj = _.find(extracurricularOptions, (ext) => ext.id === selectedVal);
      selectedExtraCurricularActivities.push(selectedValueObj);
    });
    return selectedExtraCurricularActivities;
  };

  const handleChange = (fKey, value) => {
    const stuForm = { ...studentForm };
    if (fKey === 'classLevel') {
      stuForm.selectedClassLevel = _.find(classLevelOption, (co) => co.id === value);
      stuForm.academicYear = stuForm.selectedClassLevel?.academic_infos?.academicYear;
    }
    if (fKey === 'grade') {
      stuForm.selectedGrade = _.find(gradeOptions, (co) => co.id === value);
    }
    if (fKey === 'tShirt') {
      stuForm.selectedTshirtOption = _.find(tShirtOptions, (co) => co.id === value);
    }
    if (fKey === 'sortedNearest') {
      stuForm.selectedSortedNearestAddress = getDestinationLatLng(value)?.locationAddress?.address;
    }
    if (fKey === 'extraCurricularActivities') {
      stuForm.selectedExtraCurricularActivities = getMappingOfSelectedExtraCurricularActivities(value);
    }
    stuForm[fKey] = value;
    setStudentForm(stuForm);
    setStudentError({ ...studentError, [fKey]: value ? '' : studentError[fKey] });
    setMainForm({ ...stuForm, [fKey]: value });
    setMainFormError({ ...studentError, [fKey]: value ? '' : studentError[fKey] });
  };
  const capitalizeFirstLetter = (value) => {
    if (value) {
      return value.charAt(0).toUpperCase() + value.slice(1);
    }
    return value; // Devolver el valor original si es una cadena vacía
  };

  const checkAutofillValues = () => {
    const firstName = capitalizeFirstLetter(firstNameRef?.current?.value);
    const midName = capitalizeFirstLetter(midNameRef?.current?.value);
    const lastName = capitalizeFirstLetter(lastNameRef?.current?.value);

    if (!capitalizeFirstLetter(studentForm.firstName) && firstName) {
      handleChange(
        'firstName',
        // eslint-disable-next-line no-nested-ternary
        hasNumericOrSpecialChar(firstName) ? (firstName ? capitalizeFirstLetter(studentForm.firstName) : '') : firstName,
      );
    }

    if (!capitalizeFirstLetter(studentForm.midName) && midName) {
      handleChange(
        'midName',
        // eslint-disable-next-line no-nested-ternary
        hasNumericOrSpecialChar(midName) ? (midName ? capitalizeFirstLetter(studentForm.midName) : '') : midName,
      );
    }

    if (!capitalizeFirstLetter(studentForm.lastName) && lastName) {
      handleChange(
        'lastName',
        // eslint-disable-next-line no-nested-ternary
        hasNumericOrSpecialChar(lastName) ? (lastName ? capitalizeFirstLetter(studentForm.lastName) : '') : lastName,
      );
    }
  };

  useEffect(() => {
    checkAutofillValues();
  }, [studentForm.firstName, studentForm.middleName, studentForm.lastName]);

  useEffect(() => {
    if (address) {
      handleChange('searchSchool', address);
      onFieldBlur('searchSchool');
    }
  }, [address]);

  useEffect(() => {
    getMapOptions(studentInfoFromStore?.locations);
  }, [studentInfoFromStore?.locations]);

  const getFormatTime = (time) => moment(time, 'hh:mm a').format('hh:mm a');

  function displayValue(key, value) {
    return (
      <Grid container spacing={0} className={classes.dataPadding}>
        <Grid item xs={4} className={classes.label}>
          {key}
        </Grid>
        <Grid item xs={8} className={classes.value}>
          <span> : </span>
          <span className={classes.previewValue}>{`${value}`}</span>
        </Grid>
      </Grid>
    );
  }

  const getCoordinatorDetail = (locObj) => {
    const locCoor = locObj?.location_coordinators || [];
    return locCoor.find((o) => o?.isPrimary);
  };
  return (
    <Grid container>
      <form
        name="student-info"
        noValidate
        autoComplete="off"
        className={classes.form}
      >
        <Grid container spacing={2} flexDirection="row">
          <Grid item xs={12} md={2} lg={2} className={classes.imageAlign}>
            <ImageUpload imageUploaded={studentForm.profileImage} setImageUpload={(e) => handleChange('profileImage', e)} />
            {getErrorText('profileImage')}
          </Grid>
          <Grid container item xs={12} md={10} lg={10} spacing={2} className={classes.innerContainer}>
            <Tooltip title={t('HOVER_STU_FIRSTNAME')} placement="bottom-end" arrow>
              <Grid item xs={12} md={4} lg={4} className={classes.alignGrid}>
                <CommonInput
                  label={`${t('FIRST_NAME')}`}
                  id="firstName"
                  required
                  name="firstName"
                  inputRef={firstNameRef}
                  inputProps={{
                    style: {
                      WebkitBoxShadow: '0 0 0 1000px white inset',
                    },
                  }}
                  type="text"
                  value={capitalizeFirstLetter(studentForm.firstName)}
                  onBlur={() => onFieldBlur('firstName')}
                  // error={studentError.firstName}
                  onChange={(e) => {
                    const capitalizedValue = capitalizeFirstLetter(e.target.value);
                    const cleanedValue = removeSpecialChar(capitalizedValue);
                    handleChange('firstName', hasNumericOrSpecialChar(cleanedValue) ? studentForm.firstName : cleanedValue);

                    const isMatch = SiblingsData.some(
                      (sibling) => sibling?.studentInfo?.firstName && sibling.studentInfo.firstName.toLowerCase().trim() === cleanedValue.toLowerCase().trim(),
                    );

                    setIsSiblingFirstNameMatch(isMatch);
                  }}
                />
                {getErrorText('firstName', isSiblingFirstNameMatch ? `${t('SIBLING_FIRSTNAME_ERROR4')}` : null)}
              </Grid>
            </Tooltip>
            <Tooltip title={t('HOVER_STU_MIDDLENAME')} placement="bottom-end" arrow>
              <Grid item xs={12} md={4} lg={4} className={classes.alignGrid}>
                <CommonInput
                  label={t('MIDDLE_NAME')}
                  id="middleName"
                  required={false}
                  inputRef={midNameRef}
                  name="middleName"
                  type="text"
                  shouldValidate={false}
                  value={capitalizeFirstLetter(studentForm.middleName)}
                  onBlur={() => onFieldBlur('middleName')}
                  onChange={(e) => handleChange('middleName', capitalizeFirstLetter(e.target.value))}
                  mustHaveValue={false}
                  inputProps={{
                    style: {
                      WebkitBoxShadow: '0 0 0 1000px white inset',
                    },
                  }}
                />
              </Grid>
            </Tooltip>
            <Tooltip title={t('HOVER_STU_LASTNAME')} placement="bottom-end" arrow>
              <Grid item xs={12} md={4} lg={4} className={classes.alignGrid}>
                <CommonInput
                  label={`${t('LAST_NAME')}`}
                  id="lastName"
                  required
                  inputRef={lastNameRef}
                  name="lastName"
                  type="text"
                  value={capitalizeFirstLetter(studentForm.lastName)}
                  onBlur={() => onFieldBlur('lastName')}
                  // error={studentError.lastName}
                  onChange={(e) => {
                    const capitalizedValue = capitalizeFirstLetter(e.target.value);
                    const cleanedValue = removeSpecialChar(capitalizedValue);
                    handleChange('lastName', hasNumericOrSpecialChar(cleanedValue) ? studentForm.lastName : cleanedValue);

                    const isMatch = SiblingsData.some(
                      (sibling) => sibling?.studentInfo?.lastName && sibling.studentInfo.lastName.toLowerCase().trim() !== cleanedValue.toLowerCase().trim(),
                    );

                    setIsSiblingLastNameMismatch(isMatch);
                  }}
                  inputProps={{
                    style: {
                      WebkitBoxShadow: '0 0 0 1000px white inset',
                    },
                  }}
                />
                {getErrorText('lastName', isSiblingLastNameMismatch ? `${t('SIBLING_LASTNAME_ERROR')}` : null)}
              </Grid>
            </Tooltip>
            <Grid item xs={12} md={4} lg={4} className={classes.alignGrid} style={{ paddingTop: '20px' }}>
              <Datepicker
                wrapperClassName={classes.datePicker}
                customClass={classes.datePickerCustom}
                label={`${t('DOB')} *`}
                minWidth="100%"
                id="dateOfBirth"
                name="dateOfBirth"
                type="date"
                disabled={dobDisable}
                value={formatDateToPST(studentForm.dateOfBirth)}
                onBlur={() => onFieldBlur('dateOfBirth')}
                onChange={(newDate) => {
                  window.newDate = formatDateToPST(newDate);
                  handleChange('dateOfBirth', formatDateToPST(newDate));
                }}
                error={(studentError.dateOfBirth && !checkDateValid(studentError.dateOfBirth)) || studentError.dateOfBirth}
                maxDate={new Date()}
                inputProps={{
                  style: {
                    WebkitBoxShadow: '0 0 0 1000px white inset',
                  },
                }}
              />
              {getErrorText('dateOfBirth')}
            </Grid>
            <Grid item xs={12} md={4} lg={4} className={classes.alignGrid} style={{ paddingTop: '20px' }}>
              <Dropdown
                minWidth="100%"
                label={`${t('GENDER')} *`}
                id="gender"
                name="gender"
                value={studentForm.gender}
                onBlur={() => onFieldBlur('gender')}
                error={isStudentDashboard ? null : studentError.gender}
                handleChange={(e) => handleChange('gender', e.target.value)}
                options={genderOptions}
                required={!isStudentDashboard}
                labelId="gender"
              />
              {getErrorText('gender')}
            </Grid>
            <Grid item xs={10} md={2} lg={2} className={classes.alignGrid} style={{ paddingTop: '20px' }}>
              <Dropdown
                minWidth="100%"
                label={`${t('TSHIRT')} *`}
                id="tShirt"
                name="tShirt"
                value={studentForm.tShirt}
                onBlur={() => onFieldBlur('tShirt')}
                error={isStudentDashboard ? null : studentError.tShirt}
                handleChange={(e) => handleChange('tShirt', e.target.value)}
                options={tShirtOptions}
                required={!isStudentDashboard}
                labelId="tShirt"
              />
              {getErrorText('tShirt')}
            </Grid>
            <Grid
              item
              xs={2}
              style={{
                marginTop: '4px',
              }}
            >
              <Button
                fullWidth
                variant="contained"
                onClick={handleShirtSizeDropdownClick}
                sx={{
                  fontSize: '12px !important',
                  padding: '9px !important',
                  color: 'white !important',
                  fontWeight: '600 !important',
                  textTransform: 'capitalize',
                  fontFamily: 'inherit !important',
                  background: '#3971b5 !important',
                  borderRadius: '6px !important',
                }}
              >
                Size Chart
              </Button>
            </Grid>
          </Grid>
          <Grid item xs={6} md={2} lg={2} />
          <Grid container item xs={12} md={10} lg={10} flexDirection="row" spacing={2} className={classes.innerContainer}>
            <Grid item xs={12} md={4} lg={4} className={`${classes.alignGrid} ${classes.academicYear}`} style={{ paddingTop: '20px' }}>
              <TextField
                minWidth="100%"
                label={`${t('ACADEMICYEAR')}`}
                id="academicYear"
                name="academicYear"
                disable
                value={selectedYear || studentForm?.academicYear || getCurrentAcaYear()?.id}
                required
                labelId="academicYear"
                customFormControlCss={{ margin: '0' }}
              />
            </Grid>
            <Grid item xs={12} md={4} lg={4} className={classes.alignGrid} style={{ paddingTop: '20px' }}>
              <Dropdown
                minWidth="100%"
                label={`${t('ACA_GRADE')} *`}
                id="grade"
                name="grade"
                value={studentForm.grade}
                onBlur={() => onFieldBlur('grade')}
                error={studentError.grade}
                handleChange={(e) => handleChange('grade', e.target.value)}
                options={gradeOptions}
                required
                labelId="grade"
              />
              {getErrorText('grade')}
            </Grid>
            <Grid item xs={12} md={4} lg={4} style={{ marginTop: '-5px' }} className={classes.siblingAddress}>
              <AddressAutoComplete
                shrink
                showLabel
                label={`${t('ACADEMIC_SCHOOL')}`}
                id="searchSchool"
                required={!isStudentDashboard}
                name="searchSchool"
                value={studentForm.searchSchool}
                error={getErrorText('searchSchool')}
                onChange={(e) => {
                  handleChange('searchSchool', e.target.value);
                }}
                onBlur={(e) => {
                  const onlyName = e.target.value.split(',')[0].trim();
                  setAddress(onlyName);
                  setStudentForm({
                    ...studentForm,
                    studentSchoolInfo: getPlaceInformation(e.target.value),
                    searchSchool: e.target.value,
                  });
                }}
              />
              {getErrorText('searchSchool')}
            </Grid>
          </Grid>
          <Grid item xs={6} md={2} lg={2} />
          <Grid container item xs={12} md={10} lg={10} spacing={2} className={classes.innerContainer}>
            <Grid item xs={12} md={4} lg={4} className={`${classes.alignGridDropDown} ${classes.alignGrid}`} style={{ paddingTop: '20px' }}>
              <Dropdown
                minWidth="100%"
                label={`${t('CHOOSE_MANABADI_LOCATION')} *`}
                id="sortedNearest"
                name="sortedNearest"
                value={studentForm.sortedNearest}
                onBlur={() => onFieldBlur('sortedNearest')}
                error={studentError.sortedNearest}
                handleChange={(e) => handleChange('sortedNearest', e.target.value)}
                options={mapOptions}
                required
                labelId="sortedNearest"
              />
              {getErrorText('sortedNearest')}
            </Grid>
            <Grid item xs={12} md={4} lg={4} className={classes.alignGrid} style={{ paddingTop: '20px' }}>
              <Dropdown
                minWidth="100%"
                label={`${t('CLASSLEVEL')} *`}
                id="classLevel"
                name="classLevel"
                value={studentForm.classLevel}
                onBlur={() => onFieldBlur('classLevel')}
                error={studentError.classLevel}
                handleChange={(e) => {
                  handleChange('classLevel', e.target.value);
                }}
                options={classLevelOption}
                required
                labelId="classLevel"
              />
              {getErrorText('classLevel')}

            </Grid>
            <Grid item xs={12} md={4} lg={4} style={{ paddingTop: '20px' }}>
              <MultiSelectDropdownRegistration
                customClass="regActivities"
                // tooltip
                label={t('EXTRA_ACTIVITIES')}
                options={extracurricularOptions}
                id="extraCurricularActivities"
                name="extraCurricularActivities"
                onBlur={() => onFieldBlur('extraCurricularActivities')}
                error={isStudentDashboard ? null : studentError.extraCurricularActivities}
                handleChange={(e) => handleChange('extraCurricularActivities', e.target.value)}
                value={studentForm.extraCurricularActivities}
                required={!isStudentDashboard}
              />
              {getErrorText('extraCurricularActivities')}
            </Grid>
          </Grid>
          <Grid
            container
            className={classes.buttons}
          >
            <Grid item xs={12} md={6} lg={6} className={classes.mapContainerGrid}>
              <MapContainer
                originLatLng={parentInfo?.homeAddressInfo || { lat: 0, lng: 0 }}
                destinationLatLng={{
                  lat: studentForm.sortedNearest !== '906e5655-10ee-4948-a35c-28f99d152e71' && studentForm.sortedNearest !== '846413a4-a9de-4454-92e5-035f9d1a2884' ? getDestinationLatLng(
                    studentForm.sortedNearest,
                  )?.locationAddress?.latitude || 0 : parentInfo?.homeAddressInfo?.lat,
                  lng: studentForm.sortedNearest !== '906e5655-10ee-4948-a35c-28f99d152e71' && studentForm.sortedNearest !== '846413a4-a9de-4454-92e5-035f9d1a2884' ? getDestinationLatLng(
                    studentForm.sortedNearest,
                  )?.locationAddress?.longitude || 0 : parentInfo?.homeAddressInfo?.lng,
                }}
              />
            </Grid>
            <Grid item xs={12} md={6} lg={6} className={classes.mapContainerDescGrid}>
              <div className={classes.locationHeader}>
                {getDestinationLatLng(studentForm.sortedNearest)?.name || ''}
              </div>

              <div className={classes.borderBottom} />
              {studentForm.sortedNearest ? (
                <>
                  {displayValue(t('LOCATION_ADDRESS'), (studentForm.sortedNearest !== '906e5655-10ee-4948-a35c-28f99d152e71' && studentForm.sortedNearest !== '846413a4-a9de-4454-92e5-035f9d1a2884' ? `${getDestinationLatLng(studentForm.sortedNearest)?.locationAddress?.address}` : ''), classes)}
                  {displayValue(t('COORDINATOR'), `${getCoordinatorDetail(getDestinationLatLng(studentForm.sortedNearest))?.user?.first_name || ''} ${getCoordinatorDetail(getDestinationLatLng(studentForm.sortedNearest))?.user?.last_name || ''}`, classes)}
                  {displayValue(t('COORDINATOREMAIL'), `${getCoordinatorDetail(getDestinationLatLng(studentForm.sortedNearest))?.user?.manabadi_email || ''}`, classes)}
                  {displayValue(t('CONTACT_NO'), `${getCoordinatorDetail(getDestinationLatLng(studentForm.sortedNearest))?.user?.contact_number || ''}`, classes)}
                  {displayValue(t('CLASS_TIMINGS'), `${getDestinationLatLng(studentForm.sortedNearest)?.classTiming || ''} -
                    ${getFormatTime(getDestinationLatLng(studentForm.sortedNearest)?.startTime) || ''} to
                    ${getFormatTime(getDestinationLatLng(studentForm.sortedNearest)?.endTime) || ''}`, classes)}
                  {displayValue(t('DISTANCEFROMHOME'), (studentForm.sortedNearest !== '906e5655-10ee-4948-a35c-28f99d152e71' && studentForm.sortedNearest !== '846413a4-a9de-4454-92e5-035f9d1a2884' ? `${(getDestinationLatLng(studentForm.sortedNearest)?.distance) !== null && (getDestinationLatLng(studentForm.sortedNearest)?.distance) !== undefined ? getDestinationLatLng(studentForm.sortedNearest)?.distance : ''} miles` : '0 miles'), classes)}
                </>
              ) : ''}
            </Grid>

          </Grid>
          <div className={classes.footerBottom} />
        </Grid>
      </form>
      <Dialog
        open={isShirtSizeDialogOpen}
        onClose={handleShirtSizeDialogClose}
        PaperProps={{
          style: {
            borderRadius: '20px',
          },
        }}
      >
        <DialogTitle
          disableTypography
          style={{
            backgroundColor: '#e4f5fd',
            color: '#104F96',
            padding: '.75vw 1vw .75vw 2vw',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            fontSize: '1vw',
            fontWeight: '600',
          }}
        >
          T-Shirt Sizes
          <IconButton
            aria-label="close"
            onClick={handleShirtSizeDialogClose}
            style={{ position: 'absolute', right: 8, top: 8 }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <div>
            {/* {loading && <p>Loading image...</p>} */}
            {/* {!loading && !error401 && imageUrl && (
            )} */}
            <img src="https://storage.googleapis.com/manabadi-portal-bucket/Others/T-ShirtSizeChart.png" alt="Student Iage" style={{ maxWidth: '100%', height: 'auto', borderRadius: '8px' }} />
            {/* {!loading && error401 && <p>Session expired. Please log in again.</p>}
            {!loading && !error401 && !imageUrl && !imageNotFound && <p>No image available.</p>}
            {!loading && !error401 && imageNotFound && <p>Image has been deleted or is no longer available.</p>} */}
          </div>
        </DialogContent>
      </Dialog>
    </Grid>
  );
}
export default memo(AddStudent);
