/* eslint-disable no-nested-ternary */
/* eslint-disable import/order */
/* eslint-disable no-unused-vars */
import React from 'react';
import moment from 'moment';
import { fonts } from '../../../../theme';
import {
  Grid, Typography,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import {
  Legend,
  Bar,
  Surface,
  Tooltip,
  Symbols,
  Cell,
} from 'recharts';
import { useCubeQuery } from '@cubejs-client/react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';

import useStyles from '../../../../custom-hooks/useStyles';
import styles from './style';
import Chart from '../../../common/bar-chart';
import DoNotDisturbIcon from '@mui/icons-material/DoNotDisturb';

export default function AttendanceBreakDown({
  currentYear, year, teacherPanel, attendanceRange,
}) {
  const classes = useStyles(styles)();
  const categories = [];
  const datesChartWeek = [
    {
      name: 'Present',
      data: [],
    },
    {
      name: 'Absent',
      data: [],
    },
  ];

  if (teacherPanel?.classesTimingsStudents.length !== 0) {
    teacherPanel?.classesTimingsStudents.filter((valueStudent) => {
      categories.push(valueStudent.name);
      return valueStudent;
    });
    const present = [];
    const absent = [];

    teacherPanel.classesTimingsStudents.forEach((valueStudent) => {
      let counterPresent = 0;
      let counterAbsent = 0;

      if (valueStudent.students) {
        counterPresent = valueStudent.students.length;

        valueStudent.students.forEach((student) => {
          if (!student.student) {
            return;
          }
          student.student.studentHomeworkMarks.forEach((mark) => {
            if (mark.studentHomeworkMarksDetail[0]?.isPresent === false) {
              counterPresent--;
              counterAbsent++;
            }
          });
        });
      }

      present.push(counterPresent);
      absent.push(counterAbsent);
    });

    datesChartWeek[0].data = present;
    datesChartWeek[1].data = absent;
  }

  const defaultOptions = {
    chart: {
      type: 'column',
      style: {
        width: '100ox',
      },
    },
    credits: {
      enabled: false,
    },
    title: {
      text: '',
      align: 'center',
      style: {
        fontWeight: 'bold',
        color: '#0745BA',
        textOutline: 'none',
      },
    },
    xAxis: {
      categories,
    },
    yAxis: {
      min: 0,
      max: teacherPanel.classesTimingsStudents.length ? teacherPanel.classesTimingsStudents[0].students.length + 1 : 2,
      title: {
        text: 'Students',
      },
      stackLabels: {
        enabled: true,
        style: {
          fontWeight: 'bold',
          color: (
            Highcharts.defaultOptions.title.style
                    && Highcharts.defaultOptions.title.style.color
          ) || 'gray',
          textOutline: 'none',
        },
      },
    },
    legend: {
      align: 'left',
      x: 50,
      verticalAlign: 'top',
      y: 5,
      floating: false,
      symbolWidth: 14,
      symbolHeight: 14,
      backgroundColor:
            Highcharts.defaultOptions.legend.backgroundColor || 'white',
      shadow: false,
      itemStyle: {
        fontWeight: fonts.fontWeight.normal,
        fontSize: '16px',
      },
      className: classes.legend,
    },
    tooltip: {
      headerFormat: '<b>{point.x}</b><br/>',
      pointFormat: '{series.name}: {point.y}<br/>Total: {point.stackTotal}',
    },
    plotOptions: {
      column: {
        stacking: 'normal',
        borderWidth: 0,
        width: '80%',
        dataLabels: {
          enabled: false,
        },
      },
    },
    colors: ['#0745BA', '#F45D18'],
    series: datesChartWeek,
  };
  const chartOptions = { ...defaultOptions };
  const { t } = useTranslation();

  return (
    <Grid container item direction="column">
      <div className={`${classes.title} `}>
        {t('ATTENDANCE_BREAKDOWN')}
      </div>
      <div className={classes.horizontalLine} />
      {teacherPanel?.classesTimingsStudents.length > 0
        ? (
          <div className={classes.chartWrapper}>
            <HighchartsReact highcharts={Highcharts} options={chartOptions} />
          </div>
        )
        : (
          <Grid className={classes.noData}>
            <DoNotDisturbIcon />
            <Grid>
              <Typography variant="subtitle2" color="text.secondary">
                {t('NO_DATA')}
              </Typography>
            </Grid>
          </Grid>
        )}
    </Grid>
  );
}
