/* eslint-disable */
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Box, Grid, Paper, Typography,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { captureDonation, captureOrder, captureWebsiteDonation, updateTempRegLog } from '../../store/actions/getPayment';
import useStyles from '../../custom-hooks/useStyles';
import styles from './style';
import logo from '../../assets/images/logo.png';
import successBoy from '../../assets/images/successCheckout.png';
import { getLocalStorage, setLocalStorage } from '../../utils/localStorageMethod';
import { NavigateRoutes, Buttons } from '../../constant';
import { colors } from '../../theme';
import { getStudentAccountDetails } from '../../store/actions/getStudent';
import DialogAtom from '../../components/atoms/dialog';
import ButtonAtom from '../../components/atoms/button';
const useStylesSheet = makeStyles(() => ({
  studentBox: {
    maxWidth: 'unset !important',
    '& .MuiGrid-item': {
      maxWidth: 'unset !important',
      textAlign: 'right !important',
      marginTop: '30px',
    },
  },
  alertprimary: {
    height: '3vw',
    display: 'flex',
    color: colors.green,
    fontSize: '16px',
    '@media (max-width: 900px)': {
      height: '15vw',
    },
  },
}));
function Success() {
  const { t } = useTranslation();
  const classes = useStyles(styles)();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const classesSheet = useStylesSheet();
  let registrationId = getLocalStorage('registrationId') || '';
  let isDonation = getLocalStorage('isDonation') || null;
  let isWebsiteDonation = getLocalStorage('isWebsiteDonation') || null;
  let paymentFor = getLocalStorage('paymentFor') || '';
  const orderId = getLocalStorage('orderId');
  const [isLinkEnabled, setIsLinkEnabled] = useState(false);
  const [sRegistrationId, setregistrationId] = useState(registrationId);
  const [adminDuplicateAlert, setAdminDuplicateAlert] = useState(false);
  const [captureOrderError, setCaptureOrderError] = useState(false);
  setTimeout(() => {
    setIsLinkEnabled(true);
  }, 15000);
  const handleAdminDuplicateAlertOpen = () => {
    setAdminDuplicateAlert(true);
  };
  const handleAdminDuplicateAlertClose = () => {
    setAdminDuplicateAlert(false);
  };
  const onCaptureOrderSuccess = () => {
    handleAdminDuplicateAlertClose();
    if (paymentFor === 'sibling') {
      dispatch(getStudentAccountDetails());
    }
    setTimeout(() => {
      setIsLinkEnabled(true);
      switch (paymentFor) {
        case 'sibling':
          navigate(NavigateRoutes.STUDENT_DASHBOARD);
          window.location.reload();
          break;
        case 'donation':
          navigate(NavigateRoutes.STUDENT_DASHBOARD);
          window.location.reload();
          break;
        case 'enroll':
          navigate(NavigateRoutes.STUDENT_DASHBOARD);
          window.location.reload();
          break;
        case 'backdoor':
          navigate(NavigateRoutes.ADMIN_DASHBOARD);
          window.location.reload();
          break;
        case 'websiteDonation':
          window.location.href = 'https://manabadi.siliconandhra.org/';
          break;
        default:
          navigate('/');
          window.location.reload();
      }
      setLocalStorage('paymentFor', '');
    }, 2000);
  };

  const onCaptureOrderError = (e) => {
    setCaptureOrderError(true);
    const payload = {
      registrationId,
      orderId,
      message: e.error,
    };
    dispatch(updateTempRegLog(payload));
  };

  useEffect(() => {
    if (!isDonation && !isWebsiteDonation) {
      handleAdminDuplicateAlertOpen();
    }
    const url = new URL(window.location);

    const params = new URLSearchParams(url.search);
    const pRegistrationId = params.get('id');
    const pIsDonation = params.get('isDonation');
    const pIsWebsiteDonation = params.get('isWebsiteDonation');
    const pPaymentFor = params.get('paymentFor');

    if(!registrationId) {
      registrationId = pRegistrationId;
      setregistrationId(pRegistrationId);
    }
    if(!isDonation) {
      isDonation = pIsDonation === 'true';
    }
    if(!isWebsiteDonation) {
      isWebsiteDonation = pIsWebsiteDonation === 'true';
    }
    if(!paymentFor) {
      paymentFor = pPaymentFor
    }

    callCaptureOrder();
  }, []);

    // Function to show alert when user tries to leave the page
    useEffect(() => {
      localStorage.setItem('paymentSuccess', 'success');
    }, []);

  const callCaptureOrder = () => {
    const donationPayload = {
      registrationId,
      orderId,
      isNewRegistration: paymentFor !== 'donation',
    };
    const payload = {
      registrationId,
      orderId,
      isNewRegistration: paymentFor !== 'sibling',
    };
  
    const enrollPayload = {
      registrationId,
      orderId,
      isNewEnrollment: true,
    };
  
    if (isDonation) {
      const donationCause = getLocalStorage('donationCause') || '';
      dispatch(captureDonation({ orderId: donationPayload?.orderId, donationCause }, onCaptureOrderSuccess, onCaptureOrderError));
    } else if (isWebsiteDonation) {
      const websiteDonationName = getLocalStorage('websiteDonationName') || 'Donater';
      const websiteDonationEmail = getLocalStorage('websiteDonationEmail') || '';
      const websiteDonationCause = getLocalStorage('websiteDonationCause') || '';
      const websiteDonationFirstName = getLocalStorage('websiteDonationFirstName') || '';
      const websiteDonationLastName = getLocalStorage('websiteDonationLastName') || '';
      const websiteDonationPhone = getLocalStorage('websiteDonationPhone') || '';
      const websiteDonationIsAnonymous = getLocalStorage('websiteDonationIsAnonymous') || false;
      dispatch(captureWebsiteDonation({
        orderId: donationPayload?.orderId,
        websiteDonationName,
        websiteDonationEmail,
        websiteDonationCause,
        donaterFirstName: websiteDonationFirstName,
        donaterLastName: websiteDonationLastName,
        donaterPhoneNumber: websiteDonationPhone,
        isAnonymous: websiteDonationIsAnonymous
       }, onCaptureOrderSuccess, onCaptureOrderError));
    } else if (paymentFor === 'enroll') {
      dispatch(captureOrder(enrollPayload, onCaptureOrderSuccess, onCaptureOrderError, handleAdminDuplicateAlertClose));
    } else {
      dispatch(captureOrder(payload, onCaptureOrderSuccess, onCaptureOrderError, handleAdminDuplicateAlertClose));
    }
  };
  
  const renderCaptureOrderError = () => (
    <Grid className={classesSheet.studentBox}>
      <Box
        component="form"
        sx={{
          '& .MuiTextField-root': { m: 1, width: '25ch' },
        }}
        noValidate
        autoComplete="off"
      >
        <Grid>
          <div className={classesSheet.alertprimary} role="alert">
              <p>
                An unexpected error occurred. Please contact technical support with
                the ID: <strong>{sRegistrationId}</strong>.
              </p>
          </div>
        </Grid>
      </Box>
      <Grid
         item
         xs={12}
         className={`${classesSheet.dialogButtons} ${classesSheet.popupButton}`}
       >
        <ButtonAtom
          className={classesSheet.popupBtn}
          name={t('OK')}
          onClick={() => {
            setCaptureOrderError(false);
          }}
          btntype={Buttons.PRIMARY}
        />
      </Grid>
    </Grid>
  );

  const adminDuplicateAlertContent = () => (
    <Grid className={classesSheet.studentBox}>
      <Box
        component="form"
        sx={{
          '& .MuiTextField-root': { m: 1, width: '25ch' },
        }}
        noValidate
        autoComplete="off"
      >
        <Grid>
          <div className={classesSheet.alertprimary} role="alert">
            <p>
              Please do not use browser back arrow. It will create a duplicate profile.
            </p>
          </div>
        </Grid>
      </Box>
      <Grid
        item
        xs={12}
        className={`${classesSheet.dialogButtons} ${classesSheet.popupButton}`}
      >
        <ButtonAtom
          className={classesSheet.popupBtn}
          name={t('OK')}
          onClick={() => {
            handleAdminDuplicateAlertClose();
          }}
          btntype={Buttons.PRIMARY}
        />
      </Grid>
    </Grid>
  );

  return (
    <Paper className={classes.root}>
      <Grid container>
        <img
          src={logo}
          width={441}
          alt="Italian Trulli"
          className={classes.headerLogo}
          onClick={() => {{isLinkEnabled ? (navigate('/')) : null}}}
        />
      </Grid>
      <Grid container justifyContent="space-between" className={classes.mainContainer}>
        <Grid item xs={11} lg={8} md={10} justifyContent="center" className={classes.cardRight}>
          <Grid container justifyContent="center">
            <img
              src={successBoy}
              alt="Success"
              width={409}
              className={classes.successBackgroundImg}
            />
          </Grid>
          <Grid item justifyContent="center" xs={12} md={12} lg={12} className={classes.successContent}>
            {paymentFor === 'donation' ? (
              <Grid>
                <Typography mb={2} className={classes.successText}>
                  {t('DONATION_SUCCESS_TEXT')}
                </Typography>
                <Typography mb={2} className={classes.successSubtextMail}>
                  {isLinkEnabled ? (
                    <>
                      {t('REDIRECT_STU_DASHBOARD')}
                      {' '}
                      <a href={NavigateRoutes.STUDENT_DASHBOARD}>{t('CLICK_HERE')}</a>
                    </>
                  ) : (
                    <span>{t('PLEASE_WAIT_STU_DASHBOARD')}</span>
                  )}
                </Typography>
              </Grid>
            ) : paymentFor === 'websiteDonation' ? (
              <Grid>
                <Typography mb={2} className={classes.successText}>
                  {t('DONATION_SUCCESS_TEXT')}
                </Typography>
                <Typography mb={2} className={classes.successSubtextMail}>
                  {isLinkEnabled ? (
                    <>
                      {t('PAYMENT_SUCCESS_REDIRECT_DONATION')}
                      {' '}
                      <a href="https://manabadi.siliconandhra.org/">{t('CLICK_HERE')}</a>
                    </>
                  ) : (
                    <span>{t('PLEASE_WAIT_REDIRECT_DONATION')}</span>
                  )}
                </Typography>
              </Grid>
            ) : (
                <Grid>
                  <Typography mb={2} className={classes.successText}>
                    {t('PAYMENT_SUCCESS_TEXT')}
                  </Typography>
                  <Typography mb={2} className={classes.successSubtextMail}>
                    {t('CHECK_MAIL')}
                  </Typography>
                  <Typography
                    mb={4}
                    xs={3}
                    md={3}
                    lg={3}
                    className={classes.successSubtext}
                  >
                     {isLinkEnabled ? (
                      <>
                        {t('PAYMENT_SUCCESS_REDIRECT')}
                        {' '}
                        <a href={'/'}>{t('CLICK_HERE')}</a>
                      </>
                    ) : (
                      <span>{t('PLEASE_WAIT_REDIRECT')}</span>
                    )}
                  </Typography>
                </Grid>
              )}

          </Grid>
        </Grid>
      </Grid>
      <DialogAtom
        isOpen={adminDuplicateAlert}
        secHandle={() => handleAdminDuplicateAlertClose(false)}
        dialogHeading={t('ADMINISTRATION')}
        content={adminDuplicateAlertContent()}
      />
      <DialogAtom
        isOpen={captureOrderError}
        secHandle={() => setCaptureOrderError(false)}
        dialogHeading={t('ERROR')}
        content={renderCaptureOrderError()}
      />
    </Paper>
  );
}

export default Success;