/* eslint-disable no-unsafe-optional-chaining */
/* eslint-disable no-nested-ternary */
/* eslint-disable */
/* eslint-disable prefer-regex-literals */
/* eslint-disable consistent-return */
/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable no-useless-escape */
import moment from 'moment';
import { isInteger, isNaN } from 'lodash';
import { getLocalStorage } from './localStorageMethod';

const limitDecimal = (event) => {
  let val = event.target.value;
  val = val.replace(/([^0-9.]+)/, '');
  val = val.replace(/^(0|\.)/, '');
  const match = /(\d{0,7})[^.]*((?:\.\d{0,2})?)/g.exec(val);
  const value = match[1] + match[2];
  event.target.value = value;
  if (val.length > 0) {
    event.target.value = Number(value).toFixed(2);
    return Number(value).toFixed(2);
  }
  return Number(value);
};
const handleSnackBar = (action, text = '', _type = 'success') => ({
  isVisible: action !== 'hide',
  data: text,
  type: _type,
});

const hasNumericOrSpecialChar = (value) => {
  const hasNumericCase = (val) => new RegExp('(?=.*\\d)').test(val);
  const hasSpecialCase = (val) => new RegExp('(?=.*[-+_!@#$%^&*.,?])').test(val);
  return hasNumericCase(value) || hasSpecialCase(value);
};

function calculateAgeInYears(date) {
  const birthDate = new Date(date);
  const now = new Date(localStorage.getItem('currentDobCutoff'));
  const currentYear = now.getFullYear();
  const yearDiff = currentYear - birthDate.getFullYear();
  const birthdayThisYear = new Date(currentYear, birthDate.getMonth(), birthDate.getDate());
  const isBirthdayYear = (now >= birthdayThisYear);

  return isBirthdayYear
    ? yearDiff
    : yearDiff - 1;
}

function calculateAge(dob) {
  const birthDate = new Date(dob);
  const diff = Math.floor(Date.now() - birthDate.getTime());
  const day = 1000 * 60 * 60 * 24;
  const days = Math.floor(diff / day);
  const yr = parseInt(days / 365);
  return days % 365 && yr >= 4 ? yr + 1 : yr;
}

const removeSpecialChar = (string) => string && string?.length && string?.replace(/[^a-zA-Z ]/g, '');

const logDate = (inputDate) => {
  const dateFormat = 'MMM DD, YYYY hh:mm:ss A';
  const date = moment(new Date(inputDate)).format(dateFormat);
  return date;
};
const newDOB = (inputDate) => {
  const dateFormat = 'MMMM DD, YYYY';
  const date = moment.utc(inputDate).format(dateFormat);
  return date;
};

const formatDate = (inputDate, format) => {
  const dateFormat = format;
  const date = moment.utc(inputDate).format(dateFormat);
  return date === 'Invalid date' ? '' : date;
};

const getFormattedDate = (inputDate, format) => {
  if (inputDate === '' || !inputDate) {
    return '';
  }
  const date = new Date(inputDate);
  const year = date.getFullYear();
  let month = date.getMonth() + 1;
  let dt = date.getDate();
  let hh = date.getHours();
  let mm = date.getMinutes();
  let ss = date.getSeconds();

  if (dt < 10) {
    dt = `0${dt}`;
  }
  if (month < 10) {
    month = `0${month}`;
  }
  if (hh < 10) {
    hh = `0${hh}`;
  }
  if (mm < 10) {
    mm = `0${mm}`;
  }
  if (ss < 10) {
    ss = `0${ss}`;
  }

  return format === 'MMDDYYYY' ? `${month}.${dt}.${year} ${hh}.${mm}.${ss}` : `${dt}.${month}.${year} ${hh}.${mm}.${ss}`;
};
const getNewFormattedDate = (inputDate) => {
  if (inputDate === '' || !inputDate) {
    return '';
  }
  const date = new Date(inputDate);
  const year = date.getFullYear();
  let month = date.getMonth() + 1;
  let dt = date.getDate();

  if (dt < 10) {
    dt = `0${dt}`;
  }
  if (month < 10) {
    month = `0${month}`;
  }

  return `${month}.${dt}.${year}`;
};
const getTimeFromUnixFormat = (inputTime) => {
  const fommatted = new Intl.DateTimeFormat('en-US', {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit',
  }).format(inputTime);

  return fommatted;
};
const getNewTimeFromUnixFormat = (inputTime) => {
  const fommatted = new Intl.DateTimeFormat('en-US', {
    hour: '2-digit',
    minute: '2-digit',
  }).format(inputTime);

  return fommatted;
};
function containsSpecialChars(str) {
  const specialChars = /[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;
  return specialChars.test(str);
}
const avoidNegativeValue = (value) => (value <= 0 ? '0.00' : value);
const checkDateValid = (inputDate) => {
  let year;
  if (inputDate === null) {
    year = 1800;
  } else {
    year = new Date(inputDate).getFullYear();
  }
  const currentYear = new Date().getFullYear();
  const dateFormat = 'DD-MM-YYYY';
  const toDateFormat = moment(new Date(inputDate)).format(dateFormat);
  const isValid = moment(toDateFormat, dateFormat, true).isValid();
  return isValid && year >= 1900 && year <= currentYear;
};

const getMaskedEmail = (email) => {
  if (!email) return;
  const output = email?.replace(/(..)(.{1,3})(?=.*@)/g, (_, a, b) => a + '*'.repeat(b.length));
  return output;
};

const getDistanceBetweenTwoPoint = (p1, p2) => {
  const rad = (x) => (x * Math.PI) / 180;

  if (!p1 || !p2 || !p1.lat || !p1.lng || !p2.lat || !p2.lng) return 0;

  const R = 6378137; // Earth's mean radius in meter
  const dLat = rad(p2.lat - p1.lat);
  const dLong = rad(p2.lng - p1.lng);
  const a = Math.sin(dLat / 2) * Math.sin(dLat / 2)
    + Math.cos(rad(p1?.lat)) * Math.cos(rad(p2?.lat))
    * Math.sin(dLong / 2) * Math.sin(dLong / 2);
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  const d = R * c;
  return d; // returns the distance in meter
};
const calculateTotalFee = (studentData) => {
  let totalAmo = 0;
  studentData?.forEach((lineItem) => {
    totalAmo += Number(lineItem?.enrollmentCourse?.feeStructure?.total);
  });

  return totalAmo?.toFixed(2);
};
const calculateTotalPayment = (studentData, contributionAmount) => {
  let totalAmo = 0;
  studentData?.forEach((lineItem) => {
    totalAmo += Number(lineItem?.enrollmentCourse?.feeStructure?.total);
  });

  const res = totalAmo
    + Number(
      contributionAmount < 0
        ? 0
        : contributionAmount,
    );
  return res?.toFixed(2);
};

const getWeekNumOfMonthOfDate = (d) => {
  const firstDay = new Date(d.getFullYear(), d.getMonth(), 1).getDay();
  const month = d.toLocaleString('default', { month: 'long' });
  const week = Math.ceil((d.getDate() + (firstDay - 1)) / 7);
  return { month, week };
};

const getCurrentYear = () => {
  let currentYear = getLocalStorage('academicYear');
  if (!currentYear || currentYear === 'null') {
    currentYear = getLocalStorage('currentAcademicYear');
  }
  const selectedAcademicYear = {
    id: currentYear,
    name: currentYear,
  };
  return selectedAcademicYear;
};

const getCurrentAcaYear = () => {
  let currentYear = getLocalStorage('currentAcademicYear');

  const selectedAcademicYear = {
    id: currentYear,
    name: currentYear,
  };
  return selectedAcademicYear;
};

const roundedValue = (toBeRounded) => {
  let val = toBeRounded;
  val = val.replace(/([^0-9.]+)/, '');
  val = val.replace(/^(0|\.)/, '');
  const match = /(\d{0,7})[^.]*((?:\.\d{0,2})?)/g.exec(val);
  const value = match[1] + match[2];
  return value;
};
const getRegistrationAcademicYear = () => {
  const value = localStorage.getItem('registrationYear');
  const registrationYear = value || getCurrentYear();
  const selectedAcademicYear = {
    id: registrationYear,
    name: registrationYear,
  };

  return selectedAcademicYear;
};

const hasRole = (allUserRoles, roleNAme) => {
  if (allUserRoles?.find((role) => role.name === roleNAme)) {
    return true;
  }
  return false;
};

const checkFloatOrIntGreaterThanZero = (value) => {
  if (parseFloat(value) >= 0 || parseInt(value, 10) >= 0) {
    return true;
  }
  return false;
};

const isNullOrUndefined = (value) => {
  return (value !== null && value !== undefined);
};

const isValidSemesterYear = (year) => {
  let valid = false;
  const selectedStartYear = (typeof year === 'string') && year?.split('-')[0];
  if (selectedStartYear > '2022') {
    valid = true;
  }
  return valid;
};

const calculateAnnualScore = (course, maxScore, annualScore, marks) => {
  let score = annualScore;
  return Number.isInteger(score) ? `${parseInt(score, 10)}%` : `${parseFloat(score, 10).toFixed(2)}%`;
};

const getSemester3Title = (courseName, t) => {
  let semester = t('SUPP');

  if (courseName === 'Prabhasam' || courseName === 'Prakasam' || courseName === 'Telugu-2' || courseName === 'Telugu-4') {
    semester = t('PSTU');
  }

  return semester;
};

const getGradeforScore = (score, grades) => {
  let grade = 'N/A';
  grades?.forEach((obj) => {
    if (score <= obj.maxScore && score >= obj.minScore) {
      grade = obj.grade;
    }
  });

  return grade;
};

function checkPSTU(courseName) {
  const isPSTU = (courseName === 'Prakasam' || courseName === 'Prabhasam' || courseName === 'Telugu-2' || courseName === 'Telugu-4');
  return isPSTU;
}

function checkFLC(courseName) {
  const isFLC = (courseName === 'Telugu-1' || courseName === 'Telugu-2' || courseName === 'Telugu-3' || courseName === 'Telugu-4');
  return isFLC;
}

function isCalculatePSTU(courseName) {
  const isPSTU = (courseName === 'Prakasam' || courseName === 'Prabhasam');
  return isPSTU;
}

function isValidAnnualScore(values) {
  if (isValidSemesterYear(values.row.enrolled_courses[0].academicYear)) {
    return ((isNullOrUndefined(values.row.S2Attended) && isNullOrUndefined(values.row.S1Attended)) || isNullOrUndefined(values.row.S3Attended));
  }
  return (values.row.Q1Attended || values.row.Q2Attended || values.row.Q3Attended);
}

function renderGradeCell(values) {
  return isValidAnnualScore(values) ? values.row.grade : 'N/A'
}

function renderGpaCell(values) {
  return isValidAnnualScore(values) ? values.row.gpa : 'N/A'
}

function renderAnnualScoreCell(values) {
  return isValidAnnualScore(values) ? values.row.annualScore : 'N/A'
}
function getTotalMarks(course, sem, courseName) {
  const isPSTU = (courseName !== 'Prabhasam' && courseName !== 'Prakasam');
  const hw = isPSTU ? ((course?.homeworkMarks?.[sem]?.weightage / 100) * course?.homeworkMarks?.[sem]?.obtainedMarks)
    : (isNullOrUndefined(course?.homeworkMarks?.[sem]?.weightage)) ? ((course?.homeworkMarks?.[sem]?.weightage / 100) * course?.homeworkMarks?.[sem]?.obtainedMarks) : '-';

  const marks = isPSTU ? ((course?.marks?.[sem]?.weightage / 100) * course?.marks?.[sem]?.obtainedMarks)
    : (isNullOrUndefined(course?.marks?.[sem]?.weightage)) ? ((course?.marks?.[sem]?.weightage / 100) * course?.marks?.[sem]?.obtainedMarks) : '-';

  const quiz1 = isNullOrUndefined((course?.quizMarks?.[`${sem}Quiz 1`]?.weightage))
    ? isPSTU
      ? (course?.quizMarks?.[`${sem}Quiz 1`]?.weightage / 100) * course?.quizMarks?.[`${sem}Quiz 1`]?.obtainedMarks
      : ((course?.quizMarks?.[`${sem}Quiz 1`]?.weightage / 100) * course?.quizMarks?.[`${sem}Quiz 1`]?.obtainedMarks)
    : '-';

  const quiz2 = isNullOrUndefined((course?.quizMarks?.[`${sem}Quiz 2`]?.weightage))
    ? isPSTU
      ? (course?.quizMarks?.[`${sem}Quiz 2`]?.weightage / 100) * course?.quizMarks?.[`${sem}Quiz 2`]?.obtainedMarks
      : ((course?.quizMarks?.[`${sem}Quiz 2`]?.weightage / 100) * course?.quizMarks?.[`${sem}Quiz 2`]?.obtainedMarks)
    : '-';

  const assignment = isPSTU ? ((course?.assignmentMarks?.[sem]?.weightage / 100) * course?.assignmentMarks?.[sem]?.obtainedMarks)
    : (isNullOrUndefined(course?.assignmentMarks?.[sem]?.weightage)) ? ((course?.assignmentMarks?.[sem]?.weightage / 100) * course?.assignmentMarks?.[sem]?.obtainedMarks) : '-';

  const curricular = isPSTU ? ((course?.attendenceActivitiesMarks?.[sem]?.weightage / 100) * course?.attendenceActivitiesMarks?.[sem]?.obtainedMarks)
    : (isNullOrUndefined(course?.attendenceActivitiesMarks?.[sem]?.weightage)) ? ((course?.attendenceActivitiesMarks?.[sem]?.weightage / 100) * course?.attendenceActivitiesMarks?.[sem]?.obtainedMarks) : '-';

  let total = 0;
  let isTotalMarks = false;

  if (!isNaN(hw) && hw !== '-') {
    total += hw;
    isTotalMarks = true;
  }
  if (!isNaN(marks) && marks !== '-') {
    total += marks;
    isTotalMarks = true;
  }
  if (!isNaN(quiz1) && quiz1 !== '-') {
    total += quiz1;
    isTotalMarks = true;
  }
  if (!isNaN(quiz2) && quiz2 !== '-') {
    total += quiz2;
    isTotalMarks = true;
  }
  if (!isNaN(assignment) && assignment !== '-') {
    total += assignment;
    isTotalMarks = true;
  }
  if (!isNaN(curricular) && curricular !== '-') {
    total += curricular;
    isTotalMarks = true;
  }

  return (!isNaN(total) && isTotalMarks ? (isInteger(total) ? parseInt(total, 10) : parseFloat(total, 10).toFixed(2)) : '-');
}

export {
  limitDecimal,
  getWeekNumOfMonthOfDate,
  handleSnackBar,
  getCurrentYear,
  getFormattedDate,
  getNewFormattedDate,
  calculateAge,
  removeSpecialChar,
  getTimeFromUnixFormat,
  getNewTimeFromUnixFormat,
  containsSpecialChars,
  avoidNegativeValue,
  checkDateValid,
  hasNumericOrSpecialChar,
  getMaskedEmail,
  logDate,
  formatDate,
  newDOB,
  getDistanceBetweenTwoPoint,
  calculateTotalFee,
  calculateTotalPayment,
  getRegistrationAcademicYear,
  roundedValue,
  hasRole,
  checkFloatOrIntGreaterThanZero,
  isNullOrUndefined,
  isValidSemesterYear,
  calculateAnnualScore,
  getSemester3Title,
  getGradeforScore,
  calculateAgeInYears,
  checkPSTU,
  getTotalMarks,
  checkFLC,
  isCalculatePSTU,
  renderGradeCell,
  renderGpaCell,
  renderAnnualScoreCell,
  getCurrentAcaYear,
};
