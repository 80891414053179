/* eslint-disable no-nested-ternary */
import React from 'react';
import { Box, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { DialogAtom } from '../../../../components/atoms';
import useStyles from '../../../../custom-hooks/useStyles';
import styles from './style';
import ButtonAtom from '../../../../components/atoms/button';
import { Buttons } from '../../../../constant';

function PreviewDialog({
  isOpen,
  sendAnnouncement,
  editAnnouncement,
  announcementDetails,
}) {
  const classes = useStyles(styles)();
  const { t } = useTranslation();
  const returnJSXFragment = (tag) => tag;

  return (
    <DialogAtom
      isOpen={isOpen}
      secHandle={editAnnouncement}
      customClass={classes.announcementDialogAtom}
      content={(
        <Box className={classes.announcementDetails}>
          <Box mt={0}>
            <Box display="flex" justifyContent="flex-start" alignItems="center">
              <Typography style={{ marginBottom: '10px' }}>
                <b className={classes.menssageHeader}>{`${t('SENDER')}:`}</b>
              </Typography>
              <Typography style={{ marginBottom: '10px' }}>
                {announcementDetails?.sender || ''}
              </Typography>
            </Box>
            <Box display="flex" justifyContent="flex-start" alignItems="center">
              <Typography style={{ marginBottom: '10px' }}>
                <b className={classes.menssageHeader}>{`${t('MAIL_SUBJECT')}:`}</b>
              </Typography>
              <Typography style={{ marginBottom: '10px' }}>
                {announcementDetails?.subject || ''}
              </Typography>
            </Box>
            <Box display="flex" justifyContent="flex-start" alignItems="center">
              <Typography style={{ marginBottom: '10px' }}>
                <b className={classes.menssageHeader}>{`${t('RECIPIENTS_COUNT')}:`}</b>
              </Typography>
              <Typography style={{ marginBottom: '10px' }}>
                {announcementDetails?.count || ''}
              </Typography>
            </Box>
            <Box display="flex" justifyContent="flex-start" alignItems="center">
              <Typography style={{ marginBottom: '10px' }}>
                <b className={classes.menssageHeader}>{`${t('FILTERS')}:`}</b>
              </Typography>
              <Typography
                className={classes.scrollableContent}
                style={{ marginBottom: '10px' }}
              >
                {announcementDetails?.filter || ''}
              </Typography>
            </Box>
            {announcementDetails?.tags && (
              <Box display="flex" justifyContent="flex-start" alignItems="center">
                <Typography style={{ marginBottom: '10px' }}>
                  <b className={classes.menssageHeader}>{`${t('SUB_TAGS')}:`}</b>
                </Typography>
                <Typography
                  className={classes.scrollableContent}
                  style={{ marginBottom: '10px' }}
                >
                  {announcementDetails?.tags || ''}
                </Typography>
              </Box>
            )}
          </Box>
          <Box className={classes.mailID}>
            <Typography>
              <div
                // eslint-disable-next-line react/no-danger
                dangerouslySetInnerHTML={{ __html: returnJSXFragment(announcementDetails?.emailBody) }}
              />
            </Typography>
          </Box>
          <Box
            style={{
              display: 'flex',
              justifyContent: 'flex-end',
              gap: '10px',
            }}
          >
            <ButtonAtom
              name={t('EDIT_ANNOUNCEMENTS')}
              onClick={() => editAnnouncement()}
              btntype={Buttons.SECONDARY}
            />
            <ButtonAtom
              name={t('SEND_ANNOUNCEMENTS')}
              onClick={() => sendAnnouncement()}
              btntype={Buttons.PRIMARY}
            />
          </Box>
        </Box>
      )}
      dialogHeading={t('PREVIEW_ANNOUNCEMENTS')}
    />

  );
}

export default PreviewDialog;
