import { fonts, colors } from '../../../../theme';

const { userAgent } = navigator;

const chromeAgent = userAgent.indexOf('Chrome') > -1;
const safariAgent = userAgent.indexOf('Safari') > -1;

const isSafari = !(chromeAgent && safariAgent);

const styles = {
  dropDownCont: {
    padding: '0px !important',
    margin: 0,
  },

  textFieldLabel: {
    '@media (max-width: 1200px)': {
      fontSize: '0.9vw',
    },
  },
  courseTableBox: {
    overflowX: 'auto',
    '@media (max-width: 1199px)': {
      overflowX: 'hidden',
    },
  },

  courseTableHeadingCont: {
    padding: '0, 0, 10, 10',
    borderBottom: `3px solid ${colors.primary}`,
    '@media (max-width: 900px)': {
      minWidth: '600px',
    },
  },
  formControlBox: {
    width: '10%',
    padding: '10px !important',
    '@media (max-width: 1199px)': {
      padding: '24px !important',
    },
  },

  courseTableHeading: {
    padding: 0,
    fontWeight: 600,
    color: colors.primary,
    fontSize: 12,

  },

  saveBtnCont: {
    textAlign: 'right',
  },

  errorText: {
    fontSize: '0.9vw !important',
    color: colors.errorText,
    fontWeight: 'normal !important',
  },

  errorCont: {
    marginTop: '3vh',
    marginBottom: '3vh',
  },

  successText: {
    fontSize: '0.7vw !important',
    color: colors.green,
    fontWeight: 'normal !important',
  },

  successCont: {
    marginTop: '3vh',
    marginBottom: '3vh',
  },
};

export const materialStyles = (theme) => ({
  formControlBox: {
    paddingLeft: '24px !important',
    width: '100%',
    '@media (max-width: 1199px)': {
      width: 'auto',
      paddingTop: '10px !important',
      paddingLeft: '24px !important',
    },
  },
  formControl: {
    '@media (max-width: 1199px)': {
      marginRight: 0,
    },
    '@media (max-width: 900px)': {
      display: 'grid',
      width: '100%',
    },
    '& .MuiOutlinedInput-input.Mui-disabled': {
      background: `${colors.disabledField} !important`,
      '-webkit-text-fill-color': `${colors.black} !important`,
    },
    '& .MuiInputLabel-root': {
      fontFamily: `${fonts.fontType.roboto} !important`,
      fontSize: '0.9vw !important',
      color: `${colors.primary} !important`,
      [theme.breakpoints.down('md')]: {
        fontSize: '12px !important',
      },
    },
    '& .MuiOutlinedInput-root': {
      background: '#fff',
      borderRadius: '0.4vw',
      ...(isSafari && { WebkitBorderRadius: '0.4vw' }),
      color: colors.placeHolderColor,
      outline: 'none',
      '@media (min-width: 1200px)': {
        fontSize: '0.9vw !important',
        height: '2.7vw',
      },
      '& .MuiOutlinedInput-notchedOutline': {
        border: `0.1vw solid ${colors.primary}`,
        ...(isSafari && { border: `0.8px solid ${colors.primary}` }),
      },
      '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
        border: `0.2vw solid ${colors.primary}`,
        ...(isSafari && { border: `1.6px solid ${colors.primary}` }),
      },
      '&.Mui-error .MuiOutlinedInput-notchedOutline': {
        border: `0.1vw solid ${colors.errorText}`,
      },
      '&.Mui-error.Mui-focused .MuiOutlinedInput-notchedOutline': {
        border: `0.2vw solid ${colors.errorText}`,
      },
      '& input': {
        '@media (min-width: 1200px) and (max-width: 1599px)': {
          padding: '0.7vw',
          fontSize: '0.9vw !important',
        },
        fontFamily: `${fonts.fontType.roboto} !important`,
        color: `${colors.black}!important`,
        textOverflow: 'ellipsis',
        [theme.breakpoints.down('md')]: {
          fontSize: '12px !important',
        },
      },
    },
    '& .MuiFormHelperText-contained': {
      margin: 0,
    },
  },
  gridContainer: {
    padding: '2vw 2vw 2vw 3vw !important',
    minHeight: '65vh',
  },
  titleRow: {
    justifyContent: 'space-between',
  },
  headerTitle: {
    fontWeight: fonts.fontWeight.bold,
    '@media (min-width: 1200px)': {
      fontSize: '1.5vw',
      padding: '1vw 0.5vw 1vw 0',
    },
  },
  subHeaderTitle: {
    fontWeight: '400',
    color: '#505050',
    '@media (min-width: 1200px)': {
      fontSize: '.9vw',
      padding: '1vw 0.5vw 0.6vw 0',
    },
  },
  headerDesc: {
    fontWeight: fonts.fontWeight.bold,
    '@media (min-width: 1200px)': {
      fontSize: '1vw',
    },
    color: colors.settingsDescription,
    padding: '1.4vw 1vw 1vw 0',
  },
  LateFeeAmount: {
    padding: '1vw 0vw 1vw 2vw !important',
    '& .MuiFormControl-root': {
      marginLeft: '-0.5vw !important',
      '@media (min-width: 1200px)': {
        height: '2.5vw',
      },
      '& .MuiTableCell-head': {
        padding: '1vw 0vw 1vw 2vw',
      },
    },
  },
  secButton: {
    color: '#1976d2 !important',
    border: '0.1px solid #1976d2',
    '@media (min-width: 1200px)': {
      height: '3vw',
      fontSize: '1vw',
      padding: '0.8vw',
      width: '13vw',
    },
    background: 'white',
    boxSizing: 'border-box',
    fontFamily: 'Roboto, sans-serif',
    marginRight: '1vw',
    borderRadius: '0.4vw !important',
    textTransform: 'none',
    '& .MuiSvgIcon-root': {
      '@media (min-width: 1200px)': {
        width: '0.8vw',
        height: '0.8vw',
      },
    },
  },
  actionBtn: {
    '@media (min-width: 1200px)': {
      minWidth: '13.021vw !important',
      height: '3vw !important',
      fontSize: '0.9vw',
    },
    marginRight: '1.5vw !important',
  },
  addAcademicBtn: {
    '@media (min-width: 1200px)': {
      minWidth: '13.021vw !important',
      height: '3vw !important',
      fontSize: '0.9vw',
    },
    paddingRight: '1.7vw',
    textAlign: 'right',
  },
  tableCells: {
    '@media (min-width: 1200px)': {
      width: '20vw',
    },
  },

  datePicker: {
    margin: '0.5vw 0',
    height: '4vw',
  },

  tableCellCourse: {
    '@media (min-width: 1200px)': {
      width: '11vw',
    },
  },
  title: {
    '@media (min-width: 1200px)': {
      fontSize: '1.2vw',
      padding: '1vw 1vw 1vw 0',
    },
  },
  dropdown: {
    '@media (min-width: 1200px)': {
      minWidth: '25vw !important',
      marginTop: '0 !important',
      maxWidth: '200vw',
      fontSize: '1vw',
      marginLeft: '-1vw',
    },
  },
  registrationTable: {
    width: '99%',
    '& .MuiOutlinedInput-root': {
      '@media (min-width: 1200px)': {
        width: '13vw !important',
      },
    },
  },
  select: {
    padding: '0',
    minWidth: '20vw',
    transform: 'translate(16px, 0vw) scale(1)',
    '@media (min-width: 1200px)': {
      fontSize: '1vw',
      height: '2vw',
      transform: 'translate(1vw, 0vw) scale(1)',
    },
    '& .MuiSelect-select': {
      paddingRight: '1vw !important',
      minWidth: '150px !important',
      '@media (min-width: 1200px)': {
        minWidth: '5vw !important',
        minHeight: '1vw !important',
      },
    },
    '& svg': {
      '@media (min-width: 1200px)': {
        width: '1.5vw',
        height: '1.5vw',
      },
    },
  },
  dropdownWrapper: {
    justifyContent: 'space-between',
  },
  tableHead: {
    '& .MuiTableCell-head': {
      fontWeight: `${fonts.fontWeight.bold}`,
      color: '#015EEA',
    },
    '& .MuiTableCell-root': {
      borderBottom: '0.2vw solid #015EEA',
      '@media (min-width: 1200px)': {
        fontSize: '1vw',
      },
      padding: '1vw',
    },
  },
  tableBody: {
    '& .MuiTableCell-root': {
      borderBottom: 'none',
      '& p': {
        color: '#505050',
        fontWeight: '700',
      },
      '@media (min-width: 1200px)': {
        fontSize: '1vw',
      },
      padding: '0.3vw 1vw 1vw;',
    },
    '& .MuiTableRow-root:first-child': {
      display: 'contents',
    },
    '& .MuiTableRow-root:last-child': {
      borderBottom: '1px solid #858585',
    },
    '& .MuiTableCell-body': {
      '& .MuiInputLabel-root': {
        color: '#104F96',
        transform: 'translate(16px, 0vw) scale(1)',
        '@media (min-width: 1200px)': {
          fontSize: '0.7vw !important',
          transform: 'translate(1vw, 0vw) scale(1)',
        },
        fontFamily: fonts.fontType.roboto,
        marginLeft: 0,
        [theme.breakpoints.down('lg')]: {
          fontSize: '12px !important',
        },
        [theme.breakpoints.down('md')]: {
          fontSize: '10px !important',
        },
      },
      '& .MuiFormControl-root .MuiFormControl-root': {
        '& .MuiTypography-root': {
          fontSize: '1vw',
        },
        '& .MuiInputLabel-root': {
          transform: 'translate(16px, -6px) scale(1)',
          '@media (min-width: 1200px)': {
            transform: 'translate(1vw, 0vw) scale(1)',
          },
        },
      },
      '& .MuiOutlinedInput-root': {
        '@media (min-width: 1200px)': {
          height: '3vw',
        },
        borderRadius: '0.4vw',
        paddingLeft: '0.2vw',
      },
    },
    '& #datePickerDiv': {
      '@media (min-width: 1200px)': {
        height: '2vw',
      },
      transform: 'translate(0vw, 0.2vw) scale(1)',
      paddingLeft: '0.9vw',
    },
  },
  input: {
    textAlign: 'right',
    '& .MuiInputBase-input': {
      textAlign: 'right !important',
    },
  },
  errorText: {
    '@media (min-width: 1200px)': {
      fontSize: '0.7vw !important',
      color: `${colors.errorText} !important`,
      fontWeight: 'normal !important',
    },
    color: `${colors.errorText} !important`,
    fontWeight: 'normal !important',
  },
  academicErrorText: {
    '@media (min-width: 1200px)': {
      fontSize: '0.9vw !important',
    },
    color: 'red',
  },

  contentCont: {
    border: `1px solid ${colors.border}`,
    minHeight: 600,
    '@media (min-width: 1200px)': {
      margin: 30,
      padding: 30,
      marginTop: 0,
      borderRadius: 4,
      display: 'flex',
    },
    '@media (max-width: 599px)': {
      margin: 10,
      padding: 10,
      borderRadius: 4,
      display: 'block',
    },
  },
  dateControl: {
    '@media (max-width: 900px)': {
      paddingTop: '20px !important',
      '& .MuiInputLabel-root': {
        fontSize: '12px !important',
        top: '0px',
        left: '0px',
      },
    },
  },
});

export default styles;
