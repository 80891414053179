/* eslint-disable no-nested-ternary */
/* eslint-disable no-unsafe-optional-chaining */
/* eslint-disable react/jsx-props-no-spreading */
import * as React from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { FormControl } from '@mui/material';
import styles from './style';
import useStyles from '../../../custom-hooks/useStyles';

export default function GroupedAutoComplete({
  enterNotAllowed,
  label,
  options,
  onSelection,
  value = [],
  isStudentFilter,
  isDashboard,
  onInputChange,
  disabled,
  placeholder,
  allowUserInput,
  inputRef,
}) {
  const classes = useStyles(styles)();
  return (
    <FormControl className={isStudentFilter ? `${classes.formControlStudentFilter} ${isDashboard ? classes.formControlFlter : ''}` : classes.formControl}>
      <Autocomplete
        id="grouped-demo"
        multiple
        options={Array.isArray(options?.[0]?.groupBy) === false ? options?.sort((a, b) => -b?.groupBy?.localeCompare(a.groupBy)) : options}
        groupBy={(option) => option.groupBy}
        getOptionLabel={(option) => (option?.groupBy === 'Status' ? (option?.label === 'true' || option?.label === 'Active') ? 'Active' : 'Inactive' : option?.label?.toString()) || ''}
        renderInput={(params) => (
          <TextField
            focused
            {...params}
            onKeyDown={(e) => {
              if (enterNotAllowed && e.key === 'Enter') {
                e.stopPropagation();
              }
            }}
            label={label}
            placeholder={placeholder}
            inputRef={inputRef}
          />
        )}
        onChange={(e, val) => {
          const filtered = val.filter((valu, index, self) => index === self.findIndex((t) => (
            t.label === valu.label && t.groupBy === valu.groupBy
          )));
          onSelection(e, filtered);
        }}
        value={value}
        label={label}
        onInputChange={onInputChange}
        disabled={disabled}
        freeSolo={allowUserInput}
        filterOptions={(option, { inputValue }) => {
          if (inputValue) {
            return option.filter((optionss) => optionss.label.toLowerCase().includes(inputValue.toLowerCase()));
          }
          return option.slice(0, 1000);
        }}
      />
    </FormControl>
  );
}
