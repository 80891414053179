/* eslint-disable no-unused-vars */
/* eslint-disable */
import React, {
  useState, memo, useEffect, useRef,
} from 'react';
import { Box, Grid } from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';
import Drawer from '@mui/material/Drawer';
import CssBaseline from '@mui/material/CssBaseline';
import ClearIcon from '@mui/icons-material/Clear';
import TuneOutlinedIcon from '@mui/icons-material/TuneOutlined';
import StudentFilters from '../filters';
import {
  getAllCourses,
  getLocations,
  getTShirts,
  getAcademicGrades,
  getExtraCurricularActivities,
  addFilterView,
  getAllFilter,
  getAllFilterView,
  getAllStudentsByAdmin,
  getAllStudentsAfterCancel,
  getAllStudents,
  newAddedFilterView,
} from '../../../store/actions/getStudent';
import {
  getHearAboutUs,
  getVolunteers,
  editParentAction,
} from '../../../store/actions/getParent';
import { getStudentPaymentInfo } from '../../../store/actions/getPayment';
import { Buttons, NavigateRoutes } from '../../../constant';
import ParentInfo from '../../register/parent-info/index';
import SwapCourseLocation from '../swap-course-location';
import CancelEnroll from '../cancel-enroll';
import RepeatStatus from '../repeat-status';
import EditStudent from './edit-student/edit';
import EditExamDetails from './editExam/index';
import { getCurrentYear, getMaskedEmail } from '../../../utils/methods';
import { postForgotPassword } from '../../../store/actions/auth';
import { Loader } from '../../../components/atoms';
import ButtonAtom from '../../../components/atoms/button';
import {
  Main,
  AppBar,
  DrawerHeader,
  DrawerWidth,
  getStudentObj,
  getParentInfoObj,
  getParentPayload,
  parentInfoInitialValues,
  SwapCourseDialogFooter,
  getCancelEnrollStudentObj,
  getCancelEditExamDetails,
  CancelCourseDialogFooter,
  RepeatStatusDialogFooter,
  getEditStudentObj,
  EditExamDetailsFooter,
} from './helper';
import Header from './header/header';
import DisplayDialog from './dialogs/dialogs';
import useDispatchHook from '../../../custom-hooks/useDispatch';
import MainViewWithHeader from './main-view-with-header/mainViewWithHeader';
import useDataTableParams from '../../../custom-hooks/useDataTableParams';
import useStudent from '../../../custom-hooks/useStudent';
import { useStyles } from './student-style';
import userRoles from '../../../constant/userRoles';
import { getLocalStorage, setLocalStorage } from '../../../utils/localStorageMethod';
import AssignScore from './assign-score/index';
import {
  getEditExamDetails,
  getParentService,
  getStudentService,
} from '../../../store/services/auth';
import constant from '../../../store/constant';
import decryptedUserRoles from '../../../constant/decryptedUserRoles';

const selectedAcademicYear = getCurrentYear();
function Students() {
  const {
    pageNumber,
    pageLimit,
    nextPage,
    setIsFetching,
    isFetching,
    hasMore,
    handlePageNumberChange,
  } = useDataTableParams();
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);
  const [studentLoading, setStudentLoading] = useState(true);
  const [parentLoading, setParentLoading] = useState(false);
  const [localFilterPayload, setLocalFilterPayload] = useState({});
  const [openMarksPopUp, setOpenMarksPopUp] = useState(false);
  const [dataAssignScore, setDataAssignScore] = useState({});
  const [assignLoading, setAssignLoading] = useState(false);
  const [loadingSpinner, setLoadingSpinner] = React.useState(false);
  const [isSelectAllCheck, setSelectAllCheck] = useState(false);
  const [unselectedStudents, setUnselectedStudents] = useState(false);
  const [source, setSource] = useState('editParent');
  localStorage.setItem('moduleReturn', 'student');
  localStorage.removeItem('userManager', true);

  const { state } = useLocation();

  useDispatchHook([
    getAllFilterView,
    getVolunteers,
    getAllCourses,
    getHearAboutUs,
    getLocations,
    getTShirts,
    getAcademicGrades,
    getExtraCurricularActivities,
  ]);

  React.useEffect(() => {
    setLoading(true);
  }, []);

  const classes = useStyles();
  const { t } = useTranslation();
  const [studentInfo, setStudentInfo] = useState(0);
  const [studentFormError, setStudentFormError] = useState(0);
  const [customForm, setCustomForm] = useState('');
  const [isPaymentDialogOpen, setIsPaymentDialogOpen] = useState(false);
  const [isEditExamDetailsOpen, setIsEditExamDetailsOpen] = useState(false);
  const [isRecoveryDialogOpen, setIsRecoveryDialogOpen] = useState(false);
  const [isCancelEnroll, setCancelEnroll] = useState(false);
  const [isEditExam, setIsEditExam] = useState(false);
  const [isCancelButtonDisable, setCancelButtonDisable] = useState(true);
  const [isEditButtonDisable, setEditButtonDisable] = useState(false);
  const [isParentDialogOpen, setIsParentDialogOpen] = useState(false);
  const [recoverPasswordSuccess, setRecoverPasswordSuccess] = useState(false);
  const [isEditStudentProfile, setEditStudentProfile] = useState(false);
  const [isRepeatStatusOpen, setRepeatStatusOpen] = useState(false);
  const [isSwapCourseDialog, setSwapCourseOrLocation] = useState(false);
  const [isForIncativeStudent, setIsForIncativeStudent] = useState(false);
  const [sortBoth, setSortBoth] = useState(false);
  const [selected, setSelected] = useState([]);
  const [selectedStudentIds, setSelectedStudentIds] = useState([]);
  const refSelectedStudentIds = useRef([]);
  const refUnSelectedStudentIds = useRef([]);
  const [parentInfo, setParentInfo] = useState(parentInfoInitialValues);
  const [searchText, setSearchText] = useState([]);
  const [disableMovestudent, setDisableMovestudent] = useState(true);
  const inputRef = React.useRef(null);
  const hearOptions = useSelector(
    (reduxState) => reduxState.getParent.hearAboutUs,
  );
  const studentFilters = useSelector(
    (reduxState) => reduxState.getStudent.studentFilters,
  );

  const filterOptions = useSelector(
    (reduxState) => reduxState.getStudent.filterOptions,
  );

  const selectedFilter = useSelector(
    (reduxState) => reduxState.getStudent.selectedFilter,
  );

  useEffect(() => {
    const currentYear = getLocalStorage('academicYear');
    const year = selectedFilter.find((item) => !item.label);
    const checkYear = selectedFilter.find((item) => item.label !== currentYear && item.filterKey === 'academic_year');
    if (year || checkYear) {
      const filter = [
        {
          label: currentYear,
          groupBy: 'Academic Year',
          filterKey: 'academic_year',
        },
        {
          label: 'true',
          groupBy: 'Status',
          filterKey: 'status',
        },
      ];
      dispatch({
        type: constant.SET_SELECTED_FILTER,
        payload: filter,
      });
    }
  }, []);

  const filterChanged = useSelector(
    (reduxState) => reduxState.getStudent.filterChanged,
  );

  const expandedLabels = useSelector(
    (reduxState) => reduxState.getStudent.expandedLabels,
  );
  const [filterYear, setFilterYear] = useState('');
  const [filterView, setFilterView] = useState('');
  const [flag1, setFlag1] = useState(false);

  const reduxData = useStudent();
  const [studentsData, setStudents] = useState([]);
  useEffect(() => {
    setStudents(reduxData?.students || []);
  }, [reduxData.students]);
  useEffect(() => {
    let data = {};
    if (selectedFilter) {
      data = {
        filters: selectedFilter.reduce((obj, item) => {
          if (obj[item.filterKey]) {
            obj[item.filterKey].push(item.label);
          } else {
            obj[item.filterKey] = [item.label];
          }
          return obj;
        }, {}),
      };
      if ((data.filters?.created_date?.length > 0) && (data.filters?.reg_week)) {
        delete data.filters?.reg_week;
      }
      if (data?.filters && data?.filters?.status) {
        data.filters.status = [data.filters.status[0] === 'true'];
      } else {
        data.filters.status = [true];
      }
    } else {
      data = {
        filters: {
          academic_year: [getLocalStorage('academicYear')],
          status: [true],
        },
      };
    }
    dispatch(getAllFilter(undefined, true));
    setFlag1(true);
  }, []);

  useEffect(() => {
    const data = {
      filters: selectedFilter.reduce((obj, item) => {
        if (item.filterKey === 'academic_year') {
          localStorage.setItem('academicYear', item.label);
        }
        let value = item.label;
        if ((item.label === 'true') || (item.label === true) || (item.label === 'Active')) {
          value = true;
        } else if ((item.label === 'false') || (item.label === false) || (item.label === 'Inactive')) {
          value = false;
        }
        if (obj[item.filterKey]) {
          obj[item.filterKey] = [...obj[item.filterKey], value];
        } else {
          obj[item.filterKey] = [value];
        }
        return obj;
      }, {}),
    };

    if ((data.filters?.created_date?.length > 0) && (data.filters?.reg_week)) {
      delete data.filters?.reg_week;
    }
    dispatch({
      type: constant.SET_LOADING,
      payload: true,
    });
    dispatch(getAllFilter(data));
  }, [selectedFilter]);

  const getFilteredStudents = (optionsData) => {
    setSelectedStudentIds([]);
    refSelectedStudentIds.current = [];
    refUnSelectedStudentIds.current = [];
    setSelectAllCheck(false);
    setUnselectedStudents([]);
    dispatch({
      type: constant.GET_ALL_STUDENTS,
      payload: [],
    });
    dispatch(newAddedFilterView([]));
    setIsFetching(true);
    const filOpts = optionsData;
    const index = filOpts?.findIndex((op) => op.key === 'academic_year');
    const options = filOpts[index]?.options;
    const isChecked = options?.findIndex((op) => op.checked === true);
    if (isChecked === -1) {
      options[0] = { ...options[0], checked: true };
    }
    const filterPayload = { filters: {}, searchText: [] };
    selectedFilter.forEach((filt) => {
      let value = filt.label;
      if ((filt.label === 'true') || (filt.label === true) || (filt.label === 'Active')) {
        value = true;
      } else if ((filt.label === 'false') || (filt.label === false) || (filt.label === 'Inactive')) {
        value = false;
      }
      if (filterPayload.filters[filt.filterKey]) {
        filterPayload.filters[filt.filterKey] = [...filterPayload.filters[filt.filterKey], value];
      } else {
        filterPayload.filters[filt.filterKey] = [value];
      }
    });
    filterPayload.timePeriod = filterYear;
    filterPayload.searchText.push(searchText);
    if ((filterPayload.filters?.created_date?.length > 0) && (filterPayload.filters?.reg_week)) {
      const filters = selectedFilter.filter((item) => item.filterKey !== 'reg_week');
      delete filterPayload.filters?.reg_week;
    }
    setLocalFilterPayload(filterPayload);
    setIsFetching(true);
    handlePageNumberChange(1);
    localStorage.setItem('collapseListStudent', false);
    if (Object.keys(filterPayload.filters).length === 0) {
      return;
    }
    if (flag1) {
      dispatch(
        getAllStudentsByAdmin(
          1,
          100,
          nextPage,
          setLoading,
          studentsData.length,
          filterPayload,
          setStudentLoading,
        ),
      );
    }
  };

  const filterByYearDropDown = (yearOption) => {
    const payload = { filters: {}, searchText: [] };
    filterOptions.forEach((filterOption) => {
      if (!filterOption?.options?.every((opt) => opt?.checked === false)) {
        const selectedOption = [];
        filterOption?.options?.forEach((option) => {
          if (option.checked) selectedOption.push(option?.value);
        });
        payload.filters[filterOption?.filter] = selectedOption;
      }
    });
    payload.timePeriod = yearOption;
    setIsFetching(true);
    if (Object.keys(payload.filters).length === 0) {
      return;
    }
    if (flag1) {
      dispatch(
        getAllStudentsByAdmin(
          1,
          100,
          nextPage,
          setLoading,
          studentsData.length,
          payload,
          setStudentLoading,
        ),
      );
    }
  };
  const onSetFilterOptions = (fil, refetchFilteredStudent) => {
    dispatch({
      type: constant.SET_FILTER_OPTIONS,
      payload: fil,
    });
    const selectedFil = [];
    fil?.forEach((fi) => {
      fi?.options?.forEach((fiOpt) => {
        if (fiOpt.checked) {
          selectedFil.push({
            label: fiOpt?.label?.toString(),
            groupBy: fi?.label?.toString(),
            filterKey: fi?.filter,
          });
        }
      });
    });
    if (refetchFilteredStudent) {
      getFilteredStudents(fil);
      setSelectedStudentIds([]);
      refSelectedStudentIds.current = [];
      refUnSelectedStudentIds.current = [];
      setSelectAllCheck(false);
      setUnselectedStudents([]);
    }
    const acedemicYears = selectedFil.filter(
      (item) => item.filterKey === 'academic_year',
    );
    const academicYear = acedemicYears[acedemicYears.length - 1];
    const newOptions = [...selectedFil];
    const academicYearIndex = selectedFil?.findIndex(
      (i) => i?.filterKey === 'academic_year',
    );
    const statusData = selectedFil.filter(
      (item) => item.filterKey === 'status',
    );
    const statusIndex = selectedFil.findIndex(
      (item) => item.filterKey === 'status',
    );
    const status = statusData.length ? statusData[statusData.length - 1] : null;
    if (academicYearIndex !== -1) {
      newOptions[academicYearIndex] = academicYear;
    }
    if (status && statusIndex !== -1) {
      newOptions[statusIndex] = status;
    }
  };

  const isInSelectedFilter = (label, filterKey) => {
    const isPresent = selectedFilter.find((item) => (item.label?.toString() === label?.toString())
      && (item.filterKey?.toString() === filterKey?.toString()));
    return isPresent;
  };

  const initialFilterPayload = (isRefresh = false) => {
    if (!isRefresh) {
      const regWeek = selectedFilter.filter((item) => item.filterKey === 'reg_week');
      const createdDate = selectedFilter.filter((item) => item.filterKey === 'created_date');
      const fil = studentFilters?.map((filterOpt) => {
        const options = filterOpt?.options?.map((op) => {
          let checked = !!(
            (filterOpt.filter === 'academic_year'
              && op[filterOpt.key] === selectedAcademicYear.id)
            || (filterOpt.filter === 'enrolled_status'
              && op[filterOpt.key] === true)
            || (filterOpt.filter === 'course_status'
              && op[filterOpt.key] === true)
          );
          if (filterOpt.filter === 'academic_year') {
            checked = !!(filterOpt.filter === 'academic_year');
          } else {
            let keyValue = op[filterOpt.key]?.toString();
            if (!keyValue) {
              keyValue = op?.name?.toString();
            }
            checked = isInSelectedFilter(op[filterOpt.key], filterOpt.filter) ? true : checked;
          }
          return {
            label: (op[filterOpt.key] === true || op[filterOpt.key] === false || op[filterOpt.key]) ? op[filterOpt.key] : op.name,
            count: op.count,
            checked,
            value: (op[filterOpt.key] === true || op[filterOpt.key] === false || op[filterOpt.key]) ? op[filterOpt.key] : op.name,
            filterKey: filterOpt.filter,
          };
        });
        return {
          ...filterOpt,
          expanded: expandedLabels.includes(filterOpt.label),
          options,
          value: filterOpt.label,
        };
      });

      const newIndex = fil?.findIndex((o) => o.key === 'isActive');
      const optionsData = fil?.find((o) => o.key === 'isActive')?.options;

      if (optionsData?.length) {
        const data = optionsData?.map((i) => {
          if (i.label === true || i.label === 'Active') {
            return { ...i, checked: true };
          }
          return i;
        });
        fil[newIndex].options = data;
      }

      dispatch({
        type: constant.SET_FILTER_OPTIONS,
        payload: fil,
      });
      if (fil?.length > 0) {
        onSetFilterOptions(fil, true);
      }
    }
    if (isRefresh) {
      if (filterOptions?.length > 0) {
        onSetFilterOptions(filterOptions, true);
      }
    }
  };
  React.useEffect(() => {
    initialFilterPayload();
  }, [studentFilters]);

  useEffect(() => {
    getFilteredStudents(filterOptions);
  }, [searchText, filterYear]);

  React.useEffect(() => {
    setUnselectedStudents([]);
    setSelectedStudentIds([]);
    refSelectedStudentIds.current = [];
    refUnSelectedStudentIds.current = [];
  }, [filterOptions, searchText]);

  const [selectedRecord, setSelectedRecord] = useState({});
  const navigate = useNavigate();
  const [swapCourseLocationInfo, setCourseLocationInfo] = useState({
    studentId: '',
    studentName: '',
    academicYear: '',
    courseFrom: '',
    courseTo: '',
    locationFrom: '',
    locationTo: '',
    sectionFrom: '',
    sectionTo: '',
    changeLogs: '',
  });
  const [cancelEnrollInfo, setCancelEnrollInfo] = useState({
    studentId: '',
    studentName: '',
    academicYear: '',
    course: '',
    location: '',
    changeLogs: '',
  });
  const [repeatStatusInfo, setRepeatStatusInfo] = useState({
    studentName: '',
    academicYear: '',
    actualResult: '',
    newResult: '',
    course: '',
    location: '',
    changeLogs: '',
  });
  const [editExamInfo, setEditExamInfo] = useState({
    studentId: '',
    studentName: '',
    parentName: '',
    academicYear: '',
    selectCourse: '',
    enrolledCourseId: '',
    hallTicketNumber: '',
    exam_center: '',
  });
  const encryptedUserRole = getLocalStorage('userRole');
  const userRole = decryptedUserRoles[encryptedUserRole];

  const refreshList = () => {
    if (userRoles.SYSTEM_ADMIN === userRole || userRole === userRoles.SUPER_USER) {
      dispatch(
        getAllStudentsAfterCancel(
          1,
          100,
          nextPage,
          setLoading,
          localFilterPayload,
        ),
      );
      initialFilterPayload();
    } else {
      dispatch(getAllStudents());
    }
  };

  useEffect(() => {
    const localStorageText = localStorage.getItem('studentSearchText') || '';
    if (searchText.length === 0 && (localStorageText !== '' && localStorageText !== null)) {
      const searchFilter = selectedFilter?.filter((obj) => obj.label === localStorageText);
      if (searchFilter?.length === 0) {
        selectedFilter.push({
          label: localStorageText,
          groupBy: localStorageText,
          filterKey: localStorageText,
        });
      }
      setLoading(true);
      setSearchText(localStorageText);
    }
  }, []);

  const refreshStudentsData = () => {
    initialFilterPayload(true);
  };

  const onUpdateParentProfile = (reqPayload) => {
    const hearOptSelected = [];
    reqPayload?.hearAboutUs?.forEach((opt) => {
      const temp = _.find(hearOptions, { name: opt });
      hearOptSelected.push(temp?.name);
    });
    const parentPayload = getParentPayload(reqPayload, hearOptSelected);
    const currentYear = getLocalStorage('academicYear');
    const addressPayload = {
      addresses: {
        address: reqPayload?.homeAddress,
        aptSuite: reqPayload?.aptSuite,
        latitude: Number(reqPayload?.homeAddressInfo?.lat),
        longitude: Number(reqPayload?.homeAddressInfo?.lng),
      },
    };
    const payload = {
      parent1: parentPayload.parent1,
      address: addressPayload.addresses,
      logMessage: parentPayload?.changeLog,
      academicYear: currentYear,
      isStudentDashboard: true,
    };
    const parent2 = Object.values(parentPayload?.parent2?.user);
    const result = parent2?.filter((i) => i !== undefined && i !== '');
    if (result?.length) {
      payload.parent2 = parentPayload.parent2;
    }
    setIsParentDialogOpen(false);
    dispatch(
      editParentAction(
        selectedRecord?.id,
        payload,
        setParentLoading,
        refreshStudentsData,
      ),
    );
  };

  const onSwapCourseOrLocation = (flag) => {
    setCourseLocationInfo({});
    setSwapCourseOrLocation(flag);
  };

  const renderRecoveryContent = () => (
    <Grid>
      <Grid item xs={12} className={classes.recoveryContent}>
        {t('SEND_RECOVERY')}
      </Grid>
      <Grid item xs={12} className={classes.dialogButtons}>
        <ButtonAtom
          name={t('CANCEL')}
          onClick={() => setIsRecoveryDialogOpen(false)}
          btntype={Buttons.SECONDARY}
        />
        <ButtonAtom
          name={t('CONFIRM')}
          onClick={() => setRecoverPasswordSuccess(true)}
          btntype={Buttons.PRIMARY}
        />
      </Grid>
    </Grid>
  );

  const handleRecoverDialog = () => {
    const initialValues = {
      email: selectedRecord?.studentInfo?.manabadiEmail,
    };
    dispatch(postForgotPassword(initialValues));
    setRecoverPasswordSuccess(false);
    setIsRecoveryDialogOpen(false);
  };

  const renderSuccessContent = () => (
    <Grid>
      <Grid item xs={10} className={classes.dialogContent}>
        <h1 className={classes.dialogHeader}>{t('RECOVER_PASSWORD_EMAIL')}</h1>
        {t('PASSWORD_RESET_LINK', {
          email: getMaskedEmail(selectedRecord?.parent1Info?.personalEmail),
        })}
      </Grid>
      <Grid container className={classes.content} justifyContent="flex-end">
        <ButtonAtom
          name={t('GOT_IT')}
          onClick={handleRecoverDialog}
          btntype={Buttons.SECONDARY}
        />
      </Grid>
    </Grid>
  );

  const onClickMenu = (student) => {
    const studentObj = getStudentObj(student);
    setCourseLocationInfo(studentObj);
    setSelectedRecord(student);
    setRepeatStatusInfo(studentObj);
  };

  const onClickCancelEnrollMenu = (student) => {
    setCancelEnroll(true);
    const studentObj = getCancelEnrollStudentObj(student);
    const currentTime = getCurrentYear()?.id?.split('-');
    const yearData = studentObj?.acedemicYear?.split('-');
    if (
      yearData?.length > 0
      && Number(yearData[1]) < Number(currentTime[1])
    ) {
      setIsForIncativeStudent(false);
      setCancelButtonDisable(true);
    } else if (studentObj?.isActive === false) {
      setIsForIncativeStudent(true);
      setCancelButtonDisable(true);
    } else {
      setCancelButtonDisable(false);
    }
    setCancelEnrollInfo(studentObj);
    setSelectedRecord(student);
  };

  const onClickEditExamDetails = (student) => {
    setIsEditExam(true);
    setIsEditExamDetailsOpen(true);
    setEditButtonDisable(false);
    const payload = {
      studentId: student.id,
      enrolledCourseId: student?.enrolled_courses.id,
    };
    getEditExamDetails(payload)
      .then((res) => {
        const { data } = res;
        data.studentId = student.id;
        data.enrolledCourseId = student?.enrolled_courses.id;
        const studentObject = getCancelEditExamDetails(data);
        const currentTime = getCurrentYear()?.id?.split('-');
        const yearData = studentObject?.academicYear?.split('-');
        if (
          yearData?.length > 0
          && Number(yearData[1]) < Number(currentTime[1])
        ) {
          setEditButtonDisable(true);
        } else {
          setEditButtonDisable(false);
        }
        setEditExamInfo(studentObject);
      })
      .catch(() => {
      });

    setSelectedRecord(student);
  };

  const onEditMenuClick = (student) => {
    setStudentInfo(0);
    const payload = {
      studentId: student?.id,
      enrolledCourseId: student?.enrolled_courses?.id,
    };
    getStudentService(payload)
      .then((res) => {
        const studentObj = getEditStudentObj(res?.data);
        setSelectedRecord(res?.data);
        setStudentInfo({ s1: studentObj });
      })
      .catch(() => {
      });
  };

  const paymentDialogOpen = (rowInfo) => {
    const payload = {
      studentId: rowInfo?.id,
      enrolledCourseId: rowInfo?.enrolled_courses?.id,
    };
    dispatch(getStudentPaymentInfo(payload, setIsPaymentDialogOpen));
  };

  const editExamDetailsOpen = () => {
    setIsEditExamDetailsOpen(true);
  };

  const onUpdateParentClick = async (selectedRow) => {
    getParentService(selectedRow?.id).then(async (res) => {
      setSelectedRecord({ ...res?.data, userId: selectedRow.userId });
      const info = await getParentInfoObj(res?.data);
      setParentInfo(info);
      if (parentInfo) {
        setIsParentDialogOpen(true);
      }
    });
  };

  const handleMoveStudent = () => {
    customForm.handleSubmit();
  };

  const handleRepeatStudent = () => {
    customForm.handleSubmit();
    setLoading(false);
  };

  const handleMoveStudentLocation = () => {
    customForm.handleSubmit();
  };

  const viewLogs = (onClose) => {
    onClose(false);
    navigate(NavigateRoutes.STUDENTS_LOGS, {
      state: {
        id: _.get(selectedRecord, 'userId', ''),
        lastRoute: NavigateRoutes.STUDENTS_VIEW,
      },
    });
  };

  const [open, setOpen] = useState(false);
  const handleDrawer = () => {
    setOpen(!open);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const onAddView = (title, filters, clearTitleAndError, setTitleError) => {
    const queryArray = [];
    filters.forEach((fil) => {
      fil.options.forEach((filOp) => {
        if (filOp.checked) {
          queryArray.push({
            label: filOp.label.toString(),
            groupBy: fil.label.toString(),
            filterKey: fil.filter,
          });
        }
      });
    });
    dispatch(
      addFilterView(
        {
          queryData: JSON.stringify(queryArray),
          name: title,
        },
        clearTitleAndError,
        setTitleError,
      ),
    );
  };

  const parentInfoComponent = (
    <ParentInfo
      isEditParentDialog
      setSource={setSource}
      setParentInfo={setParentInfo}
      changeLog
      parentInfo={parentInfo}
      loading={parentLoading}
      setLoading={setParentLoading}
      source={source}
      primaryButton={t('SAVE_PARENT_INFO')}
      onCancel={() => setIsParentDialogOpen(false)}
      onFormSubmit={onUpdateParentProfile}
      onViewLogs={() => navigate(NavigateRoutes.STUDENTS_LOGS, {
        state: {
          id: _.get(selectedRecord, 'userId', ''),
          lastRoute: NavigateRoutes.STUDENTS_VIEW,
        },
      })}
      isStudentDashboard={true}
    />
  );

  const swapCourseDialogFooter = (
    <SwapCourseDialogFooter
      classes={classes}
      t={t}
      primaryHandle={handleMoveStudentLocation}
      secHandle={() => onSwapCourseOrLocation(false)}
      viewLogs={viewLogs}
      disableMovestudent={disableMovestudent}
    />
  );

  const swapCourseLocation = (
    <SwapCourseLocation
      refreshStudentsData={refreshStudentsData}
      {...{
        setCourseLocationInfo,
        swapCourseLocationInfo,
        setCustomForm,
        setSwapCourseOrLocation,
      }}
      isChangeLogVisible={userRole === userRoles.SYSTEM_ADMIN || userRole === userRoles.REGION_COORDINATOR || userRole === userRoles.SUPER_USER}
      setDisableMovestudent={setDisableMovestudent}
    />
  );

  const cancelEnrollFooter = (
    <CancelCourseDialogFooter
      isCancelEnroll
      {...{
        customForm,
      }}
      classes={classes}
      t={t}
      disable={isCancelButtonDisable}
      primaryHandle={handleMoveStudent}
      secHandle={() => setCancelEnroll(false)}
      viewLogs={viewLogs}
    />
  );
  const cancelEnrollContent = (
    <CancelEnroll
      {...{
        refreshList,
        isCancelButtonDisable,
        setCustomForm,
        setCancelEnrollInfo,
        cancelEnrollInfo,
        setCancelEnroll,
        isForIncativeStudent,
      }}
    />
  );

  const editExamStudentDetails = (
    <EditExamDetails
      {...{
        refreshList,
        editExamInfo,
        setEditExamInfo,
        setIsEditExamDetailsOpen,
        isEditExamDetailsOpen,
        setIsEditExam,
        setCustomForm,
        isEditButtonDisable,
      }}
    />
  );

  const editExamDetailsFooter = (
    <EditExamDetailsFooter
      isCancelEnroll
      {...{
        customForm,
        isEditButtonDisable,
      }}
      classes={classes}
      t={t}
      primaryHandle={handleMoveStudent}
      secHandle={() => setIsEditExamDetailsOpen(false)}
      viewLogs={viewLogs}
    />
  );

  const editRepeatStatusContent = (
    <RepeatStatus
      {...{
        repeatStatusInfo,
        setRepeatStatusOpen,
        refreshList,
        setCustomForm,
      }}
    />
  );

  const editRepeatStatusFooter = (
    <RepeatStatusDialogFooter
      isRepeatStatusOpen
      {...{
        customForm,
      }}
      classes={classes}
      t={t}
      primaryHandle={handleRepeatStudent}
      secHandle={() => setCancelEnroll(false)}
      viewLogs={viewLogs}
    />
  );

  const editStudentContent = (
    <EditStudent
      setDialogOpen={setEditStudentProfile}
      refreshStudentsData={refreshStudentsData}
      isStudentDashboard={true}
      {...{
        classes,
        viewLogs,
        studentInfo,
        setStudentInfo,
        studentFormError,
        setStudentFormError,
        setEditStudentProfile,
        selectedRecord,
      }}
    />
  );

  return (
    <Box className={`${classes.gridContainer} ${classes.paddingRemove}`}>
      {!loading && (
        <Grid container>
          <Grid item lg={0.2} />
          <Grid item xs={12} lg={12} className={classes.filterSection}>
            <Box sx={{ display: 'flex' }}>
              <CssBaseline />
              <AppBar position="fixed" open={open} />
              <Drawer
                sx={{
                  width: DrawerWidth,
                  flexShrink: 0,
                  '& .MuiDrawer-paper': {
                    width: DrawerWidth,
                    boxSizing: 'border-box',
                  },
                }}
                variant="persistent"
                anchor="left"
                open={open}
              >
                <DrawerHeader>
                  <div>
                    <TuneOutlinedIcon className={classes.filterIcon} />
                    <span
                      style={{
                        verticalAlign: 'top',
                        marginLeft: '0.8vw',
                      }}
                    >
                      {t('SEARCH_BY_FILTERS')}
                    </span>
                    <ClearIcon
                      onClick={handleDrawerClose}
                      style={{
                        marginLeft: '2vw',
                      }}
                      className={classes.clearIcon}
                    />
                  </div>
                </DrawerHeader>
                <StudentFilters
                  setFilterOptions={onSetFilterOptions}
                  {... {
                    filterOptions,
                    onAddView,
                    setSortBoth,
                    sortBoth,
                    setSelected,
                    selected,
                    setSelectAllCheck,
                    setFilterView,
                    setFilterYear,
                    setUnselectedStudents,
                    unselectedStudents,
                  }}
                  setSelectAllCheck={setSelectAllCheck}
                />
              </Drawer>
              <Main open={open} className={classes.mainSection}>
                <Header
                  setSelectAllCheck={setSelectAllCheck}
                  {...{
                    handleDrawer,
                    setFilterYear,
                    selectedFilter,
                    filterOptions,
                    setSearchText,
                    setFilterView,
                    searchText,
                    setSortBoth,
                    sortBoth,
                    setSelected,
                    filterByYearDropDown,
                    open,
                    inputRef,
                    setUnselectedStudents,
                    unselectedStudents,
                  }}
                />
                <MainViewWithHeader
                  {...{
                    filterView,
                    newAddedFilterView,
                    filterYear,
                    setFilterView,
                    setFilterYear,
                    isSelectAllCheck,
                    setSelectAllCheck,
                    studentLoading,
                    filterByYearDropDown,
                    pageNumber,
                    pageLimit,
                    nextPage,
                    setIsFetching,
                    isFetching,
                    hasMore,
                    filterOptions,
                    onUpdateParentClick,
                    paymentDialogOpen,
                    editExamDetailsOpen,
                    onClickMenu,
                    onClickCancelEnrollMenu,
                    onClickEditExamDetails,
                    onEditMenuClick,
                    setEditStudentProfile,
                    setSwapCourseOrLocation,
                    setSelectedRecord,
                    setIsRecoveryDialogOpen,
                    setCancelEnroll,
                    setIsEditExam,
                    setLoading,
                    localFilterPayload,
                    selectedFilter,
                    setOpenMarksPopUp,
                    setDataAssignScore,
                    setAssignLoading,
                    setLoadingSpinner,
                    loadingSpinner,
                    setSortBoth,
                    sortBoth,
                    setSelected,
                    selected,
                    selectedStudentIds,
                    setSelectedStudentIds,
                    refSelectedStudentIds,
                    setRepeatStatusOpen,
                    setUnselectedStudents,
                    unselectedStudents,
                    refUnSelectedStudentIds,
                  }}
                />
              </Main>
            </Box>
          </Grid>
        </Grid>
      )}
      <DisplayDialog
        {...{
          isSwapCourseDialog,
          onSwapCourseOrLocation,
          swapCourseDialogFooter,
          cancelEnrollFooter,
          editExamDetailsFooter,
          cancelEnrollContent,
          editExamStudentDetails,
          setCancelEnroll,
          setIsEditExam,
          swapCourseLocation,
          isPaymentDialogOpen,
          setIsPaymentDialogOpen,
          isEditExamDetailsOpen,
          setIsEditExamDetailsOpen,
          isRecoveryDialogOpen,
          isCancelEnroll,
          isEditExam,
          isParentDialogOpen,
          setEditStudentProfile,
          parentInfoComponent,
          isEditStudentProfile,
          editStudentContent,
          recoverPasswordSuccess,
          renderSuccessContent,
          renderRecoveryContent,
          setIsRecoveryDialogOpen,
          setIsParentDialogOpen,
          isRepeatStatusOpen,
          setRepeatStatusOpen,
          editRepeatStatusContent,
          editRepeatStatusFooter,
        }}
      />
      <AssignScore
        openMarksPopUp={openMarksPopUp}
        setOpenMarksPopUp={setOpenMarksPopUp}
        dataAssignScore={dataAssignScore}
        assignLoading={assignLoading}
        setLoadingSpinner={setLoadingSpinner}
        setDataAssignScore={setDataAssignScore}
      />
      {loading && (
        <Grid>
          <Loader message={t('LOADING')} />
        </Grid>
      )}
    </Box>
  );
}

export default memo(Students);
