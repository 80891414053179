import React, { useEffect, useState } from 'react';
import {
  Box,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  FormControl,
} from '@mui/material';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Loader, TextField } from '../../../components/atoms';
import { getDonations } from '../../../store/actions/getPayment';
import useStyles from '../../../custom-hooks/useStyles';
import style from './style';
import { setLocalStorage } from '../../../utils/localStorageMethod';
import { createLinkService } from '../../../store/services/auth';
import ButtonAtom from '../../../components/atoms/button';
import { Buttons } from '../../../constant';
import { calculateTotalFee, calculateTotalPayment, roundedValue } from '../../../utils/methods';
import usePayment from '../../../custom-hooks/usePayment';
import DropdownWithLabel from '../../../components/atoms/dropdown-with-label';
import CheckboxAtom from '../../../components/atoms/checkbox';
import useStudent from '../../../custom-hooks/useStudent';

function EnrollPayment({
  enrollStudentData, loading,
  setLoading,
}) {
  const adminData = useStudent();
  const { termsSettingDetails } = adminData;
  const [contributionCause, setContributionCause] = useState('');
  const returnJSXFragment = (tag) => tag;
  const [contributionAmount, setContributionAmount] = useState('0.00');
  const [termsConditionsMessage, setTermsConditionsMessage] = useState('');
  const [iAccept, setIsAccept] = useState(false);
  const [disabled, setDisabled] = useState(true);
  const [ischeckBoxError, setCheckBoxError] = useState(false);
  const [contributionError, setContributionError] = useState('');
  const { t } = useTranslation('translation');
  const classes = useStyles(style)();
  const SuccesStatus = localStorage.getItem('paymentSuccess');
  const setIAcceptCheckBox = () => {
    setIsAccept(!iAccept);
    setCheckBoxError(false);
  };

  useEffect(() => {
    let htmlString = termsSettingDetails?.message;
    const stringToRemoveWhiteBackground = 'style="background-color: rgb(100.000000%, 100.000000%, 100.000000%)"';
    const stringToRemoveWhiteBackground2 = 'background-color: rgb(255, 255, 255);';
    const stringToRemoveTitle = 'Terms And Conditions:';
    const stringToRemoveTitle2 = 'Terms and Conditions:';
    const stringToRemoveTitle3 = 'Terms &amp; Conditions:';

    let replaced = true;
    while (replaced) {
      replaced = false;
      if (htmlString?.includes(stringToRemoveWhiteBackground)) {
        htmlString = htmlString.replace(stringToRemoveWhiteBackground, '');
        replaced = true;
      }
      if (htmlString?.includes(stringToRemoveWhiteBackground2)) {
        htmlString = htmlString.replace(stringToRemoveWhiteBackground2, '');
        replaced = true;
      }
      if (htmlString?.includes(stringToRemoveTitle)) {
        htmlString = htmlString.replace(stringToRemoveTitle, '');
        replaced = true;
      }
      if (htmlString?.includes(stringToRemoveTitle2)) {
        htmlString = htmlString.replace(stringToRemoveTitle2, '');
        replaced = true;
      }
      if (htmlString?.includes(stringToRemoveTitle3)) {
        htmlString = htmlString.replace(stringToRemoveTitle3, '');
        replaced = true;
      }
    }
    setTermsConditionsMessage(htmlString);
  }, [termsSettingDetails?.message]);

  const setTitle = (title) => (
    <Grid container className={classes.termsText}>
      <Grid item xs={12}>
        {title}
      </Grid>
    </Grid>
  );

  const getErrorText = () => (ischeckBoxError ? (
    <div className={`${classes.error} ${classes.errorTextPayment}`}>
      {t('ACCEPT_TERMS_AND_CONDITION')}
    </div>
  ) : (
    null
  ));

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getDonations());
  }, []);
  const paymentData = usePayment();
  const donationOptions = [{
    name: ' ', id: 0,
  }].concat(paymentData?.donations);

  const showError = (e) => {
    if (e.target.value !== 0 && Number(contributionAmount) === 0) {
      setContributionError('');
    } else {
      setContributionError('');
    }
  };

  useEffect(() => {
    const a = localStorage.getItem('paymentSuccess');
    if (a === 'success') {
      localStorage.removeItem('paymentSuccess');
      window.location.reload(true);
    }
  }, [localStorage.getItem('paymentSuccess'), SuccesStatus]);

  const handleSubmit = async () => {
    localStorage.removeItem('enrolling');
    const selectedType = donationOptions.find((co) => co.id === contributionCause);
    if ((selectedType?.name && Number(contributionAmount) > 0) || !selectedType?.name) {
      setContributionError('');
      setLoading(true);
      try {
        const redBody = {
          registrationId: enrollStudentData?.registrationId,
          donation: {
            type: selectedType === undefined ? 'N/A' : selectedType.name,
            amount: Number(contributionAmount),
          },
          paymentFor: 'enroll',
          isDonation: false,
        };
        createLinkService(redBody)
          .then((response) => {
            if (response?.success && response?.data?.approveLink) {
              setLocalStorage('orderId', response?.data?.orderId);
              setLocalStorage('registrationId', enrollStudentData?.registrationId);
              setLocalStorage('paymentFor', 'enroll');
              window.location.assign(response?.data?.approveLink);
              setLoading(false);
            }
          })
          .catch(() => {
            setLoading(false);
          });
      } catch (err) {
        // console.log(err);
      }
    } else if (contributionCause && Number(contributionAmount) <= 0) {
      setContributionError(t('AMOUNT_GREATER_THAN_ZERO'));
    } else if (!contributionCause) {
      setContributionError('');
    } else {
      setContributionError('');
    }
  };

  const getStudentsData = () => {
    const existingStudents = enrollStudentData?.students || [];
    const newStudents = enrollStudentData?.newStudents || [];
    return [...existingStudents, ...newStudents];
  };
  useEffect(() => {
    if (contributionCause && Number(contributionAmount) === 0) {
      setContributionError(t('AMOUNT_GREATER_THAN_ZERO'));
      setDisabled(true);
    } else {
      setContributionError('');
      if ((contributionCause && Number(contributionAmount) > 0) || (!contributionCause && Number(contributionAmount) === 0)) {
        setDisabled(false);
      } else {
        setDisabled(true);
      }
    }
  }, [contributionCause, contributionAmount]);

  return (
    <Box className={classes.gridContainer} sx={{ flexGrow: 1 }}>
      <Grid className={classes.tableView}>
        <TableContainer>
          <Table sx={12} aria-label="simple table">
            <TableHead className={classes.tableheadBg}>
              <TableRow>
                <TableCell align="left" colSpan={3}>Student Name</TableCell>
                <TableCell align="left" colSpan={3}>Class</TableCell>
                <TableCell align="right" colSpan={2}>Course Fee</TableCell>
                <TableCell align="right" colSpan={3}>Registration Fee</TableCell>
                <TableCell align="right" colSpan={2}>PSTU Fee</TableCell>
                <TableCell align="right" colSpan={2}>Discount</TableCell>
                <TableCell align="right" colSpan={2}>Total Fee</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {getStudentsData()?.map((row, index) => (
                <TableRow
                  key={index}
                  sx={{
                    '&:last-child td, &:last-child th': { borderBottom: 1, borderTop: 1, borderBlockColor: '#e0e0e0' },
                  }}
                >
                  <TableCell component="th" scope="row" align="left" colSpan={3}>
                    {row?.user?.firstName}
                    {' '}
                    {row?.user?.lastName}
                  </TableCell>
                  <TableCell align="left" colSpan={3}>{row?.enrollmentCourse?.courseName}</TableCell>
                  <TableCell
                    align="right"
                    colSpan={2}
                    sx={{
                      '&:last-child td,': {
                        '@media (max-width: 1199px)': {
                          padding: '0px !important',
                        },
                      },
                    }}
                  >
                    $
                    {' '}
                    {row?.enrollmentCourse?.feeStructure?.fee?.toFixed(2)}
                  </TableCell>
                  <TableCell
                    align="right"
                    colSpan={3}
                    sx={{
                      '&:last-child td,': {
                        '@media (max-width: 1199px)': {
                          padding: '0px !important',
                        },
                      },
                    }}
                  >
                    +$
                    {' '}
                    {row?.enrollmentCourse?.feeStructure?.registrationFee?.toFixed(2)}
                  </TableCell>
                  <TableCell
                    align="right"
                    colSpan={2}
                    sx={{
                      '&:last-child td,': {
                        '@media (max-width: 1199px)': {
                          padding: '0px !important',
                        },
                      },
                    }}
                  >
                    +$
                    {' '}
                    {row?.enrollmentCourse?.feeStructure?.examFee?.toFixed(2)}

                  </TableCell>
                  <TableCell align="right" colSpan={2} className={classes.discount}>
                    -$
                    {' '}
                    {row?.enrollmentCourse?.feeStructure?.discount ? row?.enrollmentCourse?.feeStructure?.discount.toFixed(2) : '0.00'}

                  </TableCell>
                  <TableCell align="right" component="th" scope="row" colSpan={2}>
                    $
                    {' '}
                    {row?.enrollmentCourse?.feeStructure?.total?.toFixed(2)}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
            <TableHead className={classes.tableheadColor}>
              <TableRow>
                <TableCell colSpan={15} align="right">
                  Total Fee
                </TableCell>
                <TableCell align="right" colSpan={3}>
                  {`$ ${calculateTotalFee(getStudentsData())}`}
                </TableCell>
              </TableRow>
            </TableHead>
            <TableHead className={classes.tableheadColor}>
              <TableRow>
                <TableCell colSpan={7} align="right" />
                <TableCell colSpan={8} align="right">
                  <FormControl fullWidth className={classes.contributionCause}>
                    <DropdownWithLabel
                      type="text"
                      labelId="contributionCause"
                      id="contributionCause"
                      name="contributionCause"
                      value={contributionCause}
                      longLabel
                      label={t('CONTRIBUTION_CAUSE')}
                      handleChange={(e) => {
                        if (e.target.value === 0) {
                          e.target.value = '';
                        }
                        if (!e.target.value) {
                          setContributionAmount('0.00');
                        }
                        setContributionCause(e.target.value);
                        showError(e);
                      }}
                      options={donationOptions}
                    />
                  </FormControl>
                </TableCell>
                <TableCell align="center" colSpan={3} style={{ minWidth: '88px' }}>
                  <FormControl fullWidth className={classes.contributionAmount}>
                    <TextField
                      label={(contributionCause) ? t('$ *') : t('$')}
                      id="contributionAmount"
                      value={contributionAmount}
                      type="number"
                      disable={!(contributionCause)}
                      inputProps={{ min: 0, max: 6 }}
                      onChange={(e) => {
                        if (e.target.value <= 0 || e.target.value === '') {
                          setContributionAmount(e.target.value);
                        } else {
                          const fixed = roundedValue(e.target.value);
                          e.target.value = fixed;
                          setContributionAmount(Number(fixed)?.toFixed(2)); setContributionAmount(e.target.value);
                        }
                      }}
                      onBlur={
                        (e) => {
                          if (e.target.value <= 0 || e.target.value === '') {
                            setContributionAmount('0.00');
                            setContributionError(t('AMOUNT_GREATER_THAN_ZERO'));
                          } else {
                            const fixed = e.target.value;
                            setContributionAmount(Number(fixed)?.toFixed(2));
                            setContributionError('');
                          }
                        }
                      }
                    />
                  </FormControl>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableHead className={classes.totalPaymentRow}>
              <TableRow>
                <TableCell colSpan={15} align="right">
                  Total Payable
                </TableCell>
                <TableCell align="right">
                  {`$ ${calculateTotalPayment(getStudentsData(), contributionAmount)}`}
                </TableCell>
              </TableRow>
            </TableHead>
          </Table>
        </TableContainer>
      </Grid>
      <div className={classes.footerBottom} />
      <Grid container>
        <Grid container className={classes.boxDiv}>
          <p>
            <span className={classes.titleTerms}>
              <strong>Terms &amp; Conditions</strong>
            </span>
          </p>
          <ul className={classes.list}>
            <div
              // eslint-disable-next-line react/no-danger
              dangerouslySetInnerHTML={{ __html: returnJSXFragment(termsConditionsMessage) }}
            />
          </ul>
          <div className={classes.footerBottom2} />
          <Grid item xs={1} className={classes.acceptCheckbox}>
            <CheckboxAtom
              label={setTitle(t('TERMS_AND_CONDITIONS.I_ACCEPT'))}
              id="acceptCheckbox"
              checked={iAccept}
              handleChange={setIAcceptCheckBox}
            />
            {getErrorText()}
          </Grid>
        </Grid>
        <Grid container justifyContent="flex-end">
          <Grid xs={12} className={classes.paymentStudent}>
            <span className={classes.errorText}>{contributionError}</span>
            <ButtonAtom
              disabled={!iAccept || disabled}
              name={t('CONTINUE_TO_PAYMENT')}
              btntype={Buttons.PRIMARY}
              onClick={handleSubmit}
            />
          </Grid>
        </Grid>
      </Grid>
      {loading && (
        <Grid>
          <Loader message={t('LOADING')} />
        </Grid>
      )}
    </Box>
  );
}

export default EnrollPayment;
