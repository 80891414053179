/* eslint-disable */
import React, { useEffect, useState } from 'react';
import {
  Box,
  Grid,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { formatPhoneNumberIntl } from 'react-phone-number-input';
import useStyles from '../../../custom-hooks/useStyles';
import style from './style';
import EditParent from '../../register/parent-info';
import {
  getNextEnrollCourseAdmin,
} from '../../../store/actions/getStudent';
import { getFeeStructureForEnroll } from '../../../store/actions/getPayment';
import { Button } from '../../../components/atoms';
import { Buttons } from '../../../constant';
import { getCurrentYear, removeSpecialChar } from '../../../utils/methods';

import EditStudent from '../../admin-user/students/edit-student/edit';
import StudentInfo from '../../register/student-info';
import DialogAtom from '../../../components/atoms/dialog';
import EnrollPayment from './enroll-payment';
import useStudent from '../../../custom-hooks/useStudent';
import { gerParentPayload, getPrimaryStudentPayload, getStudent } from './helper';
import { getLocalStorage, setLocalStorage } from '../../../utils/localStorageMethod';

function DisplayEditStudent({
  selectedStudent,
  isSibling,
  setStudentData,
  classes,
  setFormikControl,
  parentInfo,
  enrollCourseList,
  conscent,
  isPrimary,
  isAdminUser,
  placeSelected,
  checkClassLevel,
  SiblingsData,
}) {
  return (
    <Grid container justifyContent="flex-start">
      <Grid item xs={12}>
        <EditStudent
          isEnrollStudent
          conscent={conscent}
          isPrimary={isPrimary}
          isSibling={isSibling}
          studentInfo={selectedStudent}
          editStudentClasses={classes}
          setStudentData={setStudentData}
          setFormikControl={setFormikControl}
          parentInfo={parentInfo}
          enrollCourseList={enrollCourseList}
          isAdminUser={isAdminUser}
          placeSelected={placeSelected}
          isStudentDashboard={true}
          checkClassLevel={checkClassLevel}
          SiblingsData={SiblingsData}
        />
      </Grid>
    </Grid>
  );
}

export default function EnrollStudent({
  showAddSibling,
  setShowAddSibling,
  studentAccountDetails,
}) {
  const { t } = useTranslation();
  const [parentLoading, setParentLoading] = useState(false);
  const [checkClassLevel, setCheckClassLevel] = useState(false);
  const [studentLoading, setStudentLoading] = useState(false);
  const [isPaymentDialogOpen, setIsPaymentDialogOpen] = useState(false);
  const [isParentValid, setParentValid] = useState(false);
  const [parentFormik, setParentFormik] = useState(false);

  const [primaryStudentFormikControl, setPrimaryStudentFormikControl] = useState('');

  const [siblingFormikControl, setSiblingFormikControl] = useState({});
  const [siblingData, setSiblingData] = useState([]);
  const [primaryStudentData, setPrimaryStudentData] = useState(false);
  const [loading, setLoading] = useState(false);
  const selectedStudentData = useStudent();
  const isAdminUser = getLocalStorage('isAdminUser');
  const [selectedStudent] = useState(
    studentAccountDetails?.studentData?.find((stu) => stu.primaryStudent),
  );
  const [placeSelected, setPlaceSelected] = useState(0);
  const dispatch = useDispatch();
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [openSnackbar2, setOpenSnackbar2] = useState(false);
  const [openSnackbarEnroll, setOpenSnackbarEnroll] = useState(false);

  const handleClickSnackbar = () => {
    setOpenSnackbar(true);
  };

  const handleClickSnackbar2 = () => {
    setOpenSnackbar2(true);
  };

  const handleClickSnackbarEnroll = () => {
    setOpenSnackbarEnroll(true);
  };

  const handleClose = () => {
    setIsPaymentDialogOpen(false);
  };

  React.useEffect(() => {
    if (isAdminUser) {
      const payload = { id: selectedStudent?.id, isAdminUser };
      dispatch(getNextEnrollCourseAdmin(payload));
    } else {
      const payload = { id: selectedStudent?.id, isAdminUser };
      dispatch(getNextEnrollCourseAdmin(payload));
    }
  }, []);
  const enrollClasses = useStyles(style)();
  const [expandedPanel, setExpandedPanel] = useState(2);
  useEffect(() => {
    if (showAddSibling) {
      setExpandedPanel(siblingData.length + 3);
    }
  }, [showAddSibling]);
  const reduxStorePayment = useSelector((state) => state?.getPayment);

  const enrollCourseList = selectedStudentData?.enrollCourseList;
  const enrollStudentData = reduxStorePayment?.enrollStudentData;

  useEffect(() => {
    const sibling = studentAccountDetails?.studentData?.filter(
      (stu) => !stu.primaryStudent,
    );
    const sibData = sibling?.map((i) => {
      const studentInfo = i?.studentInfo;
      const enrolledCourses = i?.enrolled_courses;
      const {
        firstName, gender, lastName, middleName, profilePhoto,
        manabadiEmail,
      } = studentInfo;

      const {
        academicGrade,
        address,
        extraCurricularActivities,
        location,
        tShirtSize,
      } = enrolledCourses || {};
      return {
        studentId: i?.id,
        studentName: `${firstName} ${lastName}`,
        academicYear: getCurrentYear()?.id,
        profileImage: profilePhoto,
        firstName,
        middleName,
        lastName,
        selectedClassLevel: '',
        dateOfBirth: i?.dateOfBirth,
        gender,
        tShirt: tShirtSize,
        grade: academicGrade,
        searchSchool: address,
        sortedNearest: location?.id,
        academicSchool: address,
        extraCurricularActivities,
        manabadiEmail,
        latitude: address ? address.latitude : '',
        longitude: address ? address.longitude : '',
      };
    });
    setSiblingData(sibData);
  }, [studentAccountDetails?.studentData, enrollCourseList]);
  const [newParentAddressEnroll, setNewParentAddressEnroll] = useState({});
  const [renderKey, setRenderKey] = useState(0);
  const [renderKeySibbling, setRenderKeySibbling] = useState(0);
  const [parentInfo, setParentInfo] = useState({
    parentOneTitle: studentAccountDetails?.parent1Data?.user_extended?.title,
    parentOneFirstName: studentAccountDetails?.parent1Data?.firstName,
    parentOneMiddleName: studentAccountDetails?.parent1Data?.middleName,
    parentOneLastName: studentAccountDetails?.parent1Data?.lastName,
    parentOneEmail: studentAccountDetails?.parent1Data?.personalEmail,
    parentOneProfession:
      studentAccountDetails?.parent1Data?.user_extended?.profession,
    parentOneContact: formatPhoneNumberIntl(studentAccountDetails?.parent1Data?.contactNumber),
    parentOneCompany:
      studentAccountDetails?.parent1Data?.user_extended?.nameOfCompany,
    parentTwoTitle: studentAccountDetails?.parent2Data?.user_extended?.title,
    parentTwoFirstName: studentAccountDetails?.parent2Data?.firstName,
    parentTwoLastName: studentAccountDetails?.parent2Data?.lastName,
    parentTwoMiddleName: studentAccountDetails?.parent2Data?.middleName,
    parentTwoEmail: studentAccountDetails?.parent2Data?.personalEmail,
    parentTwoProfession:
      studentAccountDetails?.parent2Data?.user_extended?.profession,
    parentTwoContact: formatPhoneNumberIntl(studentAccountDetails?.parent2Data?.contactNumber),
    parentTwoCompany:
      studentAccountDetails?.parent2Data?.user_extended?.nameOfCompany,
    homeAddress: studentAccountDetails?.addressData?.address,
    homeAddressInfo: {
      lat: studentAccountDetails?.addressData?.latitude,
      lng: studentAccountDetails?.addressData?.longitude,
      add: studentAccountDetails?.addressData?.address,
    },
    aptSuite: studentAccountDetails?.addressData?.aptSuite,
    volunteer:
      studentAccountDetails?.parent1Data?.user_extended?.volunteerChoice || [],
    hearAboutUs:
      studentAccountDetails?.parent1Data?.user_extended?.recommendedSource?.filter((i) => i !== null && i !== undefined)
      || [],
    readTelugu: studentAccountDetails?.parent1Data?.user_extended?.readTelugu,
    speakTelugu: studentAccountDetails?.parent1Data?.user_extended?.speakTelugu,
  });
  const studentInfo = selectedStudent?.studentInfo;
  const enrolledCourses = selectedStudent?.enrolled_courses;
  
  useEffect(()=>{
    if (newParentAddressEnroll.add !== undefined) {
      let newParentInfo = parentInfo;
      newParentInfo.homeAddressInfo = newParentAddressEnroll;
      setParentInfo(newParentInfo);
    }
  }, [newParentAddressEnroll, placeSelected]);

  useEffect(()=>{
    setRenderKey(prevKey => prevKey + 1);
    setRenderKeySibbling(prevKeySibbling => prevKeySibbling + 1);
  }, [parentInfo]);

  useEffect(() => {
  }, [primaryStudentData, siblingData]);
  const {
    academicGrade,
    address,
    extraCurricularActivities,
    location,
    tShirtSize,
  } = enrolledCourses;
  useEffect(() => {
    const studentObj = getStudent(
      studentInfo,
      selectedStudent,
      getCurrentYear()?.id,
      tShirtSize,
      academicGrade,
      address,
      location,
      extraCurricularActivities,
    );
    setPrimaryStudentData(studentObj);
  }, [
    enrollCourseList,
    studentAccountDetails?.studentData,
    selectedStudent,
    enrolledCourses,
  ]);

  const [addedSiblingInfo, setAddedSiblingInfo] = useState(0);
  const [studentFormError, setStudentFormError] = useState(0);

  const handleAccordionChange = (panel) => (event, isExpanded) => {
    setExpandedPanel(isExpanded ? panel : false);
  };

  const classes = useStyles(style)();

  const setHeaderTitle = (title) => (
    <Grid container item xs={12} spacing={0} justifyContent="space-between">
      <Grid item xs={12}>
        <div className={classes.headerTitle}>{title}</div>
      </Grid>
      <div className={classes.borderBottom} />
    </Grid>
  );
  const makeAccordin = (summaryTitle, detail, panel, showRemove = false) => (
    <Accordion
      expanded={expandedPanel === panel}
      onChange={handleAccordionChange(panel)}
    >
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Grid item className={enrollClasses.headerContainer} xs={12}>
          <div>
            <div className={enrollClasses.siblingTitle}>
              {setHeaderTitle(summaryTitle, 0)}
            </div>
            {showRemove ? (
              <div
                className={enrollClasses.removeTitle}
                onClick={() => {
                  setShowAddSibling(false);
                  setAddedSiblingInfo(0);
                }}
              >
                {t('REMOVE')}
              </div>
            ) : (
              ''
            )}
          </div>
        </Grid>
      </AccordionSummary>

      <AccordionDetails>
        <Grid item xs={12}>
          {detail}
        </Grid>
      </AccordionDetails>
    </Accordion>
  );
  const getEnrollFeeStructure = (addSiblingData = {}) => {
    let classLevelValidation = false;
    let sibblingsLastNameValidation = false;
    let sibblingsFirstNameValidation = false;
    const payload = gerParentPayload(parentInfo, studentAccountDetails);
    const studentPayload = [];
    const primaryStudentInfo = getPrimaryStudentPayload(primaryStudentData);
    if (Object.keys(primaryStudentFormikControl.touched).length || primaryStudentInfo.enrollmentCourse?.courseId) {
      if(!Object.keys(primaryStudentFormikControl.errors).length) {
        studentPayload.push(primaryStudentInfo);
      } else {
        classLevelValidation = true;
        setCheckClassLevel(primaryStudentInfo.user?.firstName.toLowerCase());
      }
    }
    Object.keys(siblingFormikControl).forEach((sc, index) => {
      sibblingsLastNameValidation = false;
      sibblingsFirstNameValidation = false;
      const sib = { ...siblingData[index] };
      const primaryStudentLastName = primaryStudentData?.lastName;
      const siblingLastNames = Object.entries(siblingData).map((s) => s[1]?.lastName);
      const areLastNamesValid = siblingLastNames.every((siblingLastName) => primaryStudentLastName.toLowerCase()?.trim() === siblingLastName.toLowerCase()?.trim());
      if (!areLastNamesValid) {
        sibblingsLastNameValidation= true;
        return;
      }
      if (sib?.selectedClassLevel?.id === undefined && Object.keys(siblingFormikControl[sc].touched).length) {
        classLevelValidation = true;
        setCheckClassLevel(sib?.firstName.toLowerCase());
        return;
      }
      if (sib?.selectedClassLevel?.id && Object.keys(siblingFormikControl[sc].errors).length) {
        classLevelValidation = true;
        setCheckClassLevel(sib?.firstName.toLowerCase());
        return;
      }
      const primaryStudentFName = primaryStudentData?.firstName;
      const siblingFirstNames = Object.entries(siblingData).map((s) => s[1]?.firstName);
      const areFirstNamesValid = siblingFirstNames.every((siblingFirstNames) => primaryStudentFName.toLowerCase()?.trim() === siblingFirstNames.toLowerCase()?.trim());
      if (areFirstNamesValid) {
        sibblingsFirstNameValidation= true;
        return;
      }
      if (Object.keys(siblingFormikControl[sc].errors).length === 0 || ((Object.keys(siblingFormikControl[sc].errors).length === 1 && Object.keys(siblingFormikControl[sc].errors)[0] === 'changeLogs'))) {
        studentPayload.push({
          user: {
            firstName: sib?.firstName,
            middleName: sib?.middleName,
            lastName: sib?.lastName,
            studentId: sib?.studentId,
            gender: sib?.gender,
            manabadiEmail: sib?.manabadiEmail,
            profilePhoto: sib?.profileImage,
          },
          studentDetail: {
            image: sib?.profileImage,
            dateOfBirth: sib?.dateOfBirth,
          },
          academicSchool: {
            address: sib?.academicSchool?.address,
            aptSuite: 'A5',
            latitude: sib?.academicSchool?.latitude,
            longitude: sib?.academicSchool?.longitude,
          },
          enrollmentCourse: {
            academicYear: sib?.academicYear,
            academicGrade: sib?.grade,
            locationId: sib?.sortedNearest,
            tShirtSize: sib?.tShirt,
            courseId: sib?.selectedClassLevel?.id,
            courseName: sib?.selectedClassLevel?.name,
            extraCurricularActivities: sib?.extraCurricularActivities || [],
          },
        });
      }
    });
    let newStudents = [];
    if (showAddSibling && Object.keys(addSiblingData).length) {
      newStudents = Object.entries(addSiblingData).map((s) => ({
        user: {
          firstName: s[1]?.firstName && removeSpecialChar(s[1]?.firstName),
          middleName: s[1].middleName,
          lastName: s[1]?.lastName && removeSpecialChar(s[1]?.lastName),
          gender: s[1].gender,
        },
        studentDetail: {
          image: s[1]?.profileImage,
          dateOfBirth: s[1]?.dateOfBirth,
        },
        academicSchool: {
          address: s[1]?.searchSchool,
          aptSuite: 'A5',
          latitude: Number(s[1]?.studentSchoolInfo?.lat) || 0,
          longitude: Number(s[1]?.studentSchoolInfo?.lng) || 0,
        },
        enrollmentCourse: {
          academicYear: s[1].academicYear,
          academicGrade: s[1].selectedGrade?.name,
          locationId: s[1]?.sortedNearest,
          tShirtSize: s[1].selectedTshirtOption?.name,
          courseId: s[1].selectedClassLevel?.id,
          courseName: s[1].selectedClassLevel?.name,
          extraCurricularActivities: s[1]?.selectedExtraCurricularActivities?.map((i) => i?.name),
        },
      }));
    }
    payload.students = studentPayload;
    payload.isNewEnrollment = true;
    payload.isStudentDashboard = true;
    payload.newStudents = newStudents;
    for (const student of payload.students) {
      if (student.enrollmentCourse?.courseId === undefined) {
        classLevelValidation = true;
        setCheckClassLevel(student.user?.firstName.toLowerCase());
      }
    }
    for (const student of payload.newStudents) {
      if (student.enrollmentCourse?.courseId === undefined) {
        classLevelValidation = true;
        setCheckClassLevel(student.user?.firstName.toLowerCase());
      }
    }
    if (sibblingsLastNameValidation === true) {
      handleClickSnackbar();
    } else if (sibblingsFirstNameValidation === true) {
      handleClickSnackbar2();
    } else if (classLevelValidation === true || (payload.students.length === 0 && payload.newStudents.length === 0)) {
      setParentLoading(false);
      handleClickSnackbarEnroll();
    } else if (classLevelValidation === false) {
      dispatch(getFeeStructureForEnroll(payload));
      setIsPaymentDialogOpen(true);
      setParentLoading(false);
    }
  };
  const parentDetail = () => (
    <Grid item justifyContent="flex-start" xs={12}>
      <Grid className={classes.editParentBlock}>
        <EditParent
          setLoading={setParentLoading}
          setParentInfo={setParentInfo}
          setParentFormik={setParentFormik}
          parentInfo={parentInfo}
          source="editParent"
          primaryButton={t('SAVE')}
          loading={parentLoading}
          isEnrollStudent
          editParentClasses={classes}
          setParentValid={setParentValid}
          isParentValid={isParentValid}
          setNewParentAddressEnroll={setNewParentAddressEnroll}
          setPlaceSelected={setPlaceSelected}
          isStudentDashboard={true}
        />
      </Grid>
    </Grid>
  );
  const onFormSubmit = (addSiblingData = {}) => {
    setLocalStorage('enrolling', true);
    const primaryStudentLastName = primaryStudentData?.lastName;
    const siblingLastNames = Object.entries(addSiblingData).map((s) => s[1]?.lastName);
    const areLastNamesValid = siblingLastNames.every((siblingLastName) => primaryStudentLastName.toLowerCase()?.trim() === siblingLastName.toLowerCase()?.trim());
    if (!areLastNamesValid) {
      handleClickSnackbar();
      return;
    }
    let sibFirstName = Object.values(addSiblingData).map((s) => s?.firstName);
    sibFirstName = sibFirstName.join("");
    const siblingFirstNames = Object.values(siblingData).map((s) => s?.firstName?.toLowerCase()?.trim());
    const primaryStudentFirstName = primaryStudentData?.firstName;
    siblingFirstNames.push(primaryStudentFirstName.toLowerCase());
    const areFirstNamesValid = !siblingFirstNames.includes(sibFirstName.toLowerCase()?.trim());
    if (!areFirstNamesValid) {
      handleClickSnackbar2();
      return;
    }
    parentFormik.submitForm();
    primaryStudentFormikControl?.submitForm();
    if (siblingData.length) {
      Object.keys(siblingFormikControl).forEach((sc) => {
        siblingFormikControl[sc]?.submitForm();
      });
    }
    if (!isParentValid) {
      return;
    }
    getEnrollFeeStructure(addSiblingData);
  };
  const getEnrollCourseList = (stuId) => {
    if (!stuId) return [];
    const data = enrollCourseList?.find((stu) => stu.studentId === stuId);
    if (!data || !data?.course) return [];
    const courses = data?.course.map((i) => ({
      id: i?.toCourse?.id,
      name: i?.toCourse?.name,
      academicYear: i?.toCourse?.academicYear,
    }));
    return courses;
  };
  return (
    <Box className={classes.accordin}>
      {makeAccordin(t('PARENT_INFORMATION'), parentDetail(), 1)}
      {primaryStudentData && (
        <>
          {makeAccordin(
            `${primaryStudentData?.studentName} (${t('PRIMARY_STUDENT')})`,
            <Grid className={classes.editStudentBlock}>
              <DisplayEditStudent
                key={`display_edit_student_primary_${renderKey}`}
                isEnrollStudent
                isPrimary={true}
                selectedStudent={primaryStudentData}
                setStudentData={setPrimaryStudentData}
                accordin={2}
                classes={classes}
                parentInfo={parentInfo}
                enrollCourseList={getEnrollCourseList(primaryStudentData?.studentId)}
                setFormikControl={(c) => {
                  setPrimaryStudentFormikControl(c);
                }}
                isAdminUser={isAdminUser}
                placeSelected={placeSelected}
                checkClassLevel={checkClassLevel}
                SiblingsData={studentAccountDetails?.studentData?.filter((stu) => !stu.primaryStudent)}
              />
            </Grid>,
            2,
          )}
        </>
      )}
      <div className="flow">
        {siblingData?.map((point, index) => (
          <>
            {makeAccordin(
              `${point?.studentName} (${t('SIBLING')})`,
              <>
                <Grid className={classes.editStudentBlock}>
                  <DisplayEditStudent
                    key={`display_edit_student_${index}_${renderKey}`}
                    isPrimary={true}
                    isEnrollStudent
                    enrollCourseList={getEnrollCourseList(point?.studentId)}
                    setStudentData={(sData) => {
                      const siData = [...siblingData];
                      siData[index] = sData;
                      setSiblingData(siData);
                    }}
                    selectedStudent={point}
                    accordin={index + 3}
                    classes={classes}
                    parentInfo={parentInfo}
                    setFormikControl={(c) => {
                      setSiblingFormikControl({
                        ...siblingFormikControl,
                        [index]: c,
                      });
                    }}
                    isAdminUser={isAdminUser}
                    placeSelected={placeSelected}
                    checkClassLevel={checkClassLevel}
                    SiblingsData={studentAccountDetails?.studentData?.filter((stu) => stu.id !== point?.studentId)}
                  />
                </Grid>
              </>,
              index + 3,
            )}
          </>
        ))}
      </div>
      {showAddSibling
        && makeAccordin(
          t('ADD_NEW_SIBLING'),
          <StudentInfo
            key={`display_student_info_sibbling_${renderKeySibbling}`}
            setStudentInfo={setAddedSiblingInfo}
            studentInfo={addedSiblingInfo}
            loading={studentLoading}
            setLoading={setStudentLoading}
            studentFormError={studentFormError}
            setStudentFormError={setStudentFormError}
            source="editStudent"
            parentInfo={parentInfo}
            newParentAddressEnroll={newParentAddressEnroll}
            onStudentSubmit={(data) => onFormSubmit(data)}
            editStudentClasses={classes}
            isAdminUser={isAdminUser}
          />,
          siblingData.length + 3,
          true,
        )}
      <DialogAtom
        isOpen={isPaymentDialogOpen}
        onClose={handleClose}
        dialogHeading="Payment Information"
        customClass={classes.paymentDialogAtom1}
        content={(
          <EnrollPayment
            loading={loading}
            setLoading={setLoading}
            classes={classes}
            enrollStudentData={enrollStudentData}
          />
        )}
        secHandle={() => {
          setIsPaymentDialogOpen(false);
          localStorage.removeItem('enrolling');
        }}
      />
      {!showAddSibling && (
        <Grid textAlign="right" mt={2}>
          <Button
            name={t('SAVE_CONTINUE')}
            onClick={() => onFormSubmit({})}
            btntype={Buttons.PRIMARY}
          />
        </Grid>
      )}
      <DialogAtom
        isOpen={openSnackbar}
        onClose={() => setOpenSnackbar(false)}
        dialogHeading="Last name mismatch error"
        customClass={classes.paymentDialogAtom}
        content={(
          <Grid container spacing={0} justifyContent="space-between">
            <Grid item xs={12}>
              <div className={classes.lastNameError}>
                {t('SIBLING_LASTNAME_ERROR')}
              </div>
            </Grid>
          </Grid>
        )}
        secHandle={() => setOpenSnackbar(false)}
      />
      <DialogAtom
        isOpen={openSnackbar2}
        onClose={() => setOpenSnackbar2(false)}
        dialogHeading="First name error"
        customClass={classes.paymentDialogAtom}
        content={(
          <Grid container spacing={0} justifyContent="space-between">
            <Grid item xs={12}>
              <div className={classes.lastNameError}>
                {t('SIBLING_FIRSTNAME_ERROR')}
              </div>
            </Grid>
          </Grid>
        )}
        secHandle={() => setOpenSnackbar2(false)}
      />
      <DialogAtom
        isOpen={openSnackbarEnroll}
        onClose={() => setOpenSnackbarEnroll(false)}
        dialogHeading="Verify all the data from the students"
        customClass={classes.paymentDialogAtom}
        content={(
          <Grid container spacing={0} justifyContent="space-between">
            <Grid item xs={12}>
              <div className={classes.lastNameError}>
                {t('ENROLL_STUDENTS_SIBLINGS_ERROR')}
              </div>
            </Grid>
          </Grid>
        )}
        secHandle={() => setOpenSnackbarEnroll(false)}
      />
    </Box>
  );
}
