/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-nested-ternary */
import {
  Grid,
  Typography,
  Box,
  Button,
  Link,
  TextField,
  Tooltip,
  List,
  ListSubheader,
  ListItem,
  ListItemText,
  IconButton,
  ListItemButton,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import React, { useState, useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import SaveIcon from '@mui/icons-material/Save';
import { Editor } from 'react-draft-wysiwyg';
import {
  EditorState,
  convertToRaw,
  ContentState,
} from 'draft-js';
import { FormikProvider, useFormik } from 'formik';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import { useDispatch, useSelector } from 'react-redux';
import { GridSearchIcon } from '@mui/x-data-grid';
import styles from './style';
import useStyles from '../../../custom-hooks/useStyles';
import TextFieldAtom from '../../../components/atoms/text-field-with-label';
import Dropdown from '../../../components/atoms/dropdownBulkEmail';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import DialogAtom from '../../../components/atoms/dialog';
import commonStyles from '../../../utils/commonClasses';
import ButtonAtom from '../../../components/atoms/button';
import { Buttons, NavigateRoutes } from '../../../constant';
import Constant from '../../../store/constant';
import Loader from '../../../components/atoms/loader';
import {
  saveEmailTemplate,
  getEmailTemplate,
  getEmailFilters,
  sendAnnouncement,
  getRecipientsByFilter,
  getSubstitutionTagsList,
  getRecipientsCount,
} from '../../../store/actions/getEmail';
import {
  getAllStudentsAfterCancel,
} from '../../../store/actions/getStudent';
import { GroupedAutoComplete } from '../../../components/atoms';
import Filters from './filters/filters';
import { getLocalStorage, setLocalStorage } from '../../../utils/localStorageMethod';
import userRoles from '../../../constant/userRoles';
import userRolesFilter from '../../../constant/userRolesFilterEmail';
import CheckboxesAtom from '../../../components/atoms/checkbox';
import { DataList } from './helper';
import { getAllEmailUsers } from '../../../store/actions/getUser';
import decryptedUserRoles from '../../../constant/decryptedUserRoles';
import { getCurrentYear } from '../../../utils/methods';
import {
  uploadFileEmailService,
} from '../../../store/services/auth';
import PreviewDialog from '../bulk-email/preview-announcement/previewAnnouncement';

const substitutionTagsAdmin = [
  {
    title: 'User Info',
    options: [
      {
        key: 'contact_number',
        text: 'User Contact Number',
      },
      {
        key: 'user_address',
        text: 'User Address',
      },
      {
        key: 'user_image',
        text: 'User Image',
      },
      {
        key: 'user_name',
        text: 'User Name',
      },
    ],
  },
];
// Course, Section, Location, Student, User
// const filtersSingleEmail = [
//   {
//     id: 'Course',
//     name: 'Course',
//   },
//   {
//     id: 'Section',
//     name: 'Section',
//   },
//   {
//     id: 'Location',
//     name: 'Location',
//   },
//   {
//     id: 'Student',
//     name: 'Student',
//   },
//   {
//     id: 'User',
//     name: 'User',
//   },

// ];

function DialogContent({
  t, classes, formik, message, messageContent,
}) {
  if (message) {
    return (
      <Grid container item xs={6} className={classes.dialogContent}>
        {messageContent}
      </Grid>
    );
  }

  return (
    <Grid container item xs={6} className={classes.dialogContent}>
      <TextFieldAtom
        minWidth="80%"
        id="dialog-text"
        name="dialog-text"
        label={`${t('PLEASE_GIVE_TEMPLATE_TITLE')}`}
        value={formik.values.templateTitleCopy}
        onChange={(e) => formik.setFieldValue('templateTitleCopy', e.target.value)}
      />
    </Grid>
  );
}
function DialogFooter({
  classes, handleDialogCancel, handleDialogSave, t, message,
}) {
  const commonClasses1 = useStyles(commonStyles)();

  if (message) {
    return (
      <Grid container className={classes.content} justifyContent="flex-end">
        <ButtonAtom
          name={t('ACCEPT')}
          onClick={handleDialogCancel}
          btntype={Buttons.PRIMARY}
          className={commonClasses1.activeButton}
        />
      </Grid>
    );
  }

  return (
    <Grid container className={classes.content} justifyContent="flex-end">
      <ButtonAtom
        name={t('CANCEL')}
        onClick={handleDialogCancel}
        btntype={Buttons.SECONDARY}
        className={commonClasses1.activeButton}
      />
      <ButtonAtom
        name={t('CREATE')}
        onClick={handleDialogSave}
        btntype={Buttons.PRIMARY}
        className={commonClasses1.activeButton}
      />
    </Grid>
  );
}

export default function BulkEmail() {
  const { t } = useTranslation();
  const classes = useStyles(styles)();
  const navigate = useNavigate();
  const [isDialogOpen, setDialogOpen] = useState(false);
  let store = {};
  const [editorState, setEditorState] = useState(() => EditorState.createEmpty());
  const { getEmail } = useSelector((state) => state);
  const [templateOptions, setTemplateOptions] = useState([]);
  const [selectedTemplate, setSelectedTemplate] = useState(() => []);
  const [selectedRecipientOptions, setSelectedRecipientOptions] = useState([]);
  const [showNewTemplate, setNewTemplate] = useState(false);
  const [nameTemplate, setNameTemplate] = useState('');
  const [recipientOptions, setRecipentOptions] = useState([]);
  const [emailsOptions, setEmailsOptions] = useState([]);
  const [errorCode, setErrorCode] = React.useState();
  // const [tag, setTag] = useState(() => []);
  const [tag, setTag] = React.useState('');
  const [permissionDialog, setPermissionDialog] = React.useState(false);
  const bottomRef = useState(false);
  const [substitutionTag, setSubstitutionTag] = useState([]);
  const [searched, setSearched] = React.useState('');
  const [loading, setLoading] = React.useState(false);
  const [successDialog, setSuccessDialog] = React.useState(false);
  const [errorDialog, setErrorDialog] = React.useState(false);
  const [openErrorFilterDialog, setOpenErrorFilterDialog] = React.useState(false);
  const userFilters = useSelector((state) => state.getUser);
  const students = useSelector((state) => state.getStudent);
  const range = (start, end) => Array(end - start + 1)
    .fill()
    .map((_, idx) => [
      {
        id: start + idx,
        name: (start + idx).toString(),
        title: (start + idx)?.toString(),
        label: (start + idx)?.toString(),
        key: (start + idx)?.toString(),
      },
    ]);
  const marksRecipients = range(0, 100);
  const encryptedUserRole = getLocalStorage('userRole');
  const userRole = decryptedUserRoles[encryptedUserRole];
  const [showAnnouncementDialog, setShowAnnouncementDialog] = useState(false);
  const [announcementDetailsData, setAnnouncementDetails] = React.useState(null);

  useEffect(() => {
    const options = getEmail?.recipientOptions?.map((obj) => ({
      id: obj,
      name: obj,
      title: obj?.toString(),
      label: obj?.toString(),
      key: obj?.toString(),
    }));
    setRecipentOptions(options);
  }, [getEmail?.recipientOptions]);
  useEffect(() => {
    const emailFilters = getEmail?.emailFilters?.map((emailFilter) => ({
      id: emailFilter,
      name: emailFilter,
    }));
    setEmailsOptions(emailFilters);
  }, [getEmail?.emailFilters]);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getSubstitutionTagsList(setErrorDialog, setErrorCode));
    dispatch(getEmailTemplate());
    switch (userRole) {
      case userRoles.SYSTEM_ADMIN:
        dispatch(getEmailFilters(userRolesFilter.SYSTEM_ADMIN));
        break;
      case userRoles.SUPER_USER:
        dispatch(getEmailFilters(userRolesFilter.SYSTEM_ADMIN));
        break;
      case userRoles.REGION_COORDINATOR:
        dispatch(getEmailFilters(userRolesFilter.REGION_COORDINATOR));
        break;
      case userRoles.LOCATION_COORDINATOR:
        dispatch(getEmailFilters(userRolesFilter.LOCATION_COORDINATOR));
        break;
      case userRoles.TEACHER:
        dispatch(getEmailFilters(userRolesFilter.TEACHER));
        break;
      default:
        break;
    }
    dispatch(getAllEmailUsers());
    if (userRole === userRoles.REGION_COORDINATOR || userRole === userRoles.SUPER_USER) {
      dispatch(getAllStudentsAfterCancel(
        1,
        100,
        null,
        setLoading,
        null,
        {
          filters: { academic_year: [getCurrentYear()?.id] },
          searchText: [[]],
          timePeriod: 'year',
        },
      ));
    }
  }, []);

  const [recipientError, setRecipientError] = useState('');
  const [subjectError, setSubjectError] = useState('');
  const [contentError, setContentError] = useState('');
  const [titleError, setTitleError] = useState('');
  const [showSelectAllinEmail, setShowSelectAllinEmail] = useState(JSON.parse(
    getLocalStorage('showSelectAllinEmail'),
  ));
  const showLocationFilterRecipients = JSON.parse(
    getLocalStorage('showLocationFilterRecipients'),
  );
  const showLocationAnnouncementsRecipients = JSON.parse(
    getLocalStorage('showLocationAnnouncementsRecipients'),
  );

  const selectedLocation = getLocalStorage('selectedLocation');
  const selectedYear = getLocalStorage('selectedYear');
  const userEmailId = getLocalStorage('userEmailId');
  const userId = getLocalStorage('userId');

  const resetErrors = () => {
    setRecipientError('');
    setSubjectError('');
    setContentError('');
    setTitleError('');
  };

  const formik = useFormik({
    initialValues: {
      mailRecipients: [],
      emailFilter:
        getEmail?.emailSetFilter === undefined ? [] : getEmail?.emailSetFilter,
      template: '',
      mailSubject: getEmail?.mailSubject ? getEmail?.mailSubject : '',
      templateTitle: '',
      templateTitleCopy: '',
      mailContent: '',
      courseName: '',
      substitutionTags: [],
      sender: localStorage.getItem('userEmailId'),
      location: '',
      year: '',
    },
    onSubmit: () => { },
  });

  const [isSelectAllChecked, setIsSelectAllChecked] = useState(false);
  const [selectedChanged, setSelectedChanged] = useState(false);

  const SelectAllRecipients = () => {
    setSelectedChanged(true);
    setIsSelectAllChecked(!isSelectAllChecked);
  };

  const fetchEmailRecipients = (filter, locationId, name, selectedFilters, loadingFn) => {
    const list = new DataList(selectedRecipientOptions);
    const filterlist = list.finalFilters;
    const emailFilter = filter === '' ? formik.values.emailFilter : filter;
    const payload = {
      filter: emailFilter,
      name,
      locationId,
      selectedFilters,
      filterlist,
    };
    dispatch(getRecipientsByFilter(payload, loadingFn, setOpenErrorFilterDialog, setErrorCode));
  };

  const onInputChange = (e, newInputValue) => {
    if (
      formik.values.emailFilter.length !== 0
      && (formik.values.emailFilter === 'Student'
        || formik.values.emailFilter === 'User')
    ) {
      if (newInputValue.length > 2) {
        fetchEmailRecipients(formik.values.emailFilter, formik.values.location, newInputValue, {
          locationName: localStorage.getItem('locationEmailTemplate') ? localStorage.getItem('locationEmailTemplate') : '',
          regionName: localStorage.getItem('regionEmailTemplate') ? localStorage.getItem('regionEmailTemplate') : '',
          student: localStorage.getItem('studentEmailTemplate') ? localStorage.getItem('studentEmailTemplate') : '',
          section: localStorage.getItem('sectionEmailTemplate') ? localStorage.getItem('sectionEmailTemplate') : '',
          user: localStorage.getItem('userTemplate') ? localStorage.getItem('userTemplate') : '',
        });
      }
    }
  };

  useEffect(() => {
    if (!getEmail?.mailContent) {
      return;
    }
    const contentBlock = htmlToDraft(getEmail?.mailContent);
    if (contentBlock) {
      const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
      const textEditorState = EditorState.createWithContent(contentState);
      setEditorState(textEditorState);
    }
  }, []);

  useEffect(() => {
    setIsSelectAllChecked(false);
    setLoading(true);
    if (
      formik.values.emailFilter.length !== 0
      && formik.values.emailFilter !== 'Student'
      && formik.values.emailFilter !== 'User'
    ) {
      if (
        formik.values.emailFilter === 'Marks <='
        || formik.values.emailFilter === 'Marks >='
      ) {
        const marks = [];
        marksRecipients?.forEach((obj) => {
          marks.push(obj[0]);
        });
        setRecipentOptions(marks);
      } else {
        fetchEmailRecipients(formik.values.emailFilter === 'Role' ? 'RoleSetFilter' : formik.values.emailFilter, formik.values.location, '', {
          locationName: localStorage.getItem('locationEmailTemplate') ? localStorage.getItem('locationEmailTemplate') : '',
          regionName: localStorage.getItem('regionEmailTemplate') ? localStorage.getItem('regionEmailTemplate') : '',
          student: localStorage.getItem('studentEmailTemplate') ? localStorage.getItem('studentEmailTemplate') : '',
          section: localStorage.getItem('sectionEmailTemplate') ? localStorage.getItem('sectionEmailTemplate') : '',
          user: localStorage.getItem('userTemplate') ? localStorage.getItem('userTemplate') : '',
        }, setLoading);
      }
    } else {
      fetchEmailRecipients('', '', '', '', setLoading);
    }
  }, [formik.values.emailFilter, formik.values.location]);

  useEffect(() => {
    if (selectedTemplate && selectedTemplate.length !== 0) {
      resetErrors();
      formik.setFieldValue('template', selectedTemplate?.id);
      formik.setFieldValue('mailSubject', selectedTemplate?.subject);
      formik.setFieldValue(
        'templateTitle',
        selectedTemplate?.name
          ? selectedTemplate?.name
          : selectedTemplate?.title,
      );
      const contentBlock = htmlToDraft(selectedTemplate?.body);
      if (contentBlock) {
        const contentState = ContentState.createFromBlockArray(
          contentBlock.contentBlocks,
        );
        const textEditorState = EditorState.createWithContent(contentState);

        setEditorState(textEditorState);
        formik.setFieldValue(
          'mailContent',
          draftToHtml(convertToRaw(editorState.getCurrentContent())),
        );
      }
    }
  }, [selectedTemplate]);

  useEffect(() => {
    if (tag && tag.length !== 0) {
      resetErrors();
      const f = draftToHtml(convertToRaw(editorState.getCurrentContent()));
      const contentBlock = htmlToDraft(f + tag);
      // if (contentBlock) {
      const contentState = ContentState.createFromBlockArray(
        contentBlock.contentBlocks,
      );
      const textEditorState = EditorState.createWithContent(contentState);
      // store = draftToHtml(convertToRaw(editorState.getCurrentContent())) + draftToHtml(convertToRaw(textEditorState.getCurrentContent()));
      store = draftToHtml(convertToRaw(textEditorState.getCurrentContent()));
      const storeContent = htmlToDraft(store);
      if (storeContent) {
        const storeContentState = ContentState.createFromBlockArray(storeContent.contentBlocks);
        const storeEditorState = EditorState.createWithContent(storeContentState);
        setEditorState(storeEditorState);
      }
      formik.setFieldValue('substitutionTags', substitutionTag);
      formik.setFieldValue(
        'mailContent',
        draftToHtml(convertToRaw(editorState.getCurrentContent())),
      );
      bottomRef.current?.scrollIntoView({ behavior: 'smooth' });
      // }
    }
  }, [tag]);

  useEffect(() => {
    const templateOpt = getEmail?.emailTemplates || [];
    if (templateOpt.length) {
      setTemplateOptions(
        templateOpt.map((opt) => ({
          id: opt.id,
          name: opt.title,
          subject: opt.subject,
          body: opt.body,
          filters: opt.filters ? JSON.parse(opt.filters) : [],
        })),
      );
    }
    if (showNewTemplate) {
      const template = templateOpt.filter((array) => array.title === nameTemplate);
      if (template.length > 0) {
        setSelectedTemplate(template[0]);
        formik.setFieldValue('template', template[0].id);
      }
    }
  }, [getEmail?.emailTemplates]);

  useEffect(() => {
    if (!localStorage.getItem('adminBulkRecipientList') || !localStorage.getItem('adminBulkEmailStudents')) {
      if (!isSelectAllChecked) {
        formik.setFieldValue('mailRecipients', []);
        setSelectedRecipientOptions([]);
        return;
      }
    }
    if (localStorage.getItem('userManager') === null) {
      if (selectedChanged) {
        const options = [];
        if (isSelectAllChecked) {
          const emailrecipients = [];
          students?.students.forEach((obj) => {
            if (obj.studentInfo.manabadiEmail) {
              const val2 = {
                label: obj.studentInfo.manabadiEmail,
                groupBy: 'Student',
                filterKey: 'Student',
              };
              if (obj !== undefined && obj !== '') options.push(val2);
            }
          });
          emailrecipients.push(...getEmail.studentFilters);
          formik.mailRecipients = emailrecipients;
          formik.setFieldValue('mailRecipients', options);
        }
        setSelectedRecipientOptions([{
          label: t('ALL_ACTIVE_STUDENTS_WITH_EMAIL'),
          groupBy: 'Student',
          filterKey: 'Student',
        }]);
        setSelectedChanged(true);
      }
      return;
    }
    if (selectedChanged) {
      const options = [];
      if (isSelectAllChecked) {
        const emailrecipients = [];
        userFilters?.userEmails.rows.forEach((obj) => {
          const val2 = {
            label: obj.manabadi_email,
            groupBy: 'User',
            filterKey: 'User',
          };
          if (obj !== undefined && obj !== '') options.push(val2);
        });
        emailrecipients.push(...getEmail.studentFilters);
        formik.mailRecipients = emailrecipients;
        formik.setFieldValue('mailRecipients', options);
        setSelectedRecipientOptions([{
          label: t('ALL_ACTIVE_USERS_WITH_EMAIL'),
          groupBy: 'User',
          filterKey: 'User',
        }]);
      }
      setSelectedChanged(true);
    }
  }, [isSelectAllChecked]);
  useEffect(() => {
    if (getEmail.recipients === undefined) {
      const emailrecipients = [];
      getEmail.recipients?.forEach((obj) => {
        const val = {
          label: obj,
          groupBy: 'recipients',
          filterKey: 'recipients',
        };
        if (obj !== undefined && obj !== '') emailrecipients.push(val);
      });
      emailrecipients.push(...getEmail.studentFilters);
      formik.setFieldValue('mailRecipients', emailrecipients);
      setSelectedRecipientOptions(emailrecipients);
      dispatch({ type: Constant.RECIPIENTS, payload: [] });
      setLocalStorage('adminBulkRecipientList', []);
      setLocalStorage('adminBulkEmailStudents', []);
    } else if (getEmail.recipients?.length > 0) {
      const emailrecipients = [];
      getEmail.recipients?.forEach((obj) => {
        const val = {
          label: obj,
          groupBy: 'recipients',
          filterKey: 'recipients',
        };
        if (obj !== undefined && obj !== '') emailrecipients.push(val);
      });
      emailrecipients.push(...getEmail.studentFilters);
      formik.setFieldValue('mailRecipients', emailrecipients);
      setSelectedRecipientOptions(emailrecipients);
      dispatch({ type: Constant.RECIPIENTS, payload: [] });
      setLocalStorage('adminBulkRecipientList', []);
      setLocalStorage('adminBulkEmailStudents', []);
    }
  }, [getEmail.recipients]);

  useEffect(() => {
    const options = [];
    if (selectedRecipientOptions?.length > 0 && showLocationFilterRecipients) {
      selectedRecipientOptions?.forEach((stu) => {
        if (
          stu.filterKey !== 'locationFilter'
          && stu.filterKey !== 'yearFilter'
        ) {
          options.push(stu);
        }
      });
      if (selectedLocation !== '' && selectedLocation !== undefined) {
        const val = {
          label: getLocalStorage('selectedLocationName'),
          groupBy: 'recipients',
          filterKey: 'locationFilter',
        };
        options.push(val);
      }

      if (selectedYear !== '' && selectedYear !== undefined) {
        const val = {
          label: getLocalStorage('selectedYear'),
          groupBy: 'recipients',
          filterKey: 'yearFilter',
        };
        options.push(val);
      }
    }
    if (showLocationAnnouncementsRecipients) {
      if (selectedLocation !== '' && selectedLocation !== undefined) {
        const val = {
          label: getLocalStorage('selectedLocationName'),
          groupBy: 'recipients',
          filterKey: 'locationFilter',
        };
        options.push(val);
      }

      if (selectedYear !== '' && selectedYear !== undefined) {
        const val = {
          label: getLocalStorage('selectedYear'),
          groupBy: 'recipients',
          filterKey: 'yearFilter',
        };
        options.push(val);
      }
    }
    if (options.length > 0) {
      formik.setFieldValue('mailRecipients', options);
      setSelectedRecipientOptions(options);
    }
  }, [formik?.values?.location, formik?.values?.year]);

  const saveTemplate = useCallback(
    async (name, mailContent, mailSubject, id, filters) => {
      dispatch(
        saveEmailTemplate({
          title: name || formik?.values?.templateTitle,
          subject: mailSubject,
          body: mailContent,
          templateId: id,
          userId,
          filters,
        }),
      );
    },
    [],
  );
  const validateFields = (isCopy) => {
    let isValid = true;
    resetErrors();
    if ((!isCopy && formik?.values?.mailRecipients.length) === 0) {
      setRecipientError(t('MAIL_RECIPIENTS_REQUIRED'));
      isValid = false;
    }
    if (formik?.values?.mailSubject === '') {
      setSubjectError(t('MAIL_SUBJECT_REQUIRED'));
      isValid = false;
    }
    if (isCopy && formik?.values?.templateTitle === '') {
      setTitleError(t('MAIL_TITLE_REQUIRED'));
      isValid = false;
    }
    if (formik?.values?.mailContent === '<p></p>\n') {
      setContentError(t('MAIL_CONTENT_REQUIRED'));
      isValid = false;
    }
    if (isValid === true) {
      resetErrors('');
    }
    return isValid;
  };

  const handleSave = async (name, isCopy) => {
    if (validateFields(true)) {
      const templateId = isCopy ? '' : formik?.values?.template;
      await saveTemplate(
        name || formik?.values?.templateTitle,
        formik?.values?.mailContent,
        formik?.values?.mailSubject,
        templateId,
        JSON.stringify(formik?.values?.mailRecipients),
      ).finally(() => {
        setTimeout(() => {
          setNameTemplate(name);
          setNewTemplate(true);
          dispatch(getEmailTemplate());
        }, 200);
      });
    }
  };

  const openMakeCopyDialog = (open) => {
    const title = selectedTemplate?.name
      ? selectedTemplate?.name
      : selectedTemplate?.title;
    formik.setFieldValue('templateTitleCopy', `${title}-copy`);
    setDialogOpen(open);
  };

  const handleClose = () => {
    setDialogOpen(false);
    setPermissionDialog(false);
  };

  const handleMakeACopy = () => {
    formik.setFieldValue('templateTitle', formik.values.templateTitleCopy);
    handleSave(formik.values.templateTitleCopy, true);
    handleClose();
  };

  const handleCreate = () => {
    formik.setFieldValue('template', '');
    formik.setFieldValue('mailSubject', '');
    formik.setFieldValue('templateTitle', '');
    const html = '';
    const contentBlock = htmlToDraft(html);
    if (contentBlock) {
      const contentState = ContentState.createFromBlockArray(
        contentBlock.contentBlocks,
      );
      const textEditorState = EditorState.createWithContent(contentState);
      setEditorState(textEditorState);
      formik.setFieldValue(
        'mailContent',
        draftToHtml(convertToRaw(editorState.getCurrentContent())),
      );
    }
  };

  const handleCancelEmail = () => {
    const moduleReturn = getLocalStorage('moduleReturn');
    if (userRole === userRoles.LOCATION_COORDINATOR) {
      if (moduleReturn === 'dashboard') {
        navigate(NavigateRoutes.LOCATION_COORDINATOR_STUDENTS_DASHBOARD);
      } else if (moduleReturn !== 'Students') {
        navigate(NavigateRoutes.LC_ANNOUNCEMENTS);
      } else {
        navigate(NavigateRoutes.LC_STUDENTS);
      }
      // handleCreate();
    } else if (userRole === userRoles.TEACHER) {
      navigate(NavigateRoutes.MY_CLASS);
    } else if (moduleReturn === 'student') {
      navigate(NavigateRoutes.STUDENTS_VIEW);
    } else {
      navigate(NavigateRoutes.USER_MANAGER_VIEW);
    }
  };

  const sendEmail = useCallback(async (payload, loader, openSuccessDialog, openErrorDialog) => {
    setLoading(true);
    dispatch(sendAnnouncement(payload, loader, openSuccessDialog, openErrorDialog, setErrorCode));
  }, []);
  const handleEmail = async () => {
    // loading = useState(true);
    localStorage.setItem('sendAnnouncement', 'true');
    localStorage.setItem('moduleReturn', 'Announcements');
    if (validateFields(false)) {
      let data;
      let list;
      if (localStorage.getItem('userManager') === null) {
        list = new DataList(selectedRecipientOptions);
        data = list.finalFilters;
      } else {
        list = new DataList(formik.values.mailRecipients);
        data = list.finalFilters;
      }
      const eBody = formik.values.mailContent.split('*');
      const sBody = eBody.join('');
      let emailToSend = [];
      const payload = {
        filter: data,
        substitutionTags: formik?.values?.substitutionTags,
        subject: formik?.values?.mailSubject,
        emailBody: sBody,
        locationId: formik?.values?.location,
        sender: userEmailId,
        userId,
      };
      if (list.studentCount > 0) {
        emailToSend = [...data[0].Student, ...getEmail.emailParents];
        payload.recipients = emailToSend.filter(
          (obj) => obj !== undefined && obj !== '',
        );
      }
      setLocalStorage('moduleReturn', 'Announcements');
      await sendEmail(payload, setLoading, setSuccessDialog, setErrorDialog);
      // handleCancelEmail();
    }
  };
  useEffect(() => {
    formik.setFieldValue(
      'mailContent',
      draftToHtml(convertToRaw(editorState.getCurrentContent())),
    );
  }, [editorState]);
  const initialRecipientOptions = [
    /* {
      label: '',
      groupBy: '',
      filterKey: '',
    }, */
  ];
  const getGroupOptions = () => {
    const studentFil = [...recipientOptions];
    const options = [];
    studentFil?.forEach((stu) => {
      options.push({
        label: stu.key,
        groupBy: formik.values.emailFilter,
        filterKey: formik.values.emailFilter,
      });
    });
    return options.length > 0 ? options : initialRecipientOptions;
  };
  const addSelectedOptions = (arrayField) => {
    const regionValue = arrayField.find((el) => el.groupBy === 'Region');
    const locationValue = arrayField.find((el) => el.groupBy === 'Location');
    const studentValue = arrayField.find((el) => el.groupBy === 'Student');
    const sectionValue = arrayField.find((el) => el.groupBy === 'Section');
    const userValue = arrayField.find((el) => el.groupBy === 'User');

    if (regionValue) {
      localStorage.setItem('regionEmailTemplate', regionValue.label);
    } else {
      localStorage.setItem('regionEmailTemplate', '');
    }

    if (locationValue) {
      localStorage.setItem('locationEmailTemplate', locationValue.label);
    } else {
      localStorage.setItem('locationEmailTemplate', '');
    }
    if (studentValue) {
      localStorage.setItem('studentEmailTemplate', studentValue.label);
    } else {
      localStorage.setItem('studentEmailTemplate', '');
    }
    if (sectionValue) {
      localStorage.setItem('sectionEmailTemplate', sectionValue.label);
    } else {
      localStorage.setItem('sectionEmailTemplate', '');
    }

    if (userValue) {
      localStorage.setItem('userTemplate', userValue.label);
    } else {
      localStorage.setItem('userTemplate', '');
    }
  };

  const onGroupAutoCompleteSelection = (e, val) => {
    // fetchEmailRecipients('', '', '');
    addSelectedOptions(val);
    setSelectedRecipientOptions(val);
    formik.setFieldValue('mailRecipients', val);
    if (selectedRecipientOptions) {
      if (val.length === 0) {
        localStorage.setItem('showSelectAllinEmail', true);
        setShowSelectAllinEmail(true);
      } else {
        localStorage.setItem('showSelectAllinEmail', false);
        setShowSelectAllinEmail(false);
      }
    } else if (!selectedRecipientOptions) {
      localStorage.setItem('showSelectAllinEmail', true);
      setShowSelectAllinEmail(true);
    }
  };
  const substitutionTagButton = (el, item) => {
    setTag(`<strong> *${item.text}*</strong>`);
    const subTag = { title: el.title, options: [item] };
    if (substitutionTag.some((e) => e.title === el.title)) {
      const temp = [...substitutionTag];
      temp.find((e) => e.title === el.title).options.push(item);
      setSubstitutionTag(temp);
    } else {
      const temp2 = [...substitutionTag];
      temp2.push(subTag);
      setSubstitutionTag(temp2);
    }
  };

  const renderErrorDialog = () => (
    <Grid className={classes.studentBox}>
      <Box
        component="form"
        sx={{
          '& .MuiTextField-root': { m: 1, width: '25ch' },
        }}
        noValidate
        autoComplete="off"
      >
        {errorCode === 2 ? (
          <Grid>
            <div className={classes.alertprimary} role="alert">
              {t('ERROR_USER_INFO')}
            </div>
          </Grid>
        ) : errorCode === 4 ? (
          <Grid>
            <div className={classes.alertprimary} role="alert">
              {t('TAGS_ERROR')}
            </div>
          </Grid>
        ) : (
          <Grid>
            <div className={classes.alertprimary} role="alert">
              {t('ERROR_MESSAGE_ANNOUNCEMENTS')}
            </div>
          </Grid>
        )}
      </Box>
      <Grid item xs={12} className={`${classes.dialogButtons} ${classes.popupButton}`}>
        <ButtonAtom
          className={classes.popupBtn}
          name={t('OK')}
          onClick={() => setErrorDialog(false)}
          btntype={Buttons.PRIMARY}
        />
      </Grid>
    </Grid>
  );
  const renderErrorFilterDialog = () => (
    <Grid className={classes.studentBox}>
      <Box
        component="form"
        sx={{
          '& .MuiTextField-root': { m: 1, width: '25ch' },
        }}
        noValidate
        autoComplete="off"
      >
        <Grid>
          <div className={classes.alertprimary} role="alert">
            {t('ERROR_MESSAGE')}
          </div>
        </Grid>
      </Box>
      <Grid item xs={12} className={`${classes.dialogButtons} ${classes.popupButton}`}>
        <ButtonAtom
          className={classes.popupBtn}
          name={t('OK')}
          onClick={() => setOpenErrorFilterDialog(false)}
          btntype={Buttons.PRIMARY}
        />
      </Grid>
    </Grid>
  );

  const renderSuccessDialog = () => (
    <Grid className={classes.studentBox}>
      <Box
        component="form"
        sx={{
          '& .MuiTextField-root': { m: 1, width: '25ch' },
        }}
        noValidate
        autoComplete="off"
      >
        <Grid>
          <div className={classes.alertprimary} role="alert">
            {t('SUCCESS_MESSAGE_ANNOUNCEMENTS')}
          </div>
        </Grid>
      </Box>
      <Grid item xs={12} className={`${classes.dialogButtons} ${classes.popupButton}`}>
        <ButtonAtom
          className={classes.popupBtn}
          name={t('OK')}
          onClick={() => handleCancelEmail()}
          btntype={Buttons.PRIMARY}
        />
      </Grid>
    </Grid>
  );

  useEffect(() => {
    if (localStorage.getItem('permission') === 'false') {
      setPermissionDialog(true);
    }
  }, [localStorage.getItem('permission')]);

  if (loading) {
    return (
      <Grid>
        <Loader message={t('LOADING')} />
      </Grid>
    );
  }

  function handleImageUpload(file) {
    return new Promise(
      (resolve) => {
        const formData = new FormData();
        formData.append('file', file);
        formData.append('upload_preset', 'your_cloudinary_upload_preset');
        const response = uploadFileEmailService(formData);
        resolve(response);
      },
    );
  }

  const announcementDetails = async () => {
    let academicYear = formik?.values?.year;
    let data;
    let list;
    const sendAllUserList = false;
    if (localStorage.getItem('userManager') === null) {
      list = new DataList(selectedRecipientOptions);
      data = list.finalFilters;
      academicYear = getLocalStorage('academicYear');
    } else {
      list = new DataList(selectedRecipientOptions);
      data = list.finalFilters;
    }
    const transformedFilters = data.reduce((acc, obj) => {
      Object.entries(obj).forEach(([key, values]) => {
        if (!acc[key]) {
          acc[key] = [];
        }
        acc[key].push(...values);
      });
      return acc;
    }, {});
    const formattedFilters = Object.entries(transformedFilters)
      .map(([key, values]) => `${key}: (${values.join(', ')})`)
      .join(', ');
    const transformedTags = substitutionTag?.reduce((acc, obj) => {
      const { title, options } = obj;
      const values = options.map((option) => option.text);
      acc[title] = values;
      return acc;
    }, {});
    const formattedTags = Object.entries(transformedTags)
      .map(([key, values]) => `${key}: (${values.join(', ')})`)
      .join(', ');
    const eBody = formik.values.mailContent.split('*');
    const sBody = eBody.join('');
    const impersonateToken = getLocalStorage('impersonateToken');
    const payload = {
      filter: formattedFilters,
      subject: formik?.values?.mailSubject,
      emailBody: sBody,
      sender: '',
      tags: formattedTags,
      count: '0',
    };
    if (impersonateToken) {
      const impersonateUserValues = JSON.parse(getLocalStorage('impersonateUser'));
      payload.sender = impersonateUserValues?.state?.email;
    } else {
      payload.sender = userEmailId;
    }
    const body = {
      filter: data,
      substitutionTags: substitutionTag,
      locationId: formik?.values?.location,
      academicYear,
      sender: '',
      userId,
      sendAllUserList,
      userRole,
    };
    let emailToSend = [];
    if (JSON.parse(getLocalStorage('mailFilter')) === 'User') {
      data[0].User = data[0].Student;
      delete data[0].Student;
      emailToSend = [...data[0].User];
      body.recipients = emailToSend.filter(
        (obj) => obj !== undefined && obj !== '',
      );
    } else if (list.studentCount > 0) {
      emailToSend = [...data[0].Student];
      body.recipients = emailToSend.filter(
        (obj) => obj !== undefined && obj !== '',
      );
    }

    const handleFetchCount = async () => {
      try {
        const count = await getRecipientsCount(body);
        payload.count = count;
      } catch (error) {
        setErrorDialog(true);
        setErrorCode(1);
      } finally {
        setLoading(false);
      }
    };

    handleFetchCount();

    return payload;
  };

  return (
    <Box className={classes.gridContainer}>
      <FormikProvider value={formik}>
        {(userRole === userRoles.SYSTEM_ADMIN || userRole === userRoles.SUPER_USER) && showSelectAllinEmail ? (
          <Grid container className={classes.selectAllCheck}>
            <CheckboxesAtom
              label={t('SELECT_ALL')}
              id="acceptCheckbox"
              checked={isSelectAllChecked}
              handleChange={() => SelectAllRecipients()}
            />
          </Grid>
        ) : (
          <div />
        )}
        {userRole === userRoles.LOCATION_COORDINATOR ? (
          <>
            <Grid container>
              <Typography className={classes.headerTitle}>
                {t('NEW_ANNOUNEMENT')}
              </Typography>
            </Grid>
            <Grid container className={classes.locationYearDropdowns}>
              <Filters
                {...{
                  formik,
                  classes,
                  t,
                }}
              />
            </Grid>
          </>
        ) : (
          <div />
        )}
        <Grid
          container
          className={classes.title}
          style={{ paddingBottom: '1rem' }}
        >
          <Typography className={classes.headerTitle}>
            {t('RECIPIENTS')}
            :
          </Typography>
        </Grid>
        <Grid
          container
          align="center"
          spacing={1}
          justifyContent="space-between"
        >
          <Grid container xs={12} sm={6} className={classes.autoComplete}>
            <GroupedAutoComplete
              options={getGroupOptions()}
              onSelection={onGroupAutoCompleteSelection}
              value={selectedRecipientOptions}
              label={t('RECIPIENTS')}
              placeholder={formik.values.emailFilter}
              allowUserInput
              onInputChange={(e, newInputValue) => onInputChange(e, newInputValue)}
              disabled={isSelectAllChecked}
            />
            <span className={classes.errorText}>{recipientError}</span>
          </Grid>
          <Grid container xs={5} sm={2.5} className={classes.filterTemplate}>
            {
              (userRole !== 'Teacher')
              && (
                <Dropdown
                  shrink
                  minWidth="100%"
                  label={`${t('SET_FILTER')}`}
                  id="emailFilter"
                  name="emailFilter"
                  required
                  labelId="emailFilter"
                  value={formik.values.emailFilter}
                  options={emailsOptions}
                  handleChange={formik.handleChange}
                />
              )
            }

          </Grid>
          <Grid container xs={5} sm={2.5} className={classes.emailTemplate}>
            <Dropdown
              shrink
              minWidth="100%"
              label={`${t('SELECT_TEMPLATE')}`}
              id="select-template"
              name="select-template"
              required
              labelId="select-template"
              disabled={userRole === userRoles.LOCATION_COORDINATOR}
              options={templateOptions}
              value={formik.values.template}
              handleChange={(e) => {
                formik.setFieldValue('template', e.target.value);
                const template = templateOptions.find(
                  (opt) => opt.id === e.target.value,
                );
                /* eslint-disable */
                setSelectedTemplate(template);
                Array.isArray(template.filters) && template.filters.length !== 0 ? setSelectedRecipientOptions(template.filters) : null;
                formik.setFieldValue('template', e.target.value);
              }}
            />
          </Grid>
          <Grid
            container
            xs={1}
            className={classes.saveIcon}
            justifyContent="flex-end"
          >
            <Tooltip title={t('SAVE_UPDATE_TEMPLATE')} placement="right">
              <Button
                variant="contained"
                size="small"
                onClick={() => handleSave(formik?.values?.templateTitle)}
                className={classes.saveButton}
              >
                <SaveIcon />
              </Button>
            </Tooltip>
          </Grid>
        </Grid>
        <Grid
          container
          align="center"
          spacing={1}
          justifyContent="space-between"
        >
          <Grid container className={classes.mailSub} xs={8.5}>
            <TextFieldAtom
              required
              minWidth="80%"
              id="subject"
              name="subject"
              label={`${t('EMAIL_SUBJECT')}`}
              value={formik.values.mailSubject}
              onChange={(e) => {
                resetErrors();
                return formik.setFieldValue('mailSubject', e.target.value);
              }}
            />
            <span className={classes.errorText}>{subjectError}</span>
          </Grid>
          <Grid
            container
            xs={2.35}
            className={classes.mailSubright}
            justifyContent="flex-start"
          >
            <TextFieldAtom
              minWidth="98%"
              required
              id="title"
              name="title"
              label={`${t('TITLE')}`}
              value={formik.values.templateTitle}
              onChange={(e) => {
                formik.setFieldValue('templateTitle', e.target.value);
                formik.setFieldValue(
                  'templateTitleCopy',
                  `${e.target.value}-copy`,
                );
              }}
            />
            <span className={classes.errorText}>{titleError}</span>
          </Grid>
          <Grid container xs={1.15} className={classes.blankSpace} />
        </Grid>
        <Grid container item className={classes.createNew}>
          <Grid container xs={12} md={2.45}>
            <Link
              component="button"
              disabled={!formik.values.templateTitle}
              className={classes.cursorPointer}
              onClick={() => openMakeCopyDialog(true)}
              underline="none"
            >
              {t('MAKE_A_COPY')}
            </Link>
            <Link
              component="button"
              className={`${classes.cursorPointer} ${classes.createNew}`}
              onClick={() => handleCreate()}
              underline="none"
            >
              {t('CREATE_NEW')}
            </Link>
          </Grid>
        </Grid>
        <Grid container align="center" spacing={0} mt={5} ml={0}>
          <Grid container xs={12} md={8} className={classes.emailEditor}>
            <Editor
              toolbarClassName="toolbarClassName"
              wrapperClassName="wrapperClassName"
              editorClassName={classes.editorClass}
              editorState={editorState}
              onEditorStateChange={setEditorState}
              toolbar={{
                image: {
                  previewImage: true,
                  inputAccept: 'image/gif,image/jpeg,image/jpg,image/png,image/svg',
                  uploadCallback: handleImageUpload,
                  alt: { present: true, mandatory: false },
                  defaultSize: {
                    height: '500',
                    width: '500',
                  },
                },
              }}
            >
              <div ref={bottomRef} />
            </Editor>
            <span className={classes.errorText}>{contentError}</span>
          </Grid>
          <Grid container xs={12} md={4} className={classes.substitutionTag} alignContent="start">
            <Box
              className={classes.substitutionTagLabel}
            >
              <Typography color="#1976d2" fontSize="18px">
                <b>{t('Substitution Tags')}</b>
              </Typography>
              <Typography variant="body2">
                {t('Select user/student info and click on the editor.')}
              </Typography>
            </Box>
            <Grid
              container
              sx={{
                '@media (min-width: 1200px)': {
                  flexDirection: 'row',
                  justifyContent: 'center',
                  paddingTop: '30px',
                  paddingRight: '80px',
                },
                '@media (max-width: 600px)': {
                  margin: '5px 3vw 3vw 0',
                  justifyContent: 'center',
                  flexDirection: 'row',
                },
              }}
            >
              {' '}
              <TextField
                id="search"
                variant="outlined"
                sx={{
                  '@media (min-width: 1200px)': {
                    maxWidth: '70%',
                  },
                }}
                placeholder="Search"
                value={searched}
                onChange={(searchVal) => setSearched(searchVal.target.value)}
                InputProps={{
                  startAdornment: (
                    <IconButton>
                      <GridSearchIcon />
                    </IconButton>
                  ),
                }}
              />
              <List
                className={classes.substitutionTagList}
                subheader={<li />}
              >
                {localStorage.getItem('userManager') ? (
                  <div>
                    {
                      substitutionTagsAdmin.map((el) => {
                        const options = el.options.filter((item) => item.text.toLowerCase().includes(searched.toLowerCase()));
                        if (options.length === 0) return null;
                        return (
                          <li key={el.title} style={{ textAlign: 'start' }}>
                            <ul>
                              <ListSubheader className={classes.substitutionTagListHeader}>{`${el.title}:`}</ListSubheader>
                              {options.map((item) => (
                                <ListItem key={item.key} className={classes.listItem}>
                                  <ListItemButton className={classes.listItemButton} onClick={() => { substitutionTagButton(el, item); }}>
                                    <ListItemText primary={item.text} />
                                  </ListItemButton>
                                </ListItem>
                              ))}
                            </ul>
                          </li>
                        );
                      })
                    }
                  </div>
                ) : (
                  <div>
                    {
                      getEmail.substitutionTags.map((el) => {
                        const options = el.options.filter((item) => item.text.toLowerCase().includes(searched.toLowerCase()));
                        if (options.length === 0) return null;
                        return (
                          <li key={el.title} style={{ textAlign: 'start' }}>
                            <ul>
                              <ListSubheader className={classes.substitutionTagListHeader}>{`${el.title}:`}</ListSubheader>
                              {options.map((item) => (
                                <ListItem key={item.key} className={classes.listItem}>
                                  <ListItemButton className={classes.listItemButton} onClick={() => { substitutionTagButton(el, item); }}>
                                    <ListItemText primary={item.text} />
                                  </ListItemButton>
                                </ListItem>
                              ))}
                            </ul>
                          </li>
                        );
                      })
                    }
                  </div>
                )}
              </List>
            </Grid>
            {/* <span className={classes.errorText}>{contentError}</span> */}
          </Grid>
        </Grid>
        <Grid
          container
          item
          justifyContent="center"
          className={classes.actionButtons}
        >
          <ButtonAtom
            name={t('CANCEL')}
            onClick={() => handleCancelEmail()}
            btntype={Buttons.SECONDARY}
          />
          <ButtonAtom
            name={t('PREVIEW_ANNOUNCEMENTS')}
            onClick={async () => {
              if (validateFields(false)) {
                setLoading(true);
                const details = await announcementDetails();
                setAnnouncementDetails(details);
                if (!errorDialog) {
                  setShowAnnouncementDialog(true);
                }
              }
            }}
            btntype={Buttons.PRIMARY}
          />
        </Grid>
      </FormikProvider>
      <DialogAtom
        customClass={classes.dialogAtom}
        isOpen={permissionDialog}
        dialogHeading="NO PERMISSION"
        primaryHandle={() => handleClose()}
        secHandle={() => handleClose()}
        content={(
          <DialogContent
            classes={classes}
            message
            messageContent="You don't have sufficient permission to access the requested resource."
          />
        )}
        footer={(
          <DialogFooter
            classes={classes}
            handleDialogCancel={() => {
              setPermissionDialog(false);
              localStorage.removeItem('permission');
            }}
            t={t}
            message
          />
        )}
      />
      <DialogAtom
        customClass={classes.dialogAtom}
        isOpen={isDialogOpen}
        dialogHeading={t('MAKE_A_COPY')}
        primaryHandle={() => handleClose()}
        secHandle={() => handleClose()}
        content={(
          <DialogContent
            classes={classes}
            t={t}
            formik={formik}
            templateTitle={formik.values.templateTitleCopy}
          />
        )}
        footer={(
          <DialogFooter
            classes={classes}
            handleDialogCancel={() => handleClose()}
            handleDialogSave={() => handleMakeACopy()}
            t={t}
          />
        )}
      />
      <DialogAtom
        isOpen={successDialog}
        dialogHeading={t('SENDING_ANNOUNCEMENTS')}
        customClass={classes.DialogAtom}
        content={renderSuccessDialog()}
        secHandle={() => handleCancelEmail()}
      />
      <DialogAtom
        isOpen={errorDialog}
        dialogHeading={t('ERROR_ANNOUNCEMENTS')}
        customClass={classes.DialogAtom}
        content={renderErrorDialog()}
        secHandle={() => setErrorDialog(false)}
      />
      <DialogAtom
        isOpen={openErrorFilterDialog}
        dialogHeading={t('ERROR_ANNOUNCEMENTS')}
        customClass={classes.DialogAtom}
        content={renderErrorFilterDialog()}
        secHandle={() => setOpenErrorFilterDialog(false)}
      />
      <PreviewDialog
        isOpen={showAnnouncementDialog}
        sendAnnouncement={() => {
          setShowAnnouncementDialog(false);
          handleEmail();
        }}
        editAnnouncement={() => setShowAnnouncementDialog(false)}
        announcementDetails={announcementDetailsData}
      />
    </Box>
  );
}
