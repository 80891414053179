/* eslint-disable */
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import {
  Grid,
  Stack, Typography,
} from '@mui/material';
import HomeIcon from '@mui/icons-material/HomeOutlined';
import FolderIcon from '@mui/icons-material/FolderOutlined';
import GridViewIcon from '@mui/icons-material/GridView';

import DoNotDisturbIcon from '@mui/icons-material/DoNotDisturb';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import { useNavigate } from 'react-router-dom';
import ListViewIcon from '../../../../assets/svg/listViewIcon';
import MapPin from '../../../../assets/images/map-pin.png';
import {
  getAllLocationCourse,
  getAssignedLocations,
  setSelectedYear,
  setSelectedLocation,
} from '../../../../store/actions/getLocationCoordinator';

import { NavigateRoutes } from '../../../../constant';
import useStyles from '../../../../custom-hooks/useStyles';
import Dropdown from '../../../../components/atoms/dropdown';
import { Loader } from '../../../../components/atoms';
import {
  setLocalStorage,
  getLocalStorage,
} from '../../../../utils/localStorageMethod';
import userRoles from '../../../../constant/userRoles';
import styles from '../style';
import ListViewTeacher from '../list-view';
import GridView from '../grid-view';

import { getStudentResourceCourse } from '../../../../store/actions/getResources';
import { getAssignedUserRoles } from '../../../../store/actions/getUser';
import decryptedUserRoles from '../../../../constant/decryptedUserRoles';
import { classesByYear, setSelectedYear as sSYT, setSelectedClassRooms as sSCT } from '../../../../store/actions/getTeacherView';
import { removeDuplicates } from '../../../../components/atoms/tabel/utils';
import useDataTableParams from '../../../../custom-hooks/useDataTableParams';
import { getCurrentYear } from '../../../../utils/methods';

export default function ResourcesData() {
  const {
    nextPage,
  } = useDataTableParams();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const classes = useStyles(styles)();
  const [view, setView] = useState('gridView');
  const [locationData, setLocationData] = useState([]);
  const dispatch = useDispatch();
  const reduxStore = useSelector((state) => state?.getLocationCoordinator);
  const selectedYearRedux = reduxStore?.selectedYear;
  const selectedLocationRedux = reduxStore?.selectedLocations;
  const reduxAdminStore = useSelector((state) => state?.getAdmin);
  const assignedLocations = reduxStore?.assignedLocations;
  const locationCourses = reduxStore?.locationCourses;
  const assignedYears = reduxStore?.assignedYears;
  const studentResourceWRT = reduxStore?.studentResourceWRT;
  const getStudentResourceInFolderResponse = reduxAdminStore?.studentResourcesByFolder;
  const [isselectedYear, setSelectYear] = useState();
  const [isselectedLocation, setSelectedLocationn] = useState();
  const [selectedResource, setSelectedResource] = useState([]);
  const [folderName, setFolderName] = useState([]);
  const [isSelectedvalue, setSelectedValue] = useState('');
  const [studentResource, setStudentResource] = useState([]);
  const [studentResourceTeacherView, setStudentResourceTeacherView] = useState([]);
  const [loading, setLoading] = useState(false);
  const loadFalse = () => setLoading(false);
  const encryptedUserRole = getLocalStorage('userRole');
  const userRole = decryptedUserRoles[encryptedUserRole];
  const tempUserRoleLocalStorage = getLocalStorage('tempUserRole');
  const tempUserRole = decryptedUserRoles[tempUserRoleLocalStorage];
  const isTeacher = (userRole === userRoles.TEACHER);
  const isLC = (userRole === userRoles.LOCATION_COORDINATOR) || (tempUserRole === userRoles.LOCATION_COORDINATOR);
  const teacherPanel = useSelector((state) => state?.getTeacherView);
  const [assignedClassrooms, setAssignedClassRooms] = useState([]);
  const [isSelectedClassRoom, setSelectedClassRoom] = useState('');
  const [assignedCourses, setAssignedCourses] = useState([]);
  const [selectedClassroomFromSameScreen, setSelectedClassroomFromSameScreen] = useState(null);
  const [classRoomTargetValue, setClassRoomTargetValue] = useState('');
  const [totalRows, setTotalRows] = useState(0);
  const [pagenum, setPagenum] = useState(1);
  const [isFetching, setIsFetching] = useState(false);
  const [sortBoth, setSortBoth] = useState(true);
  const [assignedUserRoles, setAssignedUserRoles] = useState([]);
  const [isSysAdminOrSuperUser, setIsSysAdminOrSuperUser] = useState(false);
  const [sortOrder, setSortOrder] = useState({
    resourceN: 'both',
    createdBy: 'both',
    createdOn: 'both',
    updatedBy: 'both',
    updatedOn: 'both',
    sizeN: 'both',
  });

  const resetSortOder = () => {
    setSortOrder({
      resourceN: 'both',
      createdBy: 'both',
      createdOn: 'both',
      updatedBy: 'both',
      updatedOn: 'both',
      sizeN: 'both',
    });
  };

  useEffect(() => {
    dispatch(getAssignedUserRoles())
      .then((data) => {
        setAssignedUserRoles(data?.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  useEffect(() => {
    let isAdminOrSuperUser = false;
    assignedUserRoles.map((ur) => {
      if (ur?.name === userRoles.SYSTEM_ADMIN || ur?.name === userRoles.SUPER_USER || ur?.name === userRoles.CURRICULUM) {
        isAdminOrSuperUser = true;
      }
      return isAdminOrSuperUser;
    });
    setIsSysAdminOrSuperUser(isAdminOrSuperUser);
  }, [assignedUserRoles]);

  if (isTeacher) {
    useEffect(() => {
      setLoading(true);
      dispatch(getAssignedLocations(loadFalse));
    }, []);

    useEffect(() => {
      setLoading(true);
      const currentAcademicYear = getCurrentYear()?.id;
      const index = assignedYears.findIndex((cur) => cur.name === currentAcademicYear);
      if (index >= 0) {
        if (teacherPanel?.selectedYear?.name) {
          setSelectYear(teacherPanel?.selectedYear?.name);
        } else {
          setSelectYear(assignedYears[index]?.id);
        }
      }
    }, [assignedYears]);

    useEffect(() => {
      setLoading(true);
      const payload = {
        academicYear: isselectedYear,
      };
      if (isselectedYear !== '' && isselectedYear !== undefined) {
        dispatch(classesByYear(payload));
      }
      resetSortOder();
    }, [isselectedYear]);

    useEffect(() => {
      const classRooms = teacherPanel.classesByYear?.map((obj) => ({
        id: obj?.googleClassId,
        name: `${obj?.name}(${obj?.sectionName}) - ${obj?.locationName}`,
        courseId: obj?.courseId,
        courseName: obj?.name,
        locationId: obj?.locationId,
      }));
      setAssignedClassRooms(classRooms);
    }, [teacherPanel.classesByYear]);

    useEffect(() => {
      if (teacherPanel?.selectedClassroom?.value && selectedClassroomFromSameScreen === null) {
        setSelectedClassRoom(teacherPanel?.selectedClassroom?.value);
      } else {
        if (!classRoomTargetValue) {
          setSelectedClassRoom(assignedClassrooms?.[0]?.id);
        }
      }
    }, [assignedClassrooms, teacherPanel?.selectedClassroom?.value, selectedClassroomFromSameScreen, classRoomTargetValue]);

    useEffect(() => {
      if (assignedCourses[0]?.id) {
        const selectedClassroom = assignedClassrooms.filter((obj) => obj.id === isSelectedClassRoom);
        if (isTeacher) {
          setSelectedValue(selectedClassroom[0]?.courseId);
        } else {
          setSelectedValue(assignedCourses[0]?.id);
        }
      }
    }, [assignedCourses]);

    useEffect(() => {
      const selectedClassroom = assignedClassrooms.filter((obj) => obj.id === isSelectedClassRoom);
      const selectedLocation = assignedClassrooms.filter((obj) => obj.locationId === selectedClassroom?.[0]?.locationId);
      const courses = [];
      selectedLocation?.forEach((obj) => {
        courses.push({
          id: obj.courseId,
          name: obj.courseName,
        });
      });
      const finalCourses = removeDuplicates(courses, 'id');
      setAssignedCourses(finalCourses);
      resetSortOder();
    }, [isSelectedClassRoom]);
  }

  if (isLC) {
    useEffect(() => {
      if (selectedLocationRedux?.value) {
        setSelectedLocationn(selectedLocationRedux?.value);
      } else {
        setSelectedLocationn(assignedLocations[0]?.id);
      }
    }, [assignedLocations, selectedLocationRedux]);

    useEffect(() => {
      dispatch(getAllLocationCourse({
        locationId: isselectedLocation,
      }));
      setSelectedLocationn(isselectedLocation);
      resetSortOder();
    }, [isselectedLocation]);

    useEffect(() => {
      resetSortOder();
    }, [isSelectedvalue]);

    useEffect(() => {
      dispatch(getAssignedLocations(loadFalse));
    }, []);
  }
  useEffect(() => {
    const rows = [];
    setTotalRows(getStudentResourceInFolderResponse?.data?.length);
    getStudentResourceInFolderResponse?.data?.map((index) => {
      if (index.deactivateDate === null) {
        rows.push(index);
      }
    });
    setStudentResourceTeacherView(rows);
    setStudentResource(rows);
  }, [getStudentResourceInFolderResponse]);

  useEffect(() => {
    const rows = [];
    setTotalRows(studentResourceWRT.length);
    studentResourceWRT.map((index) => {
      if (index.deactivateDate === null) {
        rows.push(index);
      }
    });
    setStudentResourceTeacherView(rows);
    setStudentResource(rows);
  }, [studentResourceWRT]);

  const handleChange = (event) => {
    setLoading(true);
    setIsFetching(true);
    setFolderName([]);
    const payload = {
      courseId: event.target.value,
    };
    dispatch(getStudentResourceCourse(1, 100, nextPage, payload, setIsFetching));

    setSelectedResource([]);
    setSelectedValue(event.target.value);
    setPagenum(1);
  };

  const handleLocationChange = (event) => {
    setLoading(true);
    setIsFetching(true);
    setFolderName([]);
    const payload = {
      courseId: event,
    };
    dispatch(getStudentResourceCourse(1, 100, nextPage, payload, setIsFetching, setLoading));
    setSelectedResource([]);
    setSelectedValue(event);
    setPagenum(1);
  };

  const redirectToHomeScreen = () => {
    navigate(NavigateRoutes.TEACHER_PANEL_STUDENTS_RESOURCES);
    setLoading(true);
    setIsFetching(true);
    setSelectedResource([]);
    setFolderName([]);
    if (isSelectedvalue !== '' && isSelectedvalue !== undefined) {
      const payload = {
        courseId: isSelectedvalue,
      };
      dispatch(getStudentResourceCourse(1, 100, nextPage, payload, setIsFetching, setLoading));
    }
  };

  const handleAssignyear = (event) => {
    setSelectYear(event.target.value);
    const payload = {
      id: event.target.value,
      name: event.target.value,
    };
    dispatch(setSelectedYear(payload));
  };

  const handlelocation = (event) => {
    setSelectedLocationn(event.target.value);
    dispatch(setSelectedLocation(event.target, loadFalse));
  };

  if (isTeacher) {
    const selectedClassroom = assignedClassrooms.filter((obj) => obj.id === isSelectedClassRoom);
    useEffect(() => {
      if (selectedClassroom[0]?.courseId) {
        handleLocationChange(selectedClassroom[0]?.courseId);
      }
    }, [assignedCourses]);
  }
  if (isLC) {
    useEffect(() => {
      setLocationData(locationCourses);
      const currentAcademicYear = getCurrentYear()?.id;
      const index = assignedYears.findIndex((cur) => cur.name === currentAcademicYear);
      if (index >= 0) {
        if (selectedYearRedux?.id) {
          setSelectYear(selectedYearRedux?.id);
        } else {
          setSelectYear(assignedYears[index]?.id);
        }
      }

      if (locationCourses[0]?.id) {
        const event = {
          target: {
            value: locationCourses[0]?.id,
          },
        };
        handleChange(event);
      }
    }, [locationCourses]);
  }

  const handleView = (event, myResourceView) => {
    if (myResourceView !== null) {
      setView(myResourceView);
    }
  };

  return (
    <div className={classes.gridPadding}>
      <div className={classes.divstule}>
        <Stack sx={{ width: '100%' }} spacing={4}>
          <Grid container flexDirection="row" display="flex" className={`${classes.divWrapper}`}>
            <Grid container item className={classes.titleRow}>
              <Typography className={classes.headerTitle}>
                {t('STUDENT_RESOURCES')}
              </Typography>

            </Grid>
            <Grid item className={classes.HeaderWrapper}>
              {
                (isTeacher && !isLC)
                && (
                  <div className={classes.dropdownWrap}>
                    <div className={classes.assignyear}>
                      <Dropdown
                        minWidth="100%"
                        id="myClass"
                        variant="standard"
                        options={assignedYears}
                        value={isselectedYear}
                        customClass={classes.dropdown}
                        changeCss
                        customFormControlCss={{ width: '100%' }}
                        labelId="myClass"
                        handleChange={(e) => {
                          setClassRoomTargetValue('');
                          setSelectYear(e.target.value);
                          const payload = {
                            id: e.target.value,
                            name: e.target.value,
                          };
                          dispatch(sSYT(payload, loadFalse));
                          setPagenum(1);
                          setLoading(true);
                          setStudentResource([]);
                          setStudentResourceTeacherView([]);
                          setSelectedResource([]);
                          setFolderName([]);
                        }}
                      />
                    </div>
                    <div className={classes.locationTeacher}>
                      <Dropdown
                        minWidth="100%"
                        id="googleClass"
                        variant="standard"
                        options={assignedClassrooms}
                        value={isSelectedClassRoom}
                        customClass={classes.dropdown}
                        changeCss
                        customFormControlCss={{ width: '100%' }}
                        labelId="myClass"
                        handleChange={(e) => {
                          const selectedClassroom = assignedClassrooms.filter((obj) => obj.id === e.target.value);
                          dispatch(sSCT(e.target, loadFalse));
                          handleLocationChange(selectedClassroom[0].courseId);
                          localStorage.setItem('CourseTeacher', selectedClassroom[0].courseId);
                          localStorage.setItem('LocationTeacher', selectedClassroom[0].locationId);
                          setSelectedValue();
                          setSelectedClassRoom(e.target.value);
                          setClassRoomTargetValue(e.target.value);
                          setSelectedClassroomFromSameScreen(true);
                          setPagenum(1);
                          setLoading(true);
                          setStudentResource([]);
                          setStudentResourceTeacherView([]);
                          setSelectedResource([]);
                          setFolderName([]);
                        }}
                        icon={(
                          <HomeIcon
                            style={{ cursor: 'pointer' }}
                            onClick={() => {
                              setLoading(true);
                              setStudentResource([]);
                              setStudentResourceTeacherView([]);
                              setSelectedResource([]);
                              setFolderName([]);
                              redirectToHomeScreen();
                              setPagenum(1);
                            }}
                          />
                        )}
                      />
                    </div>
                    {selectedResource?.resourceName && (
                      <div className={classes.studentcourse}>
                        {folderName.map((name) => {
                          return (
                            <span style={{ display: 'flex' }}>
                              <div style={{ color: '#104F96', paddingLeft: '2px', paddingRight: '4px' }}>/</div>
                              <FolderIcon style={{ color: '#104F96', paddingRight: '2px' }} />
                              <div
                                style={{
                                  color: '#104F96',
                                  whiteSpace: 'nowrap',
                                  textOverflow: 'ellipsis',
                                  width: '10vw',
                                  overflow: 'hidden',
                                }}
                              >
                                {name?.resourceName}
                              </div>
                            </span>
                          );
                        })}
                      </div>
                    )}
                  </div>
                )
              }
              {isLC && (
                <div className={classes.dropdownWrap}>
                  <div className={classes.assignyear}>
                    <Dropdown
                      minWidth="145px"
                      id="assignyear"
                      variant="standard"
                      options={assignedYears}
                      value={isselectedYear}
                      customClass={classes.dropdownone}
                      changeCss
                      customFormControlCss={{ width: '180px' }}
                      labelId="assignyear"
                      handleChange={handleAssignyear}
                      defaultInputValue="dff"
                    />
                  </div>
                  <div className={classes.location}>
                    <Dropdown
                      id="location"
                      variant="standard"
                      options={assignedLocations}
                      value={isselectedLocation}
                      customClass={classes.dropdown}
                      changeCss
                      customFormControlCss={{ width: '250px' }}
                      labelId="location"
                      handleChange={handlelocation}
                      icon={<img src={MapPin} alt="" className={classes.mapPinImg} />}
                    />
                  </div>
                  <div className={classes.studentcourse}>
                    <Dropdown
                      id="studentCourse"
                      className={classes.helpSite}
                      variant="standard"
                      options={locationData}
                      value={isSelectedvalue}
                      customClass={classes.dropdown}
                      changeCss
                      customFormControlCss={{ width: '120px' }}
                      labelId="studentCourse"
                      handleChange={handleChange}
                      icon={(
                        <HomeIcon
                          style={{ cursor: 'pointer' }}
                          onClick={() => {
                            setLoading(true);
                            setStudentResource([]);
                            setStudentResourceTeacherView([]);
                            setSelectedResource([]);
                            setFolderName([]);
                            redirectToHomeScreen();
                            setPagenum(1);
                          }}
                        />
                      )}
                    />
                  </div>
                  {selectedResource?.resourceName && (
                    <div className={classes.studentcourse}>
                      {folderName.map((name) => {
                        return (
                          <span style={{ display: 'flex' }}>
                            <div style={{ color: '#104F96', paddingLeft: '2px', paddingRight: '4px' }}>/</div>
                            <FolderIcon style={{ color: '#104F96', paddingRight: '2px' }} />
                            <div
                              style={{
                                color: '#104F96',
                                whiteSpace: 'nowrap',
                                textOverflow: 'ellipsis',
                                width: '10vw',
                                overflow: 'hidden',
                              }}
                            >
                              {name?.resourceName}
                            </div>
                          </span>
                        );
                      })}
                    </div>
                  )}
                </div>
              )}

              <div>
                <Stack direction="row" spacing={1} justifyContent="space-between">
                  <ToggleButtonGroup
                    value={view}
                    exclusive
                    aria-label="text alignment"
                    onChange={handleView}
                    className={classes.toggleButtonGrp}
                  >
                    <ToggleButton
                      value="listView"
                      aria-label="listView"
                      className={classes.toggleList}
                      onClick={() => setView('listView')}
                    >
                      <ListViewIcon />
                    </ToggleButton>
                    <ToggleButton
                      value="gridView"
                      aria-label="gridView"
                      className={classes.toggleButtonGrid}
                      onClick={() => setView('gridView')}
                    >
                      <GridViewIcon />
                    </ToggleButton>
                  </ToggleButtonGroup>
                </Stack>
              </div>
            </Grid>
          </Grid>
          <Grid container className={classes.resourceContainer}>
            {view === 'listView'
              ? (
                <Grid container className={classes.resourcesListView}>
                  {setLocalStorage('myResourceView', 'listView')}
                  <ListViewTeacher
                    tableData={(isLC || assignedClassrooms.length > 0) ? studentResourceTeacherView : []}
                    totalRows={totalRows}
                    selectedResource={setSelectedResource}
                    folderName={folderName}
                    setFolderName={setFolderName}
                    typeResources="student"
                    courseSelectedvalue={isSelectedvalue}
                    pageNext={nextPage}
                    pagenum={pagenum}
                    setPagenum={setPagenum}
                    isFetching={isFetching}
                    setIsFetching={setIsFetching}
                    setResource={setStudentResourceTeacherView}
                    setLoading={setLoading}
                    setSortOrder={setSortOrder}
                    sortOrder={sortOrder}
                    setSortBoth={setSortBoth}
                    sortBoth={sortBoth}
                    resourcesData={studentResource}
                    isSysAdminOrSuperUser={isSysAdminOrSuperUser}
                  />
                </Grid>
              )
              : (
                <Grid container className={classes.resourcesGridView}>
                  {setLocalStorage('myResourceView', 'gridView')}
                  <GridView
                    data={(isLC || assignedClassrooms.length > 0) ? studentResource : []}
                    totalRows={totalRows}
                    selectedResource={setSelectedResource}
                    folderName={folderName}
                    setFolderName={setFolderName}
                    typeResources="student"
                    courseSelectedvalue={isSelectedvalue}
                    pageNext={nextPage}
                    pagenum={pagenum}
                    setPagenum={setPagenum}
                    isFetching={isFetching}
                    setIsFetching={setIsFetching}
                    setResource={setStudentResource}
                    setLoading={setLoading}
                  />
                </Grid>
              )}
          </Grid>
        </Stack>
      </div>
      {(loading && assignedClassrooms.length !== 0 && studentResource.length === 0) ? (
        <Grid>
          <Loader message={t('LOADING')} />
        </Grid>
      ) : ((isTeacher && !isLC && assignedClassrooms.length === 0) || studentResource.length === 0) && (
        <Grid container className={classes.noData}>
          <Grid item xs={12}>
            <DoNotDisturbIcon />
          </Grid>
          <Grid item xs={12}>
            <Typography variant="subtitle2" color="text.secondary">
              {t('NO_DATA')}
            </Typography>
          </Grid>
        </Grid>
      )}
    </div>
  );
}
