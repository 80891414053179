const ROUTES = {
  DASHBOARD: '/dashboard',
  STUDENTS_LOGS: '/dashboard/student/audit-logs',
  GET_REGION_MANAGER_LOGS: '/dashboard/region-manager/audit-logs',
  STUDENTS_VIEW: '/dashboard/students-view',
  EXAMCENTER_LOGS: '/dashboard/examcenter-audit-logs',
  ROLE_MANAGER_LOGS: '/dashboard/role-manager-log',
  LOCATION_MANAGER_LOGS: '/dashboard/location-logs',
  COURSE_MANAGER_LOGS: '/dashboard/course-logs',
  LOCATION_COORDINATOR_VIEW: '/dashboard/location-coordinator-view',
  LOCATION_COORDINATOR_STUDENT_TAB: '/dashboard/location-coordinator/students',
  REGISTER: '/register',
  BACKLOGREGISTER: '/dashboard/backlog-register',
  PSTU_CONFIG: '/dashboard/admin-settings/pstu-configuration',
  LOGIN: '/login',
  LOCATIONS: '/search-locations',
  FORGOT_PASSWORD: 'forgot-password',
  RESET_PASSWORD: '/reset-password',
  PAYPAL_CHECKOUT: '/register/paypal-checkout',
  PAYPAL_SUCCESS: '/success',
  PAYPAL_CANCEL: '/cancel',
  STUDENT_DASHBOARD: '/dashboard/students',
  REGISTRATION_DATES: '/dashboard/registration-dates',
  GRADING: '/dashboard/grading',
  SETTING_TERMS_AND_CONDITIONS: '/dashboard/admin-settings/terms-conditions',
  REPORTS: '/dashboard/admin-settings/reports',
  UNREGISTERED_STUDENTS: '/dashboard/admin-settings/unregistered-students',
  WELCOME_EMAIL: '/dashboard/admin-settings/welcome-email',
  // EMAIL: '/dashboard/email',
  HELP_SITE: '/dashboard/help-site',
  LOCATION_COORDINATOR_HELP_SITE: '/dashboard/location-coordinator-view/help-site',
  ADMIN_EMAIL: '/dashboard/email',
  RC_ANNOUNCEMENTS: '/dashboard/announcements',
  LOCATION_COORDINATOR_EMAIL: '/dashboard/location-coordinator-view/email',
  LOCATION_COORDINATOR_TEACHERS_EMAIL: '/dashboard/location-coordinator-view/teachers-email',
  USER_MANAGER_VIEW: '/dashboard/user-manager-view',
  ROLE_MANAGER_VIEW: '/dashboard/role-manager-view',
  REGION_MANAGER_VIEW: '/dashboard/region-manager-view',
  LOCATION_MANAGER_VIEW: '/dashboard/location-manager-view',
  COURSE_MANAGER_VIEW: '/dashboard/course-manager-view',
  EXAM_CENTER_MANAGER_VIEW: '/dashboard/exam-center-manager-view',
  ADMIN_DASHBOARD: '/dashboard/admin-dashboard',
  LC_CLASSES: '/dashboard/location-coordinator-view/classes',
  LC_STUDENTS: '/dashboard/location-coordinator-view/students',
  LC_ANNOUNCEMENTS: '/dashboard/location-coordinator-view/announcements',
  LC_SECTION_SYNC: '/dashboard/location-coordinator-view/section-sync',
  LC_MARKS_SYNC: '/dashboard/location-coordinator-view/marks-sync',
  LOCATION_COORDINATOR_STUDENTS_DASHBOARD:
    '/dashboard/location-coordinator-view/student-dashboard',
  TEACHERS_RESOURCES: '/dashboard/teacher-resources',
  STUDENTS_RESOURCES: '/dashboard/student-resources',
  LOCATION_COORDINATOR_TEACHERS_RESOURCES:
    '/dashboard/location-coordinator-view/teacher-resources',
  LOCATION_COORDINATOR_STUDENTS_RESOURCES:
    '/dashboard/location-coordinator-view/student-resources',
  ACADEMIC_DATES: '/dashboard/academic-dates',

  // teacher routes here
  TEACHER_VIEW: '/teacher-view',
  TEACHER_DASHBOARD: '/teacher-view/dashboard',
  TEACHER_ATTECNDANCE_VIEW: '/teacher-view/attendance',
  MY_CLASS: '/teacher-view/my-class',
  TEACHER_SYNC_VIEW: '/teacher-view/sync-view',
  TEACHER_VIEW_EMAIL: '/teacher-view/email',
  TEACHER_PANEL_STUDENTS_RESOURCES:
    '/teacher-view/student-resources',
  TEACHER_PANEL_TEACHERS_RESOURCES:
    '/teacher-view/teacher-resources',

  // Academin Panel
  ACADEMIC_PANEL: '/dashboard/academic-panel',
  ACADEMIC_PANEL_SCHEMA: '/dashboard/academic-schema',

  HOMEWORK_SCHEMA: '/dashboard/homework-schema',
  HOMEWORK_ASSIGNMENTS: '/dashboard/homework-assignments',
  QUIZ_SCHEMA: '/dashboard/quiz-schema',

  EXPORTED_SHEET: '/dashboard/exported-sheet',

  CURRICULAR_ACTIVITIES_SCHEMA: '/dashboard/curricular-activities-schema',
  ASSIGNMENTS_SCHEMA: '/dashboard/assignments-schema',

  // WEBSITE DONATION
  DONATE: '/donate',

  PAYMENT_POC: '/paymentpoc',
};

const APIROUTES = {
  CREATE_ORDER_STRIPE: '/createorder/stripe',
  CREATE_ORDER_SQUARE: '/createorder/square',
  // registration
  PUT_TEMP_REGISTER_LOG: '/update-temp-register-log',
  GET_WELCOME_EMAIL: '/welcome-email-template',
  GET_VALUE_BY_TYPE: '/get-value-by-type',
  POST_REGISTER: '/register',
  GET_ALL_COURSES: '/courses',
  GET_ACTIVE_REGION: '/active-regions',
  GET_COURSES_AGE_LOCATION: '/locationCourseAgeCheck',
  GET_LOCATION_COURSE: '/locationCourse',
  GET_LOCATION_COURSE_BACKLOG_REGISTRATION: '/CoursesBacklogRegistration',
  GET_EDIT_EXAM_DETAILS: '/studentExamCenterDetails',
  GET_ACTIVE_EXAM_CENTERS: '/getActiveExamCenters',
  UPDATE_STUDENT_EXAM_CENTER: '/updateStudentExamCenter',
  GET_ACADEMIC_GRADES: '/grades',
  GET_REGISTRATION_TEMP_INFO: '/registrationTempInfo',
  POST_EDIT_GRADE: '/grades',
  GET_EXTRA_CURRICULAR_ACTIVITIES: '/extracurricular',
  GET_VOLUNTEERS: '/volunteering',
  GET_DONATIONS: '/donation',
  GET_EMAIL_AVAILABILITY: '/email-availablity',
  HEAR_ABOUTS_US: '/advertise',
  GET_LOCATIONS: 'location',
  GET_LOCATIONS_BY_COURSE: '/course/locations',
  GET_LOCATIONS_BY_REGION: '/locationsWRTRegion',
  POST_FEE_STRUCTURE: '/getFeeStructure',
  POST_FEE_STRUCTURE_SPOT_REG: '/getFeeStructureSpotRegistration',
  POST_CREATE_COURSE: '/createCourse',
  POST_EDIT_COURSE: '/editCourse',
  GET_STUDENTS_BY_EMAIL: '/getStudentsByEmail',
  GET_STUDENTS_BY_PHONE: '/getStudentsByPhone',
  // login
  GET_TOKEN_FOR_LOGIN: '/getToken',
  POST_RESET_PASSWORD: '/resetPassword',
  POST_FORGOT_PASSWORD: '/forgotPassword',
  CAPTURE_ORDER: '/payment/captureOrder',
  CAPTURE_DONATION: '/payment/donationCaptureOrder',
  CAPTURE_WEBSITE_DONATION: '/payment/websiteDonationCaptureOrder',
  CREATE_ORDER: 'payment/createOrder',
  CREATE_DONATION_ORDER: 'payment/donationOrder',
  CREATE_WEBSITE_DONATION_ORDER: '/payment/websiteDonationOrder',
  CAPTURE_DONATION_ORDER: 'payment/donationCaptureOrder',
  GET_FEE_FOR_ENROLL: '/getFeeStructure/nextEnroll',
  // student
  GET_ALL_STUDENTS: '/students',
  GET_STUDENTS_BY_REGION: '/regionstudents',
  EDIT_STUDENT: '/student/edit',
  UPDATE_STUDENT_INFO: '/student/update-info',
  UPDATE_SIBLING_INFO: '/student/sibling/update-info',
  MOVE_STUDENT: '/move-student',
  GET_STUDENT_LOGS: '/change-logs',
  GET_STUDENT_LOG_DETAIL: '/change-logs-detail',
  GET_REGION_MANAGER_LOGS_DETAIL: '/region-log-detail',
  GET_LOCATION_MANAGER_LOGS_DETAIL: '/locationChangeLogDetails',
  GET_COURSE_MANAGER_LOGS_DETAIL: '/course/changeLogDetails',
  GET_STUDENT_SECTIONS: '/student-section',
  GET_STUDENT_PAYMENT_INFO: '/student-payment-info',
  EDIT_PARENT: '/parent/edit',
  UPDATE_PARENT_INFO: '/parent/update-info',
  GET_STUDENT_ACCOUNT_DETAILS: '/student-account-details-manabadi',
  GET_STUDENT_DETAILS_BY_YEAR: '/student-course-details',
  ADD_SIBLING: '/user/sibling',
  ADD_FILTER_VIEW: '/filter-view',
  GET_FILTER_VIEWS: '/filter-view-list',
  GET_ALL_FILTERS: '/filter',
  GET_ALL_FILTERS_REGION: '/getAllFilterRegion',
  GET_NEXT_ENROLL_COURSE: '/nextEnrollCourse ',
  GET_STUDENT_MARKS: '/student-marks',
  GET_ENROLLMENT_HISTORY: '/student-enrollment-history',
  GET_STUDENT_EXAM_DETAILS: '/studentExamCenterDetails',
  // Location coordinator
  GET_CLASSROOMS: '/classroom-list',
  ADD_CLASSROOMS_SECTIONS: '/classroom-list/update-section',
  ADD_TEACHER: '/bulk-create-teacher',
  GET_ACADEMIC_YEARS: '/getAcademicYears',
  GET_ACADEMIC_YEARS_STATUS: '/getAcademicYearsStatus',
  POST_ACADEMIC_YEARS_STATUS: '/postAcademicYearsStatus',
  GET_ACADEMIC_YEARS_INFO: '/getAcademicInfoYears',
  DELETE_GOOGLE_SHEET_BY_ID: '/deleteGoogleSheet',

  GET_ASSIGNED_LOCATIONS: '/getLocationAndYearInDropdown',
  GET_ALL_TEACHERS: '/getAllTeacher             ',
  POST_ACADEMIC_INFO: '/academicInfo/createRecord',
  UPDATE_ACADEMIC_INFO: '/academicInfo/updateRecord',
  GET_STUDENTS_BY_LOCATION: '/students-by-location',
  STUDENT_EXPORT: '/students/export',
  EMAIL_TEMPLATE: '/email-template',
  GET_SECTION_DETAILS: '/getSectionDetails',
  GET_TEACHER_RESOURCE: '/getTeacherResource',
  GET_STUDENT_RESOURCE_WRTCOURCE: '/getStudentResourceWRTCourse',
  GET_LOCATION_STUDENT_DASHBOARD: '/locationStudentDashboard',
  GET_TEACHERS: '/locationDashboard/notScored/teachers',
  GET_COURSES_MANAGER: '/getAllcourses',
  GET_COURSES_MANAGER_DROPDOWN: '/getAllCourses',
  SEND_ANNOUNCEMENT: '/sendAnnouncement',
  GET_RECIPIENTS_COUNT: '/getRecipientsCount',
  UPDATE_STUDENT_MARKS: 'student-marks',
  SAVE_STUDENT_BONUS_MARKS: '/bonusMarks',
  UPDATE_HOMEWORK_MARKS: 'student-homework-marks',
  GET_ANNOUNCEMENT_LIST: '/getEmailAnnouncementList',
  GET_ANNOUNCEMENT_LIST_RC: '/getEmailAnnouncementListRegionCoordinator',
  GET_SUBSTITUTION_TAGS: '/getSubstitutionTags',
  GET_ANNOUNCEMENT_BY_ID: '/getEmailAnnouncemnet',
  GET_EMAIL_FILTERS: '/getEmailFilters',
  GET_RECIPIENTS_BY_FILTER: '/recipientsWRTFilter',
  GET_HELP_VIDEOS: '/helpVideos',
  UPDATE_CSV_SECTION: '/updateSection',
  // Academic info
  GET_ACADEMIC_INFO: '/academicInfo',
  GET_ACADEMIC_INFO_ID: '/academicInfoId',
  GET_GOOGLE_CLASS_INFO_ID: '/googleClassInfoId',
  GET_ACADEMIC_YEAR_DATES_BY_YEAR: '/academic-year-dates-by-year',
  GET_ACADEMIC_YEAR_DATES: '/academic-year-dates',
  GET_CURRENT_ACADEMIC_YEAR: '/getCurrentAcademicYear',
  GET_CURRENT_DOB_CUTOFF: '/getCurrentDobCutoff',
  GET_REGISTRATION_ACADEMIC_YEAR: '/getRegistrationAcademicYear',
  // User Manager
  GET_ALL_USER: '/userManager/getAllUser',
  SAVE_IMPORT_EXPORT_TIME: '/saveSyncTime',
  GET_IMPORT_EXPORT_TIME: '/getSyncTime',
  GET_ALL_USER_ROLES: '/userManager/getAllUserRoles',
  ADD_USER: '/userManager/addUser',
  EDIT_USER: '/userManager/editUser',
  GET_FILTER_LIST_USER_MANAGER: 'filters/user-manager',
  GET_ALL_EMAIL_USER: '/userManager/getAllEmails',
  GET_ASSIGNED_USER_ROLES: '/userManager/getAssigneUserRoles',
  // Role Manager
  GET_ALL_ROLE: '/role',
  GET_ALL_ROLE_PERMISSIONS: '/permission',
  GET_ROLE_CHANGE_LOGS_LIST: '/roleChangeLog',
  GET_ROLE_CHANGE_LOGS_DETAILS: '/roleChangeLogDetails',
  // Region Manager
  GET_ALL_REGION: '/regions',
  GET_COUNTRIES: '/country',
  GET_REGION_COORDINATORS: '/region-coordinator',
  GET_GEO_REGION: 'georegion',
  // Location Manager
  GET_ALL_LOCATIONS: '/location',
  GET_ADMIN_LOCATIONS: '/location-list',
  ADD_UPDATE_LOCATION: '/location',
  GET_ALL_EXAM_CENTERS: '/location/examcenter',
  GET_ALL_LOCATION_COORODINATORS: 'location/coordinators',
  // Admin
  GET_UPLOADFILE: '/uploadFile',
  GET_UPLOADVIDEO: '/uploadVideo',
  ADD_HELP_VIDEO: '/helpVideos/add',
  CREATE_STUDENT_RESOURCE: '/createStudentResource',
  CANCEL_ENROLLMENT: '/academic-panel/cancel',
  DEACTIVATE_RESOURCE: '/deactivateResource',
  UPDATE_RESOURCE: '/updateStudentResource',
  IMPERSONATE_USER: '/impersonateUser',
  UPDATE_HELP_VIDEO: '/helpVideos/update',
  POST_STUDENT_MARKS_BY_QUARTER: '/student-marks-by-quarter',
  GET_REGION_MANAGER_LOGS: '/region-logs',
  GET_LOCATION_MANAGER_LOGS: '/locationChangeLog',
  GET_COURSE_MANAGER_LOGS: '/course/changeLog',
  GET_PARENT: '/student/parentInfo',
  GET_STUDENT: '/student/studentInfo',
  GET_PAYMENT: '/student/paymentInfo',
  GET_EXAM_CENTER_MANAGER_LOGS: '/getLogsExamCenter',
  GET_EXAM_CENTER_MANAGER_LOGS_DETAIL: '/getLogDetailsExamCenter',

  // Teacher View
  STUDENTS_BY_YEAR_AND_CLASSID: '/students-by-year-and-classId',
  ADD_REGION_DATA: '/createregion',
  EDIT_REGION_DATA: '/region',
  DEACTIVATE_HELP_VIDEO: '/helpVideos/deactivate',
  STUDENT_BY_LOCATION: '/student-by-location',
  CLASSES_TIMINGS: '/teacherPanel/quarter/classesTimings',
  CLASSES_TIMINGS_STUDENTS: '/teacherPanel/quarter/classesTimingsStudents',
  CLASSES_BY_ACADEMIC_YEAR: '/classes-by-academic-year',
  GET_TEACHER_PANEL_STUDENTS: '/teacherPanel/class/students',
  MAKE_UP_MARKS: 'makeUpMarks',
  POST_TEACHER_DASH_ATTEND_DETAILS: '/teacherDashboard/attendanceDetails',
  GET_STUDENTS_BY_LOCATION_MARKS: 'students-by-location-marks',

  // academic panel
  GET_ACADEMIC_SCHEMA: '/academic-panel',
  GET_QUARTER_DETAILS: '/academic-year-dates-by-year',
  GET_ALL_TOPICS: '/academicPanel/getAssignmentTopics',
  GET_GRADES: '/grades',
  GET_ASSIGNMENT_PER_COURSE: '/academicPanel/getAssignments',
  GET_ASSIGNMENT_BY_CLASSROOM: '/academicPanel/getAssignmentByClassroom',
  GET_ASSIGNMENT_PER_COURSE_YEAR_LOCATION: '/academicPanel/getAssignmentsCourse&Year&Location',
  ADD_ASSIGNMENT_PER_COURSE: '/academicPanel/addAssignment',
  DELETE_ASSIGNMENT_BY_ID: '/academicPanel/deleteAssignments',
  EDIT_ASSIGNMENT_BY_ID: '/academicPanel/editAssignment',
  EDIT_ASSIGNMENT_BY_GOOGLE_ID: '/academicPanel/editAssignmentByGoogleId',
  DELETE_ASSIGNMENT_BY_GOOGLE_ID: '/academicPanel/deleteAssignmentByGoogleId',
  DELETE_SELECTED_ASSIGNMENTS_BY_GOOGLE_ID: '/academicPanel/deleteSelectedAssignmentsByGoogleId',
  EDIT_OLD_ASSIGNMENT_BY_ID: '/academicPanel/editOldAssignment',
  PUBLISH_ASSIGNMENT_BY_ID: '/academicPanel/publishAssignment',
  REPUBLISH_OLD_ASSIGNMENT_BY_ID: '/academicPanel/republishOldAssignment',
  GET_CLASSES_AMOUNT: '/academicPanel/getClasses',
  GET_EXPORTED_SHEETS: '/getExportedSheets',
  ADD_EXPORTED_SHEETS: '/exportedSheets',

  MARK_STUDENT_ATTENDANCE: '/teacherPanel/markStudentAttendance',
  UPDATE_TERMS_CONDITIONS: '/terms-conditions',

  // homework-panel
  GET_HOMEWORK_SCHEMA: '/getHomeworkPanelWRTFilters',
  SAVE_HOMEWORK_SCHEMA: '/homework-panel',
  SAVE_QUIZ_SCHEMA: '/quiz-schema',
  UPDATE_QUIZ_MARKS: '/quiz-schema-marks',

  // Exam Center Manager
  // GET_EXAM_CENTER_MANAGER: '/getActiveExamCenters',
  GET_EXAM_CENTER_MANAGER: '/exam-center',
  POST_CREATE_EXAM_CENTER: '/createExamCenter',
  POST_EDIT_EXAM_CENTER: '/updateExamCenter',
  GET_UPLOADFILE_EMAIL: '/uploadFileEmail',

  // Curricular Activities Schema
  GET_CURRICULAR_ACTIVITIES_SCHEMA: '/attendence-activities-schema',
  CREATE_CURRICULAR_ACTIVITIES_SCHEMA: '/attendence-activities-schema',
  UPDATE_CURRICULAR_ACTIVITIES_SCHEMA: '/attendence-activities-schema',
  UPDATE_CURRICULAR_ACTIVITIES_MARKS: '/attendence-activities-marks',

  // Assignments Schema
  GET_ASSIGNMENTS_SCHEMA: '/assignment-schema',
  CREATE_ASSIGNMENTS_SCHEMA: '/assignment-schema',
  UPDATE_ASSIGNMENTS_SCHEMA: '/assignment-schema',
  UPDATE_ASSIGNMENTS_MARKS: '/assignment-schema-marks',

  // Reports
  DAILY_REPORTS: '/dailyReports',
  GET_ALL_REPORTS: '/getAllReports',
  GET_ALL_REPORTS_BY_TYPE: '/getAllReportsByType',
  POST_UNREGISTERED_STUDENTS_REPORT_BY_YEAR: '/newUnregisteredStudentsReportByYear',
  GET_REPORT_BY_ID: '/getReportById',

  // PSTU Faqs
  GET_PSTU_FAQS: '/getPstuFaqs',
  CREATE_PSTU: '/savePstuFaqs',
  UPDATE_PSTU: '/updatePstuFaqs',
  // maps
  PLACE_INFO: '/placeInformation',
  // repeat status
  UPDATE_REPEAT_STATUS: '/updateStudentEnrolledCourseStatus',
};

export { ROUTES, APIROUTES };
