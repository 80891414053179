import moment from 'moment';
import { getCourseByAgeLocationService, getCoursesForBacklogRegistratrion } from './auth';

const formatDateToString = (date) => {
  // Check if the date is in ISO format (ends with Z)
  if (typeof date === 'string' && date.endsWith('Z')) {
    const utcDate = new Date(date);
    return `${utcDate.toISOString().slice(0, 10)} 00:00:00`;
  }
  // Handle local date
  const localDate = new Date(date);
  return `${localDate.getFullYear()}-${String(localDate.getMonth() + 1).padStart(2, '0')}-${String(localDate.getDate()).padStart(2, '0')} 00:00:00`;
};

const getCourseByAge = async (dob, location, isAdminUser) => {
  const dobDate = new Date(dob);
  const year = dobDate.getFullYear();
  const currentYear = new Date().getFullYear();
  // Simple date validation
  if (!Number.isNaN(dobDate.getTime()) && year >= 1900 && year <= currentYear) {
    const payload = {
      locationId: location,
      dateOfBirth: formatDateToString(dob),
      isAdminUser,
    };
    const response = await getCourseByAgeLocationService(payload);
    return response.data.map((option) => option.course);
  }
  return [];
};

const getLocationsForBacklogRegistration = async (dob, location) => {
  if (location) {
    const payload = {
      locationId: location,
      dateOfBirth: moment(dob).format('YYYY-MM-DD HH:mm:ss'),
    };
    const response = await getCoursesForBacklogRegistratrion(payload);
    return response.data.map((option) => option.course);
  }
  return [];
};

export {
  getCourseByAge,
  getLocationsForBacklogRegistration,
};
