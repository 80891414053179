const titleOptions = [
  {
    name: ' ', id: '',
  },
  {
    name: 'MR', id: 'Mr',
  },
  {
    name: 'MRS', id: 'Mrs',
  },
  {
    name: 'MISS', id: 'Miss',
  },
  {
    name: 'MS', id: 'Ms',
  },
];

const genderOptions = [
  {
    name: 'Male', id: 'Male',
  },
  {
    name: 'Female', id: 'Female',
  },
];

export {
  titleOptions,
  genderOptions,
};
